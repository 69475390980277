import { AlertQueue } from 'app/modules/alerts/models';
import { InvestigationChecklist } from 'app/modules/investigationChecklist/models';

export enum CustomChecklistActionTypes {
  SUBMIT_CUSTOM_CHECKLIST_SUCCESS = '@@checklist/SUBMIT_CUSTOM_CHECKLIST_SUCCESS',

  RETRIEVE_CUSTOM_CHECKLIST = '@@checklist/RETRIEVE_CUSTOM_CHECKLIST',
  RETRIEVE_CUSTOM_CHECKLIST_SUCCESS = '@@checklist/RETRIEVE_CUSTOM_CHECKLIST_SUCCESS',

  RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION = '@@checklist/RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION',
  RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION_SUCCESS = '@@checklist/RETRIEVE_CUSTOM_CHECKLIST_SUBMISSION_SUCCESS',

  // update one key in the custom checklist content - TODO, remove this one
  UPDATE_CUSTOM_CHECKLIST_ITEM = '@@checklist/UPDATE_CUSTOM_CHECKLIST_ITEM',
  // update the whole content
  SET_CUSTOM_CHECKLIST_CONTENT = '@@checklist/SET_CUSTOM_CHECKLIST_CONTENT',
  // update custom checklist submission id
  SET_CUSTOM_CHECKLIST_SUBMISSION_ID = '@@checklist/SET_CUSTOM_CHECKLIST_SUBMISSION_ID',
}

export enum CustomChecklistTypes {
  ALERT_CHECKLIST = 'ALERT_CHECKLIST',
  CASE_CHECKLIST = 'CASE_CHECKLIST',
}

export enum CustomChecklistValidationFieldsTypes {
  ALL = 'ALL',
  ANY = 'ANY',
  NONE = 'NONE',
  REQUIRED = 'REQUIRED',
}

type CustomChecklistAutofillTypes = 'rule' | 'entity';

export interface CustomChecklistAutofill {
  type: CustomChecklistAutofillTypes;
  key: string;
}

export interface CustomChecklistSubmissionPayload {
  checklist_id: number;
  article_id: number;
  content: JSONObject;
}

export interface CustomChecklistSearchPayload {
  alert_queue_id?: number | null;
  type: CustomChecklistTypes;
}

export interface CustomChecklistUpdateItemPayload {
  key: string;
  value: any;
}

export interface CustomChecklistValidations {
  message?: string;
  fields?: string | string[];
}

export interface CustomChecklistContent {
  [key: string]: string | boolean | (string | null)[] | object | undefined;
}
export interface CustomChecklistDefinition {
  key: string;
  type?: string;
  label?: string;
  options?: ChecklistSelectOption[];
  autofill?: CustomChecklistAutofill;
  required?: boolean;
  validations?: CustomChecklistValidations[];
  items?: CustomChecklistDefinition[];
  enabled_by?: string;
  placeholder?: string;
  ai_investigations_enabled?: boolean;
}

export interface CustomChecklistFieldDefinition {
  name: string;
  type?: string;
}

export interface ChecklistSelectOption {
  key: string;
  text: string;
  value: string;
}

export interface CustomChecklistHistoricalSubmission {
  id: number;
  alert_queue: AlertQueue;
  content: CustomChecklistContent;
  custom_checklist: InvestigationChecklist;
  modified_at: string;
}

export interface CustomChecklistState {
  submissionId?: number;
  content: CustomChecklistContent;
  checklistId: number;
  definition?: CustomChecklistDefinition[];
  isValidated: boolean;
  modified_at: string;
  name: string;
  validations: CustomChecklistValidations;
  history: CustomChecklistHistoricalSubmission[];
}
