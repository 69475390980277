import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SettingsActionTypes } from 'app/modules/settings/models';

// Actions
import {
  createSarNarrative as createSarNarrativeAction,
  createSarNarrativeSuccess,
} from 'app/modules/settings/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// APIs
import { createSarNarrative as createSarNarrativeApi } from 'app/shared/api/settings';

const rootAction = SettingsActionTypes.CREATE_SAR_NARRATIVE;
function* createSarNarrative({
  payload,
}: ReturnType<typeof createSarNarrativeAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Unable to create new SAR narrative. Check that you did not choose an existing tag.',
    request: call(createSarNarrativeApi, payload),
    success: function* onSuccess(result) {
      yield put(createSarNarrativeSuccess(result));
      yield put(sendSuccessToast('Narrative created successfully.'));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchCreateSarNarrative() {
  yield takeLatest(rootAction, createSarNarrative);
}
