import {
  U21Section,
  U21Table,
  U21TableColumn,
  U21TablePreference,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';
import {
  CATEGORY_COLUMN,
  RISK_CATEGORIES_COLUMN_CONFIG,
} from 'app/modules/watchlists/columns';
import { useGetAdverseMediaRiskCategories } from 'app/modules/watchlists/queries/useGetAdverseMediaRiskCategories';
import { DEFAULT_TABLE_PREFRENCES } from 'app/shared/u21-ui/components/display/table/constants';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { useLocalStorage } from 'app/shared/hooks/useLocalStorage';
import { useMemo } from 'react';
import { IconFilter } from '@u21/tabler-icons';
import { selectAdverseMediaFilters } from 'app/modules/watchlists/selectors';
import { setAdverseMediaFilters } from 'app/modules/watchlists/sliceWatchlists';
import { useDispatch, useSelector } from 'react-redux';
import { AdverseMediaRiskCategory } from 'app/modules/watchlists/models';
import { NEWS_CATEGORY_OTHERS } from 'app/modules/watchlists/constants';

interface OwnProps {
  alertId: number;
}

export const AdverseMediaRiskCategoriesSection = ({ alertId }: OwnProps) => {
  const dispatch = useDispatch();
  const filters = useSelector(selectAdverseMediaFilters);

  const {
    data: riskCategories = { risk_categories: [], count: 0 },
    isLoading: riskCategoriesLoading,
    isError,
    isRefetching,
    refetch,
  } = useGetAdverseMediaRiskCategories(alertId);

  const [tablePreferences, setTablePreferences] =
    useLocalStorage<U21TablePreference>(
      LocalStorageKeys.ADVERSE_MEDIA_RISK_CATEGORY_TABLE_PREFERENCES,
      {
        pageSize: DEFAULT_TABLE_PREFRENCES.pageSize,
        sortBy: [{ desc: true, id: 'rule_threshold' }],
        columnWidths: {},
      },
    );

  const columns = useMemo<U21TableColumn<AdverseMediaRiskCategory, any>[]>(
    () => [
      ...RISK_CATEGORIES_COLUMN_CONFIG,
      {
        id: 'filter',
        type: 'button',
        width: 100,
        minWidth: 100,
        disableResizing: true,
        cellProps: (row) =>
          row.category === NEWS_CATEGORY_OTHERS
            ? {
                hidden: true,
              }
            : {
                children: 'View',
                color: 'primary',
                startIcon: <IconFilter />,
                onClick: () => {
                  dispatch(
                    setAdverseMediaFilters([
                      ...filters.filter((f) => f.key !== CATEGORY_COLUMN.id),
                      {
                        operator: 'is_one_of',
                        value: [row.category],
                        key: CATEGORY_COLUMN.id,
                      },
                    ]),
                  );
                },
              },
      },
    ],
    [dispatch, filters],
  );

  return (
    <U21Section
      title={
        <U21TitleCountLabel count={riskCategories.count} label="category">
          Risk Categories
        </U21TitleCountLabel>
      }
      collapsed
      collapsible
    >
      <U21Table
        columns={columns}
        data={riskCategories.risk_categories}
        loading={riskCategoriesLoading}
        noDataComponent={
          isError ? 'Something went wrong. Please try again.' : undefined
        }
        onRefresh={() => refetch()}
        refreshLoading={isRefetching}
        defaultSortBy={tablePreferences.sortBy}
        defaultPageSize={tablePreferences.pageSize}
        onPreferenceChange={(value) => setTablePreferences(value)}
      />
    </U21Section>
  );
};
