import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { IconArrowRight } from '@u21/tabler-icons';

import { U21Divider } from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

import { SidebarTitle } from 'app/modules/sidebar/components/SidebarTitle';
import { SidebarDescription } from 'app/modules/sidebar/components/SidebarDescription';
import { SidebarAssignedTo } from 'app/modules/sidebar/components/SidebarAssignedTo';
import { SidebarStatus } from 'app/modules/sidebar/components/SidebarStatus';
import { SidebarDisposition } from 'app/modules/sidebar/components/SidebarDisposition';
import { SidebarCaseInfo } from 'app/modules/sidebar/components/SidebarCaseInfo';

import { SidebarComments } from 'app/modules/sidebar/components/SidebarComments';
import { SidebarAssociatedItems } from 'app/modules/sidebar/components/SidebarAssociatedItems';

// Action
import { retrieveSidebarCase } from 'app/modules/casesOld/actions';

// Models
import { CaseSidebarDefinition } from 'app/modules/sidebar/models';

// Selectors
import {
  selectSidebarCase,
  selectSidebarCaseLoading,
} from 'app/modules/casesOld/selectors';

// Constants
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { OldFeatureBanner } from 'app/modules/sidebar/components/GABanner';

interface OwnProps {
  data: CaseSidebarDefinition['data'];
}

export const SidebarCase = ({ data }: OwnProps) => {
  const dispatch = useDispatch();
  const sidebarCase = useSelector(selectSidebarCase);
  const loading = useSelector(selectSidebarCaseLoading);

  useEffect(() => {
    dispatch(retrieveSidebarCase(data.id));
  }, [data.id, dispatch]);

  return (
    <U21SideMenu
      title="Case Details"
      actionButtonProps={
        data.hideActionButton
          ? undefined
          : {
              children: 'Go to case page',
              endIcon: <IconArrowRight />,
              to: ROUTES_MAP.casesId.path.replace(':id', String(data.id)),
            }
      }
      loading={loading}
    >
      <OldFeatureBanner type="CASE_SIDEBAR_REFRESH" />
      <SidebarTitle />
      <SidebarDescription />
      <SidebarAssignedTo />
      <SidebarStatus />
      <SidebarDisposition />
      <SidebarCaseInfo />

      <U21Divider horizontal />

      <SidebarAssociatedItems />
      <U21Divider horizontal />
      <SidebarComments comments={sidebarCase.comments} />
    </U21SideMenu>
  );
};
