import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';
import makeActions from 'app/shared/sagas/make-actions';

// Actions
import {
  editSarNarrative as editSarNarrativeAction,
  editSarNarrativeSuccess,
} from 'app/modules/settings/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Models
import { SettingsActionTypes } from 'app/modules/settings/models';

import { editSarNarrative as editSarNarrativeApi } from 'app/shared/api/settings';

// Helpers
import { combineRootActionWithId } from 'app/shared/helpers';

const rootAction = SettingsActionTypes.EDIT_SAR_NARRATIVE;
function* editSarNarrative({
  payload,
}: ReturnType<typeof editSarNarrativeAction>) {
  // to keep track of individual loading states
  const formattedRootAction = combineRootActionWithId(rootAction, payload.id);
  const formattedActions = makeActions(formattedRootAction);
  const config = {
    rootAction: formattedRootAction,
    errorToastMessage:
      'Unable to edit SAR narrative. Check that you did not choose an existing tag.',
    request: call(editSarNarrativeApi, payload),
    success: function* onSuccess(result) {
      yield put(editSarNarrativeSuccess(result));
      yield put(sendSuccessToast(`Narrative edited successfully.`));
      // to cancel individual loading state
      yield put(formattedActions.success({}));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchEditSarNarrative() {
  yield takeLatest(rootAction, editSarNarrative);
}
