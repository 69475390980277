import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { U21Section, U21Loading } from 'app/shared/u21-ui/components';
import { AuditTrail as NewAuditTrail } from 'app/modules/auditService/components/AuditTrail';
import AuditTrailSection from 'app/shared/auditTrail/components/AuditTrailSection';

// Selectors
import {
  selectAlert,
  selectAlertAuditTrail,
  selectAlertAuditTrailLoading,
} from 'app/modules/alerts/selectors';

// Actions
import { retrieveAlertAuditTrail } from 'app/modules/alerts/actions';
import { selectComprehensiveAuditTrailsEnabled } from 'app/shared/featureFlags/selectors';

export const AuditTrail = () => {
  const dispatch = useDispatch();

  const alert = useSelector(selectAlert);
  const auditTrail = useSelector(selectAlertAuditTrail);
  const auditTrailLoading = useSelector(selectAlertAuditTrailLoading);
  const comprehensiveAuditTrailsFlag = useSelector(
    selectComprehensiveAuditTrailsEnabled,
  );

  useEffect(() => {
    if (alert.id > 0) {
      dispatch(retrieveAlertAuditTrail(alert.id));
    }
  }, [alert.id, dispatch]);

  if (auditTrailLoading) {
    return <U21Loading loading variant="graphic" />;
  }

  return (
    <U21Section title="Audit Trail">
      {comprehensiveAuditTrailsFlag ? (
        <NewAuditTrail assetId={String(alert.id)} assetType="alert" />
      ) : (
        <AuditTrailSection trail={auditTrail} />
      )}
    </U21Section>
  );
};
