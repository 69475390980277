import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Components
import { Item } from 'app/shared/u21-ui/components/dashboard';
import { U21Subsection, U21Typography } from 'app/shared/u21-ui/components';
import { SidebarFieldValue } from 'app/modules/sidebar/components/SidebarFieldValue';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';
import {
  DataSettingsKeyType,
  FormattedData,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { SidebarDataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';

// Styles
import styled from 'styled-components';
import { DataSettingsState } from 'app/modules/dataSettings/types';

interface SidebarCustomDataProps {
  data?: FormattedData;
}

const renderNestedObjectData = (
  data: FormattedData,
  dataSettingsById: DataSettingsState['dataSettingsById'],
) => {
  return Object.keys(data || {}).reduce((acc: ReactElement[], id: string) => {
    const dataSetting = dataSettingsById[id];
    const formattedDataItem = data[id];
    if (
      formattedDataItem.formatted_value &&
      dataSetting?.key_type === DataSettingsKeyType.CUSTOM
    ) {
      acc.push(
        <Item key={id}>
          <SidebarDataSettingDataLabel dataSetting={dataSetting} />
          <SidebarFieldValue>
            <DataSettingDataDisplay
              formattedData={data}
              dataSetting={dataSetting}
            />
          </SidebarFieldValue>
        </Item>,
      );
    }
    return acc;
  }, []);
};

export const SidebarCustomDataOld: FC<SidebarCustomDataProps> = ({ data }) => {
  const dataSettingsById = useSelector(selectDataSettingsById);
  const customDataItems = renderNestedObjectData(data || {}, dataSettingsById);
  return (
    <>
      <StyledU21Typography variant="subtitle2">Custom Data</StyledU21Typography>
      {customDataItems.length > 0 ? (
        customDataItems
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No custom data found
        </U21Typography>
      )}
    </>
  );
};

export const SidebarCustomDataNew: FC<SidebarCustomDataProps> = ({ data }) => {
  const dataSettingsById = useSelector(selectDataSettingsById);
  const customDataItems = renderNestedObjectData(data || {}, dataSettingsById);

  return (
    <U21Subsection collapsible collapsed shaded title="Custom Data">
      {customDataItems.length > 0 ? (
        customDataItems
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No custom data found
        </U21Typography>
      )}
    </U21Subsection>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-top: 8px;
  margin-bottom: 8px;
`;
