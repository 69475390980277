import {
  FilterOption,
  IsOneOfOperatorAndValue,
} from 'app/modules/filters/models';

import { getEntityFilterOption } from 'app/modules/filters/filters';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';

import { ActionTypeSelect } from 'app/modules/actionEvents/components/ActionTypeSelect';
import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';

export const ACTION_EVENT_FILTER_KEYS = {
  ENTITY: 'entity',
  TIMESTAMP: 'event_time',
  TYPE: 'action_type',
} as const;

export const ALL_ACTION_EVENT_FILTER_OPTIONS: FilterOption[] = [
  {
    key: ACTION_EVENT_FILTER_KEYS.ENTITY,
    ...getEntityFilterOption(),
  },
  {
    key: ACTION_EVENT_FILTER_KEYS.TIMESTAMP,
    label: 'Event time',
    type: FILTER_TYPE.DATE_RANGE,
    unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
  },
  {
    key: ACTION_EVENT_FILTER_KEYS.TYPE,
    label: 'Type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Input: ({ onChange, value }: IsOneOfOperatorAndValue<string>) => {
          return <ActionTypeSelect onChange={onChange} value={value} />;
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
    unit21DataClassifier: Unit21DataClassifier.ACTION_EVENT,
  },
];
