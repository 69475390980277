import { post, get, upload, destroy } from 'app/shared/utils/fetchr';

// Models
import {
  CreateSarPayload,
  RetrieveSarPayload,
} from 'app/modules/fincenSar/models';
import {
  SingleFileExportPayload,
  BulkFileExportPayload,
} from 'app/modules/fileExportsOld/models';

// Actions
import {
  retrieveSarList as retrieveSarListAction,
  archiveSar as archiveSarAction,
} from 'app/modules/fincenSar/actions';

// Routes
import routes from 'app/shared/utils/routes';
import { GoAMLAttachments } from 'app/modules/goAML/models';

export const createSar = (payload: CreateSarPayload) => {
  return post(routes.patronus.sarsCreate, payload);
};

export const archiveSar = (
  payload: ReturnType<typeof archiveSarAction>['payload'],
) => {
  return post(routes.patronus.sarsArchive, payload);
};

export const addGoAMLAttachments = (payload: GoAMLAttachments) => {
  const { id, files } = payload;
  const path = routes.patronus.goAMLIdAttachment.replace(':id', `${id}`);

  const formData = new FormData();
  files.forEach((file, idx) => formData.append(`file${idx}`, file));

  return upload(path, formData);
};

export const deleteGoAMLAttachment = (sarId: number, attachmentId: number) => {
  const path = routes.patronus.goAMLIdAttachmentAttachmentId
    .replace(':id', `${sarId}`)
    .replace(':attachmentId', `${attachmentId}`);
  return destroy(path);
};

export const retrieveSar = (payload: RetrieveSarPayload) => {
  const { id } = payload;
  return get(`${routes.patronus.sars}/${id}`);
};

export const retrieveSarList = (
  payload: ReturnType<typeof retrieveSarListAction>['payload'],
) => {
  return post(routes.patronus.sarsList, payload);
};

export const sendSingleSarXlsxRequest = (payload: SingleFileExportPayload) => {
  return post(routes.patronus.sarsSingleEmailXlsx, payload);
};

export const sendBulkSarsXlsxRequest = (payload: BulkFileExportPayload) => {
  return post(routes.patronus.sarsBulkXlsx, payload);
};
