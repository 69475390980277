import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SettingsActionTypes } from 'app/modules/settings/models';

// Actions
import { retrieveSarNarrativesSuccess } from 'app/modules/settings/actions';

// APIs
import { getSarNarratives } from 'app/shared/api/settings';

const rootAction = SettingsActionTypes.RETRIEVE_SAR_NARRATIVE;
function* retrieveSarNarratives() {
  const config = {
    rootAction,
    request: call(getSarNarratives),
    success: function* onSuccess(result) {
      yield put(retrieveSarNarrativesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSarNarratives() {
  yield takeLatest(rootAction, retrieveSarNarratives);
}
