import { HTMLProps, ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { differenceInDays } from 'date-fns';

import { formatDate } from 'app/shared/utils/date';
import { U21Alert, U21Button, U21Tooltip } from 'app/shared/u21-ui/components';
import { FeatureFlag } from 'app/shared/featureFlags/models';

import {
  FEATURE_CURRENT_VERSION,
  GaFeatureTypes,
} from 'app/shared/components/GaBanner/constants';
import {
  selectNewFeatureGADate,
  selectNewUIFeatureFlag,
} from 'app/shared/components/GaBanner/selector';
import { toggleDisableNewFeature } from 'app/shared/components/GaBanner/sliceGaBanner';
import { getDOMProps } from 'app/shared/utils/react';

interface OldFeatureAlertProps
  extends Omit<HTMLProps<HTMLDivElement>, 'size' | 'title'> {
  featureName: GaFeatureTypes;
  displayName: string;
  description?: ReactNode;
  newUIFeatureFlag: FeatureFlag;
  gaDateFeatureFlag: FeatureFlag;
  variant?: 'small' | 'normal';
}

export const OldFeatureAlert = ({
  featureName,
  displayName,
  description,
  newUIFeatureFlag,
  gaDateFeatureFlag,
  variant = 'normal',
  ...rest
}: OldFeatureAlertProps) => {
  const dispatch = useDispatch();

  // New UI feature flag
  const newUIOn = useSelector((state) =>
    selectNewUIFeatureFlag(state, newUIFeatureFlag),
  );

  // GA date feature flag
  const gaDate = useSelector((state) =>
    selectNewFeatureGADate(state, gaDateFeatureFlag),
  );

  const bannerSeverity = useMemo(() => {
    if (!gaDate) {
      return 'info';
    }

    const today = new Date();
    const gaDateObj = new Date(gaDate);
    const daysToGo = differenceInDays(gaDateObj, today);

    if (daysToGo > 14) {
      return 'info';
    } else if (daysToGo <= 14 && daysToGo > 7) {
      return 'warning';
    }
    return 'error';
  }, [gaDate]);

  if (!newUIOn) {
    return null;
  }

  const goToNewButton = (
    <U21Button
      aria-label={`enable new ${featureName} v${FEATURE_CURRENT_VERSION[featureName]}`}
      color="primary"
      onClick={() =>
        dispatch(
          toggleDisableNewFeature({
            featureName,
            featureDisabled: false,
          }),
        )
      }
      variant="text"
    >
      here
    </U21Button>
  );

  return (
    <U21Alert severity={bannerSeverity} {...getDOMProps(rest)}>
      {variant === 'normal' ? (
        <div>
          You are using Unit21&apos;s old {displayName} experience. This version
          will be removed{' '}
          {gaDate ? (
            <>
              on <strong>{formatDate(new Date(gaDate))}</strong>
            </>
          ) : (
            <>in the future</>
          )}
          .
        </div>
      ) : (
        <div>
          Old {displayName} - will be removed&nbsp;
          {gaDate ? (
            <>
              on <strong>{formatDate(new Date(gaDate))}</strong>
            </>
          ) : (
            <>in the future</>
          )}
          . Click {goToNewButton}
          &nbsp;for&nbsp;
          <U21Tooltip tooltip={description}>
            <strong>new experience</strong>
          </U21Tooltip>
          .
        </div>
      )}
      {variant === 'normal' && (
        <>
          <br />
          <div>
            Click {goToNewButton} to try out the new experience
            {description ? ` that ${description}` : '.'}
          </div>
        </>
      )}
    </U21Alert>
  );
};
