import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';

// Models
import { SarActionTypes } from 'app/modules/fincenSar/models';

// Selectors
import { selectSarConfig } from 'app/modules/orgSettings/selectors';

// Constants
import { sarDetailsInitialState } from 'app/modules/fincenSar/constants/shared';

// Validations
import { SARS_SLICE_NAME } from 'app/modules/fincenSar/sliceSars';
import { INITIAL_SAR_CONTENT } from 'app/modules/fincenSarNew/constants';

export const selectCurrentSar = (state: RootState) => {
  return state.sars.sar || sarDetailsInitialState;
};

export const selectSidebarSar = (state: RootState) => {
  return state.sars.sidebarSar;
};

export const selectSarList = (state: RootState) => {
  return state.sars.sars || [];
};

export const selectSarTotalCount = (state: RootState) => {
  return state.sars.num_filings;
};

export const selectValidationErrors = (state: RootState) => {
  return state.sars.validationErrors;
};

export const selectSarFiledAt = createSelector(
  selectCurrentSar,
  (sar) => sar.filed_at,
);

export const selectNewSarContent = (newSarName: string, id?: number) =>
  createSelector(selectSarConfig, (sarConfig) => {
    const content = cloneDeep(INITIAL_SAR_CONTENT);

    // Setting up content with name
    content[0].content.filingName = newSarName || '';
    content[0].content['1_typeOfFiling']['Initial report'] = true;

    // Copy page 1 content from sar config
    Object.keys(sarConfig).forEach((key) => {
      if (key in content[1].content) {
        content[1].content[key] = sarConfig[key];
      }
    });

    const { page2 } = sarConfig;
    // Copy page 2 content from sar config
    if (page2?.forms?.length) {
      content[2].content.forms = cloneDeep(page2.forms);
    }

    if (id) {
      content[1].content['88_internalControlFileNumber'] = String(id);
      content[2].content.forms[0]['66_internalControlFileNumber'] = String(id);
    }
    return content;
  });

// Loading
export const selectSarListLoading = (state: RootState) => {
  return Boolean(state.loading[SarActionTypes.RETRIEVE_SAR_LIST]);
};

export const selectArchiveLoading = (state: RootState) => {
  return Boolean(state.loading[SarActionTypes.ARCHIVE_SAR]);
};

export const selectRequeueSarLoading = (state: RootState) =>
  state[SARS_SLICE_NAME].loadingRequeueSar;

export const selectReassignSarLoading = (state: RootState) =>
  state[SARS_SLICE_NAME].loadingReassignSar;
