import { FC } from 'react';

// Styles
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';

// Components
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import { SidebarFieldValue } from 'app/modules/sidebar/components/SidebarFieldValue';

interface OwnProps {
  label: string | number | null;
  value: string | number | null;
}

export const SidebarLabelValue: FC<OwnProps> = ({ label, value }) => {
  return (
    <StyledItem>
      <SidebarFieldLabel>{label}</SidebarFieldLabel>
      <SidebarFieldValue>{value}</SidebarFieldValue>
    </StyledItem>
  );
};
