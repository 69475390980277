import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Components
import {
  U21SideMenu,
  U21EntityChip,
  U21InstrumentChip,
  U21DynamicColorChip,
} from 'app/shared/u21-ui/components/dashboard';
import {
  U21Button,
  U21Chip,
  U21Spacer,
  U21Subsection,
} from 'app/shared/u21-ui/components';
import { IconLock } from '@u21/tabler-icons';
import { SidebarCustomDataNew } from 'app/modules/sidebar/components/SidebarCustomDataNew';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';

// Models
import { ActionEventSidebarDefinition } from 'app/modules/sidebar/models';

// Utils
import { formatDatetime } from 'app/shared/utils/date';

// Selectors
import {
  selectActionEvent,
  selectActionEventLoading,
} from 'app/modules/transactions/selectors';
import { selectDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';

// Actions
import { retrieveActionEvent } from 'app/modules/transactions/actions';

// Constants
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { selectEvaluationLog } from 'app/shared/events/selectors';
import { selectFraudTransactionRulesEnabled } from 'app/shared/featureFlags/selectors';
import {
  clearEvaluationLog,
  retrieveEvaluationLogThunk,
} from 'app/shared/events/sliceEvents';
import { SidebarEvaluationLogInfo } from 'app/modules/sidebar/components/SidebarEvaluationLogInfo';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import { SidebarFieldListValues } from 'app/modules/sidebar/components/SidebarFieldListValues';
import { isEmpty } from 'lodash';
import { selectRealTimeRulesEnabled } from 'app/modules/orgSettings/selectors';

interface OwnProps {
  data: ActionEventSidebarDefinition['data'];
}

export const SidebarActionEvent = ({ data: { id } }: OwnProps) => {
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const data = useSelector(selectActionEvent);
  const loading = useSelector(selectActionEventLoading);
  const evaluationLog = useSelector(selectEvaluationLog);
  const fraudTransactionRulesEnabled = useSelector(
    selectFraudTransactionRulesEnabled,
  );
  const dispatch = useDispatch();
  const { push } = useHistory();
  const dataSettingByNativeKey = useSelector(selectDataSettingsByNativeKey);

  const isRTREnabled = useSelector(selectRealTimeRulesEnabled);
  useEffect(() => {
    dispatch(retrieveActionEvent(id));
  }, [id, dispatch]);

  useEffect(() => {
    if ((fraudTransactionRulesEnabled || isRTREnabled) && data.external_id) {
      dispatch(clearEvaluationLog());
      dispatch(retrieveEvaluationLogThunk(data.external_id));
    }
  }, [fraudTransactionRulesEnabled, isRTREnabled, data.external_id, dispatch]);

  const isOnActionEventDetails = Boolean(
    useRouteMatch(ROUTES_MAP.dataExplorerActionEventsId.path),
  );

  return (
    <U21SideMenu
      onClose={() => {
        if (isOnActionEventDetails) {
          push(ROUTES_MAP.dataExplorerActionEvents.path);
        }
      }}
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      loading={loading}
      noPadding
      title="Action Event Details"
    >
      <U21Spacer spacing={0.5}>
        <U21Subsection
          shaded
          title={`Event ${data.external_id || data.id}`}
          titleIcon={<IconLock />}
        >
          <SidebarDataRow
            label="ID"
            showEmptyFields={showEmptyFields}
            value={data.external_id}
          />
          <SidebarDataRow
            label="Unit21 ID"
            showEmptyFields={showEmptyFields}
            value={data.id}
          />
          <SidebarDataRow
            label="Description"
            showEmptyFields={showEmptyFields}
            value={data.action_content}
          />
          <SidebarDataRow
            label="Type"
            showEmptyFields={showEmptyFields}
            value={
              data.action_type ? (
                <U21DynamicColorChip>{data.action_type}</U21DynamicColorChip>
              ) : null
            }
          />
          <SidebarDataRow
            label="Status"
            showEmptyFields={showEmptyFields}
            value={data.status ? <U21Chip>{data.status}</U21Chip> : null}
          />
          <SidebarDataRow
            label="Created at"
            showEmptyFields={showEmptyFields}
            value={formatDatetime(data.created_at)}
          />
          <SidebarDataRow
            label="Event time"
            showEmptyFields={showEmptyFields}
            value={
              <DataSettingDataDisplay
                formattedData={data.formatted_data}
                dataSetting={dataSettingByNativeKey.ACTION_EVENT.event_time}
              />
            }
          />
          <SidebarDataRow
            label="Instrument ID"
            showEmptyFields={showEmptyFields}
            value={
              data.instrument_id ? (
                <U21InstrumentChip
                  id={data.instrument_id}
                  name={`${data.instrument_external_id || data.instrument_id}`}
                />
              ) : null
            }
          />
          <SidebarDataRow
            label="Entity ID"
            showEmptyFields={showEmptyFields}
            value={
              data.entity_id ? (
                <U21EntityChip
                  id={data.entity_id}
                  name={data.entity_external_id || `Entity #${data.entity_id}`}
                />
              ) : null
            }
          />
        </U21Subsection>
        <SidebarCustomDataNew data={data.formatted_data} />
        <SidebarEvaluationLogInfo evaluationLog={evaluationLog} />
        <U21Subsection shaded title="Location data">
          <SidebarDataRow
            label="Address"
            showEmptyFields={showEmptyFields}
            value={data.address}
          />
          <SidebarDataRow
            label="IP address"
            showEmptyFields={showEmptyFields}
            value={data.ip_address}
          />
          <SidebarDataRow
            label="Client fingerprint"
            showEmptyFields={showEmptyFields}
            value={data.client_fingerprint}
          />
          <SidebarDataRow
            label="Geolocations"
            showEmptyFields={showEmptyFields}
            value={
              !isEmpty(data.geolocations) ? (
                <SidebarFieldListValues
                  list={data.geolocations.map(
                    (location) => location.geolocation_string,
                  )}
                />
              ) : null
            }
          />
        </U21Subsection>
      </U21Spacer>
    </U21SideMenu>
  );
};
