import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

// Constants
import {
  OTHER_REASON_FIELD,
  REASON_DROPDOWN_FIELD,
  SUPPORT_TICKET_FIELD,
  TEST_CHANGES_DETAILS_FIELD,
  SCREEN_SHARE_DETAILS_FIELD,
  AGENT_FIELD,
  IMPERSONATION_SCHEMA,
  ORG_FIELD,
} from 'app/modules/navigator/schema';

// Components
import {
  U21Button,
  U21Form,
  U21FormField,
  U21ModalBase,
  U21ModalBody,
  U21ModalFooter,
  U21ModalHeader,
  U21Spacer,
} from 'app/shared/u21-ui/components';

// Models
import { AuthClient } from 'app/modules/session/models';
import { ImpersonationFormValues } from 'app/modules/navigator/models';

// Actions
import { startImpersonation } from 'app/modules/agentsOld/actions';

// Selectors
import { selectImpersonationLoading } from 'app/modules/agentsOld/selectors';

// Utils
import {
  createFormInitialValues,
  generateFormFieldProps,
} from 'app/shared/utils/form';
import { IconAffiliate, IconUser } from '@u21/tabler-icons';
import { selectOrgsOptions } from 'app/modules/admin/selectors';
import { retrieveOrgs } from 'app/modules/admin/actions';
import {
  getOrgAgentsThunk,
  resetOrgAgents,
} from 'app/modules/impersonation/sliceImpersonation';
import {
  selectLoadingOrgAgents,
  selectOrgImpersonationAgentOptions,
} from 'app/modules/impersonation/selectors';

const initialValues =
  createFormInitialValues<ImpersonationFormValues>(IMPERSONATION_SCHEMA);

interface AccountImpersonationModalProps {
  open: boolean;
  handleClose: () => void;
  auth: AuthClient;
}
export const AccountImpersonationModal = ({
  open,
  handleClose,
  auth,
}: AccountImpersonationModalProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) dispatch(retrieveOrgs());
  }, [dispatch, open]);

  const orgOptions = useSelector(selectOrgsOptions);
  const impersonationAgentOptions = useSelector(
    selectOrgImpersonationAgentOptions,
  );
  const impersonationAgentLoading = useSelector(selectLoadingOrgAgents);

  const impersonationLoading = useSelector(selectImpersonationLoading);

  const setOrgId = (id?: number) => {
    dispatch(resetOrgAgents());
    if (id) {
      dispatch(getOrgAgentsThunk(id));
    }
  };

  return (
    <U21ModalBase open={open} onExited={() => dispatch(resetOrgAgents())}>
      <U21ModalHeader onClose={handleClose}>Impersonate</U21ModalHeader>
      <U21Form
        initialValues={initialValues}
        onSubmit={(model) => {
          const payload = {
            authClient: auth,
            ...model,
          };
          dispatch(startImpersonation(payload));
        }}
      >
        {(formProps) => {
          const {
            values,
            form: {
              mutators: { setFieldValue },
            },
          } = formProps;
          const orgId = values.org_id;
          return (
            <>
              <U21ModalBody>
                <U21Spacer spacing={2}>
                  <U21FormField
                    {...generateFormFieldProps(ORG_FIELD)}
                    fieldProps={{
                      startIcon: <IconAffiliate />,
                      options: orgOptions,
                      autoFocus: true,
                      placeholder: 'Select organization',
                      onChange: (id: number) => {
                        setOrgId(id);
                        setFieldValue(AGENT_FIELD.name, undefined);
                      },
                      loading: !orgOptions.length,
                    }}
                  />
                  <U21FormField
                    {...generateFormFieldProps(AGENT_FIELD)}
                    disabled={!orgId}
                    fieldProps={{
                      startIcon: <IconUser />,
                      options: impersonationAgentOptions,
                      placeholder: 'Select agent',
                      loading: orgId && impersonationAgentLoading,
                    }}
                  />
                  <U21FormField
                    {...generateFormFieldProps(REASON_DROPDOWN_FIELD)}
                  />
                  {values.impersonation_reason === 'SUPPORT_TICKET' && (
                    <U21FormField
                      {...generateFormFieldProps(SUPPORT_TICKET_FIELD)}
                    />
                  )}
                  {(values.impersonation_reason === 'OTHER' ||
                    values.impersonation_reason === 'SUPPORT_TICKET') && (
                    <U21FormField
                      {...generateFormFieldProps(OTHER_REASON_FIELD)}
                    />
                  )}
                  {values.impersonation_reason === 'VIEW_CHANGES' && (
                    <U21FormField
                      {...generateFormFieldProps(TEST_CHANGES_DETAILS_FIELD)}
                    />
                  )}
                  {values.impersonation_reason === 'SCREEN_SHARE' && (
                    <U21FormField
                      {...generateFormFieldProps(SCREEN_SHARE_DETAILS_FIELD)}
                    />
                  )}
                </U21Spacer>
              </U21ModalBody>
              <U21ModalFooter>
                <U21Button onClick={handleClose}>Cancel</U21Button>
                <U21Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  loading={impersonationLoading}
                >
                  Confirm impersonation
                </U21Button>
              </U21ModalFooter>
            </>
          );
        }}
      </U21Form>
    </U21ModalBase>
  );
};
