// Models
import { NotesActionTypes, NoteModel } from 'app/modules/notes/models';
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';
import { RawDraftContentState } from 'draft-js';

export const retrieveNotes = (articleId: number) =>
  createAction(NotesActionTypes.RETRIEVE_NOTES, articleId);

export const retrieveNotesPending = () =>
  createAction(NotesActionTypes.RETRIEVE_NOTES_PENDING);

export const retrieveNotesSuccess = (result: NoteModel[]) =>
  createAction(NotesActionTypes.RETRIEVE_NOTES_SUCCESS, result);

export const toggleEditMode = (note: NoteModel) =>
  createAction(NotesActionTypes.TOGGLE_EDIT_MODE, note);

export const toggleDeleteMode = (note: NoteModel) =>
  createAction(NotesActionTypes.TOGGLE_DELETE_MODE, note);

export const setNotes = (notes: NoteModel[]) =>
  createAction(NotesActionTypes.SET_NOTES, notes);

export const resetNotes = () => createAction(NotesActionTypes.RESET_NOTES);

export const addNote = (articleId: number, content: RawDraftContentState) =>
  createAction(NotesActionTypes.ADD_NOTE, { articleId, content });

export const addNoteSuccess = (note: NoteModel) =>
  createAction(NotesActionTypes.ADD_NOTE_SUCCESS, note);

export const updateNote = (noteId: number, content: RawDraftContentState) =>
  createAction(NotesActionTypes.UPDATE_NOTE, { noteId, content });

export const updateNotePending = () =>
  createAction(NotesActionTypes.UPDATE_NOTE_PENDING);

export const updateNoteSuccess = (result: NoteModel) =>
  createAction(NotesActionTypes.UPDATE_NOTE_SUCCESS, result);

export const deleteNote = (noteId: number) =>
  createAction(NotesActionTypes.DELETE_NOTE, noteId);

export const deleteNoteSuccess = (noteId: number) =>
  createAction(NotesActionTypes.DELETE_NOTE_SUCCESS, noteId);

const NotesActionsList = {
  retrieveNotes,
  retrieveNotesPending,
  retrieveNotesSuccess,
  setNotes,
  resetNotes,
  toggleEditMode,
  toggleDeleteMode,
  addNote,
  addNoteSuccess,
  updateNotePending,
  updateNoteSuccess,
  deleteNote,
  deleteNoteSuccess,
};

export type NotesActions = ActionsUnion<typeof NotesActionsList>;
