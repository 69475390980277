import {
  AgentDetails,
  AgentsActionTypes,
  PaginatedAgentsPayload,
  PaginatedAgentsResponse,
} from 'app/modules/agentsOld/models';
import { ImpersonationSuccessResponse } from 'app/modules/agentsOld/types/responses';
import {
  ImpersonationPayload,
  CancelImpersonationPayload,
} from 'app/modules/agentsOld/types/requests';

import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const retrievePaginatedAgents = (payload: PaginatedAgentsPayload) =>
  createAction(AgentsActionTypes.RETRIEVE_PAGINATED_AGENTS, payload);

export const retrievePaginatedAgentsSuccess = (
  result: PaginatedAgentsResponse,
) => createAction(AgentsActionTypes.RETRIEVE_PAGINATED_AGENTS_SUCCESS, result);

export const startImpersonation = (payload: ImpersonationPayload) =>
  createAction(AgentsActionTypes.START_IMPERSONATION, payload);

export const startImpersonationSuccess = (
  result: ImpersonationSuccessResponse,
) => createAction(AgentsActionTypes.START_IMPERSONATION_SUCCESS, result);

export const cancelImpersonation = (payload: CancelImpersonationPayload) =>
  createAction(AgentsActionTypes.CANCEL_IMPERSONATION, payload);

export const cancelImpersonationSuccess = (result) =>
  createAction(AgentsActionTypes.CANCEL_IMPERSONATION_SUCCESS, result);

export const addAgentsToPaginatedTable = (result: AgentDetails[]) =>
  createAction(AgentsActionTypes.ADD_AGENTS_TO_PAGINATED_TABLE, result);

export const removeAgentsFromPaginatedTable = (result: number[]) =>
  createAction(AgentsActionTypes.REMOVE_AGENTS_FROM_PAGINATED_TABLE, result);

export const retrieveUncensorFields = () =>
  createAction(AgentsActionTypes.RETRIEVE_UNCENSOR_FIELDS);

export const retrieveUncensorFieldsSuccess = (result) =>
  createAction(AgentsActionTypes.RETRIEVE_UNCENSOR_FIELDS_SUCCESS, result);

const AgentActionsList = {
  startImpersonation,
  startImpersonationSuccess,
  cancelImpersonation,
  cancelImpersonationSuccess,

  retrievePaginatedAgents,
  retrievePaginatedAgentsSuccess,
  addAgentsToPaginatedTable,
  removeAgentsFromPaginatedTable,

  retrieveUncensorFields,
  retrieveUncensorFieldsSuccess,
};

export type AgentActions = ActionsUnion<typeof AgentActionsList>;
export default AgentActions;
