// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import { CasesActionTypes, CasesState } from 'app/modules/casesOld/models';
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';
import { SarActionTypes, SarSummary } from 'app/modules/fincenSar/models';

// Actions
import CasesActions from 'app/modules/casesOld/actions';
import { DeadlinesActions } from 'app/modules/deadlines/actions';
import SarActions from 'app/modules/fincenSar/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import { INITIAL_CASE } from 'app/modules/cases/constants';

const initialState: Readonly<CasesState> = {
  case: INITIAL_CASE,
  sidebarCase: INITIAL_CASE,
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<CasesState> = (
  state = initialState,
  action: CasesActions | DeadlinesActions | SarActions,
) => {
  return produce(state, (draft: Draft<CasesState>) => {
    switch (action.type) {
      case CasesActionTypes.UPLOAD_FILES_SUCCESS:
        draft.case.attachments = action.payload.attachments;
        return;

      case CasesActionTypes.RETRIEVE_CASE_SUCCESS:
        draft.case = action.payload;
        return;

      case CasesActionTypes.RETRIEVE_SIDEBAR_CASE_SUCCESS:
        draft.sidebarCase = action.payload;
        return;

      case CasesActionTypes.EDIT_CASE_SUCCESS: {
        // only change left hand side of cases list when page is changed
        draft.case = { ...draft.case, ...action.payload };
        return;
      }

      case CasesActionTypes.DELETE_DOCUMENT_SUCCESS:
      case CasesActionTypes.ADD_DOCUMENTS_SUCCESS:
      case CasesActionTypes.EDIT_DOCUMENT_SUCCESS:
        draft.case.attachments = action.payload.attachments;
        return;

      case DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE_SUCCESS:
        if (action.payload.article_id === draft.case.id) {
          draft.case.deadline = action.payload;
        }
        return;

      case SarActionTypes.ARCHIVE_SAR_SUCCESS: {
        const sarSummary: SarSummary = action.payload;
        draft.case.sars = draft.case.sars?.map((sar) => {
          if (sar.id === sarSummary.id) {
            return {
              ...sar,
              status: sarSummary.status,
            };
          }
          return sar;
        });
        return;
      }
    }
  });
};

export { reducer as casesReducer };
