import {
  U21Chip,
  U21FormFieldCustom,
  U21FormLabel,
  U21Section,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import {
  CASE_ALERTS,
  CASE_ENTITIES,
  CASE_TAGS,
  CASE_TRANSACTIONS,
  CASE_WATCHERS,
  CASE_ACTION_EVENTS,
} from 'app/modules/cases/schemas/detailsCaseCreate';
import getFullTagName from 'app/shared/utils/getFullTagName';
import {
  formatAlertsSearchDropdown,
  formatEntityOptions,
  formatEventsSearchDropdown,
} from 'app/modules/search/helpers';
import { useSelector } from 'react-redux';
import { selectInvestigationCase } from 'app/modules/investigations/selectors/investigations';
import { selectHasReadAgentsPermissions } from 'app/modules/session/selectors';
import { generateFormFieldProps } from 'app/shared/utils/form';

interface OwnProps {
  isPdf?: boolean;
}

export const CaseDetailsReadonlyCard = ({ isPdf }: OwnProps) => {
  const details = useSelector(selectInvestigationCase);
  const hasReadAgentsPermission = useSelector(selectHasReadAgentsPermissions);
  return (
    <U21Section title="Details">
      <U21Spacer>
        <div>
          <U21FormLabel>{CASE_TAGS.label}</U21FormLabel>
          {details.tags?.length > 0 ? (
            <U21Spacer wrap>
              {details.tags.map((i) => {
                const tag = getFullTagName(i.type, i.name);
                return <U21Chip key={tag}>{tag}</U21Chip>;
              })}
            </U21Spacer>
          ) : (
            <U21Typography color="text.secondary" variant="body2">
              None
            </U21Typography>
          )}
        </div>
        {!isPdf && hasReadAgentsPermission ? (
          <U21FormFieldCustom {...generateFormFieldProps(CASE_WATCHERS)} />
        ) : (
          <div>
            <U21FormLabel>{CASE_WATCHERS.label}</U21FormLabel>
            {details.watchers?.length > 0 ? (
              <U21Spacer wrap>
                {details.watchers.map((i) => (
                  <U21Chip key={i.full_name}>{i.full_name}</U21Chip>
                ))}
              </U21Spacer>
            ) : (
              <U21Typography color="text.secondary" variant="body2">
                None
              </U21Typography>
            )}
          </div>
        )}
        <div>
          <U21FormLabel>{CASE_ALERTS.label}</U21FormLabel>
          {details.alerts?.length > 0 ? (
            <U21Spacer wrap>
              {formatAlertsSearchDropdown(details.alerts).map((i) => (
                <U21Chip key={i.value}>{i.text}</U21Chip>
              ))}
            </U21Spacer>
          ) : (
            <U21Typography color="text.secondary" variant="body2">
              None
            </U21Typography>
          )}
        </div>
        <div>
          <U21FormLabel>{CASE_ENTITIES.label}</U21FormLabel>
          {details.entities?.length > 0 ? (
            <U21Spacer wrap>
              {formatEntityOptions(details.entities).map((i) => (
                <U21Chip key={i.value}>{i.text}</U21Chip>
              ))}
            </U21Spacer>
          ) : (
            <U21Typography color="text.secondary" variant="body2">
              None
            </U21Typography>
          )}
        </div>
        <div>
          <U21FormLabel>{CASE_TRANSACTIONS.label}</U21FormLabel>
          {details.events?.length > 0 ? (
            <U21Spacer wrap>
              {formatEventsSearchDropdown(details.events).map((i) => (
                <U21Chip key={i.key}>{i.text}</U21Chip>
              ))}
            </U21Spacer>
          ) : (
            <U21Typography color="text.secondary" variant="body2">
              None
            </U21Typography>
          )}
        </div>
        <div>
          <U21FormLabel>{CASE_ACTION_EVENTS.label}</U21FormLabel>
          {details.action_events?.length > 0 ? (
            <U21Spacer wrap>
              {formatEventsSearchDropdown(details.action_events).map((i) => (
                <U21Chip key={i.key}>{i.text}</U21Chip>
              ))}
            </U21Spacer>
          ) : (
            <U21Typography color="text.secondary" variant="body2">
              None
            </U21Typography>
          )}
        </div>
      </U21Spacer>
    </U21Section>
  );
};
