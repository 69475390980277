import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import { SidebarCustomData } from 'app/modules/sidebar/components/SidebarCustomData';
import { CustomDataSidebarDefinition } from 'app/modules/sidebar/models';

export const SidebarShowCustomData = ({
  data: { customData, customDataLabels, title },
}: CustomDataSidebarDefinition) => {
  return (
    <U21SideMenu title={title}>
      <SidebarCustomData
        data={customData}
        customDataLabels={customDataLabels}
      />
    </U21SideMenu>
  );
};
