import {
  FilterOperatorAndValue,
  FilterOperatorAndPartialValue,
  FilterOption,
} from 'app/modules/filters/models';

import { hasPermissionChecker } from 'app/modules/permissions/utils';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { selectSessionAgentPermissions } from 'app/modules/session/selectors';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FilterDisplay } from 'app/modules/filters/components/FilterDisplay';
import { FilterUpdater } from 'app/modules/filters/components/FilterBuilder';
import { IconPinFilled } from '@u21/tabler-icons';
import { U21Chip, U21MenuLayout } from 'app/shared/u21-ui/components';

type Props = {
  onDelete: () => void;
  onPinChange?: () => void;
  onUpdate: (operatorAndValue: FilterOperatorAndValue) => void;
  operatorAndValue: FilterOperatorAndValue;
  option: FilterOption;
  pinned: boolean;
  disabled?: boolean;
};

export const FilterAppliedChip = (props: Props) => {
  const {
    onDelete,
    onPinChange,
    onUpdate,
    operatorAndValue,
    option,
    pinned,
    disabled,
  } = props;

  const { featureFlag, permissions } = option;

  const [open, setOpen] = useState(false);
  const [staged, setStaged] =
    useState<FilterOperatorAndPartialValue>(operatorAndValue);

  const featureFlags = useSelector(selectFeatureFlags);
  const userPermissions = useSelector(selectSessionAgentPermissions);

  const hasAccess = useMemo(() => {
    if (featureFlag && !featureFlags[featureFlag]) {
      return false;
    }
    return hasPermissionChecker(userPermissions, permissions);
  }, [featureFlag, featureFlags, permissions, userPermissions]);

  // delete filter if no permission
  useEffect(() => {
    if (!hasAccess) {
      onDelete();
    }
  }, [hasAccess, onDelete]);

  return (
    <U21MenuLayout
      onClose={() => setOpen(false)}
      onEnter={() => setStaged(operatorAndValue)}
      open={open}
      trigger={
        <U21Chip
          disabled={disabled}
          icon={pinned ? <IconPinFilled /> : undefined}
          onClick={() => setOpen(true)}
          onDelete={onDelete}
          rounded
          variant="outlined"
        >
          <FilterDisplay operatorAndValue={operatorAndValue} option={option} />
        </U21Chip>
      }
    >
      <FilterUpdater
        onClose={() => setOpen(false)}
        onDelete={onDelete}
        onOperatorAndValueChange={setStaged}
        onPinChange={onPinChange}
        onSubmit={(newOperatorAndValue) => {
          setOpen(false);
          onUpdate(newOperatorAndValue);
        }}
        option={option}
        operatorAndValue={staged}
        pinned={pinned}
      />
    </U21MenuLayout>
  );
};
