import { useDispatch } from 'react-redux';

// Mutations
import { useMutation, useQueryClient } from '@tanstack/react-query';

// Actions
import { sendErrorToast } from 'app/shared/toasts/actions';

// Utils
import { post } from 'app/shared/utils/fetchr';
import { INVESTIGATION_QUERY_KEYS } from 'app/modules/investigations/queries/keys';
import { setCustomChecklistSubmissionId } from 'app/shared/customChecklist/actions';

interface TriggerAiInvestigationPayload {
  article_id: number;
  custom_checklist_submission_id?: number;
}

export const manuallyTriggerAiInvestigation = (
  payload: TriggerAiInvestigationPayload,
): Promise<{ custom_checklist_submission_id: number }> =>
  post(`/ai-investigations/run`, payload);

export const useTriggerAiInvestigation = (
  payload: TriggerAiInvestigationPayload,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => manuallyTriggerAiInvestigation(payload),
    onSuccess: (res: { custom_checklist_submission_id: number }) => {
      queryClient.invalidateQueries({
        queryKey: INVESTIGATION_QUERY_KEYS.getAiInvestigationResult(payload),
      });
      dispatch(
        setCustomChecklistSubmissionId(res.custom_checklist_submission_id),
      );
    },
    onError: () =>
      dispatch(sendErrorToast('Failed to trigger AI Investigation.')),
  });
};
