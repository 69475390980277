import {
  ConfiguredFlow,
  DataSettingsColumnId,
  RelatedRulesChipPalette,
} from 'app/modules/dataSettings/types';
import {
  DataSettingFieldType,
  OrgDataSettingsConfig,
  RenderingOptionsResponse,
  SemanticTypes,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import {
  U21SelectOptionProps,
  U21TableColumnTypes,
} from 'app/shared/u21-ui/components';
import {
  PrimaryObject,
  SecondaryObject,
  U21Object,
} from 'app/modules/dataMapping/types';
import { RuleStatus } from 'app/modules/rules/models';

export const HUMAN_READABLE_CUSTOM_DATA_FIELD_TYPE: Record<
  DataSettingFieldType,
  string
> = {
  [DataSettingFieldType.DATE]: 'Date',
  [DataSettingFieldType.DATE_TIME]: 'Datetime',
  [DataSettingFieldType.NUMBER]: 'Number',
  [DataSettingFieldType.TEXT]: 'Text',
  [DataSettingFieldType.LIST]: 'List',
  [DataSettingFieldType.ENUM]: 'Enum',
  [DataSettingFieldType.BOOLEAN]: 'Boolean',
  [DataSettingFieldType.ENTITY_REFERENCE]: 'Entity reference',
  [DataSettingFieldType.INSTRUMENT_REFERENCE]: 'Instrument reference',
  [DataSettingFieldType.JSON]: 'JSON',
  [DataSettingFieldType.ANY]: 'Any',
};

export const HUMAN_READABLE_CUSTOM_DATA_OBJECT_TYPE: Record<
  Unit21DataClassifier,
  string
> = {
  [Unit21DataClassifier.ENTITY]: 'Entity',
  [Unit21DataClassifier.EVENT]: 'Transaction',
  [Unit21DataClassifier.ACTION_EVENT]: 'Action event',
  [Unit21DataClassifier.INSTRUMENT]: 'Instrument',
  [Unit21DataClassifier.RULE]: 'Rule',
  [Unit21DataClassifier.ALERT]: 'Alert',
  [Unit21DataClassifier.CASE]: 'Case',
  [Unit21DataClassifier.ADDRESS]: 'Address',
  [Unit21DataClassifier.WATCHLIST]: 'Watchlist',
};

export const HUMAN_READABLE_SEMANTIC_TYPE: Record<SemanticTypes, string> = {
  [SemanticTypes.ACCOUNT_NUMBER]: 'Account number',
  [SemanticTypes.ACH_SEC_CODE]: 'ACH SEC code',
  [SemanticTypes.ACH_RETURN_CODE]: 'ACH return code',
  [SemanticTypes.CHECK_MEMO]: 'Check memo',
  [SemanticTypes.CHECK_NUMBER]: 'Check number',
  [SemanticTypes.EMAIL_ADDRESS]: 'Email address',
  [SemanticTypes.INSTITUTION_NUMBER]: 'Institution number',
  [SemanticTypes.JH_CHECK_IMAGE_NUMBER]: 'Jack Henry check image number',
  [SemanticTypes.MCC_CODE]: 'Merchant Category Code (MCCs)',
  [SemanticTypes.NACHA_DESCRIPTION]: 'NACHA merchant description',
  [SemanticTypes.NAICS_CODE]: 'NAICS code',
  [SemanticTypes.ROUTING_NUMBER]: 'Routing number',
  [SemanticTypes.TRACE_ID]: 'Trace ID',
  [SemanticTypes.URL]: 'URL',
};

export const SEMANTIC_TYPE_DESCRIPTION: Partial<Record<SemanticTypes, string>> =
  {
    [SemanticTypes.ACCOUNT_NUMBER]: 'MICR bank account number (American)',
    [SemanticTypes.ACH_SEC_CODE]:
      'Three letter code that describes how a payment was authorized by the consumer or business receiving an ACH transaction. SEC stands for `Standard Entry Class`. SEC codes are defined and maintained by NACHA, the governing body for the ACH network.',
    [SemanticTypes.ACH_RETURN_CODE]:
      "ACH return codes identify the reason an ACH payment was returned by the recipient's bank. They make it easier for originating and receiving financial institutions to spot and communicate payment failures.",
    [SemanticTypes.CHECK_MEMO]: 'Memo line on checks',
    [SemanticTypes.INSTITUTION_NUMBER]:
      'Three-digit number that refers to a specific bank. Present on many check data sets',
    [SemanticTypes.NACHA_DESCRIPTION]:
      'The NACHA description gives a short description of the merchant on ACH & direct deposit payments. i.e `TARGET 0000XXX, Townsville, OR 971XX US`',
    [SemanticTypes.NAICS_CODE]:
      'North American Industry Classification System, classifying business establishments',
    [SemanticTypes.ROUTING_NUMBER]:
      'MICR bank account routing number (American)',
    [SemanticTypes.TRACE_ID]:
      'Trace referrence common for multi-stage transaction ledgers',
  };

export const DATA_CLASSIFIER_SELECT_OPTIONS = [
  {
    text: 'Entity',
    value: Unit21DataClassifier.ENTITY,
  },
  {
    text: 'Transaction',
    value: Unit21DataClassifier.EVENT,
  },
  {
    text: 'Action event',
    value: Unit21DataClassifier.ACTION_EVENT,
  },
  {
    text: 'Instrument',
    value: Unit21DataClassifier.INSTRUMENT,
  },
  {
    text: 'Alert',
    value: Unit21DataClassifier.ALERT,
  },
  {
    text: 'Address',
    value: Unit21DataClassifier.ADDRESS,
  },
  {
    text: 'Watchlist',
    value: Unit21DataClassifier.WATCHLIST,
  },
];

export const SEMANTIC_TYPE_OPTIONS: U21SelectOptionProps[] = Object.values(
  SemanticTypes,
).map((value) => ({
  value,
  text: HUMAN_READABLE_SEMANTIC_TYPE[value],
  description: SEMANTIC_TYPE_DESCRIPTION[value],
}));

// plz alphabetize
export const SEMANTIC_TYPES_TO_U21_DATA_CLASSIFIER: Partial<
  Record<Unit21DataClassifier, Set<SemanticTypes>>
> = {
  [Unit21DataClassifier.ENTITY]: new Set([
    SemanticTypes.EMAIL_ADDRESS,
    SemanticTypes.NAICS_CODE,
    SemanticTypes.URL,
  ]),
  [Unit21DataClassifier.INSTRUMENT]: new Set([
    SemanticTypes.ACCOUNT_NUMBER,
    SemanticTypes.ROUTING_NUMBER,
  ]),
  [Unit21DataClassifier.EVENT]: new Set([
    SemanticTypes.ACCOUNT_NUMBER,
    SemanticTypes.ACH_RETURN_CODE,
    SemanticTypes.ACH_SEC_CODE,
    SemanticTypes.CHECK_MEMO,
    SemanticTypes.CHECK_NUMBER,
    SemanticTypes.INSTITUTION_NUMBER,
    SemanticTypes.JH_CHECK_IMAGE_NUMBER,
    SemanticTypes.MCC_CODE,
    SemanticTypes.NACHA_DESCRIPTION,
    SemanticTypes.ROUTING_NUMBER,
    SemanticTypes.TRACE_ID,
  ]),
};

export const RELATED_RULES_CHIP_COLORS: RelatedRulesChipPalette = {
  ACTIVE: {
    color: 'success',
    style: 'filled',
  },
  INACTIVE: {
    color: 'default',
    style: 'ghost',
  },
  VALIDATION: {
    color: 'warning',
    style: 'ghost',
  },
  ACTIVE_SHADOW: {
    color: 'success',
    style: 'ghost',
  },
  PENDING_ACTIVATION: {
    color: 'success',
    style: 'filled',
  },
  PENDING_SHADOW_ACTIVATION: {
    color: 'success',
    style: 'ghost',
  },
};

export const DATA_SETTING_KEY_TYPE_READABLE_NAME: Record<string, string> = {
  NATIVE: 'Unit21 Field',
  CUSTOM: 'Custom Data Field',
};

export const DATA_SETTINGS_FIELD_TYPE_OPTIONS: U21SelectOptionProps[] =
  Object.values(DataSettingFieldType).map((val) => ({
    text: HUMAN_READABLE_CUSTOM_DATA_FIELD_TYPE[val],
    value: val,
  }));

export const DATA_SETTINGS_COLUMNS_KEY_LABEL_MAP = {
  [DataSettingsColumnId.DEFAULT_NAME]: 'Default name',
  [DataSettingsColumnId.FRIENDLY_NAME]: 'Friendly name',
  [DataSettingsColumnId.FIELD_TYPE]: 'Field type',
  [DataSettingsColumnId.DATA_TYPE]: 'Data type',
  [DataSettingsColumnId.OBJECT_TYPE]: 'Object',
  [DataSettingsColumnId.EXPORTED]: 'Exported',
  [DataSettingsColumnId.UPDATED_AT]: 'Last ingested date',
  [DataSettingsColumnId.USE_IN_GRAPH_BASED_RULES]: 'Use in graph based rules',
  [DataSettingsColumnId.SEMANTIC_TYPE]: 'Semantic type',
  [DataSettingsColumnId.DESCRIPTION]: 'Description',
  [DataSettingsColumnId.RELATED_RULES]: 'Related rules',
  [DataSettingsColumnId.RECENT_VALUES]: 'Recent values',
  [DataSettingsColumnId.ENUM_LIST]: 'Enum set',
} as const;

const DEFAULT_HIDDEN_COLS = new Set([
  DataSettingsColumnId.DESCRIPTION,
  DataSettingsColumnId.RELATED_RULES,
  DataSettingsColumnId.RECENT_VALUES,
  DataSettingsColumnId.ENUM_LIST,
]);

export const DEFAULT_DATA_SETTINGS_TABLE_CONFIG = Object.values(
  DataSettingsColumnId,
).reduce<{ key: DataSettingsColumnId; label: string; hidden: boolean }[]>(
  (acc, key) => {
    acc.push({
      key,
      label: DATA_SETTINGS_COLUMNS_KEY_LABEL_MAP[key],
      hidden: DEFAULT_HIDDEN_COLS.has(key),
    });
    return acc;
  },
  [],
);

export const UNIT21_DATA_CLASSIFIER_TO_U21_OBJECT_TYPE: Record<
  Unit21DataClassifier,
  U21Object | undefined
> = {
  [Unit21DataClassifier.ACTION_EVENT]: PrimaryObject.ACTION,
  [Unit21DataClassifier.EVENT]: PrimaryObject.TRANSACTION,
  [Unit21DataClassifier.ENTITY]: PrimaryObject.ENTITY,
  [Unit21DataClassifier.INSTRUMENT]: PrimaryObject.INSTRUMENT,
  [Unit21DataClassifier.ALERT]: PrimaryObject.ALERT,
  [Unit21DataClassifier.ADDRESS]: SecondaryObject.ADDRESS,
  [Unit21DataClassifier.CASE]: undefined,
  [Unit21DataClassifier.RULE]: undefined,
  [Unit21DataClassifier.WATCHLIST]: undefined,
};

// certain native data settings' native_keys do not match the keys used in our schema, so this map rectifies that.
// to use it when doing data-settings-based lookups, do something like:
// response[DATA_SETTINGS_ENUM_TO_NATIVE_KEY[ds.unit21_data_classifier][ds.native_key] ?? ds.native_key]
export const NATIVE_DATA_SETTINGS_NATIVE_KEY_MISMATCH_MAP: Record<
  Unit21DataClassifier,
  Record<string, string>
> = {
  [Unit21DataClassifier.ACTION_EVENT]: {
    event_id: 'external_id',
  },
  [Unit21DataClassifier.EVENT]: {
    conversion_notes: 'usd_conversion_notes',
    event_id: 'external_id',
    fee: 'internal_fee',
    type: 'internal_txn_type',
    ip_address: 'ip_address.ip_address',
  },
  [Unit21DataClassifier.ENTITY]: {
    business_name: 'name',
    entity_type: 'type',
    entity_subtype: 'internal_entity_type',
  },
  [Unit21DataClassifier.INSTRUMENT]: {
    instrument_id: 'external_id',
  },
  [Unit21DataClassifier.ALERT]: {},
  [Unit21DataClassifier.ADDRESS]: {
    postal_code: 'zip_code',
  },
  [Unit21DataClassifier.CASE]: {},
  [Unit21DataClassifier.RULE]: {},
  [Unit21DataClassifier.WATCHLIST]: {},
};

export const DATA_SETTING_FIELD_TYPE_TO_COLUMN_TYPE: Partial<
  Record<DataSettingFieldType, U21TableColumnTypes>
> = {
  [DataSettingFieldType.BOOLEAN]: U21TableColumnTypes.BOOLEAN,
  [DataSettingFieldType.DATE]: U21TableColumnTypes.DATE,
  [DataSettingFieldType.DATE_TIME]: U21TableColumnTypes.DATETIME,
};

export const NUMBER_PRECISION_RENDERING_OPTIONS: U21SelectOptionProps[] = [
  {
    text: 'Raw value',
    value: null,
  },
  ...Array.from({ length: 9 }, (_, i) => ({
    text: i.toString(),
    value: i,
  })),
];

export const FLOW_TO_READABLE_DIRECTIONALITY: Record<ConfiguredFlow, string> = {
  INBOUND: 'Receiving',
  OUTBOUND: 'Sending',
  OTHER: 'Other',
};

export const CONFIGURED_FLOW_OPTIONS: U21SelectOptionProps<ConfiguredFlow>[] = [
  {
    value: 'OUTBOUND' as const,
    text: FLOW_TO_READABLE_DIRECTIONALITY.OUTBOUND,
  },
  {
    value: 'INBOUND' as const,
    text: FLOW_TO_READABLE_DIRECTIONALITY.INBOUND,
  },
  { value: 'OTHER' as const, text: FLOW_TO_READABLE_DIRECTIONALITY.OTHER },
];

export enum ReferenceDisplayField {
  NAME_READABLE = 'name_readable',
  EXTERNAL_ID = 'external_id',
}

export const DEFAULT_REFERENCE_DISPLAY_FIELDS = [
  ReferenceDisplayField.NAME_READABLE,
];

export const REFERENCE_DISPLAY_FIELDS_OPTIONS: U21SelectOptionProps[] = [
  { value: ReferenceDisplayField.EXTERNAL_ID, text: 'Entity ID' },
  { value: ReferenceDisplayField.NAME_READABLE, text: 'Name' },
];

export const BASE_DATA_SETTINGS_BY_NATIVE_KEY = Object.values(
  Unit21DataClassifier,
).reduce(
  (acc, classifier) => ({
    ...acc,
    [classifier]: {},
  }),
  {} as Record<Unit21DataClassifier, Record<string, OrgDataSettingsConfig>>,
);

export const MAX_ALLOWED_ENUMS = 250;

export const RENDERING_OPTIONS_BY_DATA_TYPE: Record<
  DataSettingFieldType,
  (keyof RenderingOptionsResponse)[]
> = {
  [DataSettingFieldType.DATE]: [],
  [DataSettingFieldType.DATE_TIME]: ['timezone'],
  [DataSettingFieldType.NUMBER]: [
    'precision',
    'is_url',
    'url_prefix',
    'url_postfix',
  ],
  [DataSettingFieldType.TEXT]: ['is_url', 'url_prefix', 'url_postfix'],
  [DataSettingFieldType.LIST]: [],
  [DataSettingFieldType.ENUM]: ['enum_mapping'],
  [DataSettingFieldType.BOOLEAN]: [],
  [DataSettingFieldType.ENTITY_REFERENCE]: ['reference_display_fields'],
  [DataSettingFieldType.INSTRUMENT_REFERENCE]: [],
  [DataSettingFieldType.JSON]: [],
  [DataSettingFieldType.ANY]: [],
};

export const RULES_ORDER: RuleStatus[] = [
  'ACTIVE',
  'ACTIVE_SHADOW',
  'VALIDATION',
  'INACTIVE',
];

export const REFERENCE_TYPES = new Set([
  DataSettingFieldType.ENTITY_REFERENCE,
  DataSettingFieldType.INSTRUMENT_REFERENCE,
]);
export const DATA_TYPES_WITH_RENDERING_OPTIONS = new Set(
  Object.entries(RENDERING_OPTIONS_BY_DATA_TYPE)
    .filter((entries) => entries[1].length)
    .map((entries) => entries[0] as DataSettingFieldType),
);
