import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import {
  FormattedData,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { FullEntityResponse } from 'app/modules/entities/types';
import { FullTxnResponse } from 'app/modules/transactions/types/responses';
import {
  selectOtherFields,
  selectSummaryViewDataByClassifier,
} from 'app/modules/summarySettings/selectors';
import { SummaryViewConfigTypeClassifier } from 'app/modules/summarySettings/types';
import { TxnInstrumentDetails } from 'app/modules/txnInstruments/models';
import {
  U21Spacer,
  U21ShowMoreList,
  U21Chip,
  U21NoData,
  U21Subsection,
  U21DataDisplay,
  U21SubsectionProps,
} from 'app/shared/u21-ui/components';
import { Geolocation } from 'app/modules/entities/models';
import { SidebarDataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import objectIsEmpty from 'app/shared/utils/objectIsEmpty';
import { useMemo } from 'react';

const mapFieldsForSidebarSection = (
  f: OrgDataSettingsConfig,
  details: FullEntityResponse | TxnInstrumentDetails | FullTxnResponse,
  showEmptyFields: boolean,
) => {
  const formattedData: FormattedData = details.formatted_data;
  return (
    <SidebarDataRow
      key={f.id}
      label={<SidebarDataSettingDataLabel dataSetting={f} />}
      showEmptyFields={showEmptyFields}
      value={
        !objectIsEmpty(formattedData[f.id]?.formatted_value) ? (
          <DataSettingDataDisplay
            dataSetting={f}
            formattedData={formattedData}
          />
        ) : null
      }
    />
  );
};

interface BaseSidebarSummaryProps {
  showEmptyFields: boolean; // required so people don't forget this
}

type SidebarSummaryProps =
  | ({
      details?: FullEntityResponse;
      classifier: SummaryViewConfigTypeClassifier.ENTITY;
    } & BaseSidebarSummaryProps)
  | ({
      details?: TxnInstrumentDetails;
      classifier: SummaryViewConfigTypeClassifier.INSTRUMENT;
    } & BaseSidebarSummaryProps)
  | ({
      details?: FullTxnResponse;
      classifier: SummaryViewConfigTypeClassifier.EVENT;
      showEmptyFields: boolean; // required so people don't forget this
    } & BaseSidebarSummaryProps);

export const SidebarSummary = ({
  details,
  classifier,
  showEmptyFields,
}: SidebarSummaryProps) => {
  const config = useSelector((state: RootState) =>
    selectSummaryViewDataByClassifier(state, classifier),
  );
  const otherFields = useSelector((state: RootState) =>
    selectOtherFields(state, classifier),
  );

  const renderableConfig = useMemo(() => {
    if (!config || !details) {
      return [];
    }
    if (showEmptyFields) {
      return config;
    }
    return config.filter((i) => {
      return i.fields.some(
        (j) => !objectIsEmpty(details.formatted_data[j.id]?.formatted_value),
      );
    });
  }, [config, details, showEmptyFields]);

  const hasOtherContent = useMemo(() => {
    if (!details) {
      return false;
    }
    if (showEmptyFields) {
      return true;
    }
    return otherFields.some(
      (i) => !objectIsEmpty(details.formatted_data[i.id]?.formatted_value),
    );
  }, [details, otherFields, showEmptyFields]);

  if (!details) {
    return <U21NoData>Failed to load summary details</U21NoData>;
  }

  return (
    <U21Spacer spacing={0.5} marginEnd marginStart>
      {renderableConfig.map(({ group, fields }) => {
        return (
          <WrappedSubsection key={group} collapsible title={group}>
            {fields.map((f) =>
              mapFieldsForSidebarSection(f, details, showEmptyFields),
            )}
          </WrappedSubsection>
        );
      })}
      <WrappedSubsection collapsible collapsed title="Unit21 Metadata">
        <SidebarDataRow
          label="Unit21 ID"
          showEmptyFields={showEmptyFields}
          value={details.id}
        />
        {/* geolocations only on actions/transactions */}
        {'geolocations' in details && (
          <SidebarDataRow
            label="Geolocation data"
            showEmptyFields={showEmptyFields}
            value={
              !isEmpty(details.geolocations) ? (
                <StyledShowMoreList
                  value={details.geolocations}
                  displayFunc={(g: Geolocation) => (
                    <U21Chip key={g.geolocation_string} variant="outlined">
                      {g.geolocation_string}
                    </U21Chip>
                  )}
                />
              ) : null
            }
          />
        )}
        <SidebarDataRow
          label="Date created in Unit21"
          showEmptyFields={showEmptyFields}
          value={
            <U21DataDisplay variant="DATE_TIME" value={details.created_at} />
          }
        />
      </WrappedSubsection>
      {hasOtherContent && (
        <WrappedSubsection collapsible collapsed title="Other">
          {otherFields.map((f) =>
            mapFieldsForSidebarSection(f, details, showEmptyFields),
          )}
        </WrappedSubsection>
      )}
    </U21Spacer>
  );
};

const WrappedSubsection = ({ children, ...restProps }: U21SubsectionProps) => (
  <U21Subsection {...restProps} shaded>
    {children}
  </U21Subsection>
);

const StyledShowMoreList = styled(U21ShowMoreList)`
  width: 100%;
  overflow: hidden;
`;
