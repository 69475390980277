export const naicsCodes = [
  `Accommodation (721)`,
  `Accounting, Tax Preparation, Bookkeeping, and Payroll Services (5412)`,
  `Activities Related to Credit Intermediation (5223)`,
  `Activities Related to Real Estate (5313)`,
  `Administration of Economic Programs (926)`,
  `Administration of Environmental Quality Programs (924)`,
  `Administration of Housing Programs, Urban Planning, and Community Development (925)`,
  `Administration of Human Resource Programs (923)`,
  `Administrative and Support Services (561)`,
  `Advertising, Public Relations, and Related Services (5418)`,
  `Agencies, Brokerages, and Other Insurance Related Activities (5242)`,
  `Air Transportation (481)`,
  `Amusement Parks and Arcades (7131)`,
  `Animal Production (112)`,
  `Apparel Manufacturing (315)`,
  `Apparel, Piece Goods, and Notions Merchant Wholesalers (4243)`,
  `Architectural, Engineering, and Related Services (5413)`,
  `Automobile Dealers (4411)`,
  `Automotive Equipment Rental and Leasing (5321)`,
  `Automotive Parts, Accessories, and Tire Stores (4413)`,
  `Beer, Wine, and Distilled Alcoholic Beverage Merchant Wholesalers (4248)`,
  `Beverage and Tobacco Product Manufacturing (312)`,
  `Broadcasting (except Internet) (515)`,
  `Building Material and Garden Equipment and Supplies Dealers (444)`,
  `Business, Professional, Labor, Political, and Similar Organizations (8139)`,
  `Chemical and Allied Products Merchant Wholesalers (4246)`,
  `Chemical Manufacturing (325)`,
  `Civic and Social Organizations (8134)`,
  `Clothing Stores (4481)`,
  `Commercial and Industrial Machinery and Equipment Rental and Leasing (5324)`,
  `Community Care Facilities for the Elderly (6233)`,
  `Computer and Electronic Product Manufacturing (334)`,
  `Computer Systems Design and Related Services (5415)`,
  `Construction of Buildings (236)`,
  `Consumer Goods Rental (5322)`,
  `Couriers and Express Delivery Services (4921)`,
  `Crop Production (111)`,
  `Data Processing, Hosting and Related Services (518)`,
  `Depository Credit Intermediation (5221)`,
  `Direct Selling Establishments (4543)`,
  `Drugs and Druggists' Sundries Merchant Wholesalers (4242)`,
  `Educational Services (611)`,
  `Electrical and Electronic Goods Merchant Wholesalers (4236)`,
  `Electrical Equipment, Appliance, and Component Manufacturing (335)`,
  `Electronic Shopping and MaiI-Order Houses (4541)`,
  `Electronics and Appliance Stores (443)`,
  `Executive, Legislative, and Other General Government Support (921)`,
  `Fabricated Metal Product Manufacturing (332)`,
  `Farm Product Raw Material Merchant Wholesalers (4245)`,
  `Fishing, Hunting and Trapping (114)`,
  `Food and Beverage Stores (445)`,
  `Food Manufacturing (311)`,
  `Food Services and Drinking Places (722)`,
  `Forestry and Logging (113)`,
  `Furniture and Home Furnishing Merchant Wholesalers (4232)`,
  `Furniture and Home Furnishings Stores (442)`,
  `Furniture and Related Product Manufacturing (337)`,
  `Gambling Industries (7132)`,
  `Gasoline Stations (447)`,
  `General Medical and Surgical Hospitals (6221)`,
  `General Merchandise Stores (452)`,
  `General Rental Centers (5323)`,
  `Grantmaking and Giving Services (8132)`,
  `Grocery and Related Product Merchant Wholesalers (4244)`,
  `Hardware, and Plumbing and Heating Equipment and Supplies Merchant Wholesalers (4237) `,
  `Health and Personal Care Stores (446)`,
  `Heavy and Civil Engineering Construction (237)`,
  `Home Health Care Services (6216)`,
  `Insurance and Employee Benefit Funds (5251)`,
  `Insurance Carriers (5241)`,
  `Jewelry, Luggage, and Leather Goods Stores (4483)`,
  `Justice, Public Order, and Safety Activities (922)`,
  `Leather and Allied Product Manufacturing (316)`,
  `Legal Services (5411)`,
  `Lessors of Nonfinancial Intangible Assets (except Copyrighted Works) (533)`,
  `Lessors of Real Estate (5311)`,
  `Local Messengers and Local Delivery (4922)`,
  `Lumber and Other Construction Materials Merchant Wholesalers (4233)`,
  `Machinery Manufacturing (333)`,
  `Machinery, Equipment, and Supplies Merchant Wholesalers (4238)`,
  `Management of Companies and Enterprises (551)`,
  `Management, Scientific, and Technical Consulting Services (5416)`,
  `Medical and Diagnostic Laboratories (6215)`,
  `Metal and Mineral (except Petroleum) Merchant Wholesalers (4235)`,
  `Mining (except Oil and Gas) (212)`,
  `Miscellaneous Durable Goods Merchant Wholesalers (4239)`,
  `Miscellaneous Manufacturing (339)`,
  `Miscellaneous Nondurable Goods Merchant Wholesalers (4249)`,
  `Miscellaneous Store Retailers (453)`,
  `Monetary Authorities-Central Bank (521)`,
  `Mortgage and nonmortgage loan brokers (52231)`,
  `Motion Picture and Sound Recording Industries (512)`,
  `Motor Vehicle and Motor Vehicle Parts and Supplies Merchant Wholesalers (4231)`,
  `Museums, Historical Sites, and Similar Institutions (712)`,
  `National Security and International Affairs (928)`,
  `Nondepository Credit Intermediation (5222)`,
  `Nonmetallic Mineral Product Manufacturing (327)`,
  `Nursing Care Facilities (6231)`,
  `Offices of Dentists (6212)`,
  `Offices of Other Health Practitioners (6213)`,
  `Offices of Physicians (6211)`,
  `Offices of Real Estate Agents and Brokers (5312)`,
  `Oil and Gas Extraction (211)`,
  `Other Ambulatory Health Care Services (6219)`,
  `Other Amusement and Recreation Industries (7139)`,
  `Other Financial Investment Activities (5239)`,
  `Other Information Services (519)`,
  `Other Investment Pools and Funds (5259)`,
  `Other Motor Vehicle Dealers (4412)`,
  `Other Pipeline Transportation (4869)`,
  `Other Professional, Scientific, and Technical Services (5419)`,
  `Other Residential Care Facilities (6239)`,
  `Other Telecommunications (5179)`,
  `Outpatient Care Centers (6214)`,
  `Paper and Paper Product Merchant Wholesalers (4241)`,
  `Paper Manufacturing (322)`,
  `Performing Arts, Spectator Sports, and Related Industries (711)`,
  `Personal and Laundry Services (812)`,
  `Petroleum and Coal Products Manufacturing (324)`,
  `Petroleum and Petroleum Products Merchant Wholesalers (4247)`,
  `Pipeline Transportation of Crude Oil (4861)`,
  `Pipeline Transportation of Natural Gas (4862)`,
  `Plastics and Rubber Products Manufacturing (326)`,
  `Postal Service (491)`,
  `Primary Metal Manufacturing (331)`,
  `Printing and Related Support Activities (323)`,
  `Private Households (814)`,
  `Professional and Commercial Equipment and Supplies Merchant Wholesalers (4234)`,
  `Psychiatric and Substance Abuse Hospitals (6222)`,
  `Publishing Industries (except Internet) (511)`,
  `Rail Transportation (482)`,
  `Religious Organizations (8131)`,
  `Repair and Maintenance (811)`,
  `Residential Mental Retardation, Mental Health and Substance Abuse Facilities (6232)`,
  `Satellite Telecommunications (5174)`,
  `Scenic and Sightseeing Transportation (487)`,
  `Scientific Research and Development Services (5417)`,
  `Securities and Commodity Contracts Intermediation and Brokerage (5231)`,
  `Securities and Commodity Exchanges (5232)`,
  `Shoe Stores (4482)`,
  `Social Advocacy Organizations (8133)`,
  `Social Assistance (624)`,
  `Space Research and Technology (927)`,
  `Specialized Design Services (5414)`,
  `Specialty (except Psychiatric and Substance Abuse) Hospitals (6223)`,
  `Specialty Trade Contractors (238)`,
  `Sporting Goods, Hobby, Book, and Music Stores (451)`,
  `Support Activities for Agriculture and Forestry (115)`,
  `Support Activities for Mining (213)`,
  `Support Activities for Transportation (488)`,
  `Textile Mills (313)`,
  `Textile Product Mills (314)`,
  `Transit and Ground Passenger Transportation (485)`,
  `Transportation Equipment Manufacturing (336)`,
  `Truck Transportation (484)`,
  `Utilities (221)`,
  `Vending Machine Operators (4542)`,
  `Warehousing and Storage (493)`,
  `Waste Management and Remediation Services (562)`,
  `Water Transportation (483)`,
  `Wholesale Electronic Markets and Agents and Brokers (425)`,
  `Wired Telecommunications Carriers (5171)`,
  `Wireless Telecommunications Carriérs (ex'cept Satellite) (5172)`,
  `Wood Product Manufacturing (321)`,
];
