// Components
import { IconBuildingBank } from '@u21/tabler-icons';
import { SidebarTitleIcon } from 'app/modules/sidebar/components/SidebarTitleIcon';
import { U21Typography } from 'app/shared/u21-ui/components';

// Models
import { SarSummary } from 'app/modules/fincenSar/models';
import { REPORT_TYPE_PATH_MAP } from 'app/modules/fincenSar/constants/shared';
import { SidebarExternalLinkList } from 'app/modules/sidebar/components/SidebarExternalLinkList';
import { useMemo } from 'react';
import { formatDate, formatTime } from 'app/shared/utils/date';

export const SidebarAssociatedSARs = ({ sars }: { sars: SarSummary[] }) => {
  const list = useMemo(
    () =>
      sars.map((i) => ({
        label: (
          <>
            {formatDate(i.created_at)}
            <br />
            {formatTime(i.created_at)}
          </>
        ),
        id: i.id,
        value: i.name,
        route: REPORT_TYPE_PATH_MAP[i.report_type].replace(':id', String(i.id)),
      })),
    [sars],
  );
  return (
    <div>
      <SidebarTitleIcon
        count={sars.length}
        title="Associated Filings"
        icon={<IconBuildingBank />}
      />

      {sars.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No associated filings
        </U21Typography>
      )}
    </div>
  );
};
