import { QueueAccessType } from 'app/modules/queues/models';

export enum AlertType {
  TM = 'TM',
  KYC = 'KYC',
  CHAINALYSIS = 'CHAINALYSIS',
  CAR = 'CAR',
  MANUAL = 'MANUAL',
  WATCHLIST = 'WATCHLIST',
  DARK_WEB = 'DARK_WEB',
}

export enum AlertSubtype {
  CHECK_FRAUD = 'CHECK_FRAUD',
  ADVERSE_MEDIA = 'ADVERSE_MEDIA',
  PEP = 'PEP',
  SANCTIONS = 'SANCTIONS',
}

export enum AlertSource {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL',
}

export interface AssociatedAlert {
  id: number;
  title: string;
  entity_ids: (number | string)[];
  entity_external_ids: string[];
  description: string;
  disposition: string;
  status: string;
  created_at: string | null;
  queue_id: number | null;
  queue_access_type: `${QueueAccessType}`;
}

export interface AssociatedCase {
  id: number;
  title: string | number;
  entity_ids: (number | string)[];
  event_ids: (number | string)[];
  action_event_ids: number[];
  queue_id: number | null;
  queue_access_type: `${QueueAccessType}`;
}

export interface AssociatedSAR {
  id: number;
  name: string | number;
  entity_ids: number[];
  event_ids: (number | string)[];
  queue_id: number | null;
  queue_access_type: `${QueueAccessType}`;
}
