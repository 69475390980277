// Components
import { IconBriefcase } from '@u21/tabler-icons';
import { SidebarTitleIcon } from 'app/modules/sidebar/components/SidebarTitleIcon';
import { U21Typography } from 'app/shared/u21-ui/components';

// Models
import { CaseSummary } from 'app/modules/casesOld/models';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { SidebarExternalLinkList } from 'app/modules/sidebar/components/SidebarExternalLinkList';
import { useMemo } from 'react';
import { formatDate, formatTime } from 'app/shared/utils/date';

export const SidebarAssociatedCases = ({ cases }: { cases: CaseSummary[] }) => {
  const list = useMemo(
    () =>
      cases.map((i) => ({
        label: (
          <>
            {formatDate(i.created_at)}
            <br />
            {formatTime(i.created_at)}
          </>
        ),
        id: i.id,
        value: i.title,
        route: ROUTES_MAP.casesId.path.replace(':id', String(i.id)),
      })),
    [cases],
  );
  return (
    <div>
      <SidebarTitleIcon
        count={cases.length}
        title="Associated Cases"
        icon={<IconBriefcase />}
      />
      {cases.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No associated cases
        </U21Typography>
      )}
    </div>
  );
};
