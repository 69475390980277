import { Icon } from '@iconify/react';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------
// CUSTOM CHANGES
// - removed label negative margin
// - removed outline variant custom border colors
// - added label padding
// - added icon margins
// - added root styles

export default function Chip(theme: Theme) {
  return {
    MuiChip: {
      defaultProps: {
        deleteIcon: <Icon icon={closeCircleFill} />,
      },
      styleOverrides: {
        colorDefault: {
          '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
            color: theme.palette.text.secondary,
          },
        },
        icon: {
          marginLeft: 8,
        },
        label: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        root: {
          userSelect: 'auto',
        },
      },
    },
  };
}
