import { DropdownItemProps } from 'semantic-ui-react';

// Types
import {
  FullSARFilingResponse,
  ShortSARFilingResponse,
} from 'app/modules/fincenSar/types';

// Models
import { Page2BranchInformationResponse } from 'app/modules/fincenSar/tests/mock-retrieve-sar-response';
import { PaginationPayload } from 'app/shared/models';

// Calling SAR instead of filing. Assuming we will have just SAR s for a while, no need to add nested state until we need to (having a key of sar in this state)
export enum SarActionTypes {
  CREATE_SAR = '@@sars/CREATE_SAR',
  CREATE_SAR_SUCCESS = '@@sars/CREATE_SAR_SUCCESS',

  CREATE_NEW_SAR = '@@sars/CREATE_NEW_SAR',

  RETRIEVE_SIDEBAR_SAR = '@@sars/RETRIEVE_SIDEBAR_SAR',
  RETRIEVE_SIDEBAR_SAR_SUCCESS = '@@sars/RETRIEVE_SIDEBAR_SAR_SUCCESS',

  RETRIEVE_SAR_LIST = '@@sars/RETRIEVE_SAR_LIST',
  RETRIEVE_SAR_LIST_SUCCESS = '@@sars/RETRIEVE_SAR_LIST_SUCCESS',

  REASSIGN_SAR_SUCCESS = '@@sars/REASSIGN_SAR_SUCCESS',

  ARCHIVE_SAR = '@@sars/ARCHIVE_SAR',
  ARCHIVE_SAR_SUCCESS = '@@sars/ARCHIVE_SAR_SUCCESS',

  EDIT_SAR_REVIEWER_SUCCESS = '@@sars/EDIT_REVIEWER_SUCCESS',
}

export enum SarType {
  INITIAL_REPORT = 'initial_report',
  CORRECT_OR_AMEND_PRIOR_REPORT = 'correct_or_amend_prior_report',
  CONTINUING_ACTIVITY_REPORT = 'continuing_activity_report',
}

export enum SarStatus {
  ARCHIVED = 'ARCHIVED',
  PREFILING_VALIDATION_PENDING = 'PREFILING_VALIDATION_PENDING',
  PREFILING_VALIDATION_IN_PROCESS = 'PREFILING_VALIDATION_IN_PROCESS',
  PREFILING_VALIDATION_FAILED = 'PREFILING_VALIDATION_FAILED',
  PREFILING_VALIDATION_SUCCESS = 'PREFILING_VALIDATION_SUCCESS',
  QUEUED_FOR_FILING = 'QUEUED_FOR_FILING',
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  SENT_TO_FINCEN = 'SENT_TO_FINCEN',
  SENDING_TO_FINCEN = 'SENDING_TO_FINCEN',
  FILING_FAILED = 'FILING_FAILED',
  FILING_MAX_RETRIES_EXCEEDED = 'FILING_MAX_RETRIES_EXCEEDED',
  FILING_SUCCESS = 'FILING_SUCCESS',
  FINCEN_VALIDATION_FAILED = 'FINCEN_VALIDATION_FAILED',
  FINCEN_REJECTED = 'FINCEN_REJECTED',
  FINCEN_STATUS_OTHER = 'FINCEN_STATUS_OTHER',
  FINCEN_ACCEPTED = 'FINCEN_ACCEPTED',
}

export interface Page0Model {
  content: Page0Content;
  valid: boolean;
}

export interface Page1Model {
  content: Page1Content;
  valid: boolean;
}
export interface Page2Model {
  content: Page2Content;
  valid: boolean;
}
export interface Page3Model {
  content: Page3Content;
  valid: boolean;
}
export interface Page4Model {
  content: Page4Content;
  valid: boolean;
}
export interface Page5Model {
  content: Page5Content;
  valid: boolean;
}

export interface Page2BranchInformationModel
  extends Page2BranchInformationResponse {}

export type Page1ContentModel = Page1Content;
export type Page2ContentModel = Page2Content;
export type Page3ContentModel = Page3Content;

export type AllSarValues = string | boolean | CheckboxValues | string[];

export type SarFilingContent = {
  '0': Page0Model;
  '1': Page1Model;
  '2': Page2Model;
  '3': Page3Model;
  '4': Page4Model;
  '5': Page5Model;
};

export interface SarFiltersRequest extends PaginationPayload {
  sar_ids?: number[];
  agent_ids?: number[];
  reviewer_ids?: number[];
  created_at_start?: string | null;
  created_at_end?: string | null;
  updated_at_start?: string | null;
  updated_at_end?: string | null;
  filed_at_start?: string | null;
  filed_at_end?: string | null;
  statuses?: string[];
  submitted?: boolean;
  search_id?: string;
  entity_external_ids?: string[];
  report_type?: string;
  queue_ids?: number[];
  my_queues_only?: boolean;
  country?: string;
  state?: string;
  city?: string;
}

export interface AddressOptions<T = AllSarValues> {
  value: T;
  disabled: boolean;
  required: boolean;
  popupContent?: string | undefined;
  title: string;
  name: string;
  initialValue: T;
}

export interface ValidationInfo {
  valid: boolean;
  message: string;
}

export interface SarOptions {
  popupContent?: string;
  title: string;
  name: string;
  initialValue: AllSarValues;
  unknown?: boolean;
  maxLen?: number;
}

export interface SarReportTypeOptions {
  [key: string]: string;
}

export interface SarUnknownCheckbox extends SarOptions {
  initialValue: boolean;
}

export interface CheckboxValues {
  [key: string]: boolean;
}

export interface SarDropdownOptions extends SarOptions {
  options: DropdownItemProps[];
  dynamicOptions?: { [key: string]: DropdownItemProps[] };
  initialValue: string | string[];
}

export interface SarCheckboxOptions extends SarOptions {
  initialValue: CheckboxValues;
  options: string[];
}

export interface SarInput extends SarOptions {
  initialValue: string;
}

type ValidationFunction = (
  value: string | boolean | number | string[] | { [key: string]: boolean },
  type?: string | boolean,
  unknown?: boolean,
  validationOptions?: ValidationOptions,
) => ValidationInfo;

export interface Validations {
  [key: string]: ValidationFunction;
}

export type ValidationOptions = Record<string, any>;

export interface ValidationMessages {
  [key: string]: string;
}

export interface CreateSarPayload {
  name: string;
  description: string;
  content: SarFilingContent;
  report_type: ReportType;
  case_id?: number;
  alert_ids?: number[];
  case_ids?: number[];
  queue_id?: number;
}

export interface RetrieveSarPayload {
  id: string;
}

export interface SarSummary extends ShortSARFilingResponse {}

export interface ArchiveSarPayload {
  sar_filing_id: number;
}

export interface SarDetails extends FullSARFilingResponse {}

export interface SarIpAddress {
  '43_ipAddress': string;
  '43_ipAddressDate': string;
  '43_ipAddressTimestamp': string;
}

interface ValuesToClear {
  fieldToClear: string;
  // TODO fix typing
  initialValue: any;
}

export interface ValuesToClearModel {
  [key: string]: ValuesToClear[];
}

export interface SarState {
  sar: SarDetails;
  sidebarSar: SarDetails;
  num_filings: number;
  sars: SarSummary[];
  validationErrors: ValidationMessages;
}

export interface RetrieveSarListPayload {
  filings: SarSummary[];
  num_filings: number;
}

export interface UpdateObject {
  key: string;
  value: any;
}

export type BranchActivity =
  Page2ContentModel['forms'][number]['68-74_branches'];

export interface Page5Content {
  narrative: string;
}
export interface Page4Content {
  '29_amountInvolved': string;
  '29_typeOfAmountInvolved': {
    'Amount Unknown': boolean;
    'No Amount Involved': boolean;
  };
  '30_suspiciousActivityEndDate': string;
  '30_suspiciousActivityStartDate': string;
  '31_cumulativeAmount': string;
  '32_typeOfStructuring': {
    'Alters or cancels transaction to avoid BSA recordkeeping requirement': boolean;
    'Alters or cancels transaction to avoid CTR requirement': boolean;
    Other: boolean;
    'Suspicious inquiry by customer regarding  BSA reporting or recordkeeping requirements': boolean;
    'Transactions(s) below BSA recording threshold': boolean;
    'Transactions(s) below CTR threshold': boolean;
  };
  '32_typeOfStructuringOther': string;
  '33_typeOfTerroristFinancing': {
    'Known or suspected terrorist/terrorist organization': boolean;
    Other: boolean;
  };
  '33_typeOfTerroristFinancingOther': string;
  '34_typeOfFraud': {
    ACH: boolean;
    'Advance fee': boolean;
    'Business loan': boolean;
    Check: boolean;
    'Consumer loan': boolean;
    'Credit / Debit card': boolean;
    'Healthcare / Public or private health insurance': boolean;
    Mail: boolean;
    'Mass-marketing': boolean;
    Other: boolean;
    'Ponzi Scheme': boolean;
    'Pyramid Scheme': boolean;
    'Securities Fraud': boolean;
    Wire: boolean;
  };
  '34_typeOfFraudOther': string;
  '35_typeOfGamingActivities': {
    'Chip Walking': boolean;
    'Minimal Gaming with Large Transactions': boolean;
    Other: boolean;
    'Suspicious User of Counter Checks or Markers': boolean;
    'Unknown Source of Chips': boolean;
  };
  '35_typeOfGamingActivitiesOther': string;
  '36_typeOfMoneyLaundering': {
    'Exchange Small Bills for Large Bills or Vice  Versa': boolean;
    'Funnel Account': boolean;
    Other: boolean;
    'Suspicion Concerning the Physical Condition of Funds': boolean;
    'Suspicion Concerning the Source of Funds': boolean;
    'Suspicion Designation of Beneficiaries, Assignees or Joint Owners': boolean;
    'Suspicion EFT / Wire Transfers': boolean;
    'Suspicion Exchange of Currencies': boolean;
    'Suspicious Receipt of Government Payments / Benefits': boolean;
    'Suspicious Use of Multiple Accounts': boolean;
    'Suspicious Use of Noncash Monetary Instruments': boolean;
    'Suspicious Use of Third-Party Transactors (Straw-Man)': boolean;
    'Trade Based Money Laundering / Black Market Peso Exchange': boolean;
    'Transaction Out of Pattern for Customer(s)': boolean;
  };
  '36_typeOfMoneyLaunderingOther': string;
  '37_typeOfIdentificationDocumentation': {
    'Changes Spelling or Arrangement of Name': boolean;
    'Multiple Individuals with Same or Similar Identities': boolean;
    Other: boolean;
    'Provided Questionable or False Documentation': boolean;
    'Provided Questionable or False Identification': boolean;
    'Refused or Avoided Request for Documentation': boolean;
    'Single Individual with Multiple Identities': boolean;
  };
  '37_typeOfIdentificationDocumentationOther': string;
  '38_typeOfOtherSuspiciousActivities': {
    'Account Takeover': boolean;
    'Bribery or Gratuity': boolean;
    'Counterfeit Instruments': boolean;
    'Elder Financial Exploitation': boolean;
    'Embezzlement/theft/\ndisappearance of funds': boolean;
    Forgeries: boolean;
    'Human Smuggling': boolean;
    'Human Trafficking': boolean;
    'Identity Theft': boolean;
    'Little or No Concern for Product Performance Penalities, Fees, Or Tax Consequences': boolean;
    'Misuse of Position or Self-Dealing': boolean;
    Other: boolean;
    'Suspected Public /Private Corruption (Domestic)': boolean;
    'Suspected public/private corruption (foreign)': boolean;
    'Suspicious Use of Informal Value Transfer': boolean;
    'Suspicious Use of Multiple Transaction Locations': boolean;
    'Transaction with No Apparent Economic, Business, or Lawful Purpose': boolean;
    'Transaction(s) Involving Foreign High Risk Jurisdiction': boolean;
    'Two or More Individual Working Together': boolean;
    'Unlicensed or Unregistered MSB': boolean;
  };
  '38_typeOfOtherSuspiciousActivitiesOther': string;
  '39_disabled': boolean;
  '39_typeOfInsurance': {
    'Excessive Insurance': boolean;
    'Excessive or Unusual Cash Borrowing Against Policy / Annuity': boolean;
    Other: boolean;
    'Proceeds Sent To or Received from Unrelated  Third Party': boolean;
    'Suspicious Life Settlement Sales Insurance (e.g. STOLIs, Viaticals)': boolean;
    'Suspicious Termination of Policy or Contract': boolean;
    'Unclear or No Insurable Interest': boolean;
  };
  '39_typeOfInsuranceOther': string;
  '40_disabled': boolean;
  '40_typeOfSecuritiesFuturesOptions': {
    'Insider Trading': boolean;
    'Market Manipulation': boolean;
    Misappropriation: boolean;
    Other: boolean;
    'Unauthorized Pooling': boolean;
    'Wash Trading': boolean;
  };
  '40_typeOfSecuritiesFuturesOptionsOther': string;
  '41_typeOfMortgageFraud': {
    'Application Fraud': boolean;
    'Appraisal Fraud': boolean;
    'Foreclosure / Short Sale Fraud': boolean;
    'Loan Modification Fraud': boolean;
    'Origination Fraud': boolean;
    Other: boolean;
  };
  '41_typeOfMortgageFraudOther': string;
  '42_typeOfCyberEvent': {
    'Against Financial Institution Customer(s)': boolean;
    'Against Financial Institution(s)': boolean;
    Other: boolean;
  };
  '42_typeOfCyberEventOther': string;
  '43_ipAddress': SarIpAddress[];
  '44_cyberEventIndicatorDescription': string;
  '44_cyberEventIndicators': [
    {
      '44_cyberEventIndicatorDate': string;
      '44_cyberEventIndicatorTimestamp': string;
      '44_cyberEventIndicatorValue': string;
      '44_cyberEventIndicators': string;
      '44_cyberEventIndicatorsOther': string;
    },
  ];
  '45_typeOfProductsInvolvedOther': string;
  '45_typesOfProductsInvolved': {
    'Bonds / Notes': boolean;
    'Commercial Mortgage': boolean;
    'Commercial Paper': boolean;
    'Credit Card': boolean;
    'Debit Card': boolean;
    'Deposit Account': boolean;
    'Forex Transactions': boolean;
    'Futures / Options on Futures': boolean;
    'Hedge Fund': boolean;
    'Home Equity Line of Credit': boolean;
    'Home Equity Loan': boolean;
    'Insurance / Annuity Products': boolean;
    'Microcap  Securities': boolean;
    'Mutual Fund': boolean;
    'Options of Securities': boolean;
    'Other (List Below)': boolean;
    'Prepaid Access': boolean;
    'Residential Mortgage': boolean;
    'Security Futures Products': boolean;
    Stocks: boolean;
    'Swap, Hybird, or other Derivatives': boolean;
  };
  '46_typeOfPaymentInstrumentInvolved': {
    'Bank / Cashiers Check': boolean;
    'Foreign Currency': boolean;
    'Funds Transfer': boolean;
    'Gaming Instruments': boolean;
    'Government  Payment': boolean;
    'Money Orders': boolean;
    'Other (List Below)': boolean;
    'Personal / Business Check': boolean;
    'Travelers Check': boolean;
    'U.S. Currency': boolean;
  };
  '46_typeOfPaymentInstrumentInvolvedOther': string;
  '47_commodityType': [string];
  '48_productInstrumentDescription': [string];
  '49_marketWhereTraded': [string];
  '50_cusipNumber': string[];
}

export interface Page3Content {
  forms: Page3ItemModel[];
}
export interface Page3ItemModel {
  '10_occupation': string;
  '10a_naicsCode': string;
  '11-15_addressInformation': [
    {
      '11_address': string;
      '11_addressUnknown': boolean;
      '12_city': string;
      '12_cityUnknown': boolean;
      '13_state': string;
      '13_stateUnknown': boolean;
      '14_zipcode': string;
      '14_zipcodeUnknown': boolean;
      '15_country': string;
      '15_countryUnknown': boolean;
    },
  ];
  '16_taxPayerIdentificationNumber': string;
  '16_taxPayerIdentificationNumberUnknown': boolean;
  '17_taxPayerIdentificationType': string;
  '18_dateOfBirthUnknown': boolean;
  '18_formsOfId': [
    {
      '18_country': string;
      '18_idNumber': string;
      '18_idType': string;
      '18_state': string;
      '18z_OtherPartyIdentificationTypeText': string;
      disabled: boolean;
    },
  ];
  '19_dateOfBirth': string;
  '20-21_phoneNumberValues': [
    {
      '20_phoneNumberType': string;
      '21_phoneNumber': string;
    },
  ];
  '22_emailAddress': string[];
  '22a._websiteURL': string[];
  '23_statementToFiler': string;
  '24_relationships': [
    {
      '24_institutionTIN': string;
      '24_relationship': {
        Accountant: boolean;
        Agent: boolean;
        Appraiser: boolean;
        Attorney: boolean;
        Borrower: boolean;
        Customer: boolean;
        Director: boolean;
        Employee: boolean;
        Officer: boolean;
        Other: boolean;
        'Owner or Controlling Shareholder': boolean;
      };
      '24_relationshipOther': string;
      '25_status': string;
      '26_actionDate': string;
      disabled: boolean;
    },
  ];
  '27_accountNumbers': [
    {
      '27_accountNumbers': [
        {
          '27_accountNumber': string;
          '27_accountNumberClosed': boolean;
        },
      ];
      '27_tin': string;
      '27_tinUnknown': boolean;
      disabled: boolean;
    },
  ];
  '28_roleInActivity': string;
  '3_entityInformationUnknown': {
    'All critical information unavailable (does not include item 24)': boolean;
    'If entity': boolean;
  };
  '4_legalName': string;
  '4_legalNameUnknown': boolean;
  '5_firstName': string;
  '5_firstNameUnknown': boolean;
  '6_middleName': string;
  '7_suffix': string;
  '8_gender': string;
  '9_alternativeName': string[];
}

export interface Page2Content {
  forms: Page2ItemModel[];
}
export interface Page2ItemModel {
  '51_typeOfFinancialInstitution': string;
  '51_typeOfFinancialInstitutionOther': string;
  '52_primaryFederalRegulator': string;
  '53_typeOfGamingInstitution': {
    'Card club': boolean;
    Other: boolean;
    'State licensed casino': boolean;
    'Tribal authorized casino': boolean;
  };
  '53_typeOfGamingInstitutionOther': string;
  '54_typeOfSecuritiesAndFutures': {
    'Clearing broker-securities': boolean;
    'Execution-only broker securities': boolean;
    'Futures Commission Merchant': boolean;
    'Holding Company': boolean;
    'Introducing broker-commodities': boolean;
    'Introducing broker-securities': boolean;
    'Investment adviser': boolean;
    'Investment company': boolean;
    Other: boolean;
    'Retail foreign exchange dealer': boolean;
    'Self-clearing broker-securities': boolean;
    'Subsidiary of financial/bank': boolean;
  };
  '54_typeOfSecuritiesAndFuturesOther': string;
  '55_financialInstitutionIdentificationNumber': string;
  '55_financialInstitutionIdentificationType': string;
  '56_financialInstitutionRoleInTransaction': {
    Both: boolean;
    'Paying location': boolean;
    'Selling location': boolean;
  };
  '57_financialInstitutionLegalName': string;
  '57_financialInstitutionLegalNameUnknown': boolean;
  '58_financialInstitutionAlternateName': string;
  '59_taxPayerIdentificationNumber': string;
  '59_taxPayerIdentificationNumberUnknown': boolean;
  '60_taxPayerIdentificationType': string;
  '61_address': string;
  '61_addressUnknown': boolean;
  '62_city': string;
  '62_cityUnknown': boolean;
  '63_state': string;
  '63_stateUnknown': boolean;
  '64_zip': string;
  '64_zipUnknown': boolean;
  '65_country': string;
  '65_countryUnknown': boolean;
  '66_internalControlFileNumber': string;
  '67_lossToFinancialInstitution': string;
  '68-74_branches': {
    '68_roleInTransaction': {
      Both: boolean;
      'Paying location': boolean;
      'Selling location': boolean;
    };
    '69_address': string;
    '69a_noBranchActivityInvolvedIndicator': boolean;
    '70_rssdNumber': string;
    '71_city': string;
    '72_state': string;
    '73_zip': string;
    '74_country': string;
  }[];
}
export interface Page1Content {
  '75_primaryFederalRegulator': string;
  '76_filerName': string;
  '77_taxpayerIdentificationNumber': string;
  '78_taxpayerIdentificationType': string;
  '79_typeOfFinancialInstitution': string;
  '79_typeOfFinancialInstitutionOther': string;
  '80_typeOfSecuritiesAndFutures': {
    'CPO/CTA': boolean;
    'Clearing broker-securities': boolean;
    'Execution-only broker securities': boolean;
    'Futures Commission Merchant': boolean;
    'Holding company': boolean;
    'Introducing broker-commodities': boolean;
    'Introducing broker-securities': boolean;
    'Investment Adviser': boolean;
    'Investment company': boolean;
    Other: boolean;
    'Retail foreign exchange dealer': boolean;
    'SRO Futures': boolean;
    'SRO Securities': boolean;
    'Self-clearing broker securities': boolean;
    'Subsidiary of financial/bank': boolean;
  };
  '80_typeOfSecuritiesAndFuturesOther': string;
  '81_financialInstitutionIdentificationNumber': string;
  '81_financialInstitutionIdentificationType': string;
  '82_address': string;
  '83_city': string;
  '84_state': string;
  '85_zip': string;
  '86_country': string;
  '87_alternateName': string;
  '88_internalControlFileNumber': string;
  '89_leContactAgency': string;
  '90_leContactName': string;
  '91_leContactPhoneNumber': string;
  '92_leContactDate': string;
  '93_filingInstitutionContactOffice': string;
  '94_filingInstitutionContactPhone': string;
  '95_dateFiled': string;
}
export interface Page0Content {
  '1_typeOfFiling': {
    'Continuing activity report': boolean;
    'Correct/Amend prior report': boolean;
    'Initial report': boolean;
    'Joint report': boolean;
  };
  '2_filingInstitutionNote': string;
  filingName: string;
  priorBsaId: string;
}

export enum ReportType {
  FINCEN_SAR = 'FINCEN_SAR',
  GOAML_STR = 'GOAML_STR',
  FINCEN_CTR = 'FINCEN_CTR',
  /**
   * @deprecated Kept for legacy filings
   */
  ESTONIAN_FIU = 'ESTONIAN_FIU',
  /**
   * @deprecated Kept for legacy filings
   */
  NCA_SAR = 'NCA_SAR',
}

export enum FincenSarTablePages {
  REPORT_FILING = 'REPORT_FILING',
  QUEUE_DETAILS = 'QUEUE_DETAILS',
  MY_FILINGS = 'MY_FILINGS',
  QUEUED_FILINGS = 'QUEUED_FILINGS',
  QUALITY_CYCLE_SARS = 'QUALITY_CYCLE_SARS',
}

export interface SarFilingTemplate<
  Content extends Record<string, any>,
  TType extends string,
> {
  id: number;
  org_id: number;
  template_type: TType;
  content: Content;
  created_at: string;
  updated_at?: string;
  agent_id?: number;
  tag_id?: number;
}
