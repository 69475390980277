import produce, { Draft } from 'immer';
import { keyBy } from 'lodash';

// Actions
import DropdownReduxActions from 'app/shared/dropdown/actions';
import { AdminActions } from 'app/modules/admin/actions';

// Models
import { DropdownState, DropdownActionTypes } from 'app/shared/dropdown/models';
import { AdminActionTypes } from 'app/modules/admin/models';

export const initialState: DropdownState = {
  agents: {},
  teams: {},
  orgs: {},
  units: {},
};

type ReduxActions = DropdownReduxActions | AdminActions;

const reducer = (state = initialState, action: ReduxActions) => {
  return produce(state, (draft: Draft<DropdownState>) => {
    switch (action.type) {
      case DropdownActionTypes.RETRIEVE_DROPDOWN_AGENTS_SUCCESS:
        draft.agents = {
          ...draft.agents,
          ...keyBy(action.payload.agents, (agent) => agent.id),
        };
        return;

      case DropdownActionTypes.RESET_DROPDOWN_AGENTS:
        draft.agents = {};
        return;

      case DropdownActionTypes.RETRIEVE_DROPDOWN_ORGS_SUCCESS:
        draft.orgs = {
          ...draft.orgs,
          ...keyBy(action.payload.results, (org) => org.id),
        };
        return;

      case AdminActionTypes.CREATE_ORG_SUCCESS:
        draft.orgs = { ...draft.orgs, [action.payload.id]: action.payload };
        return;

      case DropdownActionTypes.RETRIEVE_DROPDOWN_TEAMS_SUCCESS:
        draft.teams = {
          ...keyBy(action.payload.teams, (team) => team.id),
        };
        return;
    }
  });
};

export { reducer as dropdownReducer };
