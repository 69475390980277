import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/blacklists/models';

// Actions
import {
  addBlacklistItem as addBlacklistItemAction,
  addBlacklistItemSuccess,
} from 'app/modules/blacklists/actions';

// API
import { addBlacklistItem as addBlacklistItemApi } from 'app/shared/api/blacklists';

const rootAction = BlacklistActionTypes.ADD_BLACKLIST_ITEM;
function* addBlacklistItem({
  payload,
}: ReturnType<typeof addBlacklistItemAction>) {
  const config = {
    rootAction,
    request: call(addBlacklistItemApi, payload),
    errorToastMessage: 'Blacklist item addition was unsuccessful',
    successToastMessage: 'Blacklist item successfully added',
    success: function* onSuccess(result) {
      yield put(addBlacklistItemSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchAddBlacklistItem() {
  yield takeLatest(rootAction, addBlacklistItem);
}
