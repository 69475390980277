import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { SessionActionTypes } from 'app/modules/session/models';

// Actions
import {
  setOrgSarsSettings as setOrgSettingsAction,
  setOrgSarsSettingsSuccess,
  setOrgSarsSettingsPending,
  setOrgSarsSettingsError,
} from 'app/modules/session/actions';
import { sendSuccessToast, sendErrorToast } from 'app/shared/toasts/actions';

// API
import { pushOrgSarsSettings } from 'app/shared/api/session';

// Utils
import { createSentryError } from 'app/shared/utils/sentry';
import { OrgSettingsResponse } from 'app/modules/orgSettings/responses';
import { updateOrgSettings } from 'app/modules/orgSettings/sliceOrgSettings';

const rootAction = SessionActionTypes.SET_ORG_SARS_SETTINGS;
function* setOrgSarsSettingsFlow({
  payload,
}: ReturnType<typeof setOrgSettingsAction>) {
  const config = {
    rootAction,
    request: call(pushOrgSarsSettings, payload),
    pending: function* onPending() {
      yield put(setOrgSarsSettingsPending());
    },
    success: function* onSuccess(result: OrgSettingsResponse) {
      if (result) {
        yield put(setOrgSarsSettingsSuccess(result));
        yield put(updateOrgSettings(result));
        yield put(sendSuccessToast('Successfully updated SAR settings'));
      } else {
        yield put(setOrgSarsSettingsError());
        const error =
          'Unexpected response from server for setOrgSarsSettingsFlow saga';
        createSentryError({ error });
      }
    },
    error: function* onError() {
      yield put(setOrgSarsSettingsError());
      yield put(
        sendErrorToast(
          'Something went wrong, we were unable to update your organization settings',
        ),
      );
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* setOrgSarsSettings() {
  yield takeLatest(rootAction, setOrgSarsSettingsFlow);
}
