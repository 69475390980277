import { SyntheticEvent, useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectActionEventTypes } from 'app/modules/actionEvents/selectors';

import { U21MultiSelect } from 'app/shared/u21-ui/components';

interface Props {
  onChange: (value: string[], e: SyntheticEvent) => void;
  value: string[];
}

export const ActionTypeSelect = (props: Props) => {
  const { onChange, value } = props;
  const types = useSelector(selectActionEventTypes);
  const options = useMemo(
    () => types.map((i) => ({ text: i, value: i })),
    [types],
  );

  return (
    <U21MultiSelect
      backdrop
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};
