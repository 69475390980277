// Helpers
import {
  createCheckboxItems,
  createState,
} from 'app/modules/fincenSar/helpers';

// Models
import {
  SarInput,
  SarCheckboxOptions,
  Page0Model,
  SarReportTypeOptions,
} from 'app/modules/fincenSar/models';

// ======================= Filing Name =========================
export const filingName: SarInput = {
  title: `Filing Name`,
  name: 'filingName',
  initialValue: '',
  popupContent: `Filing name. Enter a descriptive name to  identify the Suspicious Activity Report(SAR).`,
};
// =============================================================

// ================== 1 Type of filing =====================
export const typesOfFilingOptions: SarReportTypeOptions = {
  INITIAL_REPORT: 'Initial report',
  CORRECTED_REPORT: 'Correct/Amend prior report',
  CONTINUING_ACTIVITY_REPORT: 'Continuing activity report',
  JOINT_REPORT: 'Joint report',
};

export const typeOfFiling: SarCheckboxOptions = {
  title: '1. Type of Filing',
  name: '1_typeOfFiling',
  options: Object.values(typesOfFilingOptions),
  initialValue: createCheckboxItems(Object.values(typesOfFilingOptions)),
  popupContent: `Item 1 - Type of filing (check all that apply). Select "Initial
  report" if this is the first report filed on the suspicious activity.
  SARs filed as a result of a historical or other review of past
  transactional activity, either directed by a regulating authority
  or conducted as a financial institution initiative, must always
  be initial reports. Select "Correct/Amend prior report" if the
  report corrects or amends a previous-filed report. Select
  "Continuing activity report" if this report involves suspicious
  activity reported on one or more previous reports. Continuing
  reports should be filed at least every 90 days on continuing
  suspicious activity. If this report is being jointly filed with
  another financial institution, select "Joint report" in addition
  to selecting the appropriate box(es) for the type of filing
  mentioned above. Filers must clearly identify in Part V which
  Part III financial institutions are the joint filers on the SAR. If
  "Correct/Amend prior report" is selected and/or "Continuing
  activity report" is selected, enter the BSA Identification
  Number (BSA ID) assigned by FinCEN to the previously filed
  SAR.`,
};

// =============================================================

// ========= Prior report BSA Identification Number (BSA ID) =========
export const priorBsaId: SarInput = {
  title: `Prior report BSA Identification Number (BSA ID)`,
  name: 'priorBsaId',
  initialValue: '',
  popupContent: `Prior report BSA Identification Number. If "Correct/Amend
  prior report" and/or "Continuing activity report" is selected
  as the filing type, enter the BSA Identification Number (BSA
  ID) assigned by FinCEN to the previously filed SAR;
  otherwise, enter all zeros if the prior report BSA ID is
  unknown.`,
};
// =============================================================

// ============= 2 Filing Institution Note to FinCEN =============
export const filingInstitutionNote: SarInput = {
  title: `2. Filing Institution Note to FinCEN`,
  name: '2_filingInstitutionNote',
  initialValue: '',
  popupContent: `
  Item 2 - Filing Institution Note to FinCEN. If the FinCEN SAR
is being filed in response to a current specific geographic
targeting order (GTO), advisory, or other activity, enter the
GTO or advisory titIe/reference or provide a brief description
of the other activity. Leave this field blank if the FinCEN SAR
does not relate to a GTO, advisory, or other targeted activity.
`,
};
// =============================================================

const allState = [filingName, typeOfFiling, priorBsaId, filingInstitutionNote];

export const initialState = {
  ...createState(allState),
} as unknown as Page0Model['content'];
export const PAGE_NUMBER = '0';
