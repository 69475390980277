import { post, get, put, upload } from 'app/shared/utils/fetchr';

// Models
import { PaginationPayload } from 'app/shared/models';
import {
  CreateBlacklistPayload,
  RetrieveBlacklistItemsPayload,
  EditBlacklistPayload,
  DeactivateBlacklistItemPayload,
  AddBlacklistItemPayload,
  AddUserBlacklistItemPayload,
  AppendFileToBlacklistPayload,
  AddBusinessBlacklistItemPayload,
} from 'app/modules/blacklists/models';

// Utils
import routes from 'app/shared/utils/routes';

export const retrieveBlacklists = (payload: PaginationPayload) => {
  return post(routes.patronus.blacklistsList, payload);
};

export const createBlacklist = (payload: CreateBlacklistPayload) => {
  return post(routes.patronus.blacklistsCreate, payload);
};

export const retrieveBlacklist = (id: string) => {
  return get(routes.patronus.blacklistsId.replace(':id', id));
};

export const retrieveBlacklistItems = (
  payload: RetrieveBlacklistItemsPayload,
) => {
  const { id, ...body } = payload;
  return post(routes.patronus.blacklistsIdListItems.replace(':id', id), body);
};

export const editBlacklist = (payload: EditBlacklistPayload) => {
  const { id, ...body } = payload;
  return put(routes.patronus.blacklistsIdEdit.replace(':id', id), body);
};

export const deactivateBlacklistItem = (
  payload: DeactivateBlacklistItemPayload,
) => {
  const { blacklist_id: id, ...body } = payload;
  return post(
    routes.patronus.blacklistsIdDeactivateItem.replace(':id', id),
    body,
  );
};

export const addBlacklistItem = (payload: AddBlacklistItemPayload) => {
  const { id, ...body } = payload;
  return post(routes.patronus.blacklistsIdAddItem.replace(':id', id), body);
};

export const addUserBlacklistItem = (payload: AddUserBlacklistItemPayload) => {
  const { id, ...body } = payload;
  return post(routes.patronus.blacklistsIdAddUser.replace(':id', id), body);
};

export const addBusinessBlacklistItem = (
  payload: AddBusinessBlacklistItemPayload,
) => {
  const { id, ...body } = payload;
  return post(routes.patronus.blacklistsIdAddBusiness.replace(':id', id), body);
};

export const appendFileToBlacklist = (
  payload: AppendFileToBlacklistPayload,
) => {
  const { id, file } = payload;
  const path = routes.patronus.blacklistsIdAppendFile.replace(':id', `${id}`);

  const formData = new FormData();
  formData.append('file', file);
  return upload(path, formData);
};
