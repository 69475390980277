import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AdminActionTypes } from 'app/modules/admin/models';

// Actions
import { retrieveDefaultRulesSuccess } from 'app/modules/admin/actions';

// API
import { retrieveDefaultRules as retrieveDefaultRulesApi } from 'app/shared/api/admin';

const rootAction = AdminActionTypes.RETRIEVE_DEFAULT_RULES;
function* retrieveDefaultRules() {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to retrieve the default rules.',
    request: call(retrieveDefaultRulesApi),
    success: function* onSuccess(result) {
      yield put(retrieveDefaultRulesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveDefaultRules);
}
