import { PaginationPayload, TableConfig } from 'app/shared/pagination/models';
import {
  RulesAdminModalType,
  RulesAdminInitialState,
} from 'app/modules/rulesAdmin/models';

export const DEFAULT_PAGINATION_PAYLOAD: PaginationPayload = {
  limit: 25,
  offset: 1,
  sort_column: 'id',
  sort_direction: 'ascending',
};

export const DEFAULT_RULE_ADMIN_VALIDATION_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'ruleValidationId',
    type: 'text',
    label: 'Rule validation ID',
    width: 200,
  },
  { key: 'ruleId', type: 'text', label: 'Rule ID', width: 200 },
  { key: 'orgId', type: 'text', label: 'Org ID', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  {
    key: 'executionRangeStart',
    type: 'text',
    label: 'Execution range start',
    width: 200,
  },
  {
    key: 'executionRangeEnd',
    type: 'text',
    label: 'Execution range end',
    width: 200,
  },
  { key: 'createdAt', type: 'text', label: 'Created at', width: 200 },
  { key: 'ruleContent', type: 'text', label: 'Rule content', width: 200 },
  { key: 'result', type: 'text', label: 'Result', width: 200 },
  { key: 'status', type: 'text', label: 'Status', width: 200 },
  { key: 'progress', type: 'text', label: 'Progress', width: 200 },
];

export const DEFAULT_RULE_ADMIN_QUERY_TABLE_CONFIG: TableConfig[] = [
  { key: 'ruleQueryId', type: 'text', label: 'Rule query ID', width: 200 },
  { key: 'ruleId', type: 'text', label: 'Rule ID', width: 200 },
  {
    key: 'ruleValidationId',
    type: 'text',
    label: 'Rule validation ID',
    width: 200,
  },
  {
    key: 'ruleExecutionId',
    type: 'text',
    label: 'Rule execution ID',
    width: 200,
  },
  {
    key: 'ruleScheduleId',
    type: 'text',
    label: 'Rule schedule ID',
    width: 200,
  },
  { key: 'orgId', type: 'text', label: 'Org ID', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  { key: 'createdAt', type: 'text', label: 'Created at', width: 200 },
  { key: 'queryString', type: 'text', label: 'Query string', width: 200 },
  { key: 'errorResult', type: 'text', label: 'Error result', width: 200 },
  {
    key: 'successRowReturned',
    type: 'text',
    label: 'Success row returned',
    width: 200,
  },
  { key: 'executionStart', type: 'text', label: 'Execution start', width: 200 },
  { key: 'executionEnd', type: 'text', label: 'Execution end', width: 200 },
  { key: 'windowStart', type: 'text', label: 'Window start', width: 200 },
  { key: 'windowEnd', type: 'text', label: 'Window end', width: 200 },
  { key: 'dbEngine', type: 'text', label: 'DB engine', width: 200 },
];

export const DEFAULT_RULE_ADMIN_EXECUTION_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'ruleExecutionId',
    type: 'text',
    label: 'Rule execution ID',
    width: 200,
  },
  { key: 'ruleId', type: 'text', label: 'Rule ID', width: 200 },
  { key: 'orgId', type: 'text', label: 'Org ID', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  {
    key: 'executionRangeStart',
    type: 'text',
    label: 'Execution range start',
    width: 200,
  },
  {
    key: 'executionRangeEnd',
    type: 'text',
    label: 'Execution range end',
    width: 200,
  },
  { key: 'startTime', type: 'text', label: 'Start time', width: 200 },
  { key: 'endTime', type: 'text', label: 'End time', width: 200 },
  { key: 'executionType', type: 'text', label: 'Execution type', width: 200 },
  { key: 'stats', type: 'text', label: 'Statistics', width: 200 },
];

export const DEFAULT_RULE_ADMIN_CONTENT_TABLE_CONFIG: TableConfig[] = [
  { key: 'ruleId', type: 'text', label: 'Rule ID', width: 200 },
  { key: 'title', type: 'text', label: 'Title', width: 200 },
  { key: 'desc', type: 'text', label: 'Description', width: 200 },
  {
    key: 'deployStartDate',
    type: 'text',
    label: 'Deploy start date',
    width: 200,
  },
  { key: 'deployEndDate', type: 'text', label: 'Deploy end date', width: 200 },
  { key: 'ruleContent', type: 'text', label: 'Rule content', width: 200 },
  { key: 'status', type: 'text', label: 'Status', width: 200 },
  { key: 'createdById', type: 'text', label: 'Creator ID', width: 200 },
  { key: 'createdByName', type: 'text', label: 'Creator name', width: 200 },
  { key: 'orgId', type: 'text', label: 'Org ID', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  { key: 'scenarioId', type: 'text', label: 'Scenario ID', width: 200 },
  { key: 'scenarioName', type: 'text', label: 'Scenario name', width: 200 },
  { key: 'options', type: 'text', label: 'Options', width: 200 },
  { key: 'groupByTypes', type: 'text', label: 'Group by types', width: 200 },
  {
    key: 'generatesAlerts',
    type: 'text',
    label: 'Generates alerts',
    width: 200,
  },
  { key: 'isSynchronous', type: 'text', label: 'Is synchronous', width: 200 },
  {
    key: 'runIndependently',
    type: 'text',
    label: 'Runs independently',
    width: 200,
  },
  {
    key: 'executionFrequency',
    type: 'text',
    label: 'Execution frequency',
    width: 200,
  },
  {
    key: 'executionEngine',
    type: 'text',
    label: 'Execution engine',
    width: 200,
  },
  { key: 'updatedAt', type: 'text', label: 'Updated at', width: 200 },
  {
    key: 'customerSupportInvolved',
    type: 'text',
    label: 'Customer support involved',
    width: 200,
  },
  { key: 'templateId', type: 'text', label: 'Template ID', width: 200 },
  { key: 'templateName', type: 'text', label: 'Template name', width: 200 },
  { key: 'alertQueueId', type: 'text', label: 'Alert queue ID', width: 200 },
  {
    key: 'alertQueueName',
    type: 'text',
    label: 'Alert queue name',
    width: 200,
  },
  { key: 'activatedAt', type: 'text', label: 'Activated at', width: 200 },
  {
    key: 'actualCreatedById',
    type: 'text',
    label: 'Actual creator ID',
    width: 200,
  },
  {
    key: 'actualCreatedByName',
    type: 'text',
    label: 'Actual creator name',
    width: 200,
  },
  {
    key: 'migratedFromId',
    type: 'text',
    label: 'Migrated from ID',
    width: 200,
  },
];

export const DEFAULT_RULE_ADMIN_SCHEDULE_TABLE_CONFIG: TableConfig[] = [
  { key: 'scheduleId', type: 'text', label: 'Schedule ID', width: 200 },
  { key: 'ruleId', type: 'text', label: 'Rule ID', width: 200 },
  { key: 'createdAt', type: 'text', label: 'Created at', width: 200 },
  { key: 'windowStart', type: 'text', label: 'Window start', width: 200 },
  { key: 'windowEnd', type: 'text', label: 'Window end', width: 200 },
  {
    key: 'executionStartedAt',
    type: 'text',
    label: 'Execution started at',
    width: 200,
  },
  {
    key: 'executionCompletedAt',
    type: 'text',
    label: 'Execution completed at',
    width: 200,
  },
  { key: 'orgId', type: 'text', label: 'Org ID', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  { key: 'retryCount', type: 'text', label: 'Retry count', width: 200 },
  { key: 'scheduleStatus', type: 'text', label: 'Schedule status', width: 200 },
  { key: 'scheduleType', type: 'text', label: 'Schedule type', width: 200 },
  { key: 'executionId', type: 'text', label: 'Execution IDs', width: 200 },
];

export const DEFAULT_RULE_ADMIN_ALERT_CONTENT_TABLE_CONFIG: TableConfig[] = [
  { key: 'id', type: 'text', label: 'Id', width: 200 },
  { key: 'title', type: 'text', label: 'Title', width: 200 },
  { key: 'description', type: 'text', label: 'Description', width: 200 },
  { key: 'startDate', type: 'text', label: 'Start date', width: 200 },
  { key: 'endDate', type: 'text', label: 'End date', width: 200 },
  { key: 'histogramData', type: 'text', label: 'Histogram data', width: 200 },
  { key: 'status', type: 'text', label: 'Status', width: 200 },
  { key: 'alertType', type: 'text', label: 'Alert type', width: 200 },
  { key: 'assignedToId', type: 'text', label: 'Assigned to id', width: 200 },
  {
    key: 'ruleExecutionId',
    type: 'text',
    label: 'Rule execution id',
    width: 200,
  },
  { key: 'orgId', type: 'text', label: 'Org id', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  { key: 'customData', type: 'text', label: 'Custom data', width: 200 },
  { key: 'externalId', type: 'text', label: 'External id', width: 200 },
  { key: 'content', type: 'text', label: 'Content', width: 200 },
  { key: 'hits', type: 'text', label: 'Hits', width: 200 },
  {
    key: 'externalStatus',
    type: 'text',
    label: 'External status',
    width: 200,
  },
  {
    key: 'investigationData',
    type: 'text',
    label: 'Investigation data',
    width: 200,
  },
  { key: 'disposition', type: 'text', label: 'Disposition', width: 200 },
  { key: 'assignedAt', type: 'text', label: 'Assigned at', width: 200 },
  {
    key: 'isInvestigationValidated',
    type: 'text',
    label: 'Is investigation validated',
    width: 200,
  },
  { key: 'retrievedAt', type: 'text', label: 'Retrieved at', width: 200 },
  {
    key: 'closingActionTriggerId',
    type: 'text',
    label: 'Closing action trigger id',
    width: 200,
  },
  { key: 'groupByTypes', type: 'text', label: 'Group by types', width: 200 },
  {
    key: 'dispositionedAt',
    type: 'text',
    label: 'Dispositioned at',
    width: 200,
  },
  {
    key: 'hotStorageExpiresAt',
    type: 'text',
    label: 'Hot storage expires at',
    width: 200,
  },
  {
    key: 'workStartedOn',
    type: 'text',
    label: 'Work started on',
    width: 200,
  },
  { key: 'updatedAt', type: 'text', label: 'Updated at', width: 200 },
  { key: 'queueId', type: 'text', label: 'Queue id', width: 200 },
  {
    key: 'alertingOrgId',
    type: 'text',
    label: 'Alerting org id',
    width: 200,
  },
  {
    key: 'groupByExternalId',
    type: 'text',
    label: 'Group by external id',
    width: 200,
  },
  {
    key: 'collaboratingOrgId',
    type: 'text',
    label: 'Collaborating org id',
    width: 200,
  },
  { key: 'createdById', type: 'text', label: 'Created by id', width: 200 },
  { key: 'narrative', type: 'text', label: 'Narrative', width: 200 },
  {
    key: 'dispositionType',
    type: 'text',
    label: 'Disposition type',
    width: 200,
  },
];

export const DEFAULT_RULE_ADMIN_ALERT_HIT_TABLE_CONFIG: TableConfig[] = [
  { key: 'id', type: 'text', label: 'Id', width: 200 },
  { key: 'createdAt', type: 'text', label: 'Created at', width: 200 },
  { key: 'alertId', type: 'text', label: 'Alert id', width: 200 },
  {
    key: 'groupByExternalId',
    type: 'text',
    label: 'Group by external id',
    width: 200,
  },
  { key: 'groupByTypes', type: 'text', label: 'Group by types', width: 200 },
  { key: 'title', type: 'text', label: 'Title', width: 200 },
  { key: 'description', type: 'text', label: 'Description', width: 200 },
  { key: 'startDate', type: 'text', label: 'Start date', width: 200 },
  { key: 'endDate', type: 'text', label: 'End date', width: 200 },
  { key: 'content', type: 'text', label: 'Content', width: 200 },
  { key: 'customData', type: 'text', label: 'Custom data', width: 200 },
  { key: 'alertHitType', type: 'text', label: 'Alert hit type', width: 200 },
  {
    key: 'ruleExecutionId',
    type: 'text',
    label: 'Rule execution id',
    width: 200,
  },
  { key: 'orgId', type: 'text', label: 'Org id', width: 200 },
  { key: 'orgName', type: 'text', label: 'Org name', width: 200 },
  { key: 'unitId', type: 'text', label: 'Unit id', width: 200 },
  {
    key: 'ruleScheduleId',
    type: 'text',
    label: 'Rule schedule id',
    width: 200,
  },
  { key: 'ruleId', type: 'text', label: 'Rule id', width: 200 },
  { key: 'objectSource', type: 'text', label: 'Object source', width: 200 },
  {
    key: 'ruleValidationId',
    type: 'text',
    label: 'Rule validation id',
    width: 200,
  },
  {
    key: 'ruleValidationWindowId',
    type: 'text',
    label: 'Rule validation window id',
    width: 200,
  },
];

export const DEFAULT_RULE_ADMIN_ORG_SETTINGS_TABLE_CONFIG: TableConfig[] = [
  { key: 'createdAt', type: 'text', label: 'Created at', width: 200 },
  { key: 'updatedAt', type: 'text', label: 'Updated at', width: 200 },
  { key: 'orgId', type: 'text', label: 'Org ID', width: 200 },
  { key: 'features', type: 'text', label: 'Features', width: 200 },
  { key: 'lumosConfig', type: 'text', label: 'Lumos config', width: 200 },
  {
    key: 'queryBuilderConfig',
    type: 'text',
    label: 'Query builder config',
    width: 200,
  },
  { key: 'storageConfig', type: 'text', label: 'Storage config', width: 200 },
  { key: 'sarConfig', type: 'text', label: 'SAR config', width: 200 },
  {
    key: 'homeCurrencyCode',
    type: 'text',
    label: 'Home currency code',
    width: 200,
  },
  { key: 'currencyConfig', type: 'text', label: 'Currency config', width: 200 },
  { key: 'scenarioConfig', type: 'text', label: 'Scenario config', width: 200 },
  { key: 'alertConfig', type: 'text', label: 'Alert config', width: 200 },
  { key: 'orgEnums', type: 'text', label: 'Org enums', width: 200 },
  {
    key: 'orgEnumsConfig',
    type: 'text',
    label: 'Org enums config',
    width: 200,
  },
  {
    key: 'ruleExecutionConfig',
    type: 'text',
    label: 'Rule execution config',
    width: 200,
  },
  { key: 'tagLimit', type: 'text', label: 'Tag limit', width: 200 },
  {
    key: 'linkAnalysisConfig',
    type: 'text',
    label: 'Link analysis config',
    width: 200,
  },
  {
    key: 'ruleRelevanceMetricConfig',
    type: 'text',
    label: 'Rule relevance metric config',
    width: 200,
  },
  { key: 'dataConfig', type: 'text', label: 'Data config', width: 200 },
  { key: 'kytConfig', type: 'text', label: 'KYT config', width: 200 },
  {
    key: 'unit21IdConfig',
    type: 'text',
    label: 'Unit21 ID config',
    width: 200,
  },
];

export const rulesAdminInitialState: Readonly<RulesAdminInitialState> = {
  rulesAdminModalType: RulesAdminModalType.NONE,
};
