// Helpers
import {
  createCheckboxItems,
  createState,
} from 'app/modules/fincenSar/helpers';
import { createDropdownItems } from 'app/shared/helpers';

// Models
import {
  SarInput,
  SarDropdownOptions,
  SarCheckboxOptions,
  ValuesToClearModel,
  Page1ContentModel,
} from 'app/modules/fincenSar/models';

export const PAGE_NUMBER = '1';

// ============ 79 Types of Financial Institutions ============
const typesOfFinancialInstitutions = [
  'Casino/Card club',
  'Depository institution',
  'Insurance company',
  'MSB (Money Service Business)',
  'Securities/Futures',
  'Loan or Finance Company',
  'Housing GSE (Government Sponsored Enterprise)',
  'Other',
];

const typeOfFinancialInstitutionOptions = createDropdownItems(
  typesOfFinancialInstitutions,
);

export const typeOfFinancialInstitution: SarDropdownOptions = {
  options: typeOfFinancialInstitutionOptions,
  popupContent: `Item 79 - Type of financial institution. Select the appropriate
type of financial institution that best describes the filing
institution recorded in Item 76. If none of the options apply,
select “Other” and enter a brief description in the associated
text field. If necessary, include a more-detailed description
in Part V.`,
  title: '79. Type of Financial Institution',
  name: '79_typeOfFinancialInstitution',
  initialValue: '',
};

export const typeOfFinancialInstitutionOther: SarInput = {
  title: 'Other',
  name: `79_typeOfFinancialInstitutionOther`,
  initialValue: '',
  popupContent: `Enter a brief description of the type of financial institution if "Other" is selected in item 79.`,
};
// =============================================================

// ============ 75 Primary Federal Regulator ============
const primaryFederalRegulators = [
  'Commodities Futures Trading Commission (CFTC)',
  'Federal Reserve Board (FRB)',
  'Federal Deposit Insurance Corporation (FDIC)',
  'Internal Revenue Service (IRS)',
  'National Credit Union Administration (NCUA)',
  'Office of the Comptroller of the Currency (OCC)',
  'Securities and Exchange Commission (SEC)',
  'Federal Housing Finance Agency (FHFA)',
  'Not Applicable',
];

export const allPrimaryFederalRegulatorOptions = createDropdownItems(
  primaryFederalRegulators,
);

export const primaryFederalRegulatorDynamicOptions = {
  'Casino/Card club': createDropdownItems(['Internal Revenue Service (IRS)']),
  'Depository institution': createDropdownItems([
    'Federal Reserve Board (FRB)',
    'Federal Deposit Insurance Corporation (FDIC)',
    'Internal Revenue Service (IRS)',
    'National Credit Union Administration (NCUA)',
    'Office of the Comptroller of the Currency (OCC)',
  ]),
  'Insurance company': createDropdownItems(['Internal Revenue Service (IRS)']),
  'MSB (Money Service Business)': createDropdownItems([
    'Internal Revenue Service (IRS)',
  ]),
  'Securities/Futures': createDropdownItems([
    'Commodities Futures Trading Commission (CFTC)',
    'Securities and Exchange Commission (SEC)',
  ]),
  'Loan or Finance Company': createDropdownItems(primaryFederalRegulators),
  'Housing GSE (Government Sponsored Enterprise)': createDropdownItems(
    primaryFederalRegulators,
  ),
};

export const primaryFederalRegulator: SarDropdownOptions = {
  options: allPrimaryFederalRegulatorOptions,
  popupContent: `Item 75 - Primary federal regulator. Select the appropriate
  Primary Federal Regulator or BSA Examiner of the filing
  institution recorded in Item 76. If more than one regulator
  option could apply, select the regulator that has primary
  responsibility for enforcing compliance with the BSA. Select
  "Internal Revenue Service (IRS)" if the filing institution is
  subject to U.S. law and none of the other codes apply or if
  the filing institution type is "Casino/Card Club," "Insurance
  company," or "MSB." Select "Not Applicable" if the filing
  institution is a government agency or is not subject to US.
  law.`,
  title: '75. Primary Federal Regulator',
  name: '75_primaryFederalRegulator',
  dynamicOptions: primaryFederalRegulatorDynamicOptions,
  initialValue: '',
};
// =============================================================

// ======================= 76 Filer Name  =========================
export const filerName: SarInput = {
  title:
    '76. Filer Name (Holding company, lead financial institution, or agency, if applicable)',
  name: `76_filerName`,
  initialValue: '',
  popupContent: `Item 76 - Filer name. Enter the legal name of the filing
  institution as recorded on articles of incorporation or other
  documents establishing the institution. Enter the full name of
  the filer if the filer is an individual, such as a sole
  proprietorship, acting as a financial institution. If an
  individual’s name is recorded, enter the name in (first name)
  (middle name) (last name) or equivalent format, e.g. John
  Jacob Doe or Richard R. Roe ll, etc.`,
};
// =============================================================

// =========== 77 Taxpayer Identification Number ==============
export const taxpayerIdentificationNumber: SarInput = {
  title: `77. Taxpayer Identification Number`,
  name: '77_taxpayerIdentificationNumber',
  initialValue: '',
  popupContent: `ltem 77 - TIN. Enter the Taxpayer Identification Number
  l(TIN), either US. or foreign, of the financial institution or
  individual recorded in Item 76. Enter the TIN as a single text
  string without formatting or special characters such as
  hyphens or periods. A TIN in the format NNN-NN-NNNN
  !would be entered as NNNNNNNNN.
  `,
};
// =============================================================

// =========== 78 Taxpayer Identification Type ==============
const taxpayerTypes = ['EIN', 'SSN-ITIN', 'Foreign'];
const taxpayerTypeOptions = createDropdownItems(taxpayerTypes);
export const taxpayerIdentificationType: SarDropdownOptions = {
  title: `78. Taxpayer Identification Number Type`,
  name: `78_taxpayerIdentificationType`,
  initialValue: '',
  options: taxpayerTypeOptions,
  popupContent: `Item 78 - TIN type. Select the type of TIN entered by
  selecting either "EIN," "SSN/ITIN," or "Foreign." Select
  "Foreign" if the subject has a foreign TIN of any type and
  record in Part V the name of the country that issued the
  foreign TIN.`,
};
// =============================================================

// =========== 80 Type of Securities and Futures institution ==============
const typesOfSecuritiesAndFuturesOptions = [
  'Clearing broker-securities',
  'CPO/CTA',
  'Execution-only broker securities',
  'Futures Commission Merchant',
  'Holding company',
  'Introducing broker-commodities',
  'Introducing broker-securities',
  'Investment Adviser',
  'Investment company',
  'Retail foreign exchange dealer',
  'Self-clearing broker securities',
  'SRO Futures',
  'SRO Securities',
  'Subsidiary of financial/bank',
  'Other',
];

export const typeOfSecuritiesAndFutures: SarCheckboxOptions = {
  title: `80. Type of Securities and Futures institution or individual filing this report - check box(es) for functions that apply to this report.`,
  name: '80_typeOfSecuritiesAndFutures',
  popupContent: `Item 80 - Type of Securities and Futures institution or
  individual filing this report. If "Securities/Futures" was
  selected for the type of financial institution, check all boxes
  that apply to indicate the type of securities and futures
  institution. If "Other" is selected, record a brief description
  of the type of securities and futures institution in the
  associated text field. If necessary, include a more-detailed
  description in Part V.`,
  options: typesOfSecuritiesAndFuturesOptions,
  initialValue: createCheckboxItems(typesOfSecuritiesAndFuturesOptions),
};

const requiredTypesOfSecuritiesAndFutures = ['Securities/Futures'];

export const typesOfSecuritiesAndFuturesRequired = (
  selectedInstitution: string,
): boolean => {
  return requiredTypesOfSecuritiesAndFutures.includes(selectedInstitution);
};

export const typeOfSecuritiesAndFuturesOther: SarInput = {
  name: '80_typeOfSecuritiesAndFuturesOther',
  title: 'Other',
  initialValue: '',
};
// =============================================================

// ======= 81 Financial Institution Identification  ===========
const financialInstitutionIdentificationTypes = [
  'Central Registration Depository (CRD) number',
  'Investment Adviser Registration Depository (IARD) number',
  'National Futures Association (NFA) number',
  'Research, Statistics, Supervision, and Discount (RSSD) number',
  'Securities and Exchange Commission (SEC) number',
  'National Association of Insurance Commissioners (NAIC Code)',
  'Mortgage (NMLS ID)',
];

const financialInstitutionIdentificationTypesOptions = createDropdownItems(
  financialInstitutionIdentificationTypes,
);

const financialInstitutionIdentificationPopupContent = `Item 81 - Filing institution identification number. Select the
appropriate identification type if the financial institution
recorded in Item 76 has a Central Registration Depository
(CRD) number, Investment Adviser Registration Depository
(IARD) number, National Association of Commissioners
(NAIC) Number, National Futures Association number,
Nationwide Mortgage Licensing System (NMLS) number,
Research, Statistics, Supervision, and Discount (RSSD)
number, or Securities and Exchange Commission (SEC)
Reporting File Number (RFN). Do not enter an SEC Central
Index Key (CIK) number in place of the RFN. Enter the
identification number as a single text string without
formatting or special characters such as hyphens or periods.
For example, an identification number in the format NNN-
NN-NNNN would be entered as NNNNNNNNN.
`;

export const financialInstitutionIdentificationType: SarDropdownOptions = {
  options: financialInstitutionIdentificationTypesOptions,
  popupContent: financialInstitutionIdentificationPopupContent,
  title: '81. Financial Institution Identification Type',
  name: '81_financialInstitutionIdentificationType',
  initialValue: '',
};

export const financialInstitutionIdentificationNumber: SarInput = {
  name: '81_financialInstitutionIdentificationNumber',
  title: '81. Financial Institution Identification Number',
  initialValue: '',
  popupContent: financialInstitutionIdentificationPopupContent,
};
// =============================================================

const addressPopupContent = `Items 82-86 - Filer address items. Enter the permanent
street address, city, state/territory/province (U.S./Canada/
Mexico only), ZIP Code or foreign postal code, and country
of the financial institution identified in Item 76. Provide the
apartment number or suite number, if known, following the
street address. A non-location address such as a post office
box or rural route number should be used only if no other
street address information is available. ZIP Codes must be
five or nine digits. ZIP Codes and foreign postal codes must
be entered without formatting or special characters such as
spaces or hyphens. For example, the ZIP Code 12354-6120
would be entered as 123546120. The foreign postal code
HKW 702 would be entered HKW702. For other foreign
addresses enter the street address, city, postal code, and
two letter country abbreviation or address equivalent. If a
foreign address contains other address information that does
not conform to the SAR address format, record equivalent
address information in the SAR address items and the full
address in Part V. No abbreviations are permitted in city
names, which must be completely spelled out. A U.S. city
name must match the city name used by the US. Postal
Service for the associated state and ZIP Code.
`;

// =================== 82 Address ====================
export const address: SarInput = {
  title: `82. Address`,
  name: '82_address',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 83 City ==============================
export const city: SarInput = {
  title: `83. City`,
  name: '83_city',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 84 State ==============================
export const state: SarInput = {
  title: `84. State`,
  name: '84_state',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 85 ZIP/Postal Code ==============================
export const zip: SarInput = {
  title: `85. ZIP/Postal Code `,
  name: '85_zip',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 86 Country ==============================
export const country: SarInput = {
  title: `86. Country`,
  name: '86_country',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 87 Alternate name, e.g., AKA - individual or trade name, DBA - entity ===============
export const alternateName: SarInput = {
  title: `87. Alternate name, e.g., AKA - individual or trade name, DBA - entity`,
  name: '87_alternateName',
  initialValue: '',
  popupContent: `Item 87 - Alternate name. If the financial institution recorded
  in Item 76 has a separate doing business as (DBA) name,
  enter the name here. If an individual recorded in Item 76 has
  a separate also known as (AKA) name, enter that name here.
  Do not include the acronyms DBA or AKA with the alternate
  name.
  `,
};
// ========================================================

// =========== 88 Internal Control/File Number ==============
export const internalControlFileNumber: SarInput = {
  title: `88. Internal Control/File Number`,
  name: `88_internalControlFileNumber`,
  initialValue: '',
  popupContent: `Item 88 - Internal controI/file number. Enter the internal
  control number or file number assigned to the SAR by the
  financial institution recorded in Item 76. This number should
  be unique to the SAR if possible, allowing interested parties
  such as law enforcement or the financial institution to
  reference the SAR without committing a potentially illegal
  disclosure of SAR data.`,
};
// =============================================================

// ================== 89 LE Contact Agency =====================
export const leContactAgency: SarInput = {
  title: `89. LE Contact Agency`,
  name: `89_leContactAgency`,
  initialValue: '',
  popupContent: `Item 89 - LE contact agency. Enter the name of the law
  enforcement agency, if any, which has been informed of the
  suspicious activity. If more than one LE agency has been
  contacted about the suspicious activity, record the
  information on one agency in Items 89-98 and the
  information on additional agencies in Part V.`,
};
// =============================================================

// ================== 90 LE Contact Agency =====================
export const leContactName: SarInput = {
  title: `90. LE Contact Name`,
  name: `90_leContactName`,
  initialValue: '',
  popupContent: `Item 90 - LE contact name. Enter the name of the person
  contacted at the law enforcement agency.`,
};
// =============================================================

// ================== 91 LE Contact Agency =====================
export const leContactPhoneNumber: SarInput = {
  title: `91. LE Contact Phone Number (Include Area Code)`,
  name: `91_leContactPhoneNumber`,
  initialValue: '',
  popupContent: `Item 91 - LE contact phone number. Enter the law
  enforcement contact telephone number, as well as the
  extension of the law enforcement telephone number (if
  applicable).
  Record all telephone numbers, both foreign and domestic,
  as a single number string without formatting or special
  characters such as parentheses or hyphens. For example, a
  number in the format (NNN) NNN-NNNN would be recorded
  as NNNNNNNNNN. Telephone numbers that are part of the
  North American Numbering Plan used by the U.S., Canada,
  many Caribbean countries, and current/former US. Pacific
  island protectorates must consist of an area code and
  seven-digit telephone number. Other foreign telephone
  numbers should include the country number code. If only a
  partial telephone number is known record that number in the
  phone number item and explain in Part V that the entry is a
  partial number.`,
};
// =============================================================

// ================== 92 LE Contact Date =====================
export const leContactDate: SarInput = {
  title: `92. LE Contact Date`,
  name: `92_leContactDate`,
  initialValue: '',
  popupContent: `Item 92 - LE contact date. Enter the most-recent date the
  law enforcement agency was contacted about the
  suspicious activity. The date must be in MM/DD/YYYY
  format where MM = Month, DD = Day, and YYYY = Century
  and Year (e.g. 2018). If the agency was contacted on multiple
  dates, record the earlier contact dates in Part V.`,
};
// =============================================================

// ================ 93 Filing Institution Contact Office ===================
export const filingInstitutionContactOffice: SarInput = {
  title: `93. Filing Institution Contact Office`,
  name: `93_filingInstitutionContactOffice`,
  initialValue: '',
  popupContent: `Item 93 - Filing institution contact office. Enter the name of
  the filing institution designated contact office where
  additional information about the FinCEN SAR or supporting
  documentation can be requested. If the FinCEN SAR is
  jointly filed, enter in Part V the contact office and telephone
  number information for all Part III joint filers.`,
};
// =============================================================

// ============== 94 Filing Institution Contact Phone Number =================
export const filingInstitutionContactPhone: SarInput = {
  title: `94. Filing Institution Contact Phone (Include Area Code)`,
  name: `94_filingInstitutionContactPhone`,
  initialValue: '',
  popupContent: `Item 94 - Filing institution contact office phone number. Enter the 
  contact office telephone number. Record all telephone numbers, both foreign and domestic, 
  as a single number string without formatting or special characters such as parentheses or hyphens.
  For example, a number in the format (NNN) NNN-NNNN would be recorded as NNNNNNNNNN. Telephone numbers
  that are part of the North American Numbering plan used by the U.S., Canada, many Caribbean countries, 
  and current/former U.S. Pacific island protectorates must consist of an area code and seven-digit 
  telephone number. Other foreign telephone numbers should include the country number code. 
  If only a partial telephone number is known record that number in the phone number item 
  and explain in Part V that the entry is a partial number.`,
};
// =============================================================

// TODO add item 94a - Extension

// ============== 95 Date Filed =================
export const dateFiled: SarInput = {
  title: `95. Date Filed`,
  name: `95_dateFiled`,
  initialValue: '',
  popupContent: ``,
};
// =============================================================

const allState = [
  typeOfFinancialInstitution,
  typeOfFinancialInstitutionOther,
  primaryFederalRegulator,
  filerName,
  taxpayerIdentificationNumber,
  taxpayerIdentificationType,
  typeOfSecuritiesAndFutures,
  typeOfSecuritiesAndFuturesOther,
  financialInstitutionIdentificationNumber,
  financialInstitutionIdentificationType,
  address,
  city,
  state,
  zip,
  country,
  alternateName,
  internalControlFileNumber,
  leContactAgency,
  leContactName,
  leContactPhoneNumber,
  leContactDate,
  filingInstitutionContactOffice,
  filingInstitutionContactPhone,
  dateFiled,
];

export const initialState = {
  ...createState(allState),
} as unknown as Page1ContentModel;

export const valuesToClear: ValuesToClearModel = {
  // 79. Type of Financial Institution
  [typeOfFinancialInstitution.name]: [
    {
      fieldToClear: typeOfFinancialInstitutionOther.name,
      initialValue: typeOfFinancialInstitutionOther.initialValue,
    },
    {
      fieldToClear: typeOfSecuritiesAndFutures.name,
      initialValue: typeOfSecuritiesAndFutures.initialValue,
    },
    {
      fieldToClear: typeOfSecuritiesAndFuturesOther.name,
      initialValue: typeOfSecuritiesAndFuturesOther.initialValue,
    },
    {
      fieldToClear: primaryFederalRegulator.name,
      initialValue: primaryFederalRegulator.initialValue,
    },
  ],

  // 81. financialInstitutionIdentificationType should clear 81_financialInstitutionIdentificationNumber
  [financialInstitutionIdentificationType.name]: [
    {
      fieldToClear: financialInstitutionIdentificationNumber.name,
      initialValue: financialInstitutionIdentificationNumber.initialValue,
    },
  ],

  // 86. Country (change in country should clear 84_state)
  [country.name]: [
    {
      fieldToClear: state.name,
      initialValue: state.initialValue,
    },
  ],
};
