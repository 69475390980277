import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';

// Models

import {
  editOrgConsortiumConfig,
  runOrgConsortiumBackfill,
} from 'app/shared/api/admin';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { editOrgConsortiumConfigSuccess } from 'app/modules/admin/actions';
import { OrgConsoritiumConfigResponse } from 'app/modules/admin/types/responses';
import { EditOrgConsortiumConfigPayload } from 'app/modules/admin/types/requests';

const ADMIN_NAME = 'adminSlice'; // 'admin' is already taken

interface AdminSliceInitialState {}

const initialState: Readonly<AdminSliceInitialState> = {};

const editOrgConsortiumConfigThunkName = `${ADMIN_NAME}/EDIT_ORG_CONSORTIUM_CONFIG`;
export const editOrgConsortiumConfigThunk = u21CreateAsyncThunk<
  EditOrgConsortiumConfigPayload & { id: number },
  OrgConsoritiumConfigResponse
>(
  editOrgConsortiumConfigThunkName,
  async ({ id, ...payload }, { dispatch }) => {
    try {
      const response = await editOrgConsortiumConfig(id, payload);
      dispatch(editOrgConsortiumConfigSuccess(payload));
      dispatch(sendSuccessToast('Org consortium config edited successfully.'));
      return response;
    } catch (e) {
      dispatch(
        sendErrorToast(
          'Unable to edit org consortium config. Please try again',
        ),
      );
      throw e;
    }
  },
);

const runConsortiumBackfillThunkName = `${ADMIN_NAME}/RUN_CONSORTIUM_BACKFILL`;
export const runConsortiumBackfillThunk = u21CreateAsyncThunk<{ id: number }>(
  runConsortiumBackfillThunkName,
  async ({ id }, { dispatch }) => {
    try {
      const response = await runOrgConsortiumBackfill(id);

      dispatch(
        editOrgConsortiumConfigThunk({
          id,
          enabled: true,
          backfilled: true,
          backfill_task_uuid: response.task_uuid,
        }),
      );
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to run consortium backfill. Please try again'),
      );
      throw e;
    }
  },
);

const adminSlice = u21CreateSlice({
  name: ADMIN_NAME,
  initialState,
  reducers: {},
});

export const adminSliceName = adminSlice.name;
export default adminSlice.reducer;
