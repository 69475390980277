import { UpdateGDriveFolderPayload } from 'app/modules/orgSettings/requests';

import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { updateGDriveFolder } from 'app/modules/orgSettings/api';
import { OrgFeature } from 'app/modules/admin/models';
import { SarConfig } from 'app/modules/settings/models';
import {
  AlertConfig,
  ConsortiumConfig,
  LumosConfig,
  OrgScenarioConfig,
  QueryBuilderConfig,
  RuleExecutionConfig,
  StorageConfig,
} from 'app/modules/orgSettings/models';
import {
  INITIAL_ALERT_CONFIG,
  INITIAL_CURRENCY_CONFIG,
  INITIAL_LUMOS_CONFIG,
  INITIAL_ORG_ENUMS,
  INITIAL_QUERY_BUILDER_CONFIG,
  INITIAL_REAL_TIME_RULES_CONFIG,
  INITIAL_RULE_EXECUTION_CONFIG,
  INITIAL_SAR_CONFIG,
  INITIAL_SCENARIO_CONFIG,
} from 'app/modules/orgSettings/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  CurrencyConfig,
  OrgEnums,
  OrgSettingsResponse,
  StorageResource,
} from 'app/modules/orgSettings/responses';
import { consoleError } from 'app/shared/utils/console';
import { get } from 'app/shared/utils/fetchr';
import { RealTimeRulesConfig } from 'app/modules/orgManagement/responses';

const ORG_SETTINGS_NAME = 'orgSettings';

interface OrgSettingsState {
  alertConfig: AlertConfig;
  consortiumConfig: ConsortiumConfig;
  currencyConfig: CurrencyConfig;
  deltaLakeDataConfig: 'LEGACY' | 'DELTA_LAKE';
  homeCurrencyCode: string;
  lumosConfig: LumosConfig;
  orgFeatures: OrgFeature[];
  orgEnums: OrgEnums;
  queryBuilderConfig: QueryBuilderConfig;
  ruleExecutionConfig: RuleExecutionConfig;
  realTimeRulesConfig: RealTimeRulesConfig;
  sarConfig: SarConfig;
  scenarioConfig: OrgScenarioConfig;
  storageConfig: StorageConfig;
  storageResources: StorageResource[];

  loadingOrgSettings: boolean;
  loadingUpdateGDriveFolder: boolean;
}

export const INITIAL_STATE: Readonly<OrgSettingsState> = {
  alertConfig: INITIAL_ALERT_CONFIG,
  consortiumConfig: {},
  currencyConfig: INITIAL_CURRENCY_CONFIG,
  deltaLakeDataConfig: 'LEGACY',
  homeCurrencyCode: 'USD',
  lumosConfig: INITIAL_LUMOS_CONFIG,
  orgFeatures: [],
  orgEnums: INITIAL_ORG_ENUMS,
  queryBuilderConfig: { ...INITIAL_QUERY_BUILDER_CONFIG },
  ruleExecutionConfig: INITIAL_RULE_EXECUTION_CONFIG,
  realTimeRulesConfig: INITIAL_REAL_TIME_RULES_CONFIG,
  sarConfig: INITIAL_SAR_CONFIG,
  scenarioConfig: INITIAL_SCENARIO_CONFIG,
  storageConfig: {},
  storageResources: [],

  loadingOrgSettings: false,
  loadingUpdateGDriveFolder: false,
};

export const updateGDriveFolderThunk = u21CreateAsyncThunk(
  `${ORG_SETTINGS_NAME}/UPDATE_GDRIVE_FOLDER`,
  async (payload: UpdateGDriveFolderPayload, { dispatch }) => {
    try {
      const response = await updateGDriveFolder(payload);
      dispatch(updateOrgSettings(response));
      dispatch(sendSuccessToast('Successfully updated Google Drive folder.'));
    } catch (e) {
      dispatch(sendErrorToast('Failed to update Google Drive folder.'));
      throw e;
    }
  },
);

export const retrieveOrgSettingsThunk = u21CreateAsyncThunk(
  `${ORG_SETTINGS_NAME}/RETRIEVE_ORG_SETTINGS`,
  async (_, { dispatch }) => {
    const response = await get<OrgSettingsResponse>('/orgs/settings');
    dispatch(updateOrgSettings(response));
  },
);

const parseJSON = <TJSON = unknown>(str: string, fallback: TJSON): TJSON => {
  try {
    return JSON.parse(str) as TJSON;
  } catch (err) {
    consoleError('Invalid org settings', str);
    return fallback;
  }
};

const orgSettingsSlice = u21CreateSlice({
  name: ORG_SETTINGS_NAME,
  initialState: INITIAL_STATE,
  reducers: {
    updateOrgSettings: (draft, action: PayloadAction<OrgSettingsResponse>) => {
      const alertConfig = parseJSON(
        action.payload.alert_config,
        INITIAL_STATE.alertConfig,
      );
      draft.alertConfig =
        Object.entries(alertConfig).length > 0
          ? alertConfig
          : INITIAL_STATE.alertConfig;

      draft.consortiumConfig = parseJSON(
        action.payload.consortium_config,
        INITIAL_STATE.consortiumConfig,
      );

      draft.currencyConfig =
        action.payload.currency_config !== null &&
        typeof action.payload.currency_config === 'object'
          ? {
              ...INITIAL_CURRENCY_CONFIG,
              ...action.payload.currency_config,
            }
          : INITIAL_CURRENCY_CONFIG;

      draft.deltaLakeDataConfig = action.payload.data_config;

      draft.homeCurrencyCode = action.payload.home_currency_code;

      draft.lumosConfig = parseJSON(
        action.payload.lumos_config,
        INITIAL_STATE.lumosConfig,
      );

      draft.orgFeatures = action.payload.org_features;

      draft.orgEnums = action.payload.org_enums.enums;

      draft.queryBuilderConfig = parseJSON(
        action.payload.query_builder_config,
        INITIAL_STATE.queryBuilderConfig,
      );

      draft.ruleExecutionConfig = parseJSON(
        action.payload.rule_execution_config,
        INITIAL_STATE.ruleExecutionConfig,
      );

      draft.realTimeRulesConfig = action.payload.real_time_rules_config;

      draft.sarConfig = parseJSON(
        action.payload.sar_config,
        INITIAL_STATE.sarConfig,
      );

      draft.scenarioConfig = parseJSON(
        action.payload.scenario_config,
        INITIAL_STATE.scenarioConfig,
      );

      draft.storageConfig = parseJSON(
        action.payload.storage_config,
        INITIAL_STATE.storageConfig,
      );

      draft.storageResources = action.payload.storage_resources;
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(retrieveOrgSettingsThunk, 'loadingOrgSettings')
      .addLoadingCase(updateGDriveFolderThunk, 'loadingUpdateGDriveFolder');
  },
});

export const ORG_SETTINGS_SLICE_NAME = orgSettingsSlice.name;
export const { updateOrgSettings } = orgSettingsSlice.actions;
export default orgSettingsSlice.reducer;
