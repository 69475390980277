import {
  ENTITY_ANNOTATIONS,
  INSTRUMENT_ANNOTATIONS,
  TXN_EVENT_ANNOTATIONS,
  ADDRESS_ANNOTATIONS,
  TAG_ANNOTATIONS,
  ACTION_EVENT_ANNOTATIONS,
  DATA_MAPPING_AGGREGATION_METHODS,
  ALERT_ANNOTATIONS,
  DIRECTIONALITY_TYPES,
  DIRECTIONALITY_VALUES,
  DATA_MAPPING_SELECTION_OPTIONS,
  DATA_MAPPING_FILE_METADATA_OPTIONS,
} from 'app/modules/dataMapping/constants';
import { PrimaryObject } from 'app/modules/dataMapping/types';
import { RelationshipConfigType } from 'app/modules/relationships/models';
import { PathComponent } from 'jsonpath';

export interface TypeHandling {
  field_name: string;
  strategy: TypeHandlingStrategy;
  match_list: Array<string>;
  match_empty?: boolean;
}

export type TypeHandlingStrategy = 'ignore_list' | 'accept_list';

export interface SingletonDatetimeTransformationConfig {
  format: 'auto' | 'epoch_seconds' | 'epoch_milliseconds' | 'iso_8601';
}

export interface DatetimeStrptimeConfig {
  format: 'strptime';
  strptime_format: string;
}
export type DatetimeConversionConfig =
  | SingletonDatetimeTransformationConfig
  | DatetimeStrptimeConfig;

export interface LegacyDatetimeTransformation {
  function: 'datetime';
  configuration: DatetimeConversionConfig;
}

export interface LegacyTimezoneTransformation {
  function: 'timezone';
  configuration: {
    timezone: string;
  };
}

export interface DatetimeTimezoneTransformation {
  function: 'datetime_timezone';
  configuration: {
    datetime_config: DatetimeConversionConfig;
    timezone_transformation: boolean;
    timezone: string | null;
  };
}

export interface ValueMapTransformation {
  function: 'value_map';
  configuration: { value_map: Record<string, string> };
}

export interface PrefixTransformation {
  function: 'prefix';
  configuration: {
    prefix: string;
  };
}

export interface PostfixTransformation {
  function: 'postfix';
  configuration: {
    postfix: string;
  };
}

export interface SubstringTransformation {
  function: 'substring';
  configuration: {
    start_index: number | null;
    end_index: number | null;
  };
}

export interface FindReplaceTransformation {
  function: 'find_replace';
  configuration: {
    old_value: string;
    new_value: string;
    global_replace: boolean;
    regex_replace?: boolean;
  };
}

export enum RemoveEscapeSequenceCharacterOptions {
  A = 'a',
  B = 'b',
  F = 'f',
  N = 'n',
  R = 'r',
  T = 't',
  V = 'v',
}

export interface RemoveEscapeSequenceTransformation {
  function: 'remove_escape_sequence';
  configuration: {
    options: RemoveEscapeSequenceCharacterOptions[];
  };
}

export enum MathTransformationOperators {
  ADD = 'add',
  SUBTRACT = 'subtract',
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
}

export interface MathTransformation {
  function: 'math';
  configuration: {
    operator: MathTransformationOperators;
    operand: number | string;
  };
}

export interface SingletonTransformation {
  function:
    | 'camel_case'
    | 'uppercase'
    | 'md5_hash'
    | 'numeric_cast'
    | 'id_cleanup'
    | 'boolean_cast';
}

export interface JsonLoadsTransformation {
  function: 'json_loads';
  configuration?: {
    key_path?: PathComponent[] | string;
  };
}

export type Transformation =
  | ValueMapTransformation
  | PrefixTransformation
  | PostfixTransformation
  | SubstringTransformation
  | FindReplaceTransformation
  | MathTransformation
  | RemoveEscapeSequenceTransformation
  | SingletonTransformation
  | LegacyDatetimeTransformation
  | LegacyTimezoneTransformation
  | DatetimeTimezoneTransformation
  | JsonLoadsTransformation;

export interface Aggregation {
  values: ValueSelection<StreamSelection | FileMetadataSelection>[];
  aggregate_func: (typeof DATA_MAPPING_AGGREGATION_METHODS)[keyof typeof DATA_MAPPING_AGGREGATION_METHODS];
}

export interface StreamSelection {
  type: typeof DATA_MAPPING_SELECTION_OPTIONS.STREAM_VALUE;
  key: string;
}

export interface HardCodedSelection {
  type: typeof DATA_MAPPING_SELECTION_OPTIONS.HARD_CODED_VALUE;
  value: string;
}

export interface AggregationSelection {
  type: typeof DATA_MAPPING_SELECTION_OPTIONS.AGGREGATION;
  aggregation: Aggregation;
}

export type MetadataType =
  (typeof DATA_MAPPING_FILE_METADATA_OPTIONS)[keyof typeof DATA_MAPPING_FILE_METADATA_OPTIONS];

export interface FileMetadataSelection {
  type: typeof DATA_MAPPING_SELECTION_OPTIONS.METADATA_VALUE;
  metadata_type: MetadataType;
}

export type Selection =
  | StreamSelection
  | HardCodedSelection
  | AggregationSelection
  | FileMetadataSelection;

export interface ValueSelection<S extends Selection = Selection> {
  selection: S;
  transformations: Transformation[] | null;
}

export type Operator =
  | 'lt'
  | 'le'
  | 'eq'
  | 'ne'
  | 'ge'
  | 'gt'
  | 'regex_match'
  | 'value_present'
  | 'value_absent'
  | 'is_true'
  | 'is_false';

export interface AnnotationCondition {
  left: ValueSelection<
    AggregationSelection | StreamSelection | FileMetadataSelection
  >;
  operator: Operator;
  right: ValueSelection<
    | AggregationSelection
    | StreamSelection
    | HardCodedSelection
    | FileMetadataSelection
  >;
}

export interface TypedAnnotation<T extends string = string> {
  value: ValueSelection;
  annotation: T;
  conditions?: AnnotationCondition[];
}

export interface EntityAnnotation
  extends TypedAnnotation<keyof typeof ENTITY_ANNOTATIONS> {}

export interface InstrumentAnnotation
  extends TypedAnnotation<keyof typeof INSTRUMENT_ANNOTATIONS> {}

export interface TxnEventAnnotation
  extends TypedAnnotation<keyof typeof TXN_EVENT_ANNOTATIONS> {}

export interface AddressAnnotation
  extends TypedAnnotation<keyof typeof ADDRESS_ANNOTATIONS> {}

export interface TagAnnotation
  extends TypedAnnotation<keyof typeof TAG_ANNOTATIONS> {}

export interface ActionEventAnnotation
  extends TypedAnnotation<keyof typeof ACTION_EVENT_ANNOTATIONS> {}

export interface AlertAnnotation
  extends TypedAnnotation<keyof typeof ALERT_ANNOTATIONS> {}

export type GeneralTypedAnnotation =
  | EntityAnnotation
  | InstrumentAnnotation
  | TxnEventAnnotation
  | AddressAnnotation
  | TagAnnotation
  | ActionEventAnnotation
  | AlertAnnotation;

export type GeneralTypedAnnotations =
  | EntityAnnotation[]
  | InstrumentAnnotation[]
  | TxnEventAnnotation[]
  | AddressAnnotation[]
  | TagAnnotation[]
  | ActionEventAnnotation[]
  | AlertAnnotation[];

export interface CustomDataAnnotation {
  label: string;
  value: ValueSelection;
  conditions?: AnnotationCondition[];
}

export type Directionality =
  (typeof DIRECTIONALITY_VALUES)[keyof typeof DIRECTIONALITY_VALUES];
export interface HardCodedDirectionality {
  option: typeof DIRECTIONALITY_TYPES.HARD_CODED;
  value: Directionality;
}

export interface ConditionalDirectionality {
  option: typeof DIRECTIONALITY_TYPES.CONDITIONAL;
  condition: {
    true_outcome: Directionality;
    false_outcome: Directionality;
    left: ValueSelection;
    right: ValueSelection;
    operator: Operator;
  };
}

export interface ValueBasedDirectionality {
  option: typeof DIRECTIONALITY_TYPES.VALUE_BASED;
  value: ValueSelection;
  sending: Array<string>;
  receiving: Array<string>;
  neither: Array<string>;
  none: Array<string>;
  raise_on_missing: boolean;
}

export type DirectionaltyConfiguration =
  | ConditionalDirectionality
  | HardCodedDirectionality
  | ValueBasedDirectionality;

export interface BaseObjectReference<
  T extends string,
  U extends TypedAnnotation<string>,
> {
  name: string;
  type: T;
  typed_annotations: U[];
  custom_data_annotations?: CustomDataAttributes;
}

export interface EntityReference
  extends BaseObjectReference<'entity', EntityAnnotation> {
  directionality: DirectionaltyConfiguration | null;
}

export interface InstrumentReference
  extends BaseObjectReference<'instrument', InstrumentAnnotation> {
  directionality: DirectionaltyConfiguration | null;
}

export interface AddressReference
  extends BaseObjectReference<'address', AddressAnnotation> {}

export interface TagReference
  extends BaseObjectReference<'tag', TagAnnotation> {}

export interface TxnEventReference
  extends BaseObjectReference<'txn_event', TxnEventAnnotation> {}

export type ObjectReference =
  | EntityReference
  | InstrumentReference
  | AddressReference
  | TagReference
  | TxnEventReference;

export interface EntityToEntityRelationship {
  type: RelationshipConfigType.ENTITY_TO_ENTITY;
  relationship_id: string;
  external_id: string;
  first_entity_ref: string;
  second_entity_ref: string;
}

export interface EntityToInstrumentRelationship {
  type: RelationshipConfigType.ENTITY_TO_INSTRUMENT;
  relationship_id: string;
  external_id: string;
  entity_ref: string;
  instrument_ref: string;
}

export type Relationship =
  | EntityToEntityRelationship
  | EntityToInstrumentRelationship;

export interface CustomDataAttributes {
  strategy: 'all' | 'subset';
  annotations: CustomDataAnnotation[];
}

export interface BaseStreamConfig<
  T extends PrimaryObject,
  U extends TypedAnnotation<string>,
> {
  unit21_object: T;
  type_handling?: TypeHandling | null;
  typed_annotations: U[];
  custom_data_annotations?: CustomDataAttributes;
  objects?: ObjectReference[] | null;
  relationships?: Relationship[] | null;
  updating_existing?: boolean;
}

export interface EntityStreamConfig
  extends BaseStreamConfig<PrimaryObject.ENTITY, EntityAnnotation> {}
export interface InstrumentStreamConfig
  extends BaseStreamConfig<PrimaryObject.INSTRUMENT, InstrumentAnnotation> {}
export interface TxnEventStreamConfig
  extends BaseStreamConfig<PrimaryObject.TRANSACTION, TxnEventAnnotation> {}
export interface ActionEventStreamConfig
  extends BaseStreamConfig<PrimaryObject.ACTION, ActionEventAnnotation> {}
export interface AlertStreamConfig
  extends BaseStreamConfig<PrimaryObject.ALERT, ActionEventAnnotation> {}

export type StreamConfig =
  | EntityStreamConfig
  | InstrumentStreamConfig
  | TxnEventStreamConfig
  | ActionEventStreamConfig
  | AlertStreamConfig;

export interface StreamConfigResponse {
  config: StreamConfig | null;
}
