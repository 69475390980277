import { useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styledComponents, { css } from 'styled-components';
import { isEqual, get } from 'lodash';

// Selectors
import { selectSidebarDefinition } from 'app/modules/sidebar/selectors';

// Models
import {
  SidebarComponentTypes,
  SidebarDefinition,
} from 'app/modules/sidebar/models';

// Components
import { FeatureFlagControl } from 'app/modules/devtools/components/FeatureFlagControl';
import { PermissionsControl } from 'app/modules/devtools/components/PermissionControl';
import { SidebarAlert } from 'app/modules/sidebar/components/SidebarAlert';
import { SidebarEntity } from 'app/modules/sidebar/components/SidebarEntity';
import { SidebarInstrument } from 'app/modules/sidebar/components/SidebarInstrument';
import { SidebarActionEvent } from 'app/modules/sidebar/components/SidebarActionEvent';
import { SidebarTableConfig } from 'app/modules/sidebar/components/SidebarTableConfig';
import { SidebarCase } from 'app/modules/sidebar/components/SidebarCase';
import { SidebarSAR } from 'app/modules/sidebar/components/SidebarSAR';
import { SidebarTransaction } from 'app/modules/sidebar/components/SidebarTransaction';
import { SidebarRuleAdmin } from 'app/modules/rulesAdmin/components/sidebar/SidebarRuleAdmin';
import { ProcessingErrorsSidebar } from 'app/modules/pullBasedDataFiles/components/sidebars/ProcessingErrorsSidebar';
import { DataSettingSidebar } from 'app/modules/dataSettings/components/DataSettingSidebar';
import { SidebarFincenCtr } from 'app/modules/sidebar/components/SidebarFincenCtr';
import { hideSidebar } from 'app/modules/sidebar/slice';

// Analytics
import { heapTrack } from 'app/shared/utils/heap';
import heapEvents from 'app/shared/utils/heapEvents';
import { SidebarShowCustomData } from 'app/modules/sidebar/components/SidebarShowCustomData';
import { SidebarAlertRefresh } from 'app/modules/sidebar/components/SidebarAlertRefresh';
import { SidebarCaseRefresh } from 'app/modules/sidebar/components/SidebarCaseRefresh';
import { SidebarFinCENSAR } from 'app/modules/sidebar/components/SidebarFinCENSAR';
import { selectNewFeatureEnabled } from 'app/shared/components/GaBanner/selector';
import { FeatureFlag } from 'app/shared/featureFlags/models';

interface StyleProps {
  $open?: boolean;
}

function usePreviousDefinition(
  lastDefinition: Partial<SidebarDefinition> | undefined,
): Partial<SidebarDefinition> | undefined {
  const ref = useRef<Partial<SidebarDefinition> | undefined>(lastDefinition);

  useEffect(() => {
    ref.current = lastDefinition;
  }, [lastDefinition]);

  return ref.current;
}

export function Sidebar() {
  const sidebarDefinition: Partial<SidebarDefinition> | undefined = useSelector(
    selectSidebarDefinition,
  );
  const prevDefinition = usePreviousDefinition(sidebarDefinition);

  const hasAlertSidebarRefreshEnabled = useSelector((state) =>
    selectNewFeatureEnabled(
      state,
      'ALERT_SIDEBAR_REFRESH',
      FeatureFlag.SIDEBAR_REFRESH,
      FeatureFlag.SIDEBAR_REFRESH_GA,
    ),
  );

  const hasCaseSidebarRefreshEnabled = useSelector((state) =>
    selectNewFeatureEnabled(
      state,
      'CASE_SIDEBAR_REFRESH',
      FeatureFlag.SIDEBAR_REFRESH,
      FeatureFlag.SIDEBAR_REFRESH_GA,
    ),
  );

  const hasFilingSidebarRefreshEnabled = useSelector((state) =>
    selectNewFeatureEnabled(
      state,
      'FILING_SIDEBAR_REFRESH',
      FeatureFlag.SIDEBAR_REFRESH,
      FeatureFlag.SIDEBAR_REFRESH_GA,
    ),
  );

  const location = useLocation();
  const dispatch = useDispatch();

  const Component = useMemo(() => {
    switch (sidebarDefinition?.type) {
      case SidebarComponentTypes.ALERT:
        return hasAlertSidebarRefreshEnabled
          ? SidebarAlertRefresh
          : SidebarAlert;
      case SidebarComponentTypes.ENTITY:
        return SidebarEntity;
      case SidebarComponentTypes.INSTRUMENT:
        return SidebarInstrument;
      case SidebarComponentTypes.TRANSACTION:
        return SidebarTransaction;
      case SidebarComponentTypes.ACTION_EVENT:
        return SidebarActionEvent;
      case SidebarComponentTypes.TABLE_CONFIG:
        return SidebarTableConfig;
      case SidebarComponentTypes.CASE:
        return hasCaseSidebarRefreshEnabled ? SidebarCaseRefresh : SidebarCase;
      case SidebarComponentTypes.SAR:
        return hasFilingSidebarRefreshEnabled ? SidebarFinCENSAR : SidebarSAR;
      case SidebarComponentTypes.FILINGS_BATCH:
        return SidebarFincenCtr;
      case SidebarComponentTypes.RULE_ADMIN:
        return SidebarRuleAdmin;
      case SidebarComponentTypes.PERMISSIONS:
        return PermissionsControl;
      case SidebarComponentTypes.FEATURE_FLAGS:
        return FeatureFlagControl;
      case SidebarComponentTypes.FFIP_VIEW_ERRORS:
        return ProcessingErrorsSidebar;
      case SidebarComponentTypes.CREATE_EDIT_DATA_SETTING:
        return DataSettingSidebar;
      case SidebarComponentTypes.CUSTOM_DATA:
        return SidebarShowCustomData;
      default:
        return null;
    }
  }, [
    hasAlertSidebarRefreshEnabled,
    hasCaseSidebarRefreshEnabled,
    hasFilingSidebarRefreshEnabled,
    sidebarDefinition?.type,
  ]);

  useEffect(() => {
    if (sidebarDefinition?.type === SidebarComponentTypes.TABLE_CONFIG) {
      dispatch(hideSidebar());
    }
    // Avoid triggerring "hide" on "type" change after openning the sidebar
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch]);

  useEffect(() => {
    if (
      sidebarDefinition?.type !== prevDefinition?.type ||
      !isEqual(sidebarDefinition?.data, prevDefinition?.data)
    ) {
      // Open is true when we get a new sidebar type that is not undefined
      const open = sidebarDefinition?.type !== undefined;
      heapTrack(heapEvents.sidebar.toggle, {
        // We use the prevType if the sidebar is closed (new one is undefined)
        type: open ? sidebarDefinition.type : prevDefinition?.type,
        open,
        // Try to get the sidebar id (should exist on object sidebars like alerts, entities, cases, etc)
        id: get(sidebarDefinition?.data, 'id'),
      });
    }
  }, [
    sidebarDefinition?.type,
    sidebarDefinition?.data,
    prevDefinition?.type,
    prevDefinition?.data,
  ]);

  const validSidebar = Boolean(Component && sidebarDefinition?.data);
  return (
    <Container $open={Boolean(sidebarDefinition?.type)}>
      {/* @ts-ignore TODO update typing for sidebar definition */}
      {validSidebar && <Component data={sidebarDefinition.data} />}
    </Container>
  );
}

const Container = styledComponents.div<StyleProps>`
  grid-area: RightSlider;
  border-left: 1px solid ${(props) => props.theme.palette.divider};
  background: ${(props) => props.theme.palette.background.paper} ;
  transition: ${(props) => props.theme.transitions.create(['all'])};

  ${(props) =>
    props.$open
      ? css<StyleProps>`
          min-width: 370px;
          width: 370px;
          visibility: visible;
        `
      : css`
          min-width: 0;
          width: 0;
          visibility: hidden;
        `}
`;
