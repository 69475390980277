import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';

// Models
import {
  AssociatedAgentResponse,
  GetArticleCountResponse,
  QueueDetailsResponse,
  RetrieveQueuesListResponse,
  GetDeadlineSummaryResponse,
  ValidateAgentInQueueResponse,
} from 'app/modules/queues/responses';
import { Filter } from 'app/modules/filters/models';
import { QueueType } from 'app/modules/queues/models';
import {
  CreateQueuePayload,
  EditQueuePayload,
  RetrieveQueueListPayload,
  ValidateAgentInQueuePayload,
  GetAssociatedAgentsPayload,
} from 'app/modules/queues/requests';
import { ShortAgentResponse } from 'app/modules/agents/responses';

// Constants
import { FILING_FILTER_OPTIONS_BY_PAGE } from 'app/modules/fincenSarNew/filters';
import { FincenSarTablePages } from 'app/modules/fincenSar/models';
import { INITIAL_QUEUE } from 'app/modules/queues/constants';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// APIs
import {
  createQueueApi,
  deleteQueueApi,
  editQueueApi,
  listQueuesApi,
  retrieveQueueApi,
  retrieveQueuedArticlesCountApi,
  getDeadlineSummaryApi,
  validateAgentInQueueApi,
  retrieveAssociatedAgentsApi,
  retrieveAlertQueueBySubtypeApi,
} from 'app/modules/queues/api';

// Utils
import { fromUpperToSentenceCase } from 'app/shared/utils/string';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { getValidFilters } from 'app/modules/filters/utils';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

const QUEUES_NAME = 'queues';

interface QueuesState {
  loadingQueue: boolean;
  queue: QueueDetailsResponse;
  loadingQueues: boolean;
  alertQueues: QueueDetailsResponse[];
  caseQueues: QueueDetailsResponse[];
  sarQueues: QueueDetailsResponse[];
  alertQueuesCount: number;
  caseQueuesCount: number;
  sarQueuesCount: number;
  loadingRetrieveQueuedArticlesCount: boolean;
  queuedArticlesCount: number;
  loadingEditDeleteQueue: boolean;
  queueDeadlineSummary: GetDeadlineSummaryResponse;
  loadingQueueDeadlineSummary: boolean;
  associatedAgents: ShortAgentResponse[];
  loadingAssociatedAgents: boolean;
  alertQueueAlertFilters: Filter[];
  caseQueueCaseFilters: Filter[];
  filingQueueFilingFilters: Filter[];
}

export const queuesInitialState: Readonly<QueuesState> = {
  loadingQueue: false,
  queue: INITIAL_QUEUE,
  loadingQueues: false,
  alertQueues: [],
  caseQueues: [],
  sarQueues: [],
  alertQueuesCount: 0,
  caseQueuesCount: 0,
  sarQueuesCount: 0,
  loadingRetrieveQueuedArticlesCount: false,
  queuedArticlesCount: 0,
  loadingEditDeleteQueue: false,
  queueDeadlineSummary: { queues: [], queue_type: QueueType.CASE_QUEUE },
  loadingQueueDeadlineSummary: false,
  associatedAgents: [],
  loadingAssociatedAgents: false,
  alertQueueAlertFilters: [],
  caseQueueCaseFilters: [],
  filingQueueFilingFilters: getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.FILING_QUEUE_FILING_FILTERS),
    FILING_FILTER_OPTIONS_BY_PAGE[FincenSarTablePages.QUEUE_DETAILS],
  ),
};

export const retrieveQueuesThunkName = `${QUEUES_NAME}/RETRIEVE_QUEUES`;
export const retrieveQueuesThunk = u21CreateAsyncThunk<
  RetrieveQueueListPayload,
  RetrieveQueuesListResponse & { type: QueueType }
>(retrieveQueuesThunkName, async (payload, { dispatch }) => {
  try {
    const response = await listQueuesApi(payload);
    return { ...response, type: payload.type };
  } catch (e) {
    dispatch(sendErrorToast('Unable to retrieve queues. Please try again'));
    throw e;
  }
});

export const retrieveQueueThunkName = `${QUEUES_NAME}/RETRIEVE_QUEUE`;
export const retrieveQueueThunk = u21CreateAsyncThunk<
  { id: string; queueType: QueueType },
  QueueDetailsResponse
>(retrieveQueueThunkName, async (payload, { dispatch }) => {
  try {
    return await retrieveQueueApi(payload.id);
  } catch (e) {
    dispatch(sendErrorToast('Unable to retrieve queues. Please try again'));
    throw e;
  }
});

export const retrieveAlertQueueBySubtypeThunkName = `${QUEUES_NAME}/RETRIEVE_ALERT_QUEUE_BY_SUBTYPE`;
export const retrieveAlertQueueBySubtypeThunk = u21CreateAsyncThunk<
  { subtype: string },
  QueueDetailsResponse
>(retrieveAlertQueueBySubtypeThunkName, async (payload, { dispatch }) => {
  try {
    return await retrieveAlertQueueBySubtypeApi(payload.subtype);
  } catch (e) {
    dispatch(sendErrorToast('Unable to retrieve queue. Please try again'));
    throw e;
  }
});

export const retrieveQueuedArticlesCountThunkName = `${QUEUES_NAME}/RETRIEVE_QUEUED_ARTICLES_COUNT`;
export const retrieveQueuedArticlesCountThunk = u21CreateAsyncThunk<
  QueueType,
  GetArticleCountResponse
>(retrieveQueuedArticlesCountThunkName, async (payload, { dispatch }) => {
  try {
    return await retrieveQueuedArticlesCountApi(payload);
  } catch (e) {
    dispatch(
      sendErrorToast(
        `Failed to retrieve open ${payload.split('_')[0].toLowerCase()} count.`,
      ),
    );
    throw e;
  }
});

export const createQueueThunkName = `${QUEUES_NAME}/CREATE_QUEUE`;
export const createQueueThunk = u21CreateAsyncThunk<
  CreateQueuePayload,
  QueueDetailsResponse
>(createQueueThunkName, async (payload, { dispatch }) => {
  try {
    const response = await createQueueApi(payload);
    dispatch(
      sendSuccessToast(
        `${fromUpperToSentenceCase(payload.type)} successfully created`,
      ),
    );
    return response;
  } catch (e) {
    let errorToastMessage = 'Unable to create queue. Please try again';
    try {
      const { message } = await e.json();
      if (message) {
        errorToastMessage = message;
      }
    } catch {}
    dispatch(sendErrorToast(errorToastMessage));
    throw e;
  }
});

export const editQueueThunkName = `${QUEUES_NAME}/EDIT_QUEUE`;
export const editQueueThunk = u21CreateAsyncThunk<
  EditQueuePayload,
  QueueDetailsResponse
>(editQueueThunkName, async (payload, { dispatch }) => {
  try {
    const response = await editQueueApi(payload);
    dispatch(sendSuccessToast(`Queue successfully edited`));
    return response;
  } catch (e) {
    let errorToastMessage = 'Unable to edit queue. Please try again';
    try {
      const { message } = await e.json();
      if (message) {
        errorToastMessage = message;
      }
    } catch {}
    dispatch(sendErrorToast(errorToastMessage));
    throw e;
  }
});

export const deleteQueueThunkName = `${QUEUES_NAME}/DELETE_QUEUE`;
export const deleteQueueThunk = u21CreateAsyncThunk<
  number,
  QueueDetailsResponse
>(deleteQueueThunkName, async (payload, { dispatch }) => {
  try {
    const response = await deleteQueueApi(payload);
    dispatch(sendSuccessToast(`Queue successfully deleted`));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Unable to delete queue. Please try again'));
    throw e;
  }
});

const getDeadlineSummaryThunkName = `${QUEUES_NAME}/GET_DEADLINE_SUMMARY`;
export const getDeadlineSummaryThunk = u21CreateAsyncThunk<
  QueueType,
  GetDeadlineSummaryResponse
>(getDeadlineSummaryThunkName, async (payload, { dispatch }) => {
  try {
    return await getDeadlineSummaryApi(payload);
  } catch (e) {
    dispatch(
      sendErrorToast(
        'Unable to retrieve deadline summary information. Please try again.',
      ),
    );
    throw e;
  }
});
const validateAgentInQueueThunkName = `${QUEUES_NAME}/VALIDATE_AGENT_IN_QUEUE`;
export const validateAgentInQueueThunk = u21CreateAsyncThunk<
  ValidateAgentInQueuePayload,
  ValidateAgentInQueueResponse
>(validateAgentInQueueThunkName, async (payload, { dispatch }) => {
  try {
    return await validateAgentInQueueApi(payload);
  } catch (e) {
    let errorToastMessage = 'Unable to reassign agent and queue.';
    try {
      const { message } = await e.json();
      if (message) {
        errorToastMessage = message;
      }
    } catch {}
    dispatch(sendErrorToast(errorToastMessage));
    throw e;
  }
});

const retrieveAssociatedAgentsThunkName = `${QUEUES_NAME}/ASSOCIATED_AGENTS`;
export const retrieveAssociatedAgentsThunk = u21CreateAsyncThunk<
  GetAssociatedAgentsPayload,
  AssociatedAgentResponse
>(retrieveAssociatedAgentsThunkName, async (payload, { dispatch }) => {
  try {
    return await retrieveAssociatedAgentsApi(payload);
  } catch (e) {
    dispatch(
      sendErrorToast('Unable to retrieve assignable agents. Please try again'),
    );
    throw e;
  }
});

export const setAlertQueueAlertFilters = u21CreateAsyncThunk<
  Filter[],
  Filter[]
>(`${QUEUES_NAME}/SET_ALERT_QUEUE_ALERT_FILTERS`, (payload) => {
  localStorage.setItem(
    LocalStorageKeys.ALERT_QUEUE_ALERT_FILTERS,
    JSON.stringify(payload),
  );
  return payload;
});

export const setCaseQueueCaseFilters = u21CreateAsyncThunk<Filter[], Filter[]>(
  `${QUEUES_NAME}/SET_CASE_QUEUE_CASE_FILTERS`,
  (payload) => {
    localStorage.setItem(
      LocalStorageKeys.CASE_QUEUE_CASE_FILTERS,
      JSON.stringify(payload),
    );
    return payload;
  },
);

export const setFilingQueueFilingFilters = u21CreateAsyncThunk<
  Filter[],
  Filter[]
>(`${QUEUES_NAME}/SET_FILING_QUEUE_FILING_FILTERS`, (payload) => {
  localStorage.setItem(
    LocalStorageKeys.FILING_QUEUE_FILING_FILTERS,
    JSON.stringify(payload),
  );
  return payload;
});

const queuesSlice = u21CreateSlice({
  initialState: queuesInitialState,
  name: QUEUES_NAME,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addLoadingCase(retrieveQueuesThunk, 'loadingQueues', (draft, action) => {
        if (action.payload.type === QueueType.ALERT_QUEUE) {
          draft.alertQueues = action.payload.queues;
          draft.alertQueuesCount = action.payload.count;
        } else if (action.payload.type === QueueType.CASE_QUEUE) {
          draft.caseQueues = action.payload.queues;
          draft.caseQueuesCount = action.payload.count;
        } else if (action.payload.type === QueueType.SAR_QUEUE) {
          draft.sarQueues = action.payload.queues;
          draft.sarQueuesCount = action.payload.count;
        }
      })
      .addLoadingCase(retrieveQueueThunk, 'loadingQueue', (draft, action) => {
        draft.queue = action.payload;
      })
      .addLoadingCase(
        retrieveAlertQueueBySubtypeThunk,
        'loadingQueue',
        (draft, action) => {
          draft.queue = action.payload;
        },
      )
      .addLoadingCase(
        retrieveQueuedArticlesCountThunk,
        'loadingRetrieveQueuedArticlesCount',
        (draft, action) => {
          draft.queuedArticlesCount = action.payload.count;
        },
      )
      .addLoadingCase(createQueueThunk, 'loadingQueues', (draft, action) => {
        if (action.payload.type === QueueType.ALERT_QUEUE) {
          draft.alertQueues = [action.payload, ...draft.alertQueues];
          draft.alertQueuesCount += 1;
        } else if (action.payload.type === QueueType.CASE_QUEUE) {
          draft.caseQueues = [action.payload, ...draft.caseQueues];
          draft.caseQueuesCount += 1;
        } else if (action.payload.type === QueueType.SAR_QUEUE) {
          draft.sarQueues = [action.payload, ...draft.sarQueues];
          draft.sarQueuesCount += 1;
        }
      })
      .addLoadingCase(
        editQueueThunk,
        'loadingEditDeleteQueue',
        (draft, action) => {
          draft.queue = action.payload;
          if (action.payload.type === QueueType.ALERT_QUEUE) {
            draft.alertQueues = draft.alertQueues.map((queue) => {
              if (queue.id === action.payload.id) {
                return action.payload;
              }
              return queue;
            });
          } else if (action.payload.type === QueueType.CASE_QUEUE) {
            draft.caseQueues = draft.caseQueues.map((queue) => {
              if (queue.id === action.payload.id) {
                return action.payload;
              }
              return queue;
            });
          } else if (action.payload.type === QueueType.SAR_QUEUE) {
            draft.sarQueues = draft.sarQueues.map((queue) => {
              if (queue.id === action.payload.id) {
                return action.payload;
              }
              return queue;
            });
          }
        },
      )
      .addLoadingCase(
        deleteQueueThunk,
        'loadingEditDeleteQueue',
        (draft, action) => {
          if (action.payload.type === QueueType.ALERT_QUEUE) {
            draft.alertQueues = draft.alertQueues.filter((queue) => {
              return queue.id !== action.payload.id;
            });
            draft.alertQueuesCount -= 1;
          } else if (action.payload.type === QueueType.CASE_QUEUE) {
            draft.caseQueues = draft.caseQueues.filter((queue) => {
              return queue.id !== action.payload.id;
            });
            draft.caseQueuesCount -= 1;
          } else if (action.payload.type === QueueType.SAR_QUEUE) {
            draft.sarQueues = draft.sarQueues.filter((queue) => {
              return queue.id !== action.payload.id;
            });
            draft.sarQueuesCount -= 1;
          }
        },
      )
      .addLoadingCase(
        getDeadlineSummaryThunk,
        'loadingQueueDeadlineSummary',
        (draft, action) => {
          draft.queueDeadlineSummary.queues = action.payload.queues;
        },
      )
      .addLoadingCase(
        retrieveAssociatedAgentsThunk,
        'loadingAssociatedAgents',
        (draft, action) => {
          draft.associatedAgents = action.payload.agents;
        },
      )
      .addCase(setAlertQueueAlertFilters.fulfilled, (draft, action) => {
        draft.alertQueueAlertFilters = action.payload;
      })
      .addCase(setCaseQueueCaseFilters.fulfilled, (draft, action) => {
        draft.caseQueueCaseFilters = action.payload;
      })
      .addCase(setFilingQueueFilingFilters.fulfilled, (draft, action) => {
        draft.filingQueueFilingFilters = action.payload;
      });
  },
});

export const QUEUES_SLICE_NAME = queuesSlice.name;
export default queuesSlice.reducer;
