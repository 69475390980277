// Components
import {
  U21Alert,
  U21Button,
  U21ExternalLinkModal,
  U21Loading,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { IconSparkles } from '@u21/tabler-icons';

// Queries
import { useAiInvestigationResults } from 'app/modules/investigations/queries/useAiInvestigationResults';
import { useTriggerAiInvestigation } from 'app/modules/investigations/queries/useTriggerAiInvestigation';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Styles
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { createNarrativeThunk } from 'app/modules/narratives/sliceNarratives';
import { useAppDispatch } from 'app/store/storeHooks';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { selectLoadingCreateEditDeleteNarrative } from 'app/modules/narratives/selectors';
import { useSelector } from 'react-redux';
import { selectHasEditWebhooksPermissions } from 'app/modules/session/selectors';

interface OwnProps {
  id: number;
  customChecklistSubmissionId?: number;
}

export const AiInvestigationResults = ({
  id,
  customChecklistSubmissionId,
}: OwnProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isCreatingNarrative = useSelector(
    selectLoadingCreateEditDeleteNarrative,
  );

  const hasEditWebhooksPermissions = useSelector(
    selectHasEditWebhooksPermissions,
  );

  const { isLoading, data: aiInvestigationResults } = useAiInvestigationResults(
    InvestigationType.ALERT,
    {
      articleId: id,
      customChecklistSubmissionId,
    },
  );

  const { isPending, mutate } = useTriggerAiInvestigation({ article_id: id });

  if (isLoading) {
    return <U21Loading loading />;
  }

  if (!aiInvestigationResults) {
    return undefined;
  }

  if (
    aiInvestigationResults.current_ai_investigations_results?.status === 'ERROR'
  ) {
    const errorMsg =
      aiInvestigationResults.current_ai_investigations_results.status_message;
    const isOpenAIError = errorMsg.includes('Error 500');

    return (
      <StyledSpacer spacing={2}>
        <StyledAlert
          title="Oops... Something went wrong!"
          severity={isOpenAIError ? 'error' : 'warning'}
        >
          {isOpenAIError ? (
            <>
              There seems to be an issue with the AI Agent. It might be that
              OpenAI is down -{' '}
              <U21ExternalLinkModal url="https://status.openai.com/">
                {({ onClick }) => (
                  <U21Button
                    onClick={(event) => {
                      onClick();
                      event.stopPropagation();
                    }}
                    variant="text"
                  >
                    <u>check the status page</u>
                  </U21Button>
                )}
              </U21ExternalLinkModal>{' '}
              and if it’s down, try again later. It might also be that one of
              the checklist items is causing the agent to fail.{' '}
              {hasEditWebhooksPermissions ? (
                <U21Button
                  onClick={() =>
                    history.push(ROUTES_MAP.alertInvestigationChecklists.path)
                  }
                  variant="text"
                >
                  <u>Create a new checklist</u>
                </U21Button>
              ) : (
                'Create a new checklist'
              )}{' '}
              and try again.
            </>
          ) : (
            errorMsg
          )}
        </StyledAlert>
        <U21Button
          startIcon={<IconSparkles />}
          variant="contained"
          color="primary"
          loading={isPending}
          onClick={() => {
            mutate();
          }}
        >
          Try Again
        </U21Button>
      </StyledSpacer>
    );
  }

  if (
    aiInvestigationResults.current_ai_investigations_results?.status ===
      'COMPLETED' &&
    !isEmpty(aiInvestigationResults.current_ai_investigations_results?.results)
  ) {
    const {
      results: { overall },
    } = aiInvestigationResults.current_ai_investigations_results;

    return (
      <U21Alert
        title={
          <U21Spacer spacing={0.2}>
            <U21Typography variant="subtitle2">AI Agent Findings</U21Typography>
            <U21Typography variant="body2">{overall}</U21Typography>
          </U21Spacer>
        }
        Icon={IconSparkles}
        severity="normal"
        collapsible
      >
        {aiInvestigationResults.narrative && (
          <StyledFooter>
            <U21Button
              color="primary"
              size="small"
              loading={isCreatingNarrative}
              onClick={async () => {
                try {
                  await dispatch(
                    createNarrativeThunk({
                      article_id: id,
                      narrative: aiInvestigationResults.narrative!,
                    }),
                  ).unwrap();
                  history.push(
                    ROUTES_MAP.alertsIdTab.path
                      .replace(':id', id.toString())
                      .replace(':tab?', 'narrative'),
                  );
                } catch {}
              }}
            >
              Add to narrative
            </U21Button>
          </StyledFooter>
        )}
      </U21Alert>
    );
  }

  return <U21Loading loading />; // NOTE: status is 'PENDING'
};

const StyledSpacer = styled(U21Spacer)`
  align-items: center;
`;

const StyledAlert = styled(U21Alert)`
  width: 100%;
`;

const StyledFooter = styled.div`
  display: flex;
  flex: 1;
  margin-top: 16px;
  justify-content: flex-end;
`;
