import {
  Filter,
  FilterOption,
  IsOneOfOperatorAndValue,
} from 'app/modules/filters/models';

import { AgentStatus } from 'app/modules/agents/models';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import PERMISSIONS from 'app/shared/utils/permissions';

import { getValidFilters } from 'app/modules/filters/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';

import { TeamFilterDisplay } from 'app/modules/filters/components/TeamFilterDisplay';
import { U21TeamSelect } from 'app/shared/u21-ui/components/dashboard';

export const AGENT_FILTER_KEYS = {
  NAME_EMAIL: 'search',
  STATUS: 'status',
  TEAM: 'team',
} as const;

export const ALL_AGENT_FILTER_OPTIONS: FilterOption[] = [
  {
    key: AGENT_FILTER_KEYS.NAME_EMAIL,
    label: 'Name / Email',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: AGENT_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: {
          options: [
            { value: AgentStatus.ACTIVE, text: 'Active' },
            { value: AgentStatus.INACTIVE, text: 'Inactive' },
            { value: AgentStatus.AWAITING_APPROVAL, text: 'Awaiting approval' },
            { value: AgentStatus.AWAITING_LOGIN, text: 'Awaiting login' },
          ] satisfies { text: string; value: AgentStatus }[],
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
  {
    key: AGENT_FILTER_KEYS.TEAM,
    label: 'Team',
    permissions: [PERMISSIONS.readTeams],
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Display: ({
          value,
        }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
          return <TeamFilterDisplay ids={value} label="Team" />;
        },
        Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
          return (
            <U21TeamSelect
              autoFocus
              backdrop
              onChange={onChange}
              label={undefined}
              multi
              value={value}
            />
          );
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
];

export const TEAM_AGENT_FILTER_OPTIONS = ALL_AGENT_FILTER_OPTIONS.filter(
  (i) => i.key !== AGENT_FILTER_KEYS.TEAM,
);

export const DEFAULT_AGENT_FILTERS: Filter[] = [
  {
    key: AGENT_FILTER_KEYS.STATUS,
    operator: FILTER_OPERATOR.IS_ONE_OF,
    value: [AgentStatus.ACTIVE],
  },
];

export const getDefaultAgentFilters = (): Filter[] => {
  const filters = getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.AGENT_FILTERS),
    ALL_AGENT_FILTER_OPTIONS,
  );
  return [
    ...DEFAULT_AGENT_FILTERS,
    ...filters.filter((i) => i.key !== AGENT_FILTER_KEYS.STATUS),
  ];
};
