import { AiChatModal } from 'app/modules/codeInterpreter/components/AiChatModal';
import { IconAiSearch } from 'app/shared/components/Icons/IconAiSearch';
import { selectCodeInterpreterEnabled } from 'app/shared/featureFlags/selectors';
import { U21Badge, U21Button } from 'app/shared/u21-ui/components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAiChatPermissions } from 'app/modules/navigator/selectors';

export const GlobalAiChatModal = () => {
  const isAiChatEnabled = useSelector(selectCodeInterpreterEnabled);
  const hasAiChatPermissions = useSelector(selectAiChatPermissions);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  // Shares same permissions as 'Reporting' tab (src/app/shared/utils/routes.ts)
  if (!isAiChatEnabled || !hasAiChatPermissions) {
    return null;
  }

  return (
    <U21Badge color="info" content="New">
      <U21Button
        aria-label="AI Search"
        icon={<IconAiSearch />}
        onClick={() => setIsOpen(true)}
        tooltip="Ask Unit21 AI"
      />
      <AiChatModal fileExportId={-1} isOpen={isOpen} setIsOpen={setIsOpen} />
    </U21Badge>
  );
};
