import { useSelector } from 'react-redux';

// Components
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import {
  ALERT_SCORE_TOOLTIP_NO_RISK,
  ALERT_SCORE_TOOLTIP_RISK,
} from 'app/modules/alerts/constants';

import { U21HelpTooltip, U21Spacer } from 'app/shared/u21-ui/components';

// Styles
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';

// Selectors
import { selectSidebarObject } from 'app/modules/sidebar/selectors';

// Constants
import { AlertScoreLabel } from 'app/modules/investigations/components/SummaryCard/AlertScoreLabel';

export const SidebarScore = () => {
  const sidebarObject = useSelector(selectSidebarObject);

  if (!sidebarObject.alert_score) {
    return null;
  }

  return (
    <StyledItem>
      <SidebarFieldLabel>Alert Score</SidebarFieldLabel>
      <U21Spacer horizontal>
        <AlertScoreLabel alertScore={sidebarObject.alert_score} />
        <U21HelpTooltip
          help={
            sidebarObject.alert_score.risk
              ? ALERT_SCORE_TOOLTIP_RISK
              : ALERT_SCORE_TOOLTIP_NO_RISK
          }
          tooltipProps={{ placement: 'top' }}
        />
      </U21Spacer>
    </StyledItem>
  );
};
