import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

// Models
import {
  SarDetails,
  SarStatus,
  ReportType,
} from 'app/modules/fincenSar/models';

// Constants
import {
  PAGE_NUMBER as Page0,
  initialState as Page0InitialState,
} from 'app/modules/fincenSar/constants/00-sarHome';
import {
  PAGE_NUMBER as Page1,
  initialState as Page1InitialState,
} from 'app/modules/fincenSar/constants/01-sarInstitution';
import {
  PAGE_NUMBER as Page2,
  formInitialState as Page2InitialState,
} from 'app/modules/fincenSar/constants/02-sarActivityInstitution';
import {
  PAGE_NUMBER as Page3,
  initialContentState as Page3InitialState,
} from 'app/modules/fincenSar/constants/03-subjectInformation';
import {
  PAGE_NUMBER as Page4,
  initialState as Page4InitialState,
} from 'app/modules/fincenSar/constants/04-sarSuspiciousActivity';
import {
  PAGE_NUMBER as Page5,
  initialState as Page5InitialState,
} from 'app/modules/fincenSar/constants/05-sarNarrativeSection';
import { ROUTES_MAP } from 'app/shared/utils/routes';

export const titles = {
  page0: 'Home',
  page1: 'Step 1. Filing Institution Contact Information (75 - 95)',
  page2: 'Step 2. Financial Institution Where Activity Occurred (51 - 74)',
  page3: 'Step 3. Subject Information (3 - 28)',
  page4: 'Step 4. Suspicious Activity Information (29 - 50)',
  page5: 'Step 5. Narrative',
};

export const ADD_ICON: SemanticICONS = 'plus square outline';
export const REMOVE_ICON: SemanticICONS = 'minus square outline';

export const CARSettingsInitialState = {
  carEnabled: false,
  carSubsequentEnabled: false,
  carDaysAfter: 90,
  carDaysBefore: 120,
};

export const SARSettingsInitialState = {
  caseCloseDisabled: false,
};

export const DATE_INPUT_MMDDYYY_REGEX = /^\d{2}\/\d{2}\/\d{4}$/;
export const DATE_INPUT_YYYYMMDD_REGEX = /^\d{4}\/\d{2}\/\d{2}$/;
export const DATE_INPUT_DDMMMYYY_REGEX =
  /^\d{2}\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{4}$/i;

export const initialContent = {
  [Page0]: {
    valid: false,
    content: Page0InitialState,
  },
  [Page1]: {
    valid: false,
    content: Page1InitialState,
  },
  [Page2]: {
    valid: false,
    content: Page2InitialState,
  },
  [Page3]: {
    valid: false,
    content: Page3InitialState,
  } as any,
  [Page4]: {
    valid: false,
    content: Page4InitialState,
  },
  // Starting narrative as valid b/c is not required / always valid
  [Page5]: {
    valid: true,
    content: Page5InitialState,
  },
};

export const sarDetailsInitialState: SarDetails = {
  attachments: [],
  first_alert_created_at: '',
  deadline: null,
  deadline_config: null,
  org_id: -1,
  name: '',
  description: '',
  id: -1,
  created_at: '',
  filing_type: 'Initial report',
  status: '' as SarStatus,
  updated_at: '',
  cases: [],
  alerts: [],
  entities: [],
  events: [],
  lock: false,
  comments_count: 0,
  comments: [],
  submitted: false,
  report_type: ReportType.FINCEN_SAR,
  created_by: {
    full_name: '',
  },
  error_msg: '',
  queue: null,
  content: initialContent,
  activity_type: [],
  entity_region: [],
};

export const DO_NOT_SHOW_DEADLINE_STATUSES: SarStatus[] = [
  SarStatus.ARCHIVED,
  SarStatus.FILING_FAILED,
  SarStatus.FILING_SUCCESS,
  SarStatus.FINCEN_VALIDATION_FAILED,
  SarStatus.FINCEN_REJECTED,
  SarStatus.FINCEN_ACCEPTED,
  SarStatus.FINCEN_STATUS_OTHER,
  SarStatus.SENT_TO_FINCEN,
];

export const REPORT_TYPE_PATH_MAP: Record<ReportType, string> = {
  [ReportType.FINCEN_SAR]: ROUTES_MAP.filingsSarId.path,
  [ReportType.GOAML_STR]: ROUTES_MAP.filingsGoAMLIdEdit.path,
  [ReportType.FINCEN_CTR]: ROUTES_MAP.filingsFincenCtrEdit.path.replace(
    '/:tab?',
    '',
  ),
  [ReportType.ESTONIAN_FIU]: ROUTES_MAP.fourOhFour.path, // deprecated
  [ReportType.NCA_SAR]: ROUTES_MAP.fourOhFour.path, // deprecated
};

export const REPORT_TYPE_DISPLAY_TEXT_MAP: Record<ReportType, string> = {
  [ReportType.FINCEN_SAR]: 'FinCEN SAR',
  [ReportType.GOAML_STR]: 'goAML STR',
  [ReportType.FINCEN_CTR]: 'FinCEN CTR',
  [ReportType.ESTONIAN_FIU]: 'Unsupported', // deprecated
  [ReportType.NCA_SAR]: 'Unsupported', // deprecated
};
