// Models
import {
  AdminActionTypes,
  CreateOrgPayload,
  DefaultRule,
  EditOrgPayload,
  OrgDetails,
} from 'app/modules/admin/models';
import {
  EditOrgConsortiumConfigPayload,
  EditOrgKytConfigPayload,
} from 'app/modules/admin/types/requests';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const createOrg = (payload: CreateOrgPayload) => {
  return createAction(AdminActionTypes.CREATE_ORG, payload);
};

export const createOrgSuccess = (payload: OrgDetails) =>
  createAction(AdminActionTypes.CREATE_ORG_SUCCESS, payload);

export const retrieveOrg = (payload: number) => {
  return createAction(AdminActionTypes.RETRIEVE_ORG, payload);
};

export const retrieveOrgSuccess = (payload: OrgDetails) => {
  return createAction(AdminActionTypes.RETRIEVE_ORG_SUCCESS, payload);
};

export const retrieveOrgs = () => {
  return createAction(AdminActionTypes.RETRIEVE_ORGS);
};

export const retrieveOrgsSuccess = (payload: OrgDetails[]) => {
  return createAction(AdminActionTypes.RETRIEVE_ORGS_SUCCESS, payload);
};

export const editOrg = (payload: EditOrgPayload) => {
  return createAction(AdminActionTypes.EDIT_ORG, payload);
};

export const editOrgKytConfig = (payload: EditOrgKytConfigPayload) => {
  return createAction(AdminActionTypes.EDIT_KYT_CONFIG, payload);
};

export const editOrgSuccess = (payload: OrgDetails) => {
  return createAction(AdminActionTypes.EDIT_ORG_SUCCESS, payload);
};

export const editOrgKytConfigSuccess = (payload: EditOrgKytConfigPayload) => {
  return createAction(AdminActionTypes.EDIT_KYT_CONFIG_SUCCESS, payload);
};

export const editOrgConsortiumConfigSuccess = (
  payload: EditOrgConsortiumConfigPayload,
) => {
  return createAction(
    AdminActionTypes.EDIT_ORG_CONSORTIUM_CONFIG_SUCCESS,
    payload,
  );
};

export const retrieveDefaultRules = () => {
  return createAction(AdminActionTypes.RETRIEVE_DEFAULT_RULES);
};

export const retrieveDefaultRulesSuccess = (payload: DefaultRule[]) => {
  return createAction(AdminActionTypes.RETRIEVE_DEFAULT_RULES_SUCCESS, payload);
};

export const setDropdownCurrentOrg = (OrgId?: number) => {
  return createAction(AdminActionTypes.SET_DROPDOWN_SELECTED_ORG, OrgId);
};

const AdminActionsList = {
  createOrg,
  createOrgSuccess,
  retrieveOrg,
  retrieveOrgSuccess,
  retrieveOrgs,
  retrieveOrgsSuccess,
  editOrg,
  editOrgSuccess,
  editOrgKytConfig,
  editOrgKytConfigSuccess,
  editOrgConsortiumConfigSuccess,
  retrieveDefaultRules,
  retrieveDefaultRulesSuccess,
  setDropdownCurrentOrg,
};

export type AdminActions = ActionsUnion<typeof AdminActionsList>;
