import { useSelector } from 'react-redux';

// Components
import { PriorArticlesTable } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorArticlesTable';

// Selectors
import { selectAlertAssociatedSARs } from 'app/modules/alerts/selectors';
import { selectHasReadSARsPermissions } from 'app/modules/session/selectors';

// Models
import { U21TableColumn } from 'app/shared/u21-ui/components';
import { AssociatedSAR } from 'app/modules/alerts/types';

// Utils
import routes from 'app/shared/utils/routes';

// Columns
import {
  ENTITY_IDS_COLUMN,
  EVENT_IDS_COLUMN,
} from 'app/modules/alerts/components/AlertDetails/PriorActivity/columns';

const ALERT_ASSOCIATED_SARS_TABLE_CONFIG: U21TableColumn<AssociatedSAR>[] = [
  { id: 'id', accessor: 'id', Header: 'ID' },
  {
    id: 'name',
    accessor: 'name',
    Header: 'Title',
    cellProps: {
      variant: 'subtitle2',
    },
  },
  ENTITY_IDS_COLUMN,
  EVENT_IDS_COLUMN,
];

export const PriorSARs = () => {
  const associatedSARs = useSelector(selectAlertAssociatedSARs);
  const hasReadSarPermissions = useSelector(selectHasReadSARsPermissions);

  if (!hasReadSarPermissions) {
    return null;
  }

  return (
    <PriorArticlesTable
      category="sar"
      articles={associatedSARs}
      detailsRoute={routes.lumos.filingsSarId}
      config={ALERT_ASSOCIATED_SARS_TABLE_CONFIG}
    />
  );
};
