import { AlertType } from 'app/modules/alerts/types/alert';

import { U21TabRouterItem } from 'app/shared/u21-ui/components';
import { Permission } from 'app/shared/utils/permissions';
import { FeatureFlag } from 'app/shared/featureFlags/models';

export enum AdditionalFilters {
  // Needs custom checklist definition to be defined
  NEEDS_CHECKLIST = 'NEEDS_CHECKLIST',
  // Needed to be visible on Intuit alerts
  INTUIT = 'INTUIT',
  // Only visible on Intuit alerts
  INTUIT_ONLY = 'INTUIT_ONLY',
}

interface AlertDetailsTabVisibility {
  // Inclusionary: the types of alerts supported by the tab
  types?: AlertType[];
  // Exclusionary: the feature flags required
  flags?: FeatureFlag[];
  // Exclusionary: the permissions required
  permissions?: Permission[];
  // Exclusionary: Additional filters
  filters?: AdditionalFilters[];
}

export enum DisabledOnReason {
  // Disables the tab when there are no associated articles
  NO_ASSOCIATED_ARTICLES = 'NO_ASSOCIATED_ARTICLES',
  // Disable the tab when there's no associated entities for the network analysis or there's too many
  NO_NETWORK_ANALYSIS = 'NO_NETWORK_ANALYSIS',
}

export interface AlertDetailsTab extends U21TabRouterItem {
  labelString?: string;
  visibleOn: AlertDetailsTabVisibility;
  disabledOn?: DisabledOnReason[];
}
