import produce, { Draft } from 'immer';

// Models
import {
  BlacklistActionTypes,
  BlacklistsState,
} from 'app/modules/blacklists/models';
import BlacklistActions from 'app/modules/blacklists/actions';

// Constants
import { INITIAL_BLACKLIST } from 'app/modules/blacklists/constants';

const initialState: BlacklistsState = {
  blacklists: [],
  blacklistsCount: 0,
  blacklist: INITIAL_BLACKLIST,
  blacklistItems: [],
  blacklistItemsCount: 0,
  fileStatus: {
    status: '',
    message: '',
  },
};

const reducer = (state = initialState, action: BlacklistActions) => {
  return produce(state, (draft: Draft<BlacklistsState>) => {
    switch (action.type) {
      case BlacklistActionTypes.RETRIEVE_BLACKLISTS_SUCCESS:
        draft.blacklists = action.payload.blacklists;
        draft.blacklistsCount = action.payload.count;
        return;

      case BlacklistActionTypes.RETRIEVE_BLACKLIST_SUCCESS:
        draft.blacklist = action.payload;
        return;

      case BlacklistActionTypes.CREATE_BLACKLIST_SUCCESS:
        draft.blacklist = action.payload;
        draft.blacklists = [action.payload, ...draft.blacklists];
        draft.blacklistsCount += 1;
        return;

      case BlacklistActionTypes.EDIT_BLACKLIST_SUCCESS:
        draft.blacklist = action.payload;
        draft.blacklists = draft.blacklists.map((blacklist) => {
          if (action.payload.id === blacklist.id) {
            return action.payload;
          }
          return blacklist;
        });
        return;

      case BlacklistActionTypes.RETRIEVE_BLACKLIST_ITEMS_SUCCESS:
        draft.blacklistItems = action.payload.blacklist_items;
        draft.blacklistItemsCount = action.payload.count;
        return;

      case BlacklistActionTypes.DEACTIVATE_BLACKLIST_ITEM_SUCCESS:
      case BlacklistActionTypes.ADD_BLACKLIST_ITEM_SUCCESS:
      case BlacklistActionTypes.ADD_USER_BLACKLIST_ITEM_SUCCESS:
      case BlacklistActionTypes.ADD_BUSINESS_BLACKLIST_ITEM_SUCCESS:
        return;

      case BlacklistActionTypes.APPEND_FILE_TO_BLACKLIST_SUCCESS:
        draft.fileStatus = action.payload;
        return;
    }
  });
};

export { reducer as blacklistsReducer };
