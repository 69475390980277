// Models
import {
  FilterOption,
  IsOneOfOperatorAndValue,
} from 'app/modules/filters/models';

// Components
import { TxnTypeSelect } from 'app/modules/transactions/components/TxnTypeSelect';

// Constants
import { DATA_LABEL_FILTER_OPTION } from 'app/modules/filters/filters';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';

/** Filter keys for Txn Analysis Tabs.
 * WARNING!!! KEYS ARE STRONGLY COUPLED TO
 * BE IMPLEMENTATION.
 * Change with caution!
 */
export const TXN_ANALYSIS_FILTER_KEYS = {
  AMOUNT: 'amount',
  EVENT_TIME: 'event_time',
  IP_ADDRESS: 'ip_address',
  ORG_NAME: 'org_name',
  RECEIVED_AMOUNT: 'received_amount',
  RECEIVED_CURRENCY: 'received_currency',
  RECEIVER_ID: 'receiver_id',
  RECEIVER_NAME: 'receiver_name',
  RECEIVER_INSTRUMENT_ID: 'receiver_instrument_external_id',
  RECEIVER_INSTRUMENT_NAME: 'receiver_instrument_name',
  RECEIVER_INSTRUMENT_SUBTYPE: 'receiver_instrument_subtype',
  RECEIVER_INSTRUMENT_TYPE: 'receiver_instrument_type',
  SENDER_ID: 'sender_id',
  SENDER_NAME: 'sender_name',
  SENDER_INSTRUMENT_ID: 'sender_instrument_external_id',
  SENDER_INSTRUMENT_NAME: 'sender_instrument_name',
  SENDER_INSTRUMENT_SUBTYPE: 'sender_instrument_subtype',
  SENDER_INSTRUMENT_TYPE: 'sender_instrument_type',
  SENT_AMOUNT: 'sent_amount',
  SENT_CURRENCY: 'sent_currency',
  STATUS: 'status',
  TAG: 'tags',
  TXN_EXTERNAL_ID: 'external_id',
  TXN_TYPE: 'internal_txn_type',
  UPDATED_AT: 'updated_at',
} as const;

export const TXN_ANALYSIS_FILTER_KEYS_SET = new Set<string>(
  Object.values(TXN_ANALYSIS_FILTER_KEYS),
);

export const ALL_TXN_ANALYSIS_FILTER_OPTIONS: FilterOption[] = [
  {
    key: TXN_ANALYSIS_FILTER_KEYS.AMOUNT,
    label: 'Amount',
    type: FILTER_TYPE.NUMBER_RANGE,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.EVENT_TIME,
    label: 'Time',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.IP_ADDRESS,
    label: 'IP address',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.ORG_NAME,
    label: 'Org name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVED_AMOUNT,
    label: 'Received amount',
    type: FILTER_TYPE.NUMBER_RANGE,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVED_CURRENCY,
    label: 'Received currency',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVER_ID,
    label: 'Receiver ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVER_NAME,
    label: 'Receiver name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVER_INSTRUMENT_ID,
    label: 'Receiver instrument ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVER_INSTRUMENT_NAME,
    label: 'Receiver instrument name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVER_INSTRUMENT_SUBTYPE,
    label: 'Receiver instrument subtype',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.RECEIVER_INSTRUMENT_TYPE,
    label: 'Receiver instrument type',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENDER_ID,
    label: 'Sender ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENDER_NAME,
    label: 'Sender name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENDER_INSTRUMENT_ID,
    label: 'Sender instrument ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENDER_INSTRUMENT_NAME,
    label: 'Sender instrument name',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENDER_INSTRUMENT_SUBTYPE,
    label: 'Sender instrument subtype',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENDER_INSTRUMENT_TYPE,
    label: 'Sender instrument type',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENT_AMOUNT,
    label: 'Sent amount',
    type: FILTER_TYPE.NUMBER_RANGE,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.SENT_CURRENCY,
    label: 'Sent currency',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.TAG,
    ...DATA_LABEL_FILTER_OPTION,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.TXN_EXTERNAL_ID,
    label: 'Transaction event ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.TXN_TYPE,
    label: 'Type',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Input: ({ onChange, value }: IsOneOfOperatorAndValue<string>) => {
          return <TxnTypeSelect onChange={onChange} value={value} />;
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
  {
    key: TXN_ANALYSIS_FILTER_KEYS.UPDATED_AT,
    label: 'Updated at',
    type: FILTER_TYPE.DATE_RANGE,
  },
];
