import { useSelector } from 'react-redux';

// Components
import { U21Chip, U21_NO_VALUE } from 'app/shared/u21-ui/components';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';

// Styles
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';

// Selectors
import { selectSidebarObject } from 'app/modules/sidebar/selectors';

// Utils
import { fromUpperToSentenceCase } from 'app/shared/utils/string';

// Constants
import { STATUS_TO_COLOR } from 'app/modules/investigations/constants';

export const SidebarStatus = () => {
  const { status = '' } = useSelector(selectSidebarObject);

  return (
    <StyledItem>
      <SidebarFieldLabel>Status</SidebarFieldLabel>
      <U21Chip color={STATUS_TO_COLOR[status]}>
        {fromUpperToSentenceCase(status || U21_NO_VALUE)}
      </U21Chip>
    </StyledItem>
  );
};
