// Models
import {
  SarCheckboxOptions,
  SarDropdownOptions,
  SarInput,
} from 'app/modules/fincenSar/models';

// Helpers
import {
  createState,
  createCheckboxItems,
} from 'app/modules/fincenSar/helpers';
import { createDropdownItems } from 'app/shared/helpers';

const popupContent = `Item 24 - Relationship of the subject to an institution listed in Part III or IV. If the subject has a relationship with a financial
institution or individual listed in Part III or Part IV, enter the
financial institution's TIN and check all boxes that describe
the relationship. If "Other" is checked, enter a brief
description of the relationship in the associated text field.
When describing the "other" identification, avoid
descriptions that are too general. For example, the entry
"broker" should be made more specific by indicating the
relationship was "real estate broker," "securities broker," or
"mortgage broker." If necessary, describe the relationship
more fully in Part V.
If the subject has no known relationship with any of the Part
III and Part IV financial institutions, a single Item 21 with box
21j “No known relationship” checked and the 21a “Institution
TIN” field blank will be prepared. If the subject has a
relationship with some institutions and does not have a
relationship with others, an Item 21 will be prepared only on
those institutions where a relationship was present.
Additional Item 21 s will not be prepared for the other
institutions.
If the SAR is a joint report, the checkbox for 24h “Director,”
241 “Employee,” 24i “Officer,” or 24k “Owner or Controlling
Shareholder” must not be checked. If the subject had a
relationship with a financial institution recorded in Part ”I but
the financial institution’s TIN is unknown, record the
relationship but leave "Institution TIN" blank. If the subject
has relationships with multiple Part III and/or Part IV financial
institutions, repeat this set of items for each financial
institution.
If more than one of 24h “Director,” 241 “Employee,” 24i
“Officer,” or 24k “Owner or Controlling Shareholder” is
selected and/or the status or action date is different for each,
an Item 24 for each must be completed.`;

// ================ 24. Relationship of the subject to an institution listed in Part III or IV (check all that apply) ==================

const ownerOrControllingShareHolder = 'Owner or Controlling Shareholder';

const relationshipOptions = [
  'Accountant',
  'Agent',
  'Appraiser',
  'Attorney',
  'Borrower',
  'Customer',
  'Director',
  'Employee',
  'Officer',
  ownerOrControllingShareHolder,
  'Other',
];

export const relationship: SarCheckboxOptions = {
  title: '',
  name: '24_relationship',
  options: relationshipOptions,
  popupContent,
  initialValue: createCheckboxItems(relationshipOptions),
};

export const relationshipOther: SarInput = {
  title: 'Other',
  name: '24_relationshipOther',
  initialValue: '',
};
// ================================================================

// ============== 24. Institution TIN =============================

export const institutionTIN: SarDropdownOptions = {
  title: `Institution TIN`,
  name: '24_institutionTIN',
  initialValue: '',
  // options need to be generated dynamically based on field 59. in page 2
  options: [],
  popupContent: `TIN's are populated by item(s) 59 and item 77. Record this element for the Subject (33) in order to identify the TIN (if known) associated with the filing institution or financial institution where activity occurred in which the subject has a relationship with; otherwise, do not record this element. If the subject has no relationship with any of these institutions, record the TIN of the filing institution for this element and record a "Y" value for the <NoRelationshipToInstitutionIndicator> element.`,
};

// ================================================================

// ==================== 25. Status of relationship =================
const statusOptions = [
  'Relationship continues',
  'Terminated',
  'Suspended/barred',
  'Resigned',
];

export const status: SarDropdownOptions = {
  title: `25. Status of relationship`,
  name: '25_status',
  options: createDropdownItems(statusOptions),
  initialValue: '',
};
// ================================================================

// ==================== 26. Action date ======================
// All elements besides the first element in status options (Relationship continues) enables actionData
export const actionDate: SarInput = {
  title: `26. Action date`,
  name: `26_actionDate`,
  initialValue: '',
};
// ================================================================

const allState = [
  institutionTIN,
  relationship,
  relationshipOther,
  status,
  actionDate,
];

const restOfState = {
  disabled: false,
};

export const initialState = { ...createState(allState), ...restOfState };
