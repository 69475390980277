// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import {
  CustomTableConfig,
  CustomTableConfigPayload,
  TableConfigActionTypes,
  TableConfigState,
  TableConfigType,
} from 'app/shared/CustomConfig/models';

// Actions
import { TableConfigActions } from 'app/shared/CustomConfig/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';
import {
  DEFAULT_RULE_ADMIN_CONTENT_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_EXECUTION_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_QUERY_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_SCHEDULE_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_VALIDATION_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_ALERT_CONTENT_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_ALERT_HIT_TABLE_CONFIG,
  DEFAULT_RULE_ADMIN_ORG_SETTINGS_TABLE_CONFIG,
} from 'app/modules/rulesAdmin/constants';
import { DEFAULT_DATA_SETTINGS_TABLE_CONFIG } from 'app/modules/dataSettings/constants';
import { DEFAULT_TABLE_CONFIGS } from 'app/shared/CustomConfig/constants';

const initialState: Readonly<TableConfigState> = {
  configs: {},
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<TableConfigState> = (
  state = initialState,
  action: TableConfigActions,
) => {
  return produce(state, (draft: Draft<TableConfigState>) => {
    switch (action.type) {
      case TableConfigActionTypes.SAVE_TABLE_CONFIG_SUCCESS:
        draft.configs[action.payload.type as TableConfigType] = action.payload;
        return;

      case TableConfigActionTypes.RETRIEVE_TABLE_CONFIG_SUCCESS: {
        const configs: CustomTableConfig[] = action.payload || [];
        configs.forEach((config: CustomTableConfig) => {
          const type: string = config.type || '';
          draft.configs[type] = config;
        });
        return;
      }

      case TableConfigActionTypes.RETRIEVE_ORG_TABLE_CONFIG_SUCCESS: {
        const allTableConfigs: CustomTableConfig[] =
          [
            ...(action.payload || []),
            {
              definition: DEFAULT_RULE_ADMIN_CONTENT_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_CONTENT_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_EXECUTION_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_EXECUTION_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_QUERY_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_QUERY_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_VALIDATION_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_VALIDATION_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_SCHEDULE_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_SCHEDULE_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_ALERT_CONTENT_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_ALERT_CONTENT_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_ALERT_HIT_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_ALERT_HIT_TABLE,
            },
            {
              definition: DEFAULT_RULE_ADMIN_ORG_SETTINGS_TABLE_CONFIG,
              type: TableConfigType.RULE_ADMIN_ORG_SETTINGS_TABLE,
            },
            {
              definition: DEFAULT_DATA_SETTINGS_TABLE_CONFIG,
              type: TableConfigType.DATA_SETTINGS,
            },
          ] || [];
        const addDefaultConfigsIfAbsent = (
          tableConfigs: CustomTableConfig[],
        ): CustomTableConfig[] => {
          Object.keys(DEFAULT_TABLE_CONFIGS).forEach((key) => {
            if (!tableConfigs.some((config) => config.type === key)) {
              tableConfigs.push({
                definition: DEFAULT_TABLE_CONFIGS[key],
                type: key as TableConfigType,
              });
            }
          });
          return tableConfigs;
        };
        addDefaultConfigsIfAbsent(allTableConfigs).forEach(
          (config: CustomTableConfig) => {
            const type: string = config.type || '';
            draft.configs[type] = config;
          },
        );
        return;
      }

      case TableConfigActionTypes.SAVE_LOCAL_TABLE_CONFIG: {
        const currLocalTableConfig: CustomTableConfigPayload = action.payload;
        draft.configs[currLocalTableConfig.type] = currLocalTableConfig;
        return;
      }

      default:
        return;
    }
  });
};

export { reducer as tableConfigReducer, initialState };
