import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveSidebarAlertEntities as retrieveSidebarAlertEntitiesAction,
  retrieveSidebarAlertEntitiesSuccess,
} from 'app/modules/alerts/actions';

// API
import { retrieveAlertEntities as retrieveAPI } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_SIDEBAR_ALERT_ENTITIES;
function* retrieveSidebarAlertEntities({
  payload,
}: ReturnType<typeof retrieveSidebarAlertEntitiesAction>) {
  const config = {
    rootAction,
    request: call(retrieveAPI, payload.alertId, payload),
    success: function* onSuccess(result) {
      yield put(retrieveSidebarAlertEntitiesSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSidebarAlertEntities() {
  yield takeLatest(rootAction, retrieveSidebarAlertEntities);
}
