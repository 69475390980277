// Models
import { SarInput } from 'app/modules/fincenSar/models';

// Helpers
import { createState } from 'app/modules/fincenSar/helpers';

const popupContent = `Item 43 - IP Address(s). Enter the Internet Protocol (I P)
  address, if known, of a subject’s electronic internet based
  contact with the financial institution. In addition to the IP
  address, enter the date (MM/DD/YYYY format) of the activity
  and/or the Coordinated Universal Time (UTC) (HH:MM:SS
  format) of the first instance of the reported IP address (if
  known). Enter as many IP addresses as are known up to a
  maximum of 99.If the IP address is IPv4, use a variation of the format
  nnn.nnn.nnn.nnn (n = number) with periods between the
  segments. First set of numbers must be values between
  1-255 (inclusive); second, third and fourth set of numbers
  must be values between 0-255 (inclusive). If the IP address is
  IPv6, use a variation of the format
  cccc:cccc:cccc:cccc:cccc:cccc:cccczcccc (c = character,
  IPv6 is alphanumeric in some segments) with colons
  between the segments. Multiple IP addresses should be
  completed if multiple IP addresses are involved in the
  suspicious activity.
  `;

// ====================== 43  IP Address ========================
export const ipAddress: SarInput = {
  title: 'IP Address',
  name: '43_ipAddress',
  popupContent,
  initialValue: '',
};

export const ipAddressDate: SarInput = {
  title: 'Date',
  name: '43_ipAddressDate',
  popupContent,
  initialValue: '',
};

export const ipAddressTimestamp: SarInput = {
  title: 'Timestamp (UTC)',
  name: '43_ipAddressTimestamp',
  popupContent,
  initialValue: '',
};
// ================================================================

const allState = [ipAddress, ipAddressDate, ipAddressTimestamp];

export const initialState = { ...createState(allState) };
