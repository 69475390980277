// Redux
import { Reducer } from 'redux';
import { produce, Draft } from 'immer';

// Models
import { AdminState, AdminActionTypes } from 'app/modules/admin/models';

// Actions
import { AdminActions } from 'app/modules/admin/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Constants
import { DEFAULT_ID } from 'app/shared/constants';

const initialState: Readonly<AdminState> = {
  org: {
    id: DEFAULT_ID,
    created_at: '',
    name: '',
    display_name: '',
    default_unit: '',
    org_features: [],
    login_modes: [],
    parent_id: null,
    kyt_config: {},
    real_time_rules_config: { enabled: false },
  },
  orgs: [],
  defaultRules: [],
};

// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<AdminState> = (
  state = initialState,
  action: AdminActions,
) => {
  return produce(state, (draft: Draft<AdminState>) => {
    switch (action.type) {
      case AdminActionTypes.CREATE_ORG_SUCCESS:
        return;
      case AdminActionTypes.RETRIEVE_ORG_SUCCESS:
        draft.org = action.payload;
        return;
      case AdminActionTypes.RETRIEVE_ORGS_SUCCESS:
        draft.orgs = action.payload;
        return;
      case AdminActionTypes.EDIT_ORG_SUCCESS:
        draft.org = action.payload;
        return;
      case AdminActionTypes.EDIT_KYT_CONFIG_SUCCESS:
        draft.org.kyt_config = action.payload;
        return;
      case AdminActionTypes.EDIT_ORG_CONSORTIUM_CONFIG_SUCCESS:
        draft.org.consortium_config = action.payload;
        return;
      case AdminActionTypes.RETRIEVE_DEFAULT_RULES_SUCCESS:
        draft.defaultRules = action.payload;
        return;
      case AdminActionTypes.SET_DROPDOWN_SELECTED_ORG:
        draft.selectedOrg = action.payload;
        return;
    }
  });
};

export { reducer as adminReducer, initialState };
