import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Models
import {
  DropdownActionTypes,
  PaginatedOrgsPayload,
  PaginatedOrgsResponse,
} from 'app/shared/dropdown/models';
import {
  PaginatedAgentsPayload,
  PaginatedAgentsResponse,
} from 'app/modules/agentsOld/models';
import {
  PaginatedTeamsPayload,
  PaginatedTeamsResponse,
} from 'app/modules/teamsOld/models';

export const retrieveDropdownAgents = (payload: PaginatedAgentsPayload) => {
  return createAction(DropdownActionTypes.RETRIEVE_DROPDOWN_AGENTS, payload);
};

export const resetDropdownAgents = () => {
  return createAction(DropdownActionTypes.RESET_DROPDOWN_AGENTS);
};

export const retrieveDropdownAgentsSuccess = (
  result: PaginatedAgentsResponse,
) => {
  return createAction(
    DropdownActionTypes.RETRIEVE_DROPDOWN_AGENTS_SUCCESS,
    result,
  );
};

export const retrieveDropdownOrgs = (payload: PaginatedOrgsPayload) => {
  return createAction(DropdownActionTypes.RETRIEVE_DROPDOWN_ORGS, payload);
};

export const retrieveDropdownOrgsSuccess = (result: PaginatedOrgsResponse) => {
  return createAction(
    DropdownActionTypes.RETRIEVE_DROPDOWN_ORGS_SUCCESS,
    result,
  );
};

export const retrieveDropdownTeams = (payload: PaginatedTeamsPayload) => {
  return createAction(DropdownActionTypes.RETRIEVE_DROPDOWN_TEAMS, payload);
};

export const retrieveDropdownTeamsSuccess = (
  result: PaginatedTeamsResponse,
) => {
  return createAction(
    DropdownActionTypes.RETRIEVE_DROPDOWN_TEAMS_SUCCESS,
    result,
  );
};

const ReduxActionsList = {
  retrieveDropdownAgents,
  retrieveDropdownAgentsSuccess,
  resetDropdownAgents,
  retrieveDropdownTeams,
  retrieveDropdownTeamsSuccess,
  retrieveDropdownOrgsSuccess,
};

type ReduxActions = ActionsUnion<typeof ReduxActionsList>;
export default ReduxActions;
