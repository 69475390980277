import {
  adminAlertContent,
  adminAlertHit,
  adminOrgSettings,
  adminRuleContent,
  adminRuleExecution,
  adminRuleQuery,
  adminRuleQueryDiffCounts,
  adminRuleSchedule,
  adminRuleValidation,
  ruleAdminFilterInfo,
  updateRuleScheduleRetryCount,
  updateRuleScheduleStatus,
  updateRuleTimeout,
  updateRuleValidationStatus,
} from 'app/modules/rulesAdmin/api';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { sendErrorToast } from 'app/shared/toasts/actions';
import {
  AdminAlertContentPayload,
  AdminAlertHitPayload,
  AdminOrgSettingsPayload,
  AdminRuleContentPayload,
  AdminRuleExecutionPayload,
  AdminRuleQueryDiffCountsPayload,
  AdminRuleQueryPayload,
  AdminRuleSchedulePayload,
  AdminRuleValidationPayload,
  UpdateRuleScheduleRetryCountPayload,
  UpdateRuleScheduleStatusPayload,
  UpdateRuleTimeoutPayload,
  UpdateRuleValidationStatusPayload,
} from 'app/modules/rulesAdmin/types/requests';
import {
  AdminAlertContentResponse,
  AdminAlertHitResponse,
  AdminOrgSettingsResponse,
  AdminRuleContentResponse,
  AdminRuleExecutionResponse,
  AdminRuleQueryDiffCountsResponse,
  AdminRuleQueryResponse,
  AdminRuleScheduleResponse,
  AdminRuleValidationResponse,
  RuleAdminFilterInfoResponse,
} from 'app/modules/rulesAdmin/types/responses';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { rulesAdminInitialState } from 'app/modules/rulesAdmin/constants';
import { PayloadAction } from '@reduxjs/toolkit';
import { RulesAdminModalType } from 'app/modules/rulesAdmin/models';

const RULES_ADMIN = 'rules-admin';

export const rulesAdminFilterInfoThunk = u21CreateAsyncThunk<
  void,
  RuleAdminFilterInfoResponse
>(`${RULES_ADMIN}/RULE_ADMIN_FILTER_INFO`, async (_, { dispatch }) => {
  try {
    return await ruleAdminFilterInfo();
  } catch (e) {
    dispatch(sendErrorToast('Unable to fetch rule admin filter info'));
    return { scenario: [], template: [] };
  }
});

export const getAdminRuleContent = u21CreateAsyncThunk<
  AdminRuleContentPayload,
  AdminRuleContentResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_RULE_CONTENT`,
  async (payload: AdminRuleContentPayload, { dispatch }) => {
    try {
      return await adminRuleContent(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch rule contents.'));
      return { rules: [], count: 0 };
    }
  },
);

export const getAdminRuleQueryDiffCounts = u21CreateAsyncThunk<
  AdminRuleQueryDiffCountsPayload,
  AdminRuleQueryDiffCountsResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_RULE_QUERY_FOR_RULE_COUNT`,
  async (payload: AdminRuleQueryDiffCountsPayload, { dispatch }) => {
    try {
      return await adminRuleQueryDiffCounts(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch rule query count.'));
      return { non_validation_count: 0, validation_count: 0 };
    }
  },
);

export const getAdminRuleQuery = u21CreateAsyncThunk<
  AdminRuleQueryPayload,
  AdminRuleQueryResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_RULE_QUERY`,
  async (payload: AdminRuleQueryPayload, { dispatch }) => {
    try {
      return await adminRuleQuery(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch rule queries.'));
      return { count: 0, rows: [] };
    }
  },
);

export const getAdminRuleExecution = u21CreateAsyncThunk<
  AdminRuleExecutionPayload,
  AdminRuleExecutionResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_RULE_EXECUTION`,
  async (payload: AdminRuleExecutionPayload, { dispatch }) => {
    try {
      return await adminRuleExecution(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch rule executions.'));
      return { count: 0, ruleExecutions: [] };
    }
  },
);

export const getAdminRuleValidation = u21CreateAsyncThunk<
  AdminRuleValidationPayload,
  AdminRuleValidationResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_RULE_VALIDATION`,
  async (payload: AdminRuleValidationPayload, { dispatch }) => {
    try {
      return await adminRuleValidation(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch rule validations.'));
      return { count: 0, rows: [] };
    }
  },
);

export const getAdminRuleSchedule = u21CreateAsyncThunk<
  AdminRuleSchedulePayload,
  AdminRuleScheduleResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_RULE_SCHEDULE`,
  async (payload: AdminRuleSchedulePayload, { dispatch }) => {
    try {
      return await adminRuleSchedule(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch rule schedules.'));
      return { count: 0, ruleSchedules: [] };
    }
  },
);

export const setAdminRuleScheduleStatus = u21CreateAsyncThunk<
  UpdateRuleScheduleStatusPayload,
  void
>(
  `${RULES_ADMIN}/SET_ADMIN_RULE_SCHEDULE_STATUS`,
  async (payload: UpdateRuleScheduleStatusPayload, { dispatch }) => {
    try {
      await updateRuleScheduleStatus(payload);
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to update schedule status for rule schedules.'),
      );
    }
  },
);

export const setAdminRuleScheduleRetryCount = u21CreateAsyncThunk<
  UpdateRuleScheduleRetryCountPayload,
  void
>(
  `${RULES_ADMIN}/SET_ADMIN_RULE_SCHEDULE_RETRY_COUNT`,
  async (payload: UpdateRuleScheduleRetryCountPayload, { dispatch }) => {
    try {
      await updateRuleScheduleRetryCount(payload);
    } catch (e) {
      dispatch(
        sendErrorToast('Unable to update retry count for rule schedules.'),
      );
    }
  },
);

export const setAdminRuleTimeout = u21CreateAsyncThunk<
  UpdateRuleTimeoutPayload,
  void
>(
  `${RULES_ADMIN}/SET_ADMIN_RULE_TIMEOUT`,
  async (payload: UpdateRuleTimeoutPayload, { dispatch }) => {
    try {
      await updateRuleTimeout(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to update timeout for rules.'));
    }
  },
);

export const setAdminRuleValidationStatus = u21CreateAsyncThunk<
  UpdateRuleValidationStatusPayload,
  void
>(
  `${RULES_ADMIN}/SET_ADMIN_RULE_VALIDATION_STATUS`,
  async (payload: UpdateRuleValidationStatusPayload, { dispatch }) => {
    try {
      await updateRuleValidationStatus(payload);
    } catch (e) {
      dispatch(
        sendErrorToast(
          'Unable to update validation status for rule validations',
        ),
      );
    }
  },
);

export const getAdminAlertContent = u21CreateAsyncThunk<
  AdminAlertContentPayload,
  AdminAlertContentResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_ALERT_CONTENT`,
  async (payload: AdminAlertContentPayload, { dispatch }) => {
    try {
      return await adminAlertContent(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch alert content.'));
      return { count: 0, alerts: [] };
    }
  },
);

export const getAdminAlertHit = u21CreateAsyncThunk<
  AdminAlertHitPayload,
  AdminAlertHitResponse
>(
  `${RULES_ADMIN}/GET_ADMIN_ALERT_HIT`,
  async (payload: AdminAlertHitPayload, { dispatch }) => {
    try {
      return await adminAlertHit(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch alert hit.'));
      return { alertHits: [], count: 0 };
    }
  },
);

export const getAdminOrgSettings = u21CreateAsyncThunk<
  AdminOrgSettingsPayload,
  AdminOrgSettingsResponse[]
>(
  `${RULES_ADMIN}/GET_ADMIN_ORG_SETTINGS`,
  async (payload: AdminOrgSettingsPayload, { dispatch }) => {
    try {
      return await adminOrgSettings(payload);
    } catch (e) {
      dispatch(sendErrorToast('Unable to fetch org settings'));
      return [];
    }
  },
);

const rulesAdminSlice = u21CreateSlice({
  initialState: rulesAdminInitialState,
  name: RULES_ADMIN,
  reducers: {
    setRulesAdminModalType: (
      draft,
      action: PayloadAction<RulesAdminModalType>,
    ) => {
      draft.rulesAdminModalType = action.payload;
    },
  },
});

export const RULES_ADMIN_NAME = rulesAdminSlice.name;
export const { setRulesAdminModalType } = rulesAdminSlice.actions;
export default rulesAdminSlice.reducer;
