import { PayloadAction } from '@reduxjs/toolkit';

// Models
import {
  CheckAssignmentChangesPayload,
  CreateTeamPayload,
  EditTeamPayload,
  TeamAgentsPayload,
} from 'app/modules/teams/requests';
import { PaginatedAgentsPayload } from 'app/modules/agents/requests';
import { PaginationPayload } from 'app/shared/pagination/models';
import {
  PaginatedTeamsResponse,
  ShortTeamResponse,
  FullTeamResponse,
  CheckAssignmentChangesResponse,
  AddTeamAgentsResponse,
} from 'app/modules/teams/responses';
import { DuplicateTeamFormValues } from 'app/modules/teams/models';
import {
  ShortAgentResponse,
  PaginatedAgentsResponse,
} from 'app/modules/agents/responses';
import { Filter } from 'app/modules/filters/models';

// API
import {
  addAgentToTeam,
  removeAgentFromTeam,
  retrievePaginatedTeams,
  createTeam,
  retrieveTeamDetails,
  checkAssignmentChangesApi,
  deleteTeam,
  editTeam,
  removeTeamAgents,
} from 'app/modules/teams/api';
import { retrievePaginatedAgents } from 'app/modules/agents/api';
import {
  editPermissions,
  retrievePermissions,
} from 'app/shared/api/permissions';

// Redux
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import { u21CreateAsyncThunk } from 'app/shared/thunk/u21CreateAsyncThunk';
import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { DEFAULT_AGENT_FILTERS } from 'app/modules/agents/filters';

const INITIAL_TEAM = {
  id: -1,
  name: '',
  description: '',
  agents_count: 0,
  permissions: [],
  roles: [],
  allow_listing_unassigned_alerts: true,
  allow_viewing_unassigned_alerts: true,
  allow_viewing_entity_page_of_unassigned_alerts: true,
  allow_self_assigning_alerts: true,
  allow_assign_others_alerts: true,
  allow_listing_unassigned_entities: true,
  // default for no limit
  alert_assignment_limit: 0,
  alert_assignment_tags: [],
  allowed_tags: [],
  allow_tag_create: true,
  allow_tag_edit: true,
  allow_rule_deployment: true,
  self_signup_enabled: false,
};
const TEAMS_NAME = 'teamsRefresh';

interface TeamsState {
  loadingTeams: boolean;
  teams: ShortTeamResponse[];
  teamsCount: number;
  loadingCreateTeam: boolean;
  loadingTeamDetails: boolean;
  team: FullTeamResponse;
  agentFilters: Filter[];
  agents: ShortAgentResponse[];
  agentsCount: number;
  loadingAgents: boolean;
  assignmentChanges: CheckAssignmentChangesResponse;
  loadingCheckAssignmentChanges: boolean;
  loadingDeleteTeam: boolean;
  loadingEditTeam: boolean;
  loadingDuplicateTeam: boolean;
  loadingRemoveTeamAgents: boolean;
  loadingAddTeamAgents: boolean;
}

const initialState: Readonly<TeamsState> = {
  loadingTeams: false,
  teams: [],
  teamsCount: 0,
  loadingCreateTeam: false,
  loadingTeamDetails: false,
  team: INITIAL_TEAM,
  agentFilters: DEFAULT_AGENT_FILTERS,
  agents: [],
  agentsCount: 0,
  loadingAgents: false,
  assignmentChanges: [],
  loadingCheckAssignmentChanges: false,
  loadingDeleteTeam: false,
  loadingEditTeam: false,
  loadingDuplicateTeam: false,
  loadingRemoveTeamAgents: false,
  loadingAddTeamAgents: false,
};

export const addAgentToTeamThunk = u21CreateAsyncThunk<
  TeamAgentsPayload & { id: number },
  AddTeamAgentsResponse
>(
  `${TEAMS_NAME}/ADD_AGENT_TO_TEAM`,
  async ({ id, ...payload }, { dispatch }) => {
    try {
      return await addAgentToTeam(id, payload);
    } catch (e) {
      dispatch(sendErrorToast('Failed to add agent to team.'));
      throw e;
    }
  },
);

export const removeAgentFromTeamThunk = u21CreateAsyncThunk(
  `${TEAMS_NAME}/REMOVE_AGENT_FROM_TEAM`,
  async (
    { id, payload }: { id: number; payload: TeamAgentsPayload },
    { dispatch },
  ) => {
    try {
      return await removeAgentFromTeam(id, payload);
    } catch (e) {
      dispatch(sendErrorToast('Failed to remove agent from team.'));
      throw e;
    }
  },
);

export const retrieveTeamsThunk = u21CreateAsyncThunk<
  PaginationPayload,
  PaginatedTeamsResponse
>(`${TEAMS_NAME}/GET_TEAMS`, (payload, { dispatch }) => {
  try {
    return retrievePaginatedTeams(payload);
  } catch (e) {
    dispatch(sendErrorToast('Failed to retrieve teams list.'));
    throw e;
  }
});

export const createTeamThunk = u21CreateAsyncThunk<
  CreateTeamPayload,
  FullTeamResponse
>(`${TEAMS_NAME}/CREATE_TEAM`, async (payload, { dispatch }) => {
  try {
    return await createTeam(payload);
  } catch (e) {
    let errorToastMessage = 'Unable to create team.';
    try {
      const { message } = await e.json();
      if (message) {
        errorToastMessage = message;
      }
    } catch {}
    dispatch(sendErrorToast(errorToastMessage));
    throw e;
  }
});

export const retrieveTeamDetailsThunk = u21CreateAsyncThunk<
  number,
  FullTeamResponse
>(`${TEAMS_NAME}/GET_TEAM_DETAILS`, async (payload, { dispatch }) => {
  try {
    return await retrieveTeamDetails(payload);
  } catch (e) {
    dispatch(sendErrorToast('Failed to retrieve team details.'));
    throw e;
  }
});

export const retrieveAgentsThunk = u21CreateAsyncThunk<
  PaginatedAgentsPayload,
  PaginatedAgentsResponse
>(`${TEAMS_NAME}/GET_AGENTS`, retrievePaginatedAgents);

const checkAssignmentChangesThunkName = `${TEAMS_NAME}/VERIFY_AGENT_ASSIGNMENTS`;
export const checkAssignmentChangesThunk = u21CreateAsyncThunk<
  CheckAssignmentChangesPayload,
  CheckAssignmentChangesResponse
>(checkAssignmentChangesThunkName, async (payload, { dispatch }) => {
  try {
    if (payload.agent_ids.length === 0 || payload.team_ids.length === 0) {
      return [];
    }

    return await checkAssignmentChangesApi(payload);
  } catch (e) {
    dispatch(sendErrorToast(`Failed to validate agent assignment changes`));
    throw e;
  }
});

export const deleteTeamThunk = u21CreateAsyncThunk<number, number>(
  `${TEAMS_NAME}/DELETE_TEAM`,
  async (payload, { dispatch }) => {
    try {
      await deleteTeam(payload);
      dispatch(sendSuccessToast('Team deleted successfully'));
      return payload;
    } catch (e) {
      dispatch(sendErrorToast('Failed to delete the team.'));
      throw e;
    }
  },
);

export const editTeamThunk = u21CreateAsyncThunk<
  EditTeamPayload & { id: number },
  FullTeamResponse
>(`${TEAMS_NAME}/EDIT_TEAM`, async ({ id, ...payload }, { dispatch }) => {
  try {
    const response = await editTeam(id, payload);
    dispatch(sendSuccessToast('Team edited successfully'));
    return response;
  } catch (e) {
    dispatch(sendErrorToast('Failed to edit the team.'));
    throw e;
  }
});

export const duplicateTeamThunk = u21CreateAsyncThunk<
  DuplicateTeamFormValues & { basedOn: ShortTeamResponse },
  FullTeamResponse
>(`${TEAMS_NAME}/DUPLICATE_TEAM`, async (payload, { dispatch }) => {
  try {
    const response = await createTeam({
      name: payload.name,
      description: payload.description,
      agent_ids: payload.includeAgents ? payload.basedOn.agent_ids : [],
    });

    const upstreamTeamPermissions = await retrievePermissions({
      associationId: payload.basedOn.id,
      associationType: 'team',
    });

    const permissionIDs = upstreamTeamPermissions.map(
      (permission) => permission.id,
    );

    await editPermissions({
      associationId: response.id,
      associationType: 'team',
      permissions: permissionIDs,
    });

    dispatch(sendSuccessToast('Team duplicated successfully'));
    return response;
  } catch (e) {
    try {
      const errorMessage = await e.json();
      dispatch(
        sendErrorToast(
          `Failed to duplicate the team: ${errorMessage.message.toLowerCase()}`,
        ),
      );
    } catch {
      dispatch(sendErrorToast('Failed to duplicate the team.'));
    }
    throw e;
  }
});

export const removeTeamAgentsThunk = u21CreateAsyncThunk<number[], number[]>(
  `${TEAMS_NAME}/REMOVE_TEAM_AGENTS`,
  async (payload, { dispatch, getState }) => {
    try {
      const reduxState = getState();
      // TODO: ideally team.id should be sent by component: [SC-36951]
      await removeTeamAgents(reduxState[TEAMS_SLICE_NAME].team.id, {
        agent_ids: payload,
      });
      dispatch(sendSuccessToast('Agents removed successfully.'));
      return payload;
    } catch (e) {
      dispatch(sendErrorToast('Failed to edit the team.'));
      throw e;
    }
  },
);

export const teamsSlice = u21CreateSlice({
  name: TEAMS_NAME,
  initialState,
  reducers: {
    setAgentFilters: (draft, action: PayloadAction<Filter[]>) => {
      draft.agentFilters = action.payload;
    },
    teamDetailsUnmount: (draft) => {
      draft.team = initialState.team;
      draft.agentFilters = initialState.agentFilters;
    },
    resetAssignmentChanges: (draft) => {
      draft.assignmentChanges = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addLoadingCase(
        retrieveTeamsThunk,
        'loadingTeams',
        (draft, { payload }) => {
          draft.teams = payload.teams;
          draft.teamsCount = payload.count;
        },
      )
      .addLoadingCase(createTeamThunk, 'loadingCreateTeam')
      .addLoadingCase(
        retrieveTeamDetailsThunk,
        'loadingTeamDetails',
        (draft, { payload }) => {
          draft.team = payload;
        },
      )
      .addLoadingCase(retrieveAgentsThunk, 'loadingAgents', (draft, action) => {
        draft.agents = action.payload.agents;
        draft.agentsCount = action.payload.count;
      })
      .addLoadingCase(
        checkAssignmentChangesThunk,
        'loadingCheckAssignmentChanges',
        (draft, action) => {
          draft.assignmentChanges = action.payload;
        },
      )
      .addLoadingCase(deleteTeamThunk, 'loadingDeleteTeam', (draft, action) => {
        draft.teamsCount -= 1;
        draft.teams = draft.teams.filter((team) => team.id !== action.payload);
        if (draft.team.id === action.payload) {
          draft.team = INITIAL_TEAM;
        }
      })
      .addLoadingCase(editTeamThunk, 'loadingEditTeam', (draft, action) => {
        if (draft.team.id === action.payload.id) {
          draft.team = { ...draft.team, ...action.payload };
        }

        const teamEditedIdx = draft.teams.findIndex(
          (team) => team.id === action.payload.id,
        );
        if (teamEditedIdx !== -1) {
          draft.teams[teamEditedIdx] = {
            ...draft.teams[teamEditedIdx],
            name: action.payload.name,
            description: action.payload.description,
            agent_ids: action.payload.agent_ids,
          };
        }
      })
      .addLoadingCase(
        removeTeamAgentsThunk,
        'loadingRemoveTeamAgents',
        (draft, action) => {
          // Agent changes
          const removedAgentsIDSet = new Set(action.payload);
          draft.agents = draft.agents.filter(
            (agent) => !removedAgentsIDSet.has(agent.id),
          );
          draft.agentsCount -= action.payload.length;

          // Team changes
          draft.team.agents_count = draft.team.agents_count
            ? draft.team.agents_count - action.payload.length
            : draft.team.agents_count;
          draft.team.agent_ids = draft.team.agent_ids?.filter(
            (i) => !removedAgentsIDSet.has(i),
          );
        },
      )
      .addLoadingCase(
        addAgentToTeamThunk,
        'loadingAddTeamAgents',
        (draft, action) => {
          // Agent changes
          draft.agents = draft.agents.concat(action.payload.agents);
          draft.agentsCount += action.payload.agents.length;

          // Team changes
          draft.team = action.payload.team;
        },
      )
      .addLoadingCase(
        duplicateTeamThunk,
        'loadingDuplicateTeam',
        (draft, action) => {
          draft.teams = draft.teams.concat(action.payload);
          draft.teamsCount += 1;
        },
      );
  },
});

export const TEAMS_SLICE_NAME = teamsSlice.name;
export const { setAgentFilters, teamDetailsUnmount, resetAssignmentChanges } =
  teamsSlice.actions;
export default teamsSlice.reducer;
