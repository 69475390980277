// Constants
import { initialState } from 'app/modules/admin/reducer';

// Models
import { AdminActionTypes } from 'app/modules/admin/models';
import { createSelector } from 'reselect';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export const selectOrg = (state: RootState) => {
  return state.admin.org || initialState.org;
};

export const selectOrgs = (state: RootState) => {
  return state.admin.orgs || initialState.orgs;
};

export const selectDefaultRules = (state: RootState) => {
  return state.admin.defaultRules || initialState.defaultRules;
};

// Loading
export const selectRetrieveOrgLoading = (state: RootState) => {
  return Boolean(state.loading[AdminActionTypes.RETRIEVE_ORG]);
};

export const selectRetrieveOrgsLoading = (state: RootState) => {
  return Boolean(state.loading[AdminActionTypes.RETRIEVE_ORGS]);
};

export const selectCreateOrgLoading = (state: RootState) => {
  return Boolean(state.loading[AdminActionTypes.CREATE_ORG]);
};

export const selectEditOrgLoading = (state: RootState) => {
  return Boolean(state.loading[AdminActionTypes.EDIT_ORG]);
};

export const selectOrgsOptions = createSelector([selectOrgs], (orgs) =>
  orgs.map<U21SelectOptionProps>((i) => {
    return {
      value: i.id,
      text: i.display_name,
    };
  }),
);

// this is used in the CreateSplitterIntegrationSettings component,
// difference between this and selectOrgsOptions is that this one includes the org name in the text
export const selectIntegrationOrgOptions = createSelector(
  [selectOrgs],
  (orgs) =>
    orgs.map<U21SelectOptionProps>((i) => {
      return {
        value: i.id,
        text: `${i.display_name} (${i.name})`,
      };
    }, []),
);

export const selectParentOrgsOptions = createSelector([selectOrgs], (orgs) =>
  orgs.reduce<U21SelectOptionProps[]>((options, org) => {
    if (!org.is_parent) {
      return options;
    }

    options.push({
      value: org.id,
      text: org.display_name,
    });
    return options;
  }, []),
);

export const selectDropdownSelectedOrg = (state: RootState) => {
  return state.admin.selectedOrg;
};
