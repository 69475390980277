// Types
import { SARAttachment } from 'app/modules/fincenSar/types';

// Models
import {
  SarFiltersRequest,
  RetrieveSarListPayload,
  SarDetails,
  ReportType,
} from 'app/modules/fincenSar/models';
import { RowId } from 'app/shared/pagination/models';
import { CurrencyType } from 'app/modules/goAML/schemas/catalogues/currencyCodes';
import { AccountType } from 'app/modules/goAML/schemas/catalogues/accountType';
import { DirectorRoleType } from 'app/modules/goAML/schemas/catalogues/entityPersonRoleType';
import { CountryCode } from 'app/modules/goAML/schemas/catalogues/countryCode';
import { PersonRoleType } from 'app/modules/goAML/schemas/catalogues/accountPersonRoleType';
import { TransmodeCode } from 'app/modules/goAML/schemas/catalogues/transactionMode';
import { FundsType } from 'app/modules/goAML/schemas/catalogues/fundsType';
import { AccountStatus } from 'app/modules/goAML/schemas/catalogues/accountStatus';
import { CommunicationType } from 'app/modules/goAML/schemas/catalogues/communicationType';
import { ContactType } from 'app/modules/goAML/schemas/catalogues/contactType';
import { GenderType } from 'app/modules/goAML/schemas/catalogues/genderTypes';
import { IdentifierType } from 'app/modules/goAML/schemas/catalogues/identifierType';
import { LegalFormType } from 'app/modules/goAML/schemas/catalogues/legalFormType';
import { ReportIndicators } from 'app/modules/goAML/schemas/catalogues/reportIndicators';
import { ReactElement, RefObject } from 'react';
import { ValidateFn } from 'app/shared/models/form';

// TODO catalogues to types
type NumBool = '0' | '1';

export interface GoAMLState {
  filings: PaginatedGoAMLFilings;
  filing: GoAMLFilingDetails;
  validationErrors: GoAMLErrorsDictionary;
}

export enum GoAMLActionTypes {
  RETRIEVE_GOAML_FILING = '@@goAML/RETRIEVE_GOAML_FILING',
  RETRIEVE_GOAML_FILING_SUCCESS = '@@goAML/RETRIEVE_GOAML_FILING_SUCCESS',
  RETRIEVE_GOAML_FILINGS = '@@goAML/RETRIEVE_GOAML_FILINGS',
  RETRIEVE_GOAML_FILINGS_SUCCESS = '@@goAML/RETRIEVE_GOAML_FILINGS_SUCCESS',
  CREATE_GOAML_FILING = '@@goAML/CREATE_GOAML_FILING',
  CREATE_GOAML_FILING_SUCCESS = '@@goAML/CREATE_GOAML_FILING_SUCCESS',
  EDIT_GOAML_FILING = '@@goAML/EDIT_GOAML_FILING',
  EDIT_GOAML_FILING_SUCCESS = '@@goAML/EDIT_GOAML_FILING_SUCCESS',
  EXPORT_GOAML_FILING = '@@goAML/EXPORT_GOAML_FILING',
  EXPORT_GOAML_FILING_SUCCESS = '@@goAML/EXPORT_GOAML_FILING_SUCCESS',
  VALIDATE_GOAML_FILING = '@@goAML/VALIDATE_GOAML_FILING',
  VALIDATE_GOAML_FILING_SUCCESS = '@@goAML/VALIDATE_GOAML_FILING_SUCCESS',
  ADD_GOAML_ATTACHMENTS = '@@goAML/ADD_GOAML_ATTACHMENTS',
  ADD_GOAML_ATTACHMENTS_SUCCESS = '@@goAML/ADD_GOAML_ATTACHMENTS_SUCCESS',
  DELETE_GOAML_ATTACHMENT = '@@goAML/DELETE_GOAML_ATTACHMENT',
  DELETE_GOAML_ATTACHMENT_SUCCESS = '@@goAML/DELETE_GOAML_ATTACHMENT_SUCCESS',
  ARCHIVE_SAR_SUCCESS = '@@goAML/ARCHIVE_SAR_SUCCESS',
}

export interface GoAMLContent {
  report: STRReport;
}
export interface GoAMLFilingDetails extends Omit<SarDetails, 'content'> {
  report_type: ReportType.GOAML_STR;
  content: GoAMLContent;
  error_msgs?: ErrorMessage[];
}
export interface PaginatedGoAMLFilingsPayload extends SarFiltersRequest {
  report_type: ReportType.GOAML_STR;
}
export interface PaginatedGoAMLFilings extends RetrieveSarListPayload {}

interface CreateGoAMLTransactionPayload {
  id: number;
  t_from: TransactionFromTypes;
  t_to: TransactionToTypes;
}
export interface CreateGoAMLPayload {
  case_id?: number;
  transactions?: CreateGoAMLTransactionPayload[];
}

export interface GoAMLErrorsDictionary {
  [key: string]: ValidateFn<any, Record<string, any>>;
}

export interface EditGoAMLPayload {
  id: string;
  content: GoAMLContent;
  name: string;
  description: string;
}

export interface GoAMLTxnSelection {
  id: RowId;
  selected: boolean;
  from: TransactionFromTypes;
  to: TransactionToTypes;
}

export enum TransactionFromTypes {
  EMPTY_FROM = 'EMPTY_FROM',

  FROM_ACCOUNT = 'FROM_ACCOUNT',
  FROM_ACCOUNT_MY_CLIENT = 'FROM_ACCOUNT_MY_CLIENT',

  FROM_PERSON = 'FROM_PERSON',
  FROM_PERSON_MY_CLIENT = 'FROM_PERSON_MY_CLIENT',

  FROM_ENTITY = 'FROM_ENTITY',
  FROM_ENTITY_MY_CLIENT = 'FROM_ENTITY_MY_CLIENT',
}

export enum TransactionToTypes {
  EMPTY_TO = 'EMPTY_TO',

  TO_ACCOUNT = 'TO_ACCOUNT',
  TO_ACCOUNT_MY_CLIENT = 'TO_ACCOUNT_MY_CLIENT',

  TO_PERSON = 'TO_PERSON',
  TO_PERSON_MY_CLIENT = 'TO_PERSON_MY_CLIENT',

  TO_ENTITY = 'TO_ENTITY',
  TO_ENTITY_MY_CLIENT = 'TO_ENTITY_MY_CLIENT',
}

export interface STRReport {
  rentity_id: string;
  rentity_branch: string;
  submission_code: 'E';
  report_code: 'STR';
  entity_reference: string;
  fiu_ref_number: string;
  submission_date: string;
  /* The form only allows users to pick from the currency dropdown which means that the typing should be CurrencyType BUT the FIAU reporting instructions does not state this. */
  currency_code_local: string;
  reporting_person: TPerson;
  location: TAddress;
  reason?: string;
  action?: string;
  transaction: TransactionContent[];
  report_indicators?: {
    indicator: ReportIndicators;
  }[];
}

export type TransactionContent =
  | TransactionFromClientToClient
  | TransactionFromClientToNonClient
  | TransactionFromNonClientToClient
  | TransactionFromNonClientToNonClient;

type TransactionFromClientToClient = TransactionFromClient &
  TransactionToClient;
type TransactionFromClientToNonClient = TransactionFromClient & TransactionTo;
type TransactionFromNonClientToClient = TransactionFrom & TransactionToClient;
type TransactionFromNonClientToNonClient = TransactionFrom & TransactionTo;

interface Transaction {
  transactionnumber: string;
  internal_ref_number?: string;
  transaction_location?: string;
  transaction_description?: string;
  date_transaction: string;
  teller?: string;
  authorized?: string;

  late_deposit?: NumBool;
  date_posting?: string;

  value_date?: string;

  transmode_code: TransmodeCode;
  transmode_comment?: string;
  amount_local: string;

  // TODO add combination for involved_parties

  goods_services?: TTransItem;
  comments?: string;
}

export interface TransactionFromClient extends Transaction {
  t_from_my_client:
    | FromAccountMyClient
    | FromPersonMyClient
    | FromEntityMyClient;
}

export interface TransactionToClient extends Transaction {
  t_to_my_client: ToAccountMyClient | ToPersonMyClient | ToEntityMyClient;
}

export interface TransactionFrom extends Transaction {
  t_from: FromAccount | FromPerson | FromEntity;
}

export interface TransactionTo extends Transaction {
  t_to: ToAccount | ToPerson | ToEntity;
}

/* 
  NOT MY CLIENT
*/

// From
export interface TFrom {
  from_funds_code: FundsType;
  from_funds_comment?: string;
  from_foreign_currency?: ForeignCurrency;
  t_conductor?: TPerson;
  from_country: CountryCode;
}
export interface FromAccount extends TFrom {
  from_account: TAccount;
}

interface FromPerson extends TFrom {
  from_person: TPerson;
}

interface FromEntity extends TFrom {
  from_entity: TEntity;
}

// To
export interface TTo {
  to_funds_code: FundsType;
  to_funds_comment?: string;
  to_foreign_currency?: ForeignCurrency;
  to_country: CountryCode;
}
export interface ToAccount extends TTo {
  to_account: TAccount;
}

interface ToPerson extends TTo {
  to_person: TPerson;
}

interface ToEntity extends TTo {
  to_entity: TEntity;
}

export interface TPerson {
  gender?: GenderType;
  title?: string;
  first_name: string;
  middle_name?: string;
  prefix?: string;
  last_name: string;
  birthdate?: string;
  birth_place?: string;
  mothers_name?: string;
  alias?: string;
  ssn?: string;

  // IF passport_number we can add the passport_country
  passport_number?: string;
  passport_country?: string;

  id_number?: string;

  phones?: TPhoneArray;
  addresses?: TAddressArray;

  nationality1?: CountryCode;
  nationality2?: CountryCode;
  nationality3?: CountryCode;
  residence?: string;

  email?: string;
  occupation?: string;
  employer_name?: string;
  employer_address_id?: TAddress;
  employer_phone_id?: TPhone;
  identification?: TPersonIdentification[];

  // IF deceased we can add date_deceased
  deceased?: string;
  date_deceased?: string;

  tax_number?: string;
  tax_reg_number?: string;
  source_of_wealth?: string;
  comments?: string;
}

export interface TAccount {
  institution_name: string;

  // One of 2 is required
  institution_code?: string;
  swift?: string;

  non_bank_institution?: NumBool;
  branch?: string;
  account: string;
  currency_code?: CurrencyType;
  account_name?: string;
  iban?: string;
  client_number?: string;
  personal_account_type?: AccountType;

  t_entity?: TEntity;
  signatory?: Signatory<TPerson>[];

  opened?: string;
  closed?: string;

  // IF balance then date_balance is required
  balance?: string;
  date_balance?: string;

  status_code?: AccountStatus;
  beneficiary?: string;
  beneficiary_comment?: string;
  comments?: string;
}

export interface TEntity {
  name: string;
  commercial_name?: string;
  incorporation_legal_form: LegalFormType;
  incorporation_number?: string;
  business?: string;
  phones?: TPhoneArray;
  addresses?: TAddressArray;
  email?: string;
  url?: string;
  incorporation_state?: string;
  incorporation_country_code?: CountryCode;
  director_id?: DirectorID<TPerson>;
  incorporation_date?: string;

  // IF closed we can add date
  business_closed?: NumBool;
  date_business_closed?: string;

  tax_number?: string;
  tax_reg_number?: string;
  comments?: string;
}

/* 
  MY CLIENT
*/
// From My Client
export interface TFromMyClient {
  // TODO merge with t_from using T types
  from_funds_code: FundsType;
  from_funds_comment?: string;
  from_foreign_currency?: ForeignCurrency;
  t_conductor?: TPersonMyClient;
  from_country: CountryCode;
}
export interface FromAccountMyClient extends TFromMyClient {
  from_account: TAccountMyClient;
}

interface FromPersonMyClient extends TFromMyClient {
  from_person: TPersonMyClient;
}

interface FromEntityMyClient extends TFromMyClient {
  from_entity: TEntityMyClient;
}

// To My Client
export interface TToMyClient {
  to_funds_code: FundsType;
  to_funds_comment?: string;
  to_foreign_currency?: ForeignCurrency;
  to_country: CountryCode;
}
export interface ToAccountMyClient extends TToMyClient {
  to_account: TAccountMyClient;
}

interface ToPersonMyClient extends TToMyClient {
  to_person: TPersonMyClient;
}

interface ToEntityMyClient extends TToMyClient {
  to_entity: TEntityMyClient;
}

export interface TEntityMyClient extends TEntity {
  incorporation_number: string;
  business: string;
  phones: TPhoneArray;
  addresses: TAddressArray;
  incorporation_country_code: CountryCode;
  director_id: DirectorID<TPersonMyClient>;
  incorporation_date: string;
}
export interface TPersonMyClient extends TPerson {
  // Similar to t_person but with some required fields
  birthdate: string;
  id_number: string;
  nationality1: CountryCode;
  residence: CountryCode;
  identification: TPersonIdentification[];
}

export interface Signatory<Person extends TPersonMyClient | TPerson> {
  is_primary: NumBool;
  t_person: Person;
  role: PersonRoleType;
}

export type DirectorID<Person extends TPersonMyClient | TPerson> = Person & {
  role: DirectorRoleType;
};

export interface TAccountMyClient extends TAccount {
  // Same as t_account but this is required
  currency_code: CurrencyType;
  account_name: string;
  client_number: string;
  personal_account_type: AccountType;

  signatory: Signatory<TPersonMyClient>[];

  opened: string;
  balance: string;
  date_balance: string;

  status_code: AccountStatus;
  beneficiary: string;
}

// OTHERS

export interface ForeignCurrency {
  foreign_currency_code: CurrencyType;
  foreign_amount: string;
  foreign_exchange_rate: string;
}

export interface TPersonIdentification {
  type: IdentifierType;
  number: string;
  issue_country: string;
  issue_date?: string;
  expiry_date?: string;
  issued_by?: string;
  comments?: string;
}

export interface TPhone {
  tph_contact_type: ContactType;
  tph_communication_type: CommunicationType;
  tph_country_prefix: string;
  tph_number: string;
  tph_extension?: string;
  comments?: string;
}

type TPhoneArray = {
  phone: TPhone;
}[];

export interface TAddress {
  address_type: ContactType;
  address: string;
  town?: string;
  city: string;
  zip?: string;
  country_code: string;
  state?: string;
  comments?: string;
}

type TAddressArray = {
  address: TAddress;
}[];
interface TTransItem {
  // TODO add, node for goods_services P21
  item_type: string;
  item_make?: string;
  description?: string;
  previously_registered_to?: string;
  presently_registered_to?: string;
  estimated_value?: number;
  status_code?: string;
  status_comments?: string;
  disposed_value?: number;
  currency_code?: CurrencyType;
  size?: number;
  size_uom?: string;
  address?: TAddress;
  registration_date?: string;
  registration_number?: string;
  identification_number?: string;
  comments?: string;
}

export interface GoAMLAttachments {
  files: File[];
  id: number;
}

export interface GoAMLAttachmentsResult {
  attachments: SARAttachment[];
}

export interface IndicatorSummary {
  id: ReportIndicators;
  indicator: string;
  code: ReportIndicators;
}

export interface ErrorMessage {
  code: number;
  message: string;
  path: string;
}

export interface FilingStep {
  title: string;
  component: ReactElement<any, any>;
  ref: RefObject<HTMLDivElement>;
}

export interface EditGoAMLFormValues {
  content: GoAMLContent;
  name: string;
  description: string;
}
