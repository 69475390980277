import moment from 'moment';
import routes from 'app/shared/utils/routes';

import { DataProps } from 'app/modules/sidebar/components/SidebarExternalLinkList';
import {
  EntityExternalLinkModel,
  SelectFormatAmountPayload,
} from 'app/modules/orgSettings/models';

// Models
import { EntitySummary } from 'app/modules/entities/models';
import { ShortTxnResponse } from 'app/modules/transactions/types';

// Helpers
import { formatDate, formatTime } from 'app/shared/utils/date';
import { formatExternalUrl } from 'app/modules/entities/helpers';

export const sortByTime = (
  arr: any[],
  field: string | undefined = 'created_at',
) => {
  return [...arr].sort((itemA, itemB) =>
    moment(itemA[field]).isBefore(itemB[field]) ? -1 : 1,
  );
};

// This method returns the items that get passed into U21Table's actions
export const getExternalLinksActionsItems = (
  externalLinks: EntityExternalLinkModel[],
  entity: EntitySummary,
) => {
  return externalLinks.map((externalLink) => {
    return {
      text: externalLink.label,
      onClick: () => {
        window.open(
          formatExternalUrl(entity, externalLink),
          '_blank',
          'noopener,noreferrer',
        );
      },
    };
  });
};

export const generateTransactionsListProps = (
  list: ShortTxnResponse[],
  formatAmount: (payload: SelectFormatAmountPayload) => string,
  precision?: number | null,
): DataProps[] => {
  if (!list) return [];
  return sortByTime(list, 'event_time').map((item) => {
    const listData: DataProps = {
      label: (
        <>
          {formatDate(item.event_time)}
          <br />
          {formatTime(item.event_time)}
        </>
      ),
      value: `${formatAmount({
        amount: item.amount,
        shortenDecimalPlaces: false,
        precision,
      })}`,
      id: item.id,
      route: routes.lumos.dataExplorerTransactionsId.replace(
        ':id',
        String(item.id),
      ),
    };
    return listData;
  });
};

export const generateEntitiesListProps = (list: any[]): DataProps[] => {
  if (!list) return [];
  return sortByTime(list).map((item) => {
    const listData: DataProps = {
      label: (
        <>
          {formatDate(item.created_at)}
          <br />
          {formatTime(item.created_at)}
        </>
      ),
      value: item.external_id,
      id: item.id,
      route: routes.lumos.entitiesId.replace(':id', String(item.id)),
    };
    return listData;
  });
};

export const generateAlertsListProps = (list: any[]): DataProps[] => {
  if (!list) return [];
  return sortByTime(list).map((item) => {
    const listData: DataProps = {
      label: (
        <>
          {formatDate(item.created_at)}
          <br />
          {formatTime(item.created_at)}
        </>
      ),
      value: item.title,
      id: item.id,
      route: routes.lumos.alertsId.replace(':id', String(item.id)),
    };
    return listData;
  });
};
