// components
import { AuditTrail } from 'app/modules/auditService/components/AuditTrail';
import { U21Section } from 'app/shared/u21-ui/components';

interface Props {
  assetId: number;
}

export const CaseAuditTrail = ({ assetId }: Props) => {
  return (
    <U21Section title="Audit Trail">
      <AuditTrail assetId={String(assetId)} assetType="case" />
    </U21Section>
  );
};
