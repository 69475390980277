// Helpers
import {
  createCheckboxItems,
  createState,
} from 'app/modules/fincenSar/helpers';

// Models
import {
  SarCheckboxOptions,
  SarInput,
  ValuesToClearModel,
} from 'app/modules/fincenSar/models';

// 68 Branches role in transaction
const roleInTransactionOptions = [
  'Selling location',
  'Paying location',
  'Both',
];

const addressPopupContent = `Items 69, 71 - 74. Branch address items. Enter the
permanent street address, city, state/territory/province (U.S.l
Canada/Mexico only), ZIP Code or foreign postal code,
state, and country for the branch or office involved in the
suspicious activity. If the suspicious activity occurred at a
branch or office location, the "Address of branch or office
where activity occurred" and "Country" must be completed.
All other branch items may be left blank if unknown. If no
branch was involved in the suspicious activity, then the
checkbox to indicate that no branch activity is involved in
the suspicious activity must be selected. Provide the
apartment number or suite number, if known, following the
street address. A non-location address such as a post office
box or rural route number should be used only if no other
street address information is available. ZIP Codes must be
five or nine digits. ZIP Codes and foreign postal codes must
be entered without formatting or special characters such as
spaces or hyphens. For example, the ZIP Code 12354-6120
would be entered as 123546120. The foreign postal code
HKW 702 would be entered HKW702. For other foreign
addresses enter the street address, city, postal code, and
two letter country abbreviation or address equivalent. If a
foreign address contains other address information that does
not conform to the SAR address format, record equivalent
address information in the SAR address items and the full
address in Part V. No abbreviations are permitted in city
names, which must be completely spelled out. A U.S. city
name must match the city name used by the U.S. Postal
Service for the associated state and ZIP Code. If multiple
branches are involved in the suspicious activity, complete a
set of branch address items for each branch.`;

// ========== 68. Branch's role in transaction ==========
export const roleInTransaction: SarCheckboxOptions = {
  title: `68. Branch's role in transaction`,
  name: `68_roleInTransaction`,
  options: roleInTransactionOptions,
  initialValue: createCheckboxItems(roleInTransactionOptions),
  popupContent: `Item 68 - Branch's role in transaction. Check the box
  "Selling location" if the branch sold the products or
  instruments recorded in Items 45 or 46 to a customer. Check
  the box "Paying location" if a customer received payment
  from the branch for the products or instruments recorded in
  Items 45 or 46. Check the box "Both" if the Part III financial
  institution branch was both a paying and selling location for
  the products or instruments recorded in Items 45 or 46.`,
};
// ========================================================

// ====== 69. Address of branch or office where activity occurred ======
export const address: SarInput = {
  title: `69. Address of branch or office where activity occurred`,
  name: '69_address',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 70. RSSD Number ==============================
export const rssdNumber: SarInput = {
  title: `70. RSSD Number`,
  name: '70_rssdNumber',
  initialValue: '',
  popupContent: `Item 70 - RSSD number. Enter the RSSD (Research
  Statistics Supervision Discount) number of the branch, if
  known.`,
};
// ========================================================

// ============== 71. City ==============================
export const city: SarInput = {
  title: `71. City`,
  name: '71_city',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 72. State ==============================
export const state: SarInput = {
  title: `72. State`,
  name: '72_state',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 73. Zip / Postal Code ==============================
export const zip: SarInput = {
  title: `73. ZIP/Postal Code`,
  name: '73_zip',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 74. Country ==============================
export const country: SarInput = {
  title: `74. Country`,
  name: '74_country',
  initialValue: '',
  popupContent: addressPopupContent,
};
// ========================================================

// ============== 69a. Disabled ==============================
export const disabled = {
  title: '',
  name: `69a_noBranchActivityInvolvedIndicator`,
  initialValue: false,
};

const allState = [
  roleInTransaction,
  address,
  rssdNumber,
  city,
  state,
  zip,
  country,
  disabled,
];

export const initialState = [createState(allState)];

export const valuesToClear: ValuesToClearModel = {
  // [69a_noBranchActivityInvolvedIndicator] is handled by handleBranchDisable()
  [country.name]: [
    {
      fieldToClear: state.name,
      initialValue: state.initialValue,
    },
  ],
};
