import { post, get, put } from 'app/shared/utils/fetchr';
import routes, { pathJoin } from 'app/shared/utils/routes';

// Actions
import {
  createOrg as createOrgAction,
  editOrg as editOrgAction,
  editOrgKytConfig as editOrgKytConfigAction,
  retrieveOrg as retrieveOrgAction,
} from 'app/modules/admin/actions';

import { OrgDetails } from 'app/modules/admin/models';

import {
  OrgConsoritiumConfigResponse,
  RunOrgConsortiumBackfillResponse,
} from 'app/modules/admin/types/responses';
import { EditOrgConsortiumConfigPayload } from 'app/modules/admin/types/requests';

export const retrieveOrgs = (): Promise<OrgDetails[]> => {
  return get(routes.patronus.orgsList);
};

export const createOrg = (
  payload: ReturnType<typeof createOrgAction>['payload'],
) => {
  return post(routes.patronus.adminDashboardOrgCreate, payload);
};

export const retrieveOrg = (
  id: ReturnType<typeof retrieveOrgAction>['payload'],
) => {
  return get(
    pathJoin(routes.patronus.adminDashboardOrg.replace(':id', String(id))),
  );
};

export const editOrg = (
  payload: ReturnType<typeof editOrgAction>['payload'],
) => {
  const { id, ...body } = payload;
  return put(
    pathJoin(routes.patronus.adminDashboardOrgEdit.replace(':id', String(id))),
    body,
  );
};

export const editOrgKytConfig = (
  payload: ReturnType<typeof editOrgKytConfigAction>['payload'],
) => {
  const { id, ...body } = payload;
  return put(
    pathJoin(
      routes.patronus.admingDashboardOrgEditKytConfig.replace(
        ':id',
        String(id),
      ),
    ),
    body,
  );
};

export const editOrgConsortiumConfig = (
  id: number,
  payload: EditOrgConsortiumConfigPayload,
) => {
  return put<OrgConsoritiumConfigResponse>(
    `/admin_dashboard/org/edit/${id}/consortium_config`,
    payload,
  );
};

export const runOrgConsortiumBackfill = (
  id: number,
): Promise<RunOrgConsortiumBackfillResponse> => {
  return put(`/admin_dashboard/org/edit/${id}/run_consortium_backfill`);
};

export const retrieveDefaultRules = () => {
  return get(pathJoin(routes.patronus.adminDashboardRulesDefaultList));
};
