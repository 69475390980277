import {
  FilterOption,
  IsNotOneOfOperatorAndValue,
  IsOneOfOperatorAndValue,
} from 'app/modules/filters/models';

import PERMISSIONS from 'app/shared/utils/permissions';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { ValueField } from 'app/modules/search/helpers';

import { EntityFilterDisplay } from 'app/modules/filters/components/EntityFilterDisplay';
import {
  U21AgentSelect,
  U21EntitySearch,
  U21QueueSelect,
  U21DataLabelSelect,
  U21TagSelect,
} from 'app/shared/u21-ui/components/dashboard';
import { TagFilterDisplay } from 'app/modules/filters/components/TagFilterDisplay';
import { AgentFilterDisplay } from 'app/modules/filters/components/AgentFilterDisplay';
import { QueueFilterDisplay } from 'app/modules/filters/components/QueueFilterDisplay';
import { QueueType } from 'app/modules/queues/models';
import { DataLabelFilterDisplay } from 'app/modules/filters/components/DataLabelFilterDisplay';

type BaseFilterOption = DistributiveOmit<FilterOption, 'key'>;

export const DATA_LABEL_FILTER_OPTION: BaseFilterOption = {
  label: 'Tags',
  type: FILTER_TYPE.MULTI_SELECT,
  customize: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      Display: ({
        value,
      }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
        return <DataLabelFilterDisplay ids={value} />;
      },
      Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
        return (
          <U21DataLabelSelect
            autoFocus
            backdrop
            onChange={onChange}
            label={undefined}
            value={value}
          />
        );
      },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
  },
};

export const TAG_FILTER_OPTION: BaseFilterOption = {
  label: 'Tags',
  type: FILTER_TYPE.MULTI_SELECT,
  customize: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      Display: ({
        operator,
        value,
      }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
        return <TagFilterDisplay operator={operator} ids={value} />;
      },
      Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
        return (
          <U21TagSelect
            autoFocus
            backdrop
            onChange={onChange}
            label={undefined}
            value={value}
          />
        );
      },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
      Display: ({
        operator,
        value,
      }: Omit<IsNotOneOfOperatorAndValue<number>, 'onChange'>) => {
        return <TagFilterDisplay operator={operator} ids={value} />;
      },
      Input: ({ onChange, value }: IsNotOneOfOperatorAndValue<number>) => {
        return (
          <U21TagSelect
            autoFocus
            backdrop
            onChange={onChange}
            label={undefined}
            value={value}
          />
        );
      },
    },
  },
};

export const getAgentFilterOption = (
  label: string,
  unassigned: boolean,
): BaseFilterOption => ({
  label,
  permissions: [PERMISSIONS.readAgents],
  type: FILTER_TYPE.MULTI_SELECT,
  customize: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      Display: ({
        value,
      }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
        return <AgentFilterDisplay ids={value} label={label} unassigned />;
      },
      Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
        return (
          <U21AgentSelect
            autoFocus
            backdrop
            onChange={onChange}
            label={undefined}
            multi
            unassigned={unassigned}
            value={value}
          />
        );
      },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
  },
});

export const getQueueFilterOption = (
  queueType: QueueType,
): BaseFilterOption => ({
  label: 'Queue',
  type: FILTER_TYPE.MULTI_SELECT,
  customize: {
    [FILTER_OPERATOR.IS_ONE_OF]: {
      Display: ({
        value,
      }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
        return <QueueFilterDisplay ids={value} queueType={queueType} />;
      },
      Input: ({ onChange, value }: IsOneOfOperatorAndValue<number>) => {
        return (
          <U21QueueSelect
            autoFocus
            backdrop
            onChange={onChange}
            label=""
            multi
            queueType={queueType}
            value={value}
          />
        );
      },
    },
    [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
  },
});

export const getEntityFilterOption = (
  options: {
    label?: string;
    useExternalID?: boolean;
  } = {},
): BaseFilterOption => {
  const { label = 'Entity', useExternalID = false } = options;
  return {
    label,
    permissions: [PERMISSIONS.readEntities],
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        Display: ({
          value,
        }: Omit<IsOneOfOperatorAndValue<number>, 'onChange'>) => {
          return <EntityFilterDisplay label={label} ids={value} />;
        },
        Input: ({ onChange, value }: IsOneOfOperatorAndValue) => {
          return (
            <U21EntitySearch
              onChange={onChange}
              selectProps={{ backdrop: true, label: undefined }}
              searchProps={{
                backdrop: true,
                limitTags: undefined,
                placeholder: undefined,
              }}
              value={value}
              valueField={
                useExternalID ? ValueField.EXTERNAL_ID : ValueField.ID
              }
            />
          );
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  };
};
