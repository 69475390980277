// Models
import {
  SettingsActionTypes,
  CreateSarNarrative,
  SarNarrative,
  RetrieveGoAMLTemplatesResult,
  RetrieveGoAMLTemplatesContent,
} from 'app/modules/settings/models';

// Utils
import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

export const updateSarNarratives = (narratives: SarNarrative[]) => {
  return createAction(SettingsActionTypes.UPDATE_SAR_NARRATIVES, narratives);
};

export const editSarNarrative = (sarNarrative: SarNarrative) =>
  createAction(SettingsActionTypes.EDIT_SAR_NARRATIVE, sarNarrative);

export const editSarNarrativeSuccess = (result: SarNarrative) =>
  createAction(SettingsActionTypes.EDIT_SAR_NARRATIVE_SUCCESS, result);

export const deleteSarNarrative = (sarNarrativeId: number) =>
  createAction(SettingsActionTypes.DELETE_SAR_NARRATIVE, sarNarrativeId);

// result is the same sarNarrativeId given to deleteSarNarrative
export const deleteSarNarrativeSuccess = (result: number) =>
  createAction(SettingsActionTypes.DELETE_SAR_NARRATIVE_SUCCESS, result);

export const createSarNarrative = (sarNarrative?: CreateSarNarrative) =>
  createAction(SettingsActionTypes.CREATE_SAR_NARRATIVE, sarNarrative);

export const createSarNarrativeSuccess = (payload: SarNarrative) =>
  createAction(SettingsActionTypes.CREATE_SAR_NARRATIVE_SUCCESS, payload);

export const retrieveSarNarratives = () =>
  createAction(SettingsActionTypes.RETRIEVE_SAR_NARRATIVE);

export const retrieveSarNarrativesSuccess = (result: SarNarrative[]) =>
  createAction(SettingsActionTypes.RETRIEVE_SAR_NARRATIVE_SUCCESS, result);

export const retrieveGoAMLTemplates = () =>
  createAction(SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES);

export const retrieveGoAMLTemplatesSuccess = (
  result: RetrieveGoAMLTemplatesResult[],
) =>
  createAction(SettingsActionTypes.RETRIEVE_GO_AML_TEMPLATES_SUCCESS, result);

export const editGoAMLTemplates = (payload: {
  type: string;
  content: RetrieveGoAMLTemplatesContent;
}) => createAction(SettingsActionTypes.EDIT_GO_AML_TEMPLATES, payload);

export const editGoAMLTemplatesSuccess = (
  result: RetrieveGoAMLTemplatesResult,
) => createAction(SettingsActionTypes.EDIT_GO_AML_TEMPLATES_SUCCESS, result);

const SettingsActionsList = {
  editSarNarrative,
  editSarNarrativeSuccess,
  deleteSarNarrative,
  deleteSarNarrativeSuccess,
  createSarNarrative,
  createSarNarrativeSuccess,
  retrieveSarNarratives,
  retrieveSarNarrativesSuccess,
  updateSarNarratives,
  editGoAMLTemplatesSuccess,
  retrieveGoAMLTemplates,
  retrieveGoAMLTemplatesSuccess,
};

export type SettingsActions = ActionsUnion<typeof SettingsActionsList>;
