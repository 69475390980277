import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveSidebarAlertActionEvents as retrieveSidebarAlertActionEventsAction,
  retrieveSidebarAlertActionEventsSuccess,
} from 'app/modules/alerts/actions';

// API
import { retrieveAlertActionEvents as retrieveAPI } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_SIDEBAR_ALERT_ACTION_EVENTS;
function* retrieveSidebarAlertActionEvents({
  payload,
}: ReturnType<typeof retrieveSidebarAlertActionEventsAction>) {
  const config = {
    rootAction,
    request: call(retrieveAPI, payload),
    success: function* onSuccess(result) {
      yield put(retrieveSidebarAlertActionEventsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSidebarAlertActionEvents() {
  yield takeLatest(rootAction, retrieveSidebarAlertActionEvents);
}
