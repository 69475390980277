import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

// Components
import { IconArrowRight } from '@u21/tabler-icons';

import { U21Spacer } from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

import { InvestigationEscalations } from 'app/modules/investigations/components/InvestigationActions/InvestigationEscalations';

// Models
import { InvestigationType } from 'app/modules/investigations/models';
import { AlertSidebarDefinition } from 'app/modules/sidebar/models';

/// Sidebar related
import { SidebarTitle } from 'app/modules/sidebar/components/SidebarTitle';
import { SidebarDescription } from 'app/modules/sidebar/components/SidebarDescription';
import { SidebarStatus } from 'app/modules/sidebar/components/SidebarStatus';
import { SidebarDisposition } from 'app/modules/sidebar/components/SidebarDisposition';
import { SidebarAlertInfo } from 'app/modules/sidebar/components/SidebarAlertInfo';
import { SidebarAssociatedItems } from 'app/modules/sidebar/components/SidebarAssociatedItems';
import { SidebarScore } from 'app/modules/sidebar/components/SidebarScore';
import { SidebarCustomDataOld } from 'app/modules/sidebar/components/SidebarCustomDataNew';

// Actions
import { retrieveSidebarAlert } from 'app/modules/alerts/actions';

// Constants
import { ROUTES_MAP } from 'app/shared/utils/routes';

// Constants
import {
  selectSidebarAlert,
  selectSidebarAlertLoading,
} from 'app/modules/alerts/selectors';
import { SidebarComments } from 'app/modules/sidebar/components/SidebarComments';
import { OldFeatureBanner } from 'app/modules/sidebar/components/GABanner';

interface OwnProps {
  data: AlertSidebarDefinition['data'];
}

export const SidebarAlert = ({ data }: OwnProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveSidebarAlert(data.alert.id));
  }, [data.alert.id, dispatch]);

  const sidebarAlert = useSelector(selectSidebarAlert);
  const sidebarAlertLoading = useSelector(selectSidebarAlertLoading);

  return (
    <U21SideMenu
      title="Alert Details"
      actionButtonProps={
        data.hideActionButton
          ? undefined
          : {
              children: 'Go to Alert Page',
              endIcon: <IconArrowRight />,
              to: ROUTES_MAP.alertsId.path.replace(
                ':id',
                String(data.alert.id),
              ),
            }
      }
      loading={sidebarAlertLoading}
    >
      <OldFeatureBanner type="ALERT_SIDEBAR_REFRESH" />
      <StyledSpacer dividers>
        {/* Reassigning/Requeue'ing */}
        <InvestigationEscalations type={InvestigationType.ALERT} sidebar />

        {/* Attributes of the alert */}
        <div>
          <SidebarTitle />
          <SidebarDescription />
          <SidebarStatus />
          <SidebarScore />
          <SidebarDisposition />
          <SidebarAlertInfo />
        </div>

        <SidebarCustomDataOld data={sidebarAlert.formatted_data} />

        {/* Associated items */}
        <SidebarAssociatedItems />
        <SidebarComments comments={sidebarAlert.comments} />
      </StyledSpacer>
    </U21SideMenu>
  );
};

const StyledSpacer = styled(U21Spacer)`
  padding-top: 8px;
`;
