// Types
import { AlertType } from 'app/modules/alerts/types';

export const ALL_TYPES = [
  AlertType.TM,
  AlertType.CAR,
  AlertType.MANUAL,
  AlertType.KYC,
  AlertType.CHAINALYSIS,
  AlertType.WATCHLIST,
  AlertType.DARK_WEB,
];

export const ALL_TYPES_EXCEPT_CHAINALYSIS = ALL_TYPES.filter(
  (alertType) => alertType !== AlertType.CHAINALYSIS,
);

export const ALL_TYPES_EXCEPT_DARK_WEB = ALL_TYPES.filter(
  (alertType) => alertType !== AlertType.DARK_WEB,
);
