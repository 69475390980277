// Models
import { QueueDetailsResponse } from 'app/modules/queues/responses';
import { QueueAccessType, QueueType } from 'app/modules/queues/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { ApexOptions } from 'apexcharts';

// Constants
import { DEFAULT_ID } from 'app/shared/constants';

// Routes
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { InvestigationType } from 'app/modules/investigations/models';
import { InvestigationChecklistTypes } from 'app/modules/investigationChecklist/models';
import { QueueSubtypes } from 'app/modules/alerts/models';

export const INITIAL_QUEUE: QueueDetailsResponse = {
  id: DEFAULT_ID,
  title: '',
  description: '',
  org_id: DEFAULT_ID,
  unit_id: DEFAULT_ID,
  org_default_queue: true,
  custom_checklist_id: null,
  custom_checklist: null,
  teams: [],
  rules: [],
  open_articles: 0,
  unassigned_count: 0,
  workflows: [],
  sort_by: '',
  created_at: '',
  created_by_full_name: '',
  high_threshold: null,
  low_threshold: null,
  type: QueueType.ALERT_QUEUE,
  access_type: QueueAccessType.OPEN,
  subtype: QueueSubtypes.TM,
};

export const QUEUE_ATTRS = {
  [QueueType.ALERT_QUEUE]: {
    title: 'Alert Queues',
    countLabel: 'open alert',
    path: ROUTES_MAP.alertQueuesId.path,
    listPath: ROUTES_MAP.workflowAlertQueues.path,
    tableConfigType: TableConfigType.ALERT_QUEUES_TABLE,
  },
  [QueueType.CASE_QUEUE]: {
    title: 'Case Queues',
    countLabel: 'open case',
    path: ROUTES_MAP.caseQueuesId.path,
    listPath: ROUTES_MAP.caseQueues.path,
    tableConfigType: TableConfigType.CASE_QUEUES_TABLE,
  },
  [QueueType.SAR_QUEUE]: {
    title: 'Filing Queues',
    countLabel: 'open filing',
    path: ROUTES_MAP.sarQueuesId.path,
    listPath: ROUTES_MAP.sarQueues.path,
    tableConfigType: TableConfigType.SAR_QUEUES_TABLE,
  },
};

export const DEADLINE_SUMMARY_CHART_OPTIONS: ApexOptions = {
  tooltip: {
    x: { show: false },
    y: {
      title: {
        formatter: () => '',
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  grid: {
    show: true,
    yaxis: {
      lines: {
        show: false,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  chart: {
    stacked: true,
  },
  legend: {
    position: 'top',
  },
};

export const INVESTIGATION_TYPE_TO_QUEUE_TYPE: Record<
  InvestigationType,
  QueueType
> = {
  [InvestigationType.ALERT]: QueueType.ALERT_QUEUE,
  [InvestigationType.CASE]: QueueType.CASE_QUEUE,
};

export const INVESTIGATION_CHECKLIST_TO_QUEUE_TYPE: Record<
  InvestigationChecklistTypes,
  QueueType
> = {
  [InvestigationChecklistTypes.ALERT_CHECKLIST]: QueueType.ALERT_QUEUE,
  [InvestigationChecklistTypes.CASE_CHECKLIST]: QueueType.CASE_QUEUE,
};

export const QUEUE_TYPE_LABEL: Record<QueueType, string> = {
  [QueueType.ALERT_QUEUE]: 'Alert queues',
  [QueueType.CASE_QUEUE]: 'Case queues',
  [QueueType.SAR_QUEUE]: 'Filing queues',
};
