import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { IconFlag } from '@u21/tabler-icons';
import { IconFlagX } from 'app/shared/components/Icons/IconFlagX';
import { U21Section, U21Alert, U21Spacer } from 'app/shared/u21-ui/components';

// Selectors
import {
  selectAlertHistogramPayload,
  selectAlertHistogram,
  selectAlertHistogramLoading,
  selectAlertEntities,
} from 'app/modules/alerts/selectors';
import { selectLinkAnalysisFilters } from 'app/modules/filtersOld/selectors';

// Actions
import { retrieveAlertHistogram } from 'app/modules/alerts/actions';

// Utils
import { modifyLinkAnalysisFilters } from 'app/modules/filtersOld/actions';
import { TransactionHistogram } from 'app/modules/transactions/components/TransactionHistogram';

export const ActivityHistogram = () => {
  const dispatch = useDispatch();

  const histogramLoading = useSelector(selectAlertHistogramLoading);

  const histogramPayload = useSelector(selectAlertHistogramPayload);
  useEffect(() => {
    if (histogramPayload) {
      dispatch(retrieveAlertHistogram(histogramPayload));
    }
  }, [histogramPayload, dispatch]);

  const entities = useSelector(selectAlertEntities);
  const { selectedEntity, showCurrentAlert } = useSelector(
    selectLinkAnalysisFilters,
  );
  const currentEntity = useMemo(
    () => entities.find(({ id }) => id === selectedEntity)?.name_readable,
    [entities, selectedEntity],
  );

  useEffect(() => {
    if (entities.length) {
      dispatch(
        modifyLinkAnalysisFilters({
          selectedEntity: entities[0].id,
        }),
      );
    }
  }, [entities, dispatch]);

  const histogram = useSelector(selectAlertHistogram);

  const showWarning = useMemo(() => {
    const { in: inTxns, out: outTxns } = histogram.histogram_data;
    const numOfTxns = inTxns.length + outTxns.length;

    return numOfTxns > 25;
  }, [histogram.histogram_data]);

  return (
    <U21Section
      titleIcon={showCurrentAlert ? <IconFlag /> : <IconFlagX />}
      title={
        !showCurrentAlert && currentEntity
          ? `${currentEntity}'s Transactions`
          : 'Transactions'
      }
    >
      <U21Spacer>
        {showWarning && (
          <U21Alert severity="info">
            The Transaction Histogram below only displays the latest 25
            Transactions added to the Unit21 system.
          </U21Alert>
        )}
        <TransactionHistogram
          histogram={histogram}
          loading={histogramLoading}
        />
      </U21Spacer>
    </U21Section>
  );
};
