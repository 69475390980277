// Saga
import { call, put, takeEvery } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AgentsActionTypes } from 'app/modules/agentsOld/models';

// Api
import { retrievePaginatedAgents as retrievePaginatedAgentsAPI } from 'app/shared/api/agents';

// Actions
import {
  retrievePaginatedAgentsSuccess,
  retrievePaginatedAgents as retrievePaginatedAgentsAction,
} from 'app/modules/agentsOld/actions';

const rootAction = AgentsActionTypes.RETRIEVE_PAGINATED_AGENTS;
export function* retrievePaginatedAgents({
  payload,
}: ReturnType<typeof retrievePaginatedAgentsAction>) {
  const config = {
    rootAction,
    request: call(retrievePaginatedAgentsAPI, payload),
    errorToastMessage: `Something went wrong, unable to retrieve agents.`,
    success: function* onSuccess(
      result: ReturnType<typeof retrievePaginatedAgentsSuccess>['payload'],
    ) {
      yield put(retrievePaginatedAgentsSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchRetrievePaginatedAgents() {
  yield takeEvery(rootAction, retrievePaginatedAgents);
}
