import { FeatureFlag } from 'app/shared/featureFlags/models';
import {
  NewFeatureAlert,
  NewFeatureGaToggleButton,
} from 'app/shared/components/GaBanner/NewFeatureAlert';
import styled from 'styled-components';
import React from 'react';
import { OldFeatureAlert } from 'app/shared/components/GaBanner/OldFeatureAlert';

type Type =
  | 'ALERT_SIDEBAR_REFRESH'
  | 'CASE_SIDEBAR_REFRESH'
  | 'FILING_SIDEBAR_REFRESH';

const TYPE_LABEL: Record<Type, string> = {
  ALERT_SIDEBAR_REFRESH: 'alert',
  CASE_SIDEBAR_REFRESH: 'case',
  FILING_SIDEBAR_REFRESH: 'filing',
};

interface Props {
  type: Type;
}

export const GAToggleButton = ({ type }: Props) => (
  <NewFeatureGaToggleButton
    featureName={type}
    gaDateFeatureFlag={FeatureFlag.SIDEBAR_REFRESH_GA}
  />
);

export const NewFeatureBanner = ({ type }: Props) => (
  <StyledNewFeatureAlert
    description=""
    featureName={type}
    displayName={`${TYPE_LABEL[type]} sidebar`}
    gaDateFeatureFlag={FeatureFlag.SIDEBAR_REFRESH_GA}
    variant="small"
  />
);

const StyledNewFeatureAlert = styled(NewFeatureAlert)`
  margin-bottom: 8px;
  padding: 0 16px;
`;

export const OldFeatureBanner = ({ type }: Props) => (
  <StyledOldFeatureAlert
    displayName={`${TYPE_LABEL[type]} sidebar`}
    featureName={type}
    gaDateFeatureFlag={FeatureFlag.SIDEBAR_REFRESH_GA}
    newUIFeatureFlag={FeatureFlag.SIDEBAR_REFRESH}
    variant="small"
  />
);

const StyledOldFeatureAlert = styled(OldFeatureAlert)`
  margin-bottom: 8px;
`;
