// Models
import {
  SarInput,
  SarDropdownOptions,
  SarOptions,
} from 'app/modules/fincenSar/models';

// Helpers
import { createState } from 'app/modules/fincenSar/helpers';
import { createDropdownItems } from 'app/shared/helpers';

const popupContent = `Item 44 - Cyber Event Indicators. If one or more cyber-
events are involved in the suspicious activity, select the
appropriate indicator from the drop-down list and enter the
supporting information in the associated text fields. If none
of the indicators apply, select “Other” and provide a brief
description of the cyber—event in the associated text field.
The event value must be recorded if a cyber-event indictor is
selected. If the “Command and control IP address” or
“Suspicious IP address” indicator is selected, enter the date
(MM/DD/YYYY format) of the activity and/or the Coordinated
Universal Time (UTC) (HH:MM:SS format) of the first instance
of the reported IP address (if known). A maximum of 99
cyber—event indicators may be recorded. Cyber—event
indicator information must be explained in the FinCEN SAR
Part V narrative.`;

// ================= 44 Cyber Event  Indicators ===================
const cyberEventIndicatorOptions = [
  'Command and Control IP Address',
  'Command and Control URL/Domain',
  'Malware MD5, Malware SHA-1, or Malware SHA-256',
  'Media Access Control (MAC) Address',
  'Port',
  'Suspicious E-mail Address',
  'Suspicious File Name',
  'Suspicious IP Address',
  'Suspicious URL/Domain',
  'Targeted System',
  'Other',
];

export const cyberEventIndicatorsOptions = createDropdownItems(
  cyberEventIndicatorOptions,
);

export const typeOfCyberEventIndicators: SarDropdownOptions = {
  options: cyberEventIndicatorsOptions,
  popupContent,
  title: `Event Type`,
  name: '44_cyberEventIndicators',
  initialValue: '',
};

export const typeOfCyberEventIndicatorOther: SarOptions = {
  // In PDF form, called "Item 44 - Other description"
  title: 'Other',
  name: `44_cyberEventIndicatorsOther`,
  initialValue: '',
};

export const cyberEventIndicatorValue: SarInput = {
  title: 'Event Value',
  name: '44_cyberEventIndicatorValue',
  popupContent,
  initialValue: '',
};

export const cyberEventIndicatorDate: SarInput = {
  title: 'Date',
  name: '44_cyberEventIndicatorDate',
  popupContent,
  initialValue: '',
};

export const cyberEventIndicatorTimestamp: SarInput = {
  title: 'Timestamp (UTC)',
  name: '44_cyberEventIndicatorTimestamp',
  popupContent,
  initialValue: '',
};
// ================================================================

const allState = [
  typeOfCyberEventIndicators,
  typeOfCyberEventIndicatorOther,
  cyberEventIndicatorValue,
  cyberEventIndicatorDate,
  cyberEventIndicatorTimestamp,
];

export const initialState = { ...createState(allState) };
