// Models
import { PaginationPayload } from 'app/shared/pagination/models';
import { RuleDetails } from 'app/modules/rules/models';

export enum BlacklistActionTypes {
  RETRIEVE_BLACKLISTS = '@@blacklists/RETRIEVE_BLACKLISTS',
  RETRIEVE_BLACKLISTS_SUCCESS = '@@blacklists/RETRIEVE_BLACKLISTS_SUCCESS',

  RETRIEVE_BLACKLIST = '@@blacklists/RETRIEVE_BLACKLIST',
  RETRIEVE_BLACKLIST_SUCCESS = '@@blacklists/RETRIEVE_BLACKLIST_SUCCESS',

  RETRIEVE_BLACKLIST_ITEMS = '@@blacklists/RETRIEVE_BLACKLIST_ITEMS',
  RETRIEVE_BLACKLIST_ITEMS_SUCCESS = '@@blacklists/RETRIEVE_BLACKLIST_ITEMS_SUCCESS',

  CREATE_BLACKLIST = '@@blacklists/CREATE_BLACKLIST',
  CREATE_BLACKLIST_SUCCESS = '@@blacklists/CREATE_BLACKLIST_SUCCESS',

  EDIT_BLACKLIST = '@@blacklists/EDIT_BLACKLIST',
  EDIT_BLACKLIST_SUCCESS = '@@blacklists/EDIT_BLACKLIST_SUCCESS',

  DEACTIVATE_BLACKLIST_ITEM = '@@blacklists/DEACTIVATE_BLACKLIST_ITEM',
  DEACTIVATE_BLACKLIST_ITEM_SUCCESS = '@@blacklists/DEACTIVATE_BLACKLIST_ITEM_SUCCESS',

  ADD_BLACKLIST_ITEM = '@@blacklists/ADD_BLACKLIST_ITEM',
  ADD_BLACKLIST_ITEM_SUCCESS = '@@blacklists/ADD_BLACKLIST_ITEM_SUCCESS',

  ADD_USER_BLACKLIST_ITEM = '@@blacklists/ADD_USER_BLACKLIST_ITEM',
  ADD_USER_BLACKLIST_ITEM_SUCCESS = '@@blacklists/ADD_USER_BLACKLIST_ITEM_SUCCESS',

  ADD_BUSINESS_BLACKLIST_ITEM = '@@blacklists/ADD_BUSINESS_BLACKLIST_ITEM',
  ADD_BUSINESS_BLACKLIST_ITEM_SUCCESS = '@@blacklists/ADD_BUSINESS_BLACKLIST_ITEM_SUCCESS',

  APPEND_FILE_TO_BLACKLIST = '@@blacklists/APPEND_FILE_TO_BLACKLIST',
  APPEND_FILE_TO_BLACKLIST_SUCCESS = '@@blacklists/APPEND_FILE_TO_BLACKLIST_SUCCESS',
}

export type BlacklistType =
  | 'STRING'
  | 'IP_INET'
  | 'IP_CIDR'
  | 'USER'
  | 'BUSINESS';

export type BlacklistStatus = 'ACTIVE' | 'INACTIVE';

export interface Blacklist {
  id: number;
  name: string;
  description: string;
  type: BlacklistType;
  use_as_whitelist: boolean;
  status: BlacklistStatus;
  u21_global: boolean;
  rules: RuleDetails[];
}

export interface BlacklistsState {
  blacklists: Blacklist[];
  blacklistsCount: number;
  blacklist: Blacklist;
  blacklistItems: BlacklistItem[];
  blacklistItemsCount: number;
  fileStatus: {
    status: string;
    message: string;
  };
}

export interface RetrieveBlacklistsResponse {
  blacklists: Blacklist[];
  count: number;
}

export interface CreateBlacklistPayload {
  name: string;
  description: string;
  type: BlacklistType;
  use_as_whitelist: boolean;
}

export interface BlacklistItem {
  id: number;
  blacklist_id: number;
  value: string;
  deactivated_at: string | null;
}

export interface RetrieveBlacklistItemsResponse {
  blacklist_items: BlacklistItem[];
  count: number;
}

export interface RetrieveBlacklistItemsPayload extends PaginationPayload {
  id: string;
}

export interface EditBlacklistPayload {
  id: string;
  name?: string;
  description?: string;
  use_as_whitelist?: string;
  status?: BlacklistStatus;
}

export interface DeactivateBlacklistItemPayload {
  blacklist_id: string;
  id: string;
}

export interface AddBlacklistItemPayload {
  id: string;
  value: string;
  source?: string;
}

export interface RetrieveBlacklistsPayload extends PaginationPayload {
  types?: BlacklistType[];
}

export interface AddUserBlacklistItemPayload {
  id: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  alias_first_name?: string;
  alias_middle_name?: string;
  alias_last_name?: string;
  date_of_birth?: string;
  ssn?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
  email?: string;
  phone_number?: string;
  ip_address?: string;
  client_fingerprint?: string;
  entity_status?: string;
  entity_subtype?: string;
  registered_at?: string | null;
}

export interface AddBusinessBlacklistItemPayload {
  id: string;
  business_name?: string;
  dba_name?: string;
  corporate_tax_id?: string;
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
}

export interface AppendFileToBlacklistPayload {
  id: string;
  file: File;
}

export interface CreateBlacklistFormValues {
  name: string;
  description: string;
  type: BlacklistType;
}

export interface ListMatchlistsPayload extends PaginationPayload {
  status?: BlacklistStatus;
  types?: BlacklistType[];
  ids?: number[];
  phrase?: string;
  u21_global?: boolean;
}
