import { OrgResponse } from 'app/modules/admin/types/responses';

export enum AdminActionTypes {
  CREATE_ORG = '@@admin/CREATE_ORG',
  CREATE_ORG_SUCCESS = '@@admin/CREATE_ORG_SUCCESS',
  RETRIEVE_ORG = '@@admin/RETRIEVE_ORG',
  RETRIEVE_ORG_SUCCESS = '@@admin/RETRIEVE_ORG_SUCCESS',
  RETRIEVE_ORGS = '@@admin/RETRIEVE_ORGS',
  RETRIEVE_ORGS_SUCCESS = '@@admin/RETRIEVE_ORGS_SUCCESS',
  EDIT_ORG = '@@admin/EDIT_ORG',
  EDIT_ORG_SUCCESS = '@@admin/EDIT_ORG_SUCCESS',
  EDIT_KYT_CONFIG = '@@admin.EDIT_KYT_CONFIG',
  EDIT_KYT_CONFIG_SUCCESS = '@@admin/EDIT_KYT_CONFIG_SUCCESS',
  EDIT_ORG_CONSORTIUM_CONFIG_SUCCESS = '@@admin/EDIT_ORG_CONSORTIUM_CONFIG_SUCCESS',

  RETRIEVE_DEFAULT_RULES = '@@admin/RETRIEVE_DEFAULT_RULES',
  RETRIEVE_DEFAULT_RULES_SUCCESS = '@@admin/RETRIEVE_DEFAULT_RULES_SUCCESS',

  SET_DROPDOWN_SELECTED_ORG = '@@admin/SET_DROPDOWN_SELECTED_ORG',
}

export interface AdminState {
  org: OrgDetails;
  orgs: OrgDetails[];
  defaultRules: DefaultRule[];
  selectedOrg?: number;
}
export interface OrgDetails extends OrgResponse {}

export interface EditOrgRouterProps {
  selectedOrgID?: number;
}

export enum DefaultRuleNames {
  // Default
  ACCOUNT_TAKEOVER_1 = 'account_takeover_1',
  ABNORMAL_VOLUMES_1 = 'abnormal_volumes_1',
  ABNORMAL_VOLUMES_2 = 'abnormal_volumes_2',
  LAYERING_1 = 'layering_1',
  LAYERING_2 = 'layering_2',
  TRANSACTION_STATISTICS_A = 'transaction_statistics_a',
  // General
  DORMANT_ACTIVITY_EMBEDDED_FILTERS = 'dormant_activity_embedded_filters',
  HISTORICAL_DEVIATIONS_A_EMBEDDED_FILTERS = 'historical_deviations_a_embedded_filters',
  HISTORICAL_DEVIATIONS_B_EMBEDDED_FILTERS = 'historical_deviations_b_embedded_filters',
  NEWLY_SEEN = 'newly_seen',
  LAYERING_EMBEDDED_FILTERS = 'layering_embedded_filters',
  SIMPLE_COUNT_EMBEDDED_FILTERS = 'simple_count_embedded_filters',
  SIMPLE_FILTERS = 'simple_filters',
  SIMPLE_OBJECT_COUNT_TRANSACTIONS_EMBEDDED_FILTERS = 'simple_object_count_transactions_embedded_filters',
  SIMPLE_STATISTICS_WITH_COUNT_EMBEDDED_FILTERS = 'simple_statistics_with_count_embedded_filters',
  STRUCTURING_EMBEDDED_FILTERS = 'structuring_embedded_filters',
  TRANSACTION_STATISTICS_A_EMBEDDED_FILTERS = 'transaction_statistics_a_embedded_filters',
  // Banking
  BANKING_DORMANT_ACTIVITY_1 = 'banking_dormant_activity_1',
  BANKING_DORMANT_ACTIVITY_2 = 'banking_dormant_activity_2',
  BANKING_SIMPLE_COUNT_1 = 'banking_simple_count_1',
  BANKING_SIMPLE_COUNT_2 = 'banking_simple_count_2',
  BANKING_SIMPLE_FILTERS_1 = 'banking_simple_filters_1',
  BANKING_SIMPLE_FILTERS_2 = 'banking_simple_filters_2',
  // Neobanking
  NEOBANKING_SIMPLE_COUNT_1 = 'neobanking_simple_count_1',
  NEOBANKING_SIMPLE_COUNT_2 = 'neobanking_simple_count_2',
  NEOBANKING_SIMPLE_COUNT_3 = 'neobanking_simple_count_3',
  NEOBANKING_SIMPLE_FILTERS_1 = 'neobanking_simple_filters_1',
  NEOBANKING_SIMPLE_FILTERS_2 = 'neobanking_simple_filters_2',
  NEOBANKING_SIMPLE_FILTERS_3 = 'neobanking_simple_filters_3',
  NEOBANKING_SIMPLE_STATISTICS_1 = 'neobanking_simple_statistics_1',
  NEOBANKING_SIMPLE_STATISTICS_2 = 'neobanking_simple_statistics_2',
  NEOBANKING_SIMPLE_STATISTICS_3 = 'neobanking_simple_statistics_3',
  // Brokerage
  BROKERAGE_LAYERING_1 = 'brokerage_layering_1',
  BROKERAGE_SIMPLE_COUNT_1 = 'brokerage_simple_count_1',
  BROKERAGE_SIMPLE_COUNT_2 = 'brokerage_simple_count_2',
  BROKERAGE_SIMPLE_COUNT_3 = 'brokerage_simple_count_3',
  BROKERAGE_SIMPLE_STATISTICS_1 = 'brokerage_simple_statistics_1',
  BROKERAGE_SIMPLE_STATISTICS_2 = 'brokerage_simple_statistics_2',
  BROKERAGE_SIMPLE_STATISTICS_3 = 'brokerage_simple_statistics_3',
  // Crypto
  CRYPTO_SIMPLE_COUNT_1 = 'crypto_simple_count_1',
  CRYPTO_SIMPLE_COUNT_2 = 'crypto_simple_count_2',
  CRYPTO_SIMPLE_FILTERS_1 = 'crypto_simple_filters_1',
  CRYPTO_SIMPLE_FILTERS_2 = 'crypto_simple_filters_2',
  CRYPTO_SIMPLE_FILTERS_3 = 'crypto_simple_filters_3',
  CRYPTO_TRANSACTION_STATISTICS_A_1 = 'crypto_transaction_statistics_a_1',
  CRYPTO_TRANSACTION_STATISTICS_A_2 = 'crypto_transaction_statistics_a_2',
  // Lending
  LENDING_SIMPLE_COUNT_1 = 'lending_simple_count_1',
  LENDING_SIMPLE_COUNT_2 = 'lending_simple_count_2',
  LENDING_SIMPLE_COUNT_3 = 'lending_simple_count_3',
  LENDING_SIMPLE_STATISTICS_WITH_COUNT_1 = 'lending_simple_statistics_with_count_1',
  LENDING_SIMPLE_STATISTICS_WITH_COUNT_2 = 'lending_simple_statistics_with_count_2',
  LENDING_SIMPLE_STATISTICS_WITH_COUNT_3 = 'lending_simple_statistics_with_count_3',
  LENDING_TRANSACTION_STATISTICS_A_1 = 'lending_transaction_statistics_a_1',
  LENDING_TRANSACTION_STATISTICS_A_2 = 'lending_transaction_statistics_a_2',
  LENDING_TRANSACTION_STATISTICS_A_3 = 'lending_transaction_statistics_a_3',
  // payments
  PAYMENTS_SIMPLE_COUNT_1 = 'payments_simple_count_1',
  PAYMENTS_SIMPLE_COUNT_2 = 'payments_simple_count_2',
  PAYMENTS_SIMPLE_COUNT_3 = 'payments_simple_count_3',
  PAYMENTS_SIMPLE_STATISTIC_1 = 'payments_simple_Statistic_1',
  PAYMENTS_SIMPLE_STATISTIC_2 = 'payments_simple_Statistic_2',
}

export interface DefaultRule {
  title: string;
  description: string;
  identifier: string;
  industry_verticals_list: string[];
}

export interface AgentToAdd {
  full_name: string;
  email: string;
}

export interface CreateOrgPayload {
  // required keys
  org_name: string;
  org_features: OrgFeature[];

  // optional keys
  agents?: AgentToAdd[];
  seeded_data?: SeededDataKey[];
  org_display_name?: string;
  selected_verticals?: IndustryVerticals[];
  org_rules?: DefaultRuleNames[];
}

export interface EditOrgPayload {
  // required keys
  id: number;
  org_features: OrgFeature[];
  login_modes: LoginMode[];
}

export enum Products {
  TRANSACTION_MONITORING = 'transaction_monitoring',
  CASE_MANAGEMENT = 'case_management',
  SAR_FILINGS = 'sar_filings',
}

export enum IndustryVerticals {
  GENERAL = 'general',
  BANKING = 'banking',
  NEOBANKING = 'neobanking',
  CRYPTO_EXCHANGE = 'crypto_exchange',
  BROKERAGES = 'brokerages',
  P2P = 'P2P',
  PAYMENTS = 'payments',
  LENDING = 'lending',
}

export enum LoginMode {
  AUTH0 = 'auth0',
  GOOGLE_OAUTH2 = 'google-oauth2',
  PINGFEDERATE = 'pingfederate',
  SAMLP = 'samlp',
}

export enum OrgFeature {
  ALL = 'all',
  AGENTS = 'agents',
  AI_INVESTIGATIONS = 'ai_investigations',
  ALERT_COMPONENT_BUTTONS = 'alert_component_buttons',
  ALERTS = 'alerts',
  API_KEYS = 'api_keys',
  ASSIGNMENTS = 'assignments',
  BASIC = 'basic',
  BLACKLISTS = 'blacklists',
  CASES = 'cases',
  CENSORSHIP = 'censorship',
  DASHBOARDS = 'dashboards',
  DATAFILE_UPLOADS = 'datafile_uploads',
  DEADLINES = 'deadlines',
  ENTITIES = 'entities',
  EVENTS = 'events',
  LINK_ANALYSIS = 'link_analysis',
  ORG_SWITCHER = 'org_switcher',
  RISK_RATINGS = 'risk_ratings',
  ROLES = 'roles',
  RULES = 'rules',
  SARS = 'sars',
  SETTINGS_ADMIN = 'settings_admin',
  SETTINGS_CUSTOM_DATA = 'settings_custom_data',
  TEAMS = 'teams',
  TXN_INSTRUMENTS = 'txn_instruments',
  WEBHOOKS = 'webhooks',
  DIRECT_DB_CONNECTIONS = 'ddb_connections',
}

export type OrgFeaturesState = Partial<Record<OrgFeature, boolean>>;

export interface DefaultRulesState {
  [DefaultRuleNames.ABNORMAL_VOLUMES_1]?: boolean;
  [DefaultRuleNames.ABNORMAL_VOLUMES_2]?: boolean;
  [DefaultRuleNames.ACCOUNT_TAKEOVER_1]?: boolean;
  [DefaultRuleNames.LAYERING_1]?: boolean;
  [DefaultRuleNames.LAYERING_2]?: boolean;
  [DefaultRuleNames.TRANSACTION_STATISTICS_A]?: boolean;
}

export interface ProductsState {
  [Products.TRANSACTION_MONITORING]?: boolean;
  [Products.CASE_MANAGEMENT]?: boolean;
  [Products.SAR_FILINGS]?: boolean;
}

export interface IndustryVerticalsState {
  [IndustryVerticals.GENERAL]?: boolean;
  [IndustryVerticals.BANKING]?: boolean;
  [IndustryVerticals.NEOBANKING]?: boolean;
  [IndustryVerticals.BROKERAGES]?: boolean;
  [IndustryVerticals.CRYPTO_EXCHANGE]?: boolean;
  [IndustryVerticals.P2P]?: boolean;
  [IndustryVerticals.PAYMENTS]?: boolean;
  [IndustryVerticals.LENDING]?: boolean;
}

export interface SeededDataState {
  [OrgFeature.EVENTS]?: boolean;
  [OrgFeature.ENTITIES]?: boolean;
  [OrgFeature.TXN_INSTRUMENTS]?: boolean;
  [OrgFeature.CASES]?: boolean;
  [OrgFeature.TEAMS]?: boolean;
}

export type SeededDataKey = keyof SeededDataState;
