// Models
import { AlertQueueSummary } from 'app/modules/alerts/models';
import { PaginationPayload } from 'app/shared/models';
import { CustomChecklistDefinition } from 'app/shared/customChecklist/models';
import { U21TableColumn } from 'app/shared/u21-ui/components';
import { LocationDescriptor } from 'history';

export type ChecklistTableColumn = U21TableColumn<InvestigationChecklist, any>;

export interface InvestigationChecklistListPayload
  extends Partial<PaginationPayload> {
  type: InvestigationChecklistTypes;
}

export interface InvestigationChecklistCreatePayload
  extends Partial<Omit<InvestigationChecklist, 'alert_queues' | 'id'>> {
  alert_queues: number[];
}

export interface InvestigationChecklistCreateThunkArg {
  payload: InvestigationChecklistCreatePayload;
  successNavigate: (
    location: LocationDescriptor<unknown>,
    state?: unknown,
  ) => void;
}

export interface InvestigationChecklistUpdatePayload
  extends Partial<Omit<InvestigationChecklist, 'alert_queues' | 'status'>> {
  active?: boolean;
  alert_queues: number[];
}

export interface InvestigationChecklistRetrievePayload {
  id: number | string;
}

export interface InvestigationChecklistExportPayload {
  receive_email: boolean;
  use_csv: boolean;
}

export enum InvestigationChecklistStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface InvestigationChecklist {
  id: number;
  archived: boolean;
  name: string;
  definition: CustomChecklistDefinition[];
  description: string;
  status: `${InvestigationChecklistStatus}`;
  modified_at: string;
  alert_queues: AlertQueueSummary[];
  alert_default_checklist: boolean;
  type: `${InvestigationChecklistTypes}`;
  ai_investigations_enabled?: boolean;
}

export interface InvestigationChecklistSummary
  extends Omit<
    InvestigationChecklist,
    | 'archived'
    | 'definition'
    | 'status'
    | 'modified_at'
    | 'alert_queues'
    | 'alert_default_checklist'
  > {}

export interface RetrieveInvestigationChecklistListResponse {
  custom_checklists: InvestigationChecklist[];
  count: number;
}

export interface InvestigationChecklistState {
  checklist: InvestigationChecklist;
  checklistLoading: boolean;
  checklistUpdateLoading: boolean;
  checklists: RetrieveInvestigationChecklistListResponse;
  checklistsLoading: boolean;
  checklistUpdateUnsaved: boolean;
}

export enum InvestigationChecklistTypes {
  ALERT_CHECKLIST = 'ALERT_CHECKLIST',
  CASE_CHECKLIST = 'CASE_CHECKLIST',
}
