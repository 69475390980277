import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

import { retrieveOrgsSuccess } from 'app/modules/admin/actions';
import { AdminActionTypes, OrgDetails } from 'app/modules/admin/models';
import { retrieveOrgs as retrieveOrgsApi } from 'app/shared/api/admin';

const rootAction = AdminActionTypes.RETRIEVE_ORGS;
function* retrieveOrgs() {
  const config = {
    rootAction,
    errorToastMessage: 'Some went wrong - orgs list could not be retrieved.',
    request: call(retrieveOrgsApi),
    success: function* onSuccess(result: OrgDetails[]) {
      yield put(retrieveOrgsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveOrgs);
}
