import { ActionEventDetails } from 'app/shared/events/models';
import { GetTransactionsResponse } from 'app/modules/transactions/responses';

export const initialActionEvent: ActionEventDetails = {
  id: 0,
  created_at: '',
  event_time: '',
  status: '',
  action_type: '',
  action_content: '',
  entity_id: 0,
  entity_external_id: 'foo-1',
  instrument_id: null,
  ip_address: null,
  client_fingerprint: null,
  address: null,
  geolocations: [],
  device_id: null,
  external_id: '',
  formatted_data: {},
};

export const DEFAULT_GET_TRANSACTIONS_RESPONSE: GetTransactionsResponse = {
  count: null,
  txns: [],
};

export enum TxnEntityTypes {
  SENDER = 'SENDER',
  RECEIVER = 'RECEIVER',
}

export enum TxnDocumentModalState {
  VIEW_TXN_DOCUMENTS = 'VIEW_TXN_DOCUMENTS',
  COMPARE_OTHER_DOCUMENTS = 'COMPARE_OTHER_DOCUMENTS',
  COMPARE_CHECK_DOCUMENTS = 'COMPARE_CHECK_DOCUMENTS',
}

export const TxnDocumentModalHeader: Record<TxnDocumentModalState, string> = {
  [TxnDocumentModalState.VIEW_TXN_DOCUMENTS]: 'View Documents',
  [TxnDocumentModalState.COMPARE_OTHER_DOCUMENTS]: 'Image Comparison',
  [TxnDocumentModalState.COMPARE_CHECK_DOCUMENTS]: 'Image Comparison',
};

export enum TxnDocumentModalDisplayMode {
  SIDE_BY_SIDE = 'SIDE_BY_SIDE',
  OVERLAY = 'OVERLAY',
}
