import {
  FormattedData,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { DataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';
import { U21NoValue, U21Typography } from 'app/shared/u21-ui/components';
import { useMemo } from 'react';
import styled from 'styled-components';

type CardFieldWithLabel = {
  key: string;
  dataSetting?: OrgDataSettingsConfig;
  data: FormattedData;
  isEmpty: boolean;
};

interface MatchCardProps {
  // The fields to render in the section (ie 'Unit21 ID')
  fields: CardFieldWithLabel[];
  isEmptyFieldShown: boolean;
}

export const MatchCardSection = ({
  fields,
  isEmptyFieldShown,
}: MatchCardProps) => {
  const fieldsToDisplay = useMemo(() => {
    return fields.map((item: CardFieldWithLabel) => {
      const { key, dataSetting, data, isEmpty } = item;
      if (isEmptyFieldShown || !isEmpty) {
        return (
          <StyledItem key={`${key}`}>
            {dataSetting ? (
              <StyledDataSettingDataLabel
                variant="caption"
                color="text.secondary"
                ellipsis
                $minWidth="150px"
                dataSetting={dataSetting}
              />
            ) : (
              <StyledU21NoValue />
            )}
            {data ? (
              <StyledU21TypographyValue variant="body2">
                <DataSettingDataDisplay
                  dataSetting={dataSetting}
                  formattedData={data}
                />
              </StyledU21TypographyValue>
            ) : (
              <U21NoValue />
            )}
          </StyledItem>
        );
      }
      return null;
    });
  }, [isEmptyFieldShown, fields]);

  return <div>{fieldsToDisplay}</div>;
};

const StyledU21TypographyValue = styled(U21Typography)`
  word-break: break-all;
  white-space: pre-wrap;
  width: calc(100% - 154px);
`;

const StyledU21NoValue = styled(U21NoValue)`
  width: 150px;
  min-width: 150px;
  margin-top: 2px;
  margin-right: 4px;
`;

const StyledDataSettingDataLabel = styled(DataSettingDataLabel)<{
  $minWidth: string;
}>`
  width: ${(props) => props.$minWidth};
  min-width: ${(props) => props.$minWidth};
  margin-top: 2px;
  margin-right: 4px;
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 24px;
  margin: 8px 0;
  justify-content: flex-start;
  align-items: flex-start;
`;
