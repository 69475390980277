import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store/storeHooks';
import pluralize from 'pluralize';

// Components
import {
  U21Button,
  U21ButtonGroup,
  U21ButtonGroupButtonProps,
  U21Section,
  U21Spacer,
  U21Table,
  U21TableAction,
  U21TableState,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';
import { Filters } from 'app/modules/filters/components/Filters';
import {
  IconFlag,
  IconUserDollar,
  IconBriefcase,
  IconLayoutColumns,
  IconPlus,
  IconMinus,
  IconGitCompare,
} from '@u21/tabler-icons';
import { TotalAmountCard } from 'app/modules/txnAnalysis/components/cards/TotalAmountCard';
import { TxnAnalysisStatsCard } from 'app/modules/txnAnalysis/components/cards/TxnAnalysisStatsCard';
import { TxnImageComparisonModal } from 'app/modules/transactions/components/TxnImageComparisonModal';

// Selectors
import { selectTxnTableConfig } from 'app/shared/CustomConfig/selectors';
import { selectCustomDataFilterOptions } from 'app/modules/filters/selectors';
import {
  selectTxnAnalysisFilters,
  selectTxnAnalysisPermissions,
} from 'app/modules/txnAnalysis/selectors';
import { selectInvestigation } from 'app/modules/investigations/selectors/investigations';

// Configs
import {
  ASSOCIATED_LINKS,
  TRANSACTION_COLUMN_CONFIG,
} from 'app/modules/transactions/columns';
import {
  createPaginationPayload,
  createTableColumnConfig,
} from 'app/shared/utils/table';
import { ALL_TXN_ANALYSIS_FILTER_OPTIONS } from 'app/modules/txnAnalysis/filters';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import { PaginationPayload } from 'app/shared/pagination/models';
import { FilterOption } from 'app/modules/filters/models';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { TxnAnalysisLinks } from 'app/modules/txnAnalysis/models';
import {
  AddRemoveTxnsPayload,
  TxnAnalysisPayload,
} from 'app/modules/txnAnalysis/requests';

// Constants
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';
import {
  TXN_ANALYSIS_CAPITAL_CASE_MAP,
  TXN_ANALYSIS_INVESTIGATION_MAP,
  TXN_ANALYSIS_NO_CASE_MAP,
  TxnAnalysisType,
} from 'app/modules/txnAnalysis/constants';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// Thunks
import { setTxnAnalysisFilters } from 'app/modules/txnAnalysis/sliceTxnAnalysis';

// Actions
import {
  toggleSidebar,
  toggleTableConfigSidebar,
} from 'app/modules/sidebar/slice';

// Utils
import { getValidFilters } from 'app/modules/filters/utils';
import { createTxnAnalysisFilters } from 'app/modules/txnAnalysis/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';

// Queries
import { useGetTxnAnalysis } from 'app/modules/txnAnalysis/queries/useGetTxnAnalysis';
import { useGetTxnAnalysisStats } from 'app/modules/txnAnalysis/queries/useGetTxnAnalysisStats';
import { useAddRemoveTxn } from 'app/modules/txnAnalysis/queries/useAddRemoveTxn';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

interface OwnProps {
  type: TxnAnalysisType;
}

export const TxnAnalysisTab = ({ type }: OwnProps) => {
  const [buttonGroupValue, setButtonGroupValue] =
    useState<TxnAnalysisLinks>('IN_ARTICLE');

  // State
  const dispatch = useAppDispatch();

  const investigation = useSelector(
    selectInvestigation(TXN_ANALYSIS_INVESTIGATION_MAP[type]),
  );
  const transactionEventTableConfig = useSelector(selectTxnTableConfig);
  const customDataFilterOptions = useSelector(selectCustomDataFilterOptions);
  const filters = useSelector(selectTxnAnalysisFilters);

  const canAddRemoveTxns = useSelector((state: RootState) =>
    selectTxnAnalysisPermissions(state, type),
  );
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTxnEvents, setSelectedTxnEvents] = useState<
    ShortTxnResponse[]
  >([]);
  const [selectAllButtonPressed, setSelectAllButtonPressed] =
    useState<boolean>(false);

  const paginationPayloadRef = useRef<PaginationPayload>(
    DEFAULT_PAGINATION_PAYLOAD,
  );

  const upperCaseType = useMemo<string>(
    () => TXN_ANALYSIS_CAPITAL_CASE_MAP[type],
    [type],
  );

  const lowerCaseType = useMemo<string>(
    () => TXN_ANALYSIS_NO_CASE_MAP[type],
    [type],
  );

  const txnAnalysisPayload = useMemo<TxnAnalysisPayload>(
    () => ({
      ...createTxnAnalysisFilters(filters),
      id: investigation.id,
      type,
      links: buttonGroupValue,
    }),
    [buttonGroupValue, filters, investigation.id, type],
  );

  const { data: txnStatsData, isLoading: txnStatsLoading } =
    useGetTxnAnalysisStats(txnAnalysisPayload);

  const {
    data: txnsData,
    isLoading: txnsLoading,
    refetch,
  } = useGetTxnAnalysis({
    ...paginationPayloadRef.current,
    ...txnAnalysisPayload,
  });

  const { mutateAsync: addRemoveTxn, isPending: addRemoveTxnLoading } =
    useAddRemoveTxn({
      ...paginationPayloadRef.current,
      ...txnAnalysisPayload,
    });

  const txnsCount = useMemo<number | null>(
    () => (txnsData ? txnsData.count : null),
    [txnsData],
  );

  // Configurations
  const columns = [
    ASSOCIATED_LINKS,
    ...createTableColumnConfig<ShortTxnResponse>(
      transactionEventTableConfig,
      TRANSACTION_COLUMN_CONFIG,
    ),
  ];

  const filterOptions = useMemo<FilterOption[]>(
    () => [...ALL_TXN_ANALYSIS_FILTER_OPTIONS, ...customDataFilterOptions],
    [customDataFilterOptions],
  );

  const selected = useMemo<string[]>(
    () => selectedTxnEvents.map((txnEvent) => txnEvent.external_id),
    [selectedTxnEvents],
  );

  useEffect(() => {
    const validFilters = getValidFilters(
      getLocalStorageJSON(LocalStorageKeys.TXN_ANALYSIS_FILTERS),
      filterOptions,
    );
    if (validFilters.length !== filters.length) {
      // update txn analysis filters to valid localStorage filters
      dispatch(setTxnAnalysisFilters(validFilters));
    }
  }, [dispatch, filters, filterOptions]);

  const onStateChange = (state: U21TableState) => {
    paginationPayloadRef.current = createPaginationPayload(state);
    refetch();
    setSelectedTxnEvents([]);
    setSelectAllButtonPressed(false);
  };

  const selectedTxnsCountText = useMemo<string>(() => {
    if (selectAllButtonPressed) {
      return txnsCount !== null ? txnsCount.toString() : 'all';
    }
    if (selected.length > 0) {
      return selected.length.toString();
    }
    return '';
  }, [selectAllButtonPressed, selected.length, txnsCount]);

  const addOrRemoveTxnAnalysis = useCallback(
    async (isRemove: boolean) => {
      // If selected all txns, send payload with filters
      // Else, send payload with selected txn_ids
      const payload: AddRemoveTxnsPayload = selectAllButtonPressed
        ? {
            ...createTxnAnalysisFilters(filters),
            type,
            id: investigation.id,
            links: buttonGroupValue,
          }
        : {
            id: investigation.id,
            type,
            txn_external_ids: selected,
          };

      try {
        await addRemoveTxn({ isRemove, payload });
        setSelectedTxnEvents([]);
        setSelectAllButtonPressed(false);
      } catch {}
    },
    [
      addRemoveTxn,
      buttonGroupValue,
      filters,
      investigation.id,
      selectAllButtonPressed,
      selected,
      type,
    ],
  );

  const tableActions = useMemo<U21TableAction[]>(() => {
    const isRemove = buttonGroupValue === 'IN_ARTICLE';
    const icon = isRemove ? <IconMinus /> : <IconPlus />;
    const label = `${
      isRemove ? 'Remove' : 'Add'
    } ${selectedTxnsCountText} ${pluralize('transaction', selected.length)} ${
      isRemove ? 'from' : 'to'
    } ${lowerCaseType}`;

    return [
      ...(canAddRemoveTxns
        ? [
            {
              icon,
              label,
              onClick: async () => {
                await addOrRemoveTxnAnalysis(isRemove);
              },
              loading: addRemoveTxnLoading,
            },
          ]
        : []),
      {
        icon: <IconGitCompare />,
        label: 'Compare Images',
        onClick: () => setIsModalOpen(true),
        loading: addRemoveTxnLoading,
        disabled: selectAllButtonPressed,
        tooltip: selectAllButtonPressed
          ? 'Select transactions individually to compare images'
          : '',
      },
    ];
  }, [
    addOrRemoveTxnAnalysis,
    addRemoveTxnLoading,
    buttonGroupValue,
    canAddRemoveTxns,
    lowerCaseType,
    selectAllButtonPressed,
    selected.length,
    selectedTxnsCountText,
  ]);

  return (
    <>
      <U21Spacer>
        <TxnAnalysisStatsCard data={txnStatsData} isLoading={txnStatsLoading} />
        <U21Section
          action={
            <U21Spacer horizontal>
              <U21Button
                onClick={() => {
                  dispatch(
                    toggleTableConfigSidebar({
                      tableConfigType: TableConfigType.TXN_TABLE,
                    }),
                  );
                }}
                startIcon={<IconLayoutColumns />}
              >
                Choose Columns
              </U21Button>
            </U21Spacer>
          }
          title={
            <U21TitleCountLabel count={txnsCount} label="transaction">
              Transaction Analysis
            </U21TitleCountLabel>
          }
        >
          <U21Spacer spacing={3}>
            <TotalAmountCard
              amount={txnStatsData?.txnAnalysisTotalAmount}
              isLoading={txnStatsLoading}
            />
            <Filters
              filters={filters}
              onChange={(newFilters) =>
                dispatch(setTxnAnalysisFilters(newFilters))
              }
              options={filterOptions}
            />

            <U21ButtonGroup
              value={buttonGroupValue}
              buttons={
                [
                  {
                    label: `${upperCaseType} transactions`,
                    value: 'IN_ARTICLE',
                    startIcon:
                      type === TxnAnalysisType.ALERT ? (
                        <IconFlag />
                      ) : (
                        <IconBriefcase />
                      ),
                  },
                  {
                    label: "Entities' flagged transactions",
                    value: 'FLAGGED_ENTITY_TXNS',
                    startIcon: <IconFlag />,
                  },
                  {
                    label: "All entities' transactions",
                    value: 'ALL_ENTITY_TXNS',
                    startIcon: <IconUserDollar />,
                  },
                ] as U21ButtonGroupButtonProps<TxnAnalysisLinks>[]
              }
              onClick={(val) => {
                setButtonGroupValue(val);
              }}
              disabled={txnsLoading || addRemoveTxnLoading}
            />
            <U21Table
              // use key to force remount between entity txns | flagged | in article
              key={buttonGroupValue}
              data={txnsData?.txn_events ?? []}
              columns={columns}
              defaultColumnPin={{
                [ASSOCIATED_LINKS.id]: 'LEFT',
              }}
              filters={filters}
              loading={txnsLoading}
              count={txnsCount}
              label="transaction"
              onRefresh={onStateChange}
              onStateChange={onStateChange}
              onRowClick={
                hasReadEventsPermission
                  ? (_, data) => {
                      dispatch(
                        toggleSidebar({
                          data,
                          type: SidebarComponentTypes.TRANSACTION,
                        }),
                      );
                    }
                  : undefined
              }
              onRowSelect={(_, events) => {
                setSelectedTxnEvents(events);
              }}
              getRowID={(row) => row.external_id}
              selectable
              selected={selected}
              onSelectAllButtonPressed={setSelectAllButtonPressed}
              selectAllButtonPressed={selectAllButtonPressed}
              manualPagination
              disabled={addRemoveTxnLoading}
              actions={tableActions}
            />
          </U21Spacer>
        </U21Section>
      </U21Spacer>
      <TxnImageComparisonModal
        associatedTxnEvents={investigation.events || []}
        selectedTxnEvents={selectedTxnEvents}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
      />
    </>
  );
};
