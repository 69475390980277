import { Unit21DataClassifier } from 'app/modules/dataSettings/responses';

export enum ActionEventTypeClassification {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  PERSONAL_INFO_UPDATE = 'PERSONAL_INFO_UPDATE',
  CARD_OR_ACCOUNT_UPDATE = 'CARD_OR_ACCOUNT_UPDATE',
  PASSWORD_UPDATE = 'PASSWORD_UPDATE',

  OTHER = 'OTHER',
}

export enum TxnEventTypeClassification {
  ACH = 'ACH',
  ASSET_TRADE = 'ASSET_TRADE',
  ATM = 'ATM',
  AUTHENTICATION = 'AUTHENTICATION',
  CHARGEBACK = 'CHARGEBACK',
  CHECK = 'CHECK',
  CRYPTO = 'CRYPTO',
  CRYPTO_FIAT_CONVERSION = 'CRYPTO_FIAT_CONVERSION',
  DIRECT_DEPOSIT = 'DIRECT_DEPOSIT',
  DISBURSMENT_INTEREST_DIVIDEND = 'DISBURSMENT_INTEREST_DIVIDEND',
  DOMESTIC_WIRE = 'DOMESTIC_WIRE',
  FEE = 'FEE',
  INTERNATIONAL_WIRE = 'INTERNATIONAL_WIRE',
  P2P = 'P2P',
  PAYMENT_DEBIT_CREDIT = 'PAYMENT_DEBIT_CREDIT',
  PAYPAL = 'PAYPAL',
  PREAUTH = 'PREAUTH',

  TRANSFER = 'TRANSFER',

  OTHER = 'OTHER',
}

export enum EntityTypeClassification {
  PERSON_INDIVIDUAL_USER = 'PERSON_INDIVIDUAL_USER',
  ORGANIZATION_BUSINESS = 'ORGANIZATION_BUSINESS',
  MERCHANT = 'MERCHANT',
  SANCTIONED_ENTITY = 'SANCTIONED_ENTITY',

  OTHER = 'OTHER',
}

export enum InstrumentTypeClassification {
  BANK = 'BANK',
  BROKERAGE_RETIREMENT_ACCOUNT = 'BROKERAGE_RETIREMENT_ACCOUNT',
  CARD = 'CARD',
  CRYPTO_WALLET_ADDRESS = 'CRYPTO_WALLET_ADDRESS',
  DEVICE = 'DEVICE',
  INTERNAL_ACCOUNT = 'INTERNAL_ACCOUNT',
  LINKED_ACCOUNT = 'LINKED_ACCOUNT',

  OTHER = 'OTHER',
}

export type Classifications =
  | ActionEventTypeClassification
  | TxnEventTypeClassification
  | EntityTypeClassification
  | InstrumentTypeClassification;

export enum TxnMovementType {
  MOVEMENT = 'MOVEMENT',
  STAGE = 'STAGE',
  REVERSAL = 'REVERSAL',
}

export interface TypeClassificationMapping<C = Classifications> {
  data_setting_id: number;
  value: string;
  classification: C;
  money_movement_classification?: TxnMovementType;
  mapping_status: string;
  created_at: string;
}

export type TypeClassifications = Record<
  ClassifiableCGDOs,
  TypeClassificationMapping[]
>;

export interface TypeClassificationMappingFormValues {
  id?: number;
  data_setting_id: number;
  value: string;
  classification: Classifications;
  money_movement_classification?: TxnMovementType;
  description: string;
  color: string;
}

export type ClassifiableCGDOs =
  | Unit21DataClassifier.ACTION_EVENT
  | Unit21DataClassifier.EVENT
  | Unit21DataClassifier.ENTITY
  | Unit21DataClassifier.INSTRUMENT;

export interface DeletedMapping {
  id?: number;
  data_setting_id: number;
  value: string;
}
