// Components
import {
  U21Chip,
  U21TableBodyCellProps,
  U21TableColumn,
} from 'app/shared/u21-ui/components';
import { DeadlineChip } from 'app/modules/deadlines/components/DeadlineChip';
import { ReviewerPill } from 'app/modules/fincenSar/components/ReviewerPill';
import { AmountChip } from 'app/modules/fincenSar/components/AmountChip';

// Styling
import {
  IconUser,
  IconCheck,
  IconCircleCheck,
  IconTag,
  IconCalendar,
  IconCalendarPlus,
  IconFileText,
  IconUserSearch,
  IconClock,
  IconClipboardList,
  IconArrowRight,
  IconCurrencyDollar,
} from '@u21/tabler-icons';

// Models
import { SarSummary } from 'app/modules/fincenSar/models';
import {
  EntityRegionEntry,
  SarReviewer,
} from 'app/modules/fincenSar/types/responses';
import { ArticleDeadline } from 'app/modules/deadlines/models';

// Utils
import { entityRegionToText } from 'app/modules/fincenSar/helpers';
import { U21EntityChip } from 'app/shared/u21-ui/components/dashboard';
import { ShortEntityResponse } from 'app/modules/entities/types';
import {
  REPORT_TYPE_DISPLAY_TEXT_MAP,
  REPORT_TYPE_PATH_MAP,
} from 'app/modules/fincenSar/constants/shared';
import { StatusPill } from 'app/modules/fincenSarNew/components/StatusPill';
import { SarStatusTypes } from 'app/modules/fincenSarNew/models';

export type SarTableColumn = U21TableColumn<SarSummary, any>;

const EMPTY_OBJ = {};

const CREATED_BY_COLUMN: SarTableColumn = {
  id: 'created_by',
  accessor: 'created_by.full_name',
  headerIcon: <IconUser />,
};

const DEADLINE_COLUMN: SarTableColumn = {
  id: 'deadline',
  type: 'label',
  Cell: (props: U21TableBodyCellProps<SarSummary, ArticleDeadline>) => (
    <DeadlineChip
      articleDeadline={props.value}
      deadlinesConfig={props.row.deadline_config!}
      closed={props.row.submitted || props.row.status === 'ARCHIVED'}
    />
  ),
  headerIcon: <IconClock />,
};

const SUBMITTED_COLUMN: SarTableColumn = {
  id: 'submitted',
  type: 'label',
  Cell: (props: U21TableBodyCellProps<SarSummary, boolean>) => {
    const { value } = props;
    return (
      <U21Chip color={value ? 'success' : 'error'}>
        {value ? 'YES' : 'NO'}
      </U21Chip>
    );
  },
  headerIcon: <IconCheck />,
};

const STATUS_COLUMN: SarTableColumn = {
  id: 'status',
  headerIcon: <IconCircleCheck />,
  Cell: (props: U21TableBodyCellProps<SarSummary, SarStatusTypes>) => {
    const { value } = props;
    return <StatusPill status={value} />;
  },
};

const BSA_TRACKING_ID_COLUMN: SarTableColumn = {
  id: 'bsa_tracking_id',
  headerIcon: <IconTag />,
};

const BSA_ID_COLUMN: SarTableColumn = {
  id: 'bsa_id',
  headerIcon: <IconTag />,
};

const CREATED_AT_COLUMN: SarTableColumn = {
  id: 'created_at',
  headerIcon: <IconCalendar />,
};

const LAST_UPDATED_COLUMN: SarTableColumn = {
  id: 'updated_at',
  headerIcon: <IconCalendarPlus />,
};

const FILED_AT_COLUMN: SarTableColumn = {
  id: 'filed_at',
  headerIcon: <IconCalendar />,
};

const FILE_NAME_COLUMN: SarTableColumn = {
  id: 'name',
  headerIcon: <IconFileText />,
};

const FIRST_ALERT_CREATED_AT_COLUMN: SarTableColumn = {
  id: 'first_alert_created_at',
  headerIcon: <IconCalendar />,
};

export const ARCHIVE_COLUMN: SarTableColumn = {
  id: 'archive',
  type: 'button',
};

export const SAR_REVIEWER_COLUMN: SarTableColumn = {
  id: 'reviewer',
  Cell: (props: U21TableBodyCellProps<SarSummary, SarReviewer>) => {
    const {
      row: { id, reviewer, queue, submitted, status },
    } = props;
    return (
      <ReviewerPill
        reviewer={reviewer || EMPTY_OBJ}
        sarId={id}
        disableReassign={submitted || status === 'ARCHIVED'}
        queueId={queue?.id}
      />
    );
  },
  headerIcon: <IconUserSearch />,
};

export const SAR_QUEUE_COLUMN: SarTableColumn = {
  id: 'queue',
  accessor: 'queue.title',
  headerIcon: <IconClipboardList />,
};

export const REPORT_TYPE_COLUMN: SarTableColumn = {
  id: 'report_type',
  accessor: (row) => REPORT_TYPE_DISPLAY_TEXT_MAP[row.report_type],
};

export const ENTITY_REGION_COLUMN: SarTableColumn = {
  id: 'entity_region',
  type: 'list',
  cellProps: {
    displayFunc: (i: EntityRegionEntry) => {
      const region = entityRegionToText(i);
      return <U21Chip key={region}>{region}</U21Chip>;
    },
  },
};

export const ASSOCIATED_ENTITIES: SarTableColumn = {
  id: 'entities',
  accessor: 'entities',
  type: 'list',
  cellProps: {
    displayFunc: (i: ShortEntityResponse) => (
      <U21EntityChip key={i.id} id={i.id} name={i.external_id} />
    ),
  },
};

export const AMOUNT_COLUMN: SarTableColumn = {
  id: 'amount',
  Cell: (props: U21TableBodyCellProps<SarSummary, string>) => {
    const {
      row: { amount = '' },
    } = props;
    return <AmountChip amount={Number(amount)} />;
  },
  headerIcon: <IconCurrencyDollar />,
};

export const GOTO_COLUMN: SarTableColumn = {
  id: 'goto',
  type: 'button',
  cellProps: (sar: SarSummary) => ({
    children: <IconArrowRight />,
    to: REPORT_TYPE_PATH_MAP[sar.report_type].replace(':id', `${sar.id}`),
  }),
  disableSortBy: true,
};

export const SAR_COLUMN_CONFIG = {
  [CREATED_BY_COLUMN.id]: CREATED_BY_COLUMN,
  [SUBMITTED_COLUMN.id]: SUBMITTED_COLUMN,
  [STATUS_COLUMN.id]: STATUS_COLUMN,
  [BSA_TRACKING_ID_COLUMN.id]: BSA_TRACKING_ID_COLUMN,
  [BSA_ID_COLUMN.id]: BSA_ID_COLUMN,
  [CREATED_AT_COLUMN.id]: CREATED_AT_COLUMN,
  [LAST_UPDATED_COLUMN.id]: LAST_UPDATED_COLUMN,
  [FILED_AT_COLUMN.id]: FILED_AT_COLUMN,
  [FILE_NAME_COLUMN.id]: FILE_NAME_COLUMN,
  [FIRST_ALERT_CREATED_AT_COLUMN.id]: FIRST_ALERT_CREATED_AT_COLUMN,
  [SAR_REVIEWER_COLUMN.id]: SAR_REVIEWER_COLUMN,
  [DEADLINE_COLUMN.id]: DEADLINE_COLUMN,
  [REPORT_TYPE_COLUMN.id]: REPORT_TYPE_COLUMN,
  [SAR_QUEUE_COLUMN.id]: SAR_QUEUE_COLUMN,
  [ENTITY_REGION_COLUMN.id]: ENTITY_REGION_COLUMN,
  [ASSOCIATED_ENTITIES.id]: ASSOCIATED_ENTITIES,
  [AMOUNT_COLUMN.id]: AMOUNT_COLUMN,
};
