// Models
import { CasesActionTypes } from 'app/modules/casesOld/models';

export const selectCase = (state: RootState) => {
  return state.cases.case || {};
};

export const selectSidebarCase = (state: RootState) => {
  return state.cases.sidebarCase || {};
};

export const selectCaseLoading = (state: RootState) =>
  Boolean(state.loading[CasesActionTypes.RETRIEVE_CASE]);

export const selectSidebarCaseLoading = (state: RootState) =>
  Boolean(state.loading[CasesActionTypes.RETRIEVE_SIDEBAR_CASE]);
