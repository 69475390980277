// Components
import { IconFlag } from '@u21/tabler-icons';
import { SidebarTitleIcon } from 'app/modules/sidebar/components/SidebarTitleIcon';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Models
import { AlertSummary } from 'app/modules/alerts/models';

// Helpers
import { generateAlertsListProps } from 'app/modules/dataExplorer/helpers';
import { useMemo } from 'react';
import { formatDate, formatTime } from 'app/shared/utils/date';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { ShortAlertResponse } from 'app/modules/alerts/types';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

export const SidebarAssociatedAlertsOld = ({
  alerts,
}: {
  alerts: AlertSummary[];
}) => {
  return (
    <div>
      <SidebarTitleIcon
        count={alerts.length}
        title="Associated Alerts"
        icon={<IconFlag />}
      />
      {alerts.length ? (
        <SidebarExternalLinkList list={generateAlertsListProps(alerts)} />
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No associated alerts
        </U21Typography>
      )}
    </div>
  );
};

interface Props {
  alerts: ShortAlertResponse[];
  count: number | null;
}

export const SidebarAssociatedAlerts = ({ alerts, count }: Props) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const list = useMemo(
    () =>
      alerts.map<DataProps>((i) => ({
        label: (
          <>
            {formatDate(i.created_at)}
            <br />
            {formatTime(i.created_at)}
          </>
        ),
        value: i.title,
        id: i.id,
        route: hasReadAlertsPermission
          ? ROUTES_MAP.alertsId.path.replace(':id', String(i.id))
          : undefined,
      })),
    [alerts, hasReadAlertsPermission],
  );
  return (
    <U21Subsection
      aria-label="Associated Alerts"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Associated Alerts
        </U21TitleCountLabel>
      }
      titleIcon={<IconFlag />}
    >
      {alerts.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No associated alerts
        </U21Typography>
      )}
    </U21Subsection>
  );
};
