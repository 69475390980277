import user from '@tabler/icons/user.svg';
import home from '@tabler/icons/home.svg';
import fingerprint from '@tabler/icons/fingerprint.svg';
import mail from '@tabler/icons/mail.svg';
import creditCard from '@tabler/icons/credit-card.svg';
import wifi from '@tabler/icons/wifi.svg';
import phone from '@tabler/icons/phone.svg';
import license from '@tabler/icons/license.svg';
import number from '@tabler/icons/number.svg';
import coin from '@tabler/icons/coin.svg';
import bank from '@tabler/icons/building-bank.svg';
import building from '@tabler/icons/building.svg';
import calendar from '@tabler/icons/calendar.svg';
import mapPin from '@tabler/icons/map-pin.svg';
import tag from '@tabler/icons/tag.svg';
import filter from '@tabler/icons/filter.svg';
import filterOff from '@tabler/icons/filter-off.svg';
import plus from '@tabler/icons/plus.svg';
import minus from '@tabler/icons/minus.svg';
import folders from '@tabler/icons/folders.svg';
import foldersOff from '@tabler/icons/folders-off.svg';

import { encodeSvg } from 'app/shared/components/Graphs/utils';

// standard SVGs (24x24) – meant for standard nodes (32x32)
export const User = encodeSvg(user);
export const Home = encodeSvg(home);
export const Fingerprint = encodeSvg(fingerprint);
export const Mail = encodeSvg(mail);
export const Wifi = encodeSvg(wifi);
export const Phone = encodeSvg(phone);
export const License = encodeSvg(license);
export const Number = encodeSvg(number);
export const CreditCard = encodeSvg(creditCard);
export const Coin = encodeSvg(coin);
export const Bank = encodeSvg(bank);
export const Building = encodeSvg(building);
export const Calendar = encodeSvg(calendar);
export const MapPin = encodeSvg(mapPin);
export const Tag = encodeSvg(tag);

// mini SVGs (8x8) – meant for mini nodes (glyphs – 12x12)
const MINI_SVG_OPTIONS = { width: 8, height: 8 };

export const FilterMini = encodeSvg(filter, MINI_SVG_OPTIONS);
export const FilterOffMini = encodeSvg(filterOff, MINI_SVG_OPTIONS);
export const PlusMini = encodeSvg(plus, MINI_SVG_OPTIONS);
export const MinusMini = encodeSvg(minus, MINI_SVG_OPTIONS);
export const FoldersMini = encodeSvg(folders, MINI_SVG_OPTIONS);
export const FoldersOffMini = encodeSvg(foldersOff, MINI_SVG_OPTIONS);
