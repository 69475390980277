import { ConfiguredFlow } from 'app/modules/dataSettings/types';
import { RuleStatus } from 'app/modules/rules/models';

export enum Unit21DataClassifier {
  ENTITY = 'ENTITY',
  INSTRUMENT = 'INSTRUMENT',
  EVENT = 'EVENT',
  ALERT = 'ALERT',
  CASE = 'CASE',
  RULE = 'RULE',
  ACTION_EVENT = 'ACTION_EVENT',
  ADDRESS = 'ADDRESS',
  WATCHLIST = 'WATCHLIST',
}

export enum DataSettingFieldType {
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  LIST = 'LIST',
  ENUM = 'ENUM',
  BOOLEAN = 'BOOLEAN',
  ENTITY_REFERENCE = 'ENTITY_REFERENCE',
  INSTRUMENT_REFERENCE = 'INSTRUMENT_REFERENCE',
  JSON = 'JSON',
  ANY = 'ANY',
}

export type CustomDataRelatedRules = {
  id: number;
  title: string;
  status: RuleStatus;
};

export enum DataSettingsKeyType {
  NATIVE = 'NATIVE',
  CUSTOM = 'CUSTOM',
}

export enum SemanticTypes {
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  ACH_SEC_CODE = 'ACH_SEC_CODE',
  ACH_RETURN_CODE = 'ACH_RETURN_CODE',
  CHECK_MEMO = 'CHECK_MEMO',
  CHECK_NUMBER = 'CHECK_NUMBER',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  INSTITUTION_NUMBER = 'INSTITUTION_NUMBER',
  JH_CHECK_IMAGE_NUMBER = 'JH_CHECK_IMAGE_NUMBER',
  MCC_CODE = 'MCC_CODE',
  NACHA_DESCRIPTION = 'NACHA_DESCRIPTION',
  NAICS_CODE = 'NAICS_CODE',
  ROUTING_NUMBER = 'ROUTING_NUMBER',
  TRACE_ID = 'TRACE_ID',
  URL = 'URL',
}

export interface BaseDataSettingsConfigResponse {
  configured_flow: ConfiguredFlow | null;
  data_type: DataSettingFieldType;
  description?: string;
  enum_set: string[];
  exported: boolean;
  id: number;
  last_customer_update_time: string | null;
  matchable?: boolean;
  recent_values: string[];
  formatted_recent_values: FormattedDataItem[];
  rendering_options?: RenderingOptionsResponse;
  rules?: CustomDataRelatedRules[];
  unit21_data_classifier: Unit21DataClassifier;
  user_facing_label?: string;
}

export interface CustomDataSettingsConfigResponse
  extends BaseDataSettingsConfigResponse {
  key_type: DataSettingsKeyType.CUSTOM;
  key_path: string[];
  semantic_type?: SemanticTypes;
}

export interface NativeDataSettingsConfigResponse
  extends BaseDataSettingsConfigResponse {
  key_type: DataSettingsKeyType.NATIVE;
  native_field: string;
  // TODO: find constants in lumos to derive key types from
  native_key: string;
}

export type OrgDataSettingsConfig =
  | CustomDataSettingsConfigResponse
  | NativeDataSettingsConfigResponse;

export interface OrgDataSettingsConfigResponse {
  data: OrgDataSettingsConfig[];
}

export interface OrgDataSettingsConfigAddEditResponse {
  data: OrgDataSettingsConfig;
}

export enum FormattedDataCensorshipStatus {
  UNCENSORED = 'UNCENSORED',
  CENSORED = 'CENSORED',
  UNCENSORABLE = 'UNCENSORABLE',
}

// for use in cgdo response objects' `formatted_data` dict
// `formatted_value` is the value returned after being parsed according to its type in data settings
// e.g. a datetime being returned in ISO-8601 format
export interface FormattedDataItem {
  formatted_value: any;
  is_parseable: boolean;
  raw_value: any;
  censorship: FormattedDataCensorshipStatus;
}

export type FormattedData = Record<number, FormattedDataItem>;

export type FormattedRecentValues = Record<number, FormattedDataItem[]>;

export interface EnumMappingItem {
  description?: string;
  color?: string;
}

export type RenderingOptionsResponse = {
  precision?: number | null;
  timezone?: string | null;
  is_url?: boolean;
  url_prefix?: string;
  url_postfix?: string;
  enum_mapping?: Record<string, EnumMappingItem | undefined>;
  reference_display_fields?: string[];
  // render_in_color?: boolean; Not yet implemented
};
