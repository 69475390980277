import { post } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import {
  InvestigationsAlertResponse,
  InvestigationsPayload,
} from 'app/modules/investigations/types';
import { useSelector } from 'react-redux';
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

export const useGetAlert = (id: number) => {
  const hasReadAlertsPermission = useSelector(selectHasReadAlertsPermission);
  const query = useQuery({
    enabled: hasReadAlertsPermission,
    queryKey: ALERT_QUERY_KEYS.getAlert(id),
    queryFn: () =>
      post<InvestigationsAlertResponse<false>>(
        '/investigations/retrieve/alert',
        {
          short: false,
          object_type: 'ALERT',
          object_ids: [id],
        } satisfies InvestigationsPayload,
      ),
  });
  return {
    ...query,
    alert: query.data?.alerts[0],
  };
};
