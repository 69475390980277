import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { SidebarLabelValue } from 'app/modules/sidebar/components/SidebarLabelValue';

// Selectors
import { selectSidebarCase } from 'app/modules/casesOld/selectors';

// Utils
import { formatDatetime } from 'app/shared/utils/date';

export const SidebarCaseInfo = () => {
  const sidebarCase = useSelector(selectSidebarCase);

  const createdAt = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Created at"
        value={formatDatetime(sidebarCase.created_at)}
      />
    );
  }, [sidebarCase.created_at]);

  const createdBy = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Created by"
        value={sidebarCase?.created_by?.full_name || ''}
      />
    );
  }, [sidebarCase?.created_by?.full_name]);

  const startedAt = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Started at"
        value={formatDatetime(sidebarCase.start_date)}
      />
    );
  }, [sidebarCase.start_date]);

  return (
    <>
      {createdAt}
      {createdBy}
      {startedAt}
    </>
  );
};
