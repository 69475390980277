// Helpers
import {
  createCheckboxItems,
  createState,
  createUnknownName,
} from 'app/modules/fincenSar/helpers';
import { createDropdownItems } from 'app/shared/helpers';

// Models
import {
  SarInput,
  SarDropdownOptions,
  SarCheckboxOptions,
  SarUnknownCheckbox,
  ValuesToClearModel,
  Page2ItemModel,
  Page2ContentModel,
} from 'app/modules/fincenSar/models';

// Constants
import { initialState as branchInitialState } from 'app/modules/fincenSar/constants/branchInformation';

export const PAGE_NUMBER = '2';

export const countriesRequiringStateField = ['MX', 'CA', 'US', 'UM'];

// ============ 51. Types of Financial Institutions ============
const typesOfFinancialInstitutions = [
  'Casino / Card Club',
  'Depository institution',
  'Insurance company',
  'MSB (Money Service Business)',
  'Securities/Futures',
  'Loan or Finance Company',
  'Housing GSE (Government Sponsored Enterprise)',
  'Other',
];

const typeOfFinancialInstitutionOptions = createDropdownItems(
  typesOfFinancialInstitutions,
);

export const typeOfFinancialInstitution: SarDropdownOptions = {
  options: typeOfFinancialInstitutionOptions,
  popupContent: `Item 51 - Type of financial institution. Select the appropriate
  type of financial institution that best describes the financial
  institution identified in Item 57. If none of the options apply,
  select “Other” and enter a brief description in the associated
  text field. If necessary, include a more-detailed description
  in Part V. If "Casino/Card Club" is selected, then Item 53 for
  type of gaming institution must be completed. If "Securities/
  Futures" is selected, then Item 54 for type of securities and
  futures institution must be completed.`,
  title: '51. Type of Financial Institution',
  name: '51_typeOfFinancialInstitution',
  initialValue: '',
};

export const typeOfFinancialInstitutionOther: SarInput = {
  title: 'Other',
  name: `51_typeOfFinancialInstitutionOther`,
  initialValue: '',
  popupContent: `Enter a brief description of the type of financial institution if "Other" is selected in item 51.`,
};
// =============================================================

// ============ 52. Primary Federal Regulator ============
const primaryFederalRegulators = [
  'Commodities Futures Trading Commission (CFTC)',
  'Federal Reserve Board (FRB)',
  'Federal Deposit Insurance Corporation (FDIC)',
  'Internal Revenue Service (IRS)',
  'National Credit Union Administration (NCUA)',
  'Office of the Comptroller of the Currency (OCC)',
  'Securities and Exchange Commission (SEC)',
  'Federal Housing Finance Agency (FHFA)',
  'Not Applicable',
];

export const allPrimaryFederalRegulatorOptions = createDropdownItems(
  primaryFederalRegulators,
);

export const primaryFederalRegulatorDynamicOptions = {
  'Casino / Card Club': createDropdownItems(['Internal Revenue Service (IRS)']),
  'Depository institution': createDropdownItems([
    'Federal Reserve Board (FRB)',
    'Federal Deposit Insurance Corporation (FDIC)',
    'Internal Revenue Service (IRS)',
    'National Credit Union Administration (NCUA)',
    'Office of the Comptroller of the Currency (OCC)',
  ]),
  'Insurance company': createDropdownItems(['Internal Revenue Service (IRS)']),
  'MSB (Money Service Business)': createDropdownItems([
    'Internal Revenue Service (IRS)',
  ]),
  'Securities/Futures': createDropdownItems([
    'Commodities Futures Trading Commission (CFTC)',
    'Securities and Exchange Commission (SEC)',
  ]),
};

export const primaryFederalRegulator: SarDropdownOptions = {
  options: allPrimaryFederalRegulatorOptions,
  popupContent: `Item 52 - Primary federal regulator. Select the appropriate
  Primary Federal Regulator or BSA Examiner of the financial
  institution recorded in Item 57. If more than one regulator
  option could apply, select the regulator that has primary
  responsibility for enforcing compliance with the BSA. Select
  "Internal Revenue Service (|RS)" if the filing institution is
  subject to U.S. law and none of the other codes apply or if
  the financial institution type recorded in Item 51 is "Casino/
  Card Club," "Insurance company," or "MSB." Select "Not
  Applicable" if the financial institution is not subject to U.S.
  law.`,
  title: '52. Primary Federal Regulator',
  name: '52_primaryFederalRegulator',
  dynamicOptions: primaryFederalRegulatorDynamicOptions,
  initialValue: '',
};
// =============================================================

// ============ 53. Type of Gaming Institution =================
const requiredGaming = ['Casino / Card Club'];
export const gamingInstitutionRequired = (
  selectedInstitution: string,
): boolean => {
  return requiredGaming.includes(selectedInstitution);
};

const typeOfGamingInstitutionsOptions = [
  'State licensed casino',
  'Tribal authorized casino',
  'Card club',
  'Other',
];

export const typeOfGamingInstitution: SarCheckboxOptions = {
  title: '53. Type of Gaming Institution',
  name: '53_typeOfGamingInstitution',
  popupContent: `Item 53 - Gaming institution type. If "Casino/Card Club" was
  selected in Item 51 indicating the financial institution
  recorded in Item 57 is a gaming institution such as a casino
  or card club, record the type of gaming institution by
  checking the appropriate box. If none of the options for type
  of gaming institution are appropriate, check "Other" and
  provide a brief description in the associated text field. If
  necessary, include a more detailed description in Part V.`,
  options: typeOfGamingInstitutionsOptions,
  initialValue: createCheckboxItems(typeOfGamingInstitutionsOptions),
};

export const typeOfGamingInstitutionOther: SarInput = {
  title: 'Other',
  name: `53_typeOfGamingInstitutionOther`,
  initialValue: '',
};
// =============================================================

// ============ 54. Type of Securities =========================
const typesOfSecuritiesAndFuturesOptions = [
  'Clearing broker-securities',
  'Execution-only broker securities',
  'Futures Commission Merchant',
  'Holding Company',
  'Introducing broker-commodities',
  'Introducing broker-securities',
  'Investment adviser',
  'Investment company',
  'Retail foreign exchange dealer',
  'Self-clearing broker-securities',
  'Subsidiary of financial/bank',
  'Other',
];

export const typeOfSecuritiesAndFutures: SarCheckboxOptions = {
  title: `54. Type of Securities and Futures institution or individual where
  activity occurred. Check all boxes that apply to this report.`,
  name: '54_typeOfSecuritiesAndFutures',
  popupContent: `Item 54 - Securities and futures type. If "Securities/Futures"
  was selected in Item 51 indicating the financial institution
  recorded in Item 57 is part of the securities and futures
  industries, check all boxes that apply to indicate the type of
  securities and futures institution. If none of the options for
  securities and futures type are appropriate, check "Other"
  and provide a brief description in the associated text field.
  Examples of this are commodity pool operator or commodity
  trading adviser. If necessary, include a more detailed
  description in Part V.`,
  options: typesOfSecuritiesAndFuturesOptions,
  initialValue: createCheckboxItems(typesOfSecuritiesAndFuturesOptions),
};

const requiredTypesOfSecuritiesAndFutures = ['Securities/Futures'];

export const typesOfSecuritiesAndFuturesRequired = (
  selectedInstitution: string,
): boolean => {
  return requiredTypesOfSecuritiesAndFutures.includes(selectedInstitution);
};

export const typeOfSecuritiesAndFuturesOther: SarInput = {
  name: '54_typeOfSecuritiesAndFuturesOther',
  title: 'Other',
  initialValue: '',
};
// =============================================================

// ======= 55. Financial Institution Identification  ===========
const financialInstitutionIdentificationTypes = [
  'Central Registration Depository (CRD) number',
  'Investment Adviser Registration Depository (IARD) number',
  'National Futures Association (NFA) number',
  'Research, Statistics, Supervision, and Discount (RSSD) number',
  'Securities and Exchange Commission (SEC) number',
  'National Association of Insurance Commissioners (NAIC Code)',
  'Mortgage (NMLS ID)',
];

const financialInstitutionIdentificationTypesOptions = createDropdownItems(
  financialInstitutionIdentificationTypes,
);

const popupContent55 = `Item 55 - Financial institution identification number. Check the appropriate box if the financial institution recorded in
Item 57 has a Central Registration Depository (CRD) number,
Investment Adviser Registration Depository (IARD) number,
National Association of Commissioners (NAIC) Number,
National Futures Association number, Nationwide Mortgage
Licensing System (NMLS) number, Research, Statistics,
Supervision, and Discount (RSSD) number, or Securities and
Exchange Commission (SEC) Reporting File Number (RFN).
Do not enter an SEC Central Index Key (CIK) number in
place of the RFN. Enter the identification number as a single
text string without formatting or special characters such as
hyphens or periods. For example, an identification number in
the format NNN-NN-NNNN would be entered as
NNNNNNNNN.`;

export const financialInstitutionIdentificationType: SarDropdownOptions = {
  options: financialInstitutionIdentificationTypesOptions,
  popupContent: popupContent55,
  title: '55. Financial Institution Identification Type',
  name: '55_financialInstitutionIdentificationType',
  initialValue: '',
};

export const financialInstitutionIdentificationNumber: SarInput = {
  name: '55_financialInstitutionIdentificationNumber',
  title: 'Financial Institution Identification Number ',
  initialValue: '',
  popupContent: popupContent55,
};
// =============================================================

// ======= 56. Financial Institution Role In Transaction  ===========
const financialInstitutionRoleInTransactionOptions = [
  'Selling location',
  'Paying location',
  'Both',
];

export const financialInstitutionRoleInTransaction: SarCheckboxOptions = {
  title: `56. Financial Institution's Role in Transaction`,
  name: `56_financialInstitutionRoleInTransaction`,
  options: financialInstitutionRoleInTransactionOptions,
  initialValue: createCheckboxItems(
    financialInstitutionRoleInTransactionOptions,
  ),
  popupContent: `Item 56 - Financial institution role in transaction. Check the
  box "Selling location" if the customer purchased at the Part
  III financial institution the products or instruments recorded
  in Items 45 or 46. Check the box "Paying location" if the
  customer received payment from the Part III financial
  institution for the products or instruments recorded in Items
  45 or 46. Check the box "Both" if the Part III financial
  institution was both a paying and selling location for the
  products or instruments recorded in Items 45 or 46.`,
};
// =============================================================

// ================ 57. Legal name of FI =======================
export const financialInstitutionLegalName: SarInput = {
  title: `57. Legal Name of Financial Institution`,
  name: '57_financialInstitutionLegalName',
  popupContent: `Item 57 - Enter the legal name of the financial institution
  where activity occurred as recorded on articles of
  incorporation or other documents establishing the institution.
  Enter the full name of the filer if the filer is an individual, such
  as a sole proprietorship, acting as a financial institution. If an
  individual’s name is recorded, enter the name in (first name)
  (middle name) (last name) or equivalent format, e.g. John
  Jacob Doe or Richard R. Roe II, etc. Check "Unknown" if the
  legal name is unknown and leave this item blank.`,
  initialValue: '',
};

export const financialInstitutionLegalNameUnknown: SarUnknownCheckbox = {
  title: 'Other',
  name: createUnknownName(financialInstitutionLegalName.name),
  initialValue: false,
};
// =============================================================

// ================ 58. Alternate name of FI ===================
export const financialInstitutionAlternateName: SarInput = {
  title: `58. Alternate Name, e.g., AKA - individual or trade name, DBA - entity`,
  name: '58_financialInstitutionAlternateName',
  initialValue: '',
  popupContent: `Item 58 - Alternate name. If the financial institution recorded
  in Item 57 has a separate doing business as (DBA) name,
  enter the name here. If an individual recorded in Item 57 has
  a separate also known as (AKA) name, enter that name here.
  Do not include the acronyms DBA or AKA with the alternate
  name.`,
};
// =============================================================

// =========== 59. Taxpayer Identification Number ==============
export const taxPayerIdentificationNumber: SarInput = {
  title: `59. Taxpayer Identification Number`,
  name: '59_taxPayerIdentificationNumber',
  initialValue: '',
  popupContent: `Item 59 - TIN. Enter the Taxpayer Identification Number
  (T IN), either U.S. or foreign, of the financial institution or
  individual recorded in Item 57. Enter the TIN as a single text
  string without formatting or special characters such as
  hyphens or periods. A TIN in the format NNN-NN-NNNN
  would be entered as NNNNNNNNN. Check "Unknown" if
  the TIN is unknown. If the TIN in Item 59 is unknown and the
  Item 57 financial institution has a U.S. address, select TIN
  Type option "EIN" if the financial institution is an entity and
  "SSN-ITIN" if the financial institution is an individual. If the
  financial institution has a foreign address and the TIN is
  unknown, select "Foreign."`,
};

export const taxPayerIdentificationNumberUnknown: SarUnknownCheckbox = {
  title: 'Unknown',
  name: createUnknownName(taxPayerIdentificationNumber.name),
  initialValue: false,
};

// =============================================================

// =========== 60. Taxpayer Identification Type ==============
const taxPayerTypes = ['EIN', 'SSN-ITIN', 'Foreign'];
const taxpayerTypeOptions = createDropdownItems(taxPayerTypes);
export const taxPayerIdentificationType: SarDropdownOptions = {
  title: `60. Taxpayer Identification Number Type`,
  name: `60_taxPayerIdentificationType`,
  initialValue: '',
  options: taxpayerTypeOptions,
  popupContent: `Item 60 - TIN Type. Select the type of TIN entered by
  selecting either "EIN," "SSN/ITIN," or "Foreign." Select
  "Foreign" if the financial institution or individual recorded in
  Item 57 has a foreign TIN of any type and record in Part V
  the name of the country that issued the foreign TIN. If the
  TIN in Item 59 is unknown and the Item 57 financial
  institution has a U.S. address, select TIN Type option "EIN" if
  the financial institution is an entity and "SSN-ITIN" if the
  financial institution is an individual. If the financial institution
  has a foreign address and the TIN is unknown, select
  "Foreign." Select "EIN" as the TIN type if the TIN is known
  and TIN type cannot be derived from other financial
  institution information.`,
};
// =============================================================

const addressPopupContent = `Items 61 - 65. Financial institution address items. Enter the permanent street address, city, state/territory/province (U.S./
Canada/Mexico only), ZIP Code or foreign postal code,
state, and country for the financial institution identified in
Item 57. Complete any address item that is known and
check "Unknown" for any required address item that is not
known. Provide the apartment number or suite number, if
known, following the street address. A non-location address
such as a post office box or rural route number should be
used only if no other street address information is available.
ZIP Codes must be five or nine digits. ZIP Codes and
foreign postal codes must be entered without formatting or
special characters such as spaces or hyphens. For
example, the ZIP Code 12354-6120 would be entered as
123546120. The foreign postal code HKW 702 would be
entered HKW702. For other foreign addresses enter the
street address, city, postal code, and two letter country
abbreviation or address equivalent. If a foreign address
contains other address information that does not conform to
the SAR address format, record equivalent address
information in the SAR address items and the full address in
Part V. No abbreviations are permitted in city names, which
must be completely spelled out. A U.S. city name must
match the city name used by the U.S. Postal Service for the
associated state and ZIP Code.
`;

// =================== 61. Address ====================
export const address: SarInput = {
  title: `61. Address`,
  name: '61_address',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const addressUnknown = {
  name: createUnknownName(address.name),
  initialValue: false,
};
// ========================================================

// ============== 62. City ==============================
export const city: SarInput = {
  title: `62. City`,
  name: '62_city',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const cityUnknown = {
  name: createUnknownName(city.name),
  initialValue: false,
};
// ========================================================

// ============== 63. State ==============================
// only required if country is MX/CA/US
export const state: SarInput = {
  title: `63. State`,
  name: '63_state',
  initialValue: '',
  popupContent: addressPopupContent,
};

// ========================================================

// ============== 64. ZIP/Postal Code ==============================
export const zip: SarInput = {
  title: `64. ZIP/Postal Code `,
  name: '64_zip',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const zipUnknown = {
  name: createUnknownName(zip.name),
  initialValue: false,
};
// ========================================================

// ============== 65. Country ==============================
export const country: SarInput = {
  title: `65. Country`,
  name: '65_country',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const countryUnknown = {
  name: createUnknownName(country.name),
  initialValue: false,
};

// =========== 66. Internal Control/File Number ==============
export const internalControlFileNumber: SarInput = {
  title: `66. Internal Control/File Number`,
  name: `66_internalControlFileNumber`,
  initialValue: '',
  popupContent: `Item 66 - Internal control/file number. Enter the internal
  control number or file number, if applicable, assigned to the
  SAR by the financial institution recorded in Item 57. This
  number should be unique to the SAR if possible, allowing
  interested parties such as law enforcement or the financial
  institution to reference the SAR without committing a
  potentially illegal disclosure of SAR data.`,
};
// =============================================================

// =========== 67. Loss to financial institution ==============
export const lossToFinancialInstitution: SarInput = {
  title: `67. Loss to financial institution`,
  name: `67_lossToFinancialInstitution`,
  initialValue: '',
  popupContent: `Item 67 - Loss to financial institution. If the financial
  institution recorded in Item 57 has suffered a loss because of
  the suspicious activity, record the amount of loss here. If
  some losses are known and other losses unknown, enter the
  total of known losses as of the date of filing. If "Continuing
  Activity Report" is selected as the filing type, enter the loss
  amount for the current SAR here and record in Part V the
  total aggregated losses for all SARs filed on the suspicious
  activity. All monetary amounts must be recorded in U.S.
  Dollars rounded up to the next whole dollar. The amount
  $5,265.25 would be recorded as $5,266. The amount
  entered cannot be “0” or greater than the amount in Item 29
  (Amount involved in this report).`,
};
// =============================================================

// =========== 68. - 74. Branches ==============
export const branchesConfig = {
  name: '68-74_branches',
  initialValue: branchInitialState,
};

const allState = [
  typeOfFinancialInstitution,
  typeOfFinancialInstitutionOther,
  primaryFederalRegulator,
  typeOfGamingInstitution,
  typeOfGamingInstitutionOther,
  typeOfSecuritiesAndFutures,
  typeOfSecuritiesAndFuturesOther,
  financialInstitutionIdentificationType,
  financialInstitutionIdentificationNumber,
  financialInstitutionRoleInTransaction,
  financialInstitutionLegalName,
  financialInstitutionLegalNameUnknown,
  financialInstitutionAlternateName,
  taxPayerIdentificationNumberUnknown,
  taxPayerIdentificationNumber,
  taxPayerIdentificationType,
  address,
  city,
  state,
  zip,
  country,
  internalControlFileNumber,
  lossToFinancialInstitution,
];

const restOfState = {
  [addressUnknown.name]: addressUnknown.initialValue,
  [cityUnknown.name]: addressUnknown.initialValue,
  // (only 63. State doesn't have a Unknown checkbox)
  [zipUnknown.name]: addressUnknown.initialValue,
  [countryUnknown.name]: addressUnknown.initialValue,
  [branchesConfig.name]: branchesConfig.initialValue,
};

export const initialState = {
  ...createState(allState),
  ...restOfState,
} as unknown as Page2ItemModel;

// users can make n amount of these pages, the initial state is one of the those pages that we keep in array w/ a key of forms
export const formInitialState: Page2ContentModel = {
  forms: [initialState],
};

export const valuesToClear: ValuesToClearModel = {
  // 51.
  [typeOfFinancialInstitution.name]: [
    {
      fieldToClear: typeOfFinancialInstitutionOther.name,
      initialValue: typeOfFinancialInstitutionOther.initialValue,
    },
    {
      fieldToClear: typeOfSecuritiesAndFutures.name,
      initialValue: typeOfSecuritiesAndFutures.initialValue,
    },
    {
      fieldToClear: typeOfSecuritiesAndFuturesOther.name,
      initialValue: typeOfSecuritiesAndFuturesOther.initialValue,
    },
    {
      fieldToClear: typeOfGamingInstitution.name,
      initialValue: typeOfGamingInstitution.initialValue,
    },
    {
      fieldToClear: typeOfGamingInstitutionOther.name,
      initialValue: typeOfGamingInstitutionOther.initialValue,
    },
    {
      fieldToClear: primaryFederalRegulator.name,
      initialValue: primaryFederalRegulator.initialValue,
    },
  ],
  // 55.
  [financialInstitutionIdentificationType.name]: [
    {
      fieldToClear: financialInstitutionIdentificationNumber.name,
      initialValue: financialInstitutionIdentificationNumber.initialValue,
    },
  ],
  // 57.
  [financialInstitutionLegalNameUnknown.name]: [
    {
      fieldToClear: financialInstitutionLegalName.name,
      initialValue: financialInstitutionLegalName.initialValue,
    },
  ],
  // 59. - 60.
  [taxPayerIdentificationNumberUnknown.name]: [
    {
      fieldToClear: taxPayerIdentificationNumber.name,
      initialValue: taxPayerIdentificationNumber.initialValue,
    },
    {
      fieldToClear: taxPayerIdentificationType.name,
      initialValue: taxPayerIdentificationType.initialValue,
    },
  ],
  // 61. - 65. (63_State doesn't have an applicable Unknown checkbox, grouped with 65_Country)
  [addressUnknown.name]: [
    {
      fieldToClear: address.name,
      initialValue: address.initialValue,
    },
  ],
  [cityUnknown.name]: [
    {
      fieldToClear: city.name,
      initialValue: city.initialValue,
    },
  ],

  [zipUnknown.name]: [
    {
      fieldToClear: zip.name,
      initialValue: zip.initialValue,
    },
  ],
  [countryUnknown.name]: [
    {
      fieldToClear: country.name,
      initialValue: country.initialValue,
    },
    {
      fieldToClear: state.name,
      initialValue: state.initialValue,
    },
  ],
  [country.name]: [
    {
      fieldToClear: state.name,
      initialValue: state.initialValue,
    },
  ],
};
