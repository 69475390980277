// Utils
import { get, post, destroy } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';

// Actions
import {
  editSarNarrative as editSarNarrativeAction,
  deleteSarNarrative as deleteSarNarrativeAction,
  createSarNarrative as createSarNarrativeAction,
} from 'app/modules/settings/actions';

// Models
import {
  EditSarNarrativePayload,
  RetrieveGoAMLTemplatesContent,
} from 'app/modules/settings/models';
import { APIKey } from 'app/modules/apiKeys/responses';

export const editSarNarrative = (
  payload: ReturnType<typeof editSarNarrativeAction>['payload'],
) => {
  const formattedPayload: EditSarNarrativePayload = {
    tag_id: payload.tag_id!,
    content: payload.content,
  };
  const path = routes.patronus.sarsNarrativeEditId.replace(
    ':id',
    String(payload.id),
  );

  return post(path, formattedPayload);
};

export const deleteSarNarrative = (
  id: ReturnType<typeof deleteSarNarrativeAction>['payload'],
) => {
  const path = routes.patronus.sarsNarrativeIdDelete.replace(':id', String(id));
  return destroy(path);
};

export const createSarNarrative = (
  payload: ReturnType<typeof createSarNarrativeAction>['payload'],
) => {
  return post(routes.patronus.sarsNarrativeCreate, payload);
};

export const getSarNarratives = () => get(routes.patronus.sarsNarrativeList);

export const getAPIKeys = () => get(routes.patronus.apiKeysList);

export const createAPIKey = () => post<APIKey>(routes.patronus.apiKeysCreate);

export const updateAPIKeyPermissions = (payload) =>
  post(routes.patronus.apiKeysUpdatePermissions, payload);

export const deactivateAPIKey = (keyPrefix: string) =>
  destroy(
    routes.patronus.apiKeysDeactivateKeyPrefix.replace(':keyPrefix', keyPrefix),
  );

export const getGoAMLTemplates = () => get(routes.patronus.goAMLTemplates);

export const editGoAMLTemplates = (payload: {
  type: string;
  content: RetrieveGoAMLTemplatesContent;
}) => post(routes.patronus.goAMLTemplatesUpdate, payload);
