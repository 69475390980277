import { u21CreateSlice } from 'app/shared/thunk/u21CreateSlice';
import { DropdownBuilderFormValues } from 'app/modules/detectionModels/components/DropdownBuilderForm/models';
import { PayloadAction } from '@reduxjs/toolkit';

const DROPDOWN_BUILDER_FORM_VALUES = 'DROPDOWN_BUILDER_FORM_VALUES';
interface DropdownBuilderFormValuesState {
  formValues: DropdownBuilderFormValues;
}

const initialState: DropdownBuilderFormValuesState = {
  formValues: {
    eventSubTypes: [],
    primaryConditionGroupAndOrOperator: 'AND', // NOTE: this is the top level wrapping condition's operator
    conditions: [
      // NOTE: these conditions can be nested with more AND/OR conditions
      {
        type: 'rule_condition_composite',
        operator: 'EQ',
        leftOperand: {
          type: 'FIELD',
          field: '',
          datatype: '',
          model: '',
        },
        rightOperand: null,
        betweenOperand: null,
      },
    ],
    facts: [],
    objectToFlag: 'TXN_EVENT',
  },
};

const dropdownBuilderFormValuesSlice = u21CreateSlice({
  name: DROPDOWN_BUILDER_FORM_VALUES,
  initialState,
  reducers: {
    setCurrentFormValues: (
      draft,
      { payload }: PayloadAction<DropdownBuilderFormValues>,
    ) => {
      draft.formValues = payload;
    },
  },
});

export const DROPDOWN_BUILDER_FORM_VALUES_SLICE_NAME =
  dropdownBuilderFormValuesSlice.name;
export const { setCurrentFormValues } = dropdownBuilderFormValuesSlice.actions;
export default dropdownBuilderFormValuesSlice.reducer;
