import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { U21Spacer } from 'app/shared/u21-ui/components';

import { SidebarAssociatedTags } from 'app/modules/sidebar/components/SidebarAssociatedTags';
import { SidebarAssociatedEntitiesOld } from 'app/modules/sidebar/components/SidebarAssociatedEntities';
import { SidebarAssociatedAlertsOld } from 'app/modules/sidebar/components/SidebarAssociatedAlerts';
import { SidebarAssociatedCases } from 'app/modules/sidebar/components/SidebarAssociatedCases';
import { SidebarAssociatedSARs } from 'app/modules/sidebar/components/SidebarAssociatedSARs';
import { SidebarAssociatedTransactionsOld } from 'app/modules/sidebar/components/SidebarAssociatedTransactions';
import { SidebarAssociatedRules } from 'app/modules/sidebar/components/SidebarAssociatedRules';
import { SidebarAssociatedActionEventsOld } from 'app/modules/sidebar/components/SidebarAssociatedActionEvents';

// Selectors
import { selectSidebarObject } from 'app/modules/sidebar/selectors';

// Types
import { ShortTxnResponse } from 'app/modules/transactions/types';

export const SidebarAssociatedItems = () => {
  const sidebarObject = useSelector(selectSidebarObject);

  const associatedTags = useMemo(() => {
    return sidebarObject.tags ? (
      <SidebarAssociatedTags tags={sidebarObject.tags} />
    ) : null;
  }, [sidebarObject.tags]);

  const associatedEntities = useMemo(() => {
    return sidebarObject.entities ? (
      <SidebarAssociatedEntitiesOld entities={sidebarObject.entities} />
    ) : null;
  }, [sidebarObject.entities]);

  const associatedAlerts = useMemo(() => {
    return sidebarObject.alerts ? (
      <SidebarAssociatedAlertsOld alerts={sidebarObject.alerts} />
    ) : null;
  }, [sidebarObject.alerts]);

  const associatedCases = useMemo(() => {
    return sidebarObject.cases ? (
      <SidebarAssociatedCases cases={sidebarObject.cases} />
    ) : null;
  }, [sidebarObject.cases]);

  const associatedSARs = useMemo(() => {
    return sidebarObject.sars ? (
      <SidebarAssociatedSARs sars={sidebarObject.sars} />
    ) : null;
  }, [sidebarObject.sars]);

  const associatedRules = useMemo(() => {
    return sidebarObject.rules ? (
      <SidebarAssociatedRules rules={sidebarObject.rules} />
    ) : null;
  }, [sidebarObject.rules]);

  const associatedTransactions = useMemo(() => {
    if (!sidebarObject.events) {
      return null;
    }

    // One of the distinguishing properties between transactions
    // and action events is the former has an `amount` field
    const transactions = sidebarObject.events.filter((event) =>
      Object.prototype.hasOwnProperty.call(event, 'amount'),
    ) as ShortTxnResponse[];

    return <SidebarAssociatedTransactionsOld transactions={transactions} />;
  }, [sidebarObject.events]);

  const associatedActionEvents = useMemo(() => {
    if (!sidebarObject.action_events) {
      return null;
    }

    return (
      <SidebarAssociatedActionEventsOld
        actionEvents={sidebarObject.action_events}
      />
    );
  }, [sidebarObject.action_events]);

  return (
    <U21Spacer dividers marginEnd marginStart>
      {associatedTags}
      {associatedEntities}
      {associatedAlerts}
      {associatedCases}
      {associatedSARs}
      {associatedRules}
      {associatedTransactions}
      {associatedActionEvents}
    </U21Spacer>
  );
};
