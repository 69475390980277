import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';

// Redux
import { connect } from 'react-redux';

// Models
import { ScenarioConstants } from 'app/modules/rules/models';
import { RetrieveBlacklistsPayload } from 'app/modules/blacklists/models';
import { WidgetProps } from 'app/modules/detectionModels/components/scenarioWidgets/models';

// Components
import { Dropdown, Icon, Segment } from 'semantic-ui-react';
import LoadingWrapper from 'app/shared/components/LoadingWrapper';

// Actions
import { retrieveBlacklists as retrieveBlacklistsAction } from 'app/modules/blacklists/actions';

// Selectors
import {
  selectBlacklistsDropdownOptions,
  selectBlacklistsLoading,
} from 'app/modules/blacklists/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';

// Styles
import styles from 'app/modules/rules/styles/ScenarioBlacklistSelector.module.scss';

// Utils
import permissions from 'app/shared/utils/permissions';
import { getMatchlistTypes } from 'app/modules/detectionModels/helpers';

const selectHasReadBlacklistsPermission = selectHasPermissionsFactory(
  permissions.readBlacklists,
);

const mapStateToProps = (state: RootState) => ({
  blacklistsLoading: selectBlacklistsLoading(state),
  blacklistsOptions: selectBlacklistsDropdownOptions(state),
  hasReadBlacklistsPermission: selectHasReadBlacklistsPermission(state),
});

const mapDispatchToProps = {
  retrieveBlacklists: retrieveBlacklistsAction,
};

type AllProps = WidgetProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

class ScenarioBlacklistSelector extends PureComponent<AllProps> {
  componentDidMount() {
    const { retrieveBlacklists, hasReadBlacklistsPermission, editingScenario } =
      this.props;

    if (hasReadBlacklistsPermission) {
      const payload: RetrieveBlacklistsPayload = {
        offset: 1,
        limit: 50,
      };

      payload.types = getMatchlistTypes(editingScenario.name);

      retrieveBlacklists(payload);
    }
  }

  handleBlackListDropdownChange = (e, data) => {
    const { editingScenario, onChange } = this.props;
    const newEditingScenario = cloneDeep(editingScenario);
    onChange({
      ...newEditingScenario,
      parameters: {
        ...newEditingScenario.parameters,
        [ScenarioConstants.BLACKLIST_ID]: data.value,
      },
    });
  };

  getViewModeAttributes(selectedBlacklistId: number | undefined): {
    selectorLabel: string;
    blacklistsOption: string;
  } {
    const { blacklistsOptions, viewOnly } = this.props;
    let selectedOption;
    if (viewOnly) {
      const blacklistsOption = blacklistsOptions.find(
        (item) => item.value === selectedBlacklistId,
      );
      selectedOption = {
        selectorLabel: 'Blacklist:',
        blacklistsOption: blacklistsOption?.text,
      };
    } else {
      selectedOption = {
        selectorLabel: 'Select a blacklist:',
        blacklistsOption: '',
      };
    }
    return selectedOption;
  }

  render() {
    const {
      editingScenario,
      blacklistsLoading,
      hasReadBlacklistsPermission,
      blacklistsOptions,
      viewOnly,
    } = this.props;

    if (!hasReadBlacklistsPermission) {
      return (
        <Segment>
          <span className={styles.dropdownLabel}>
            You do not have permission to view blacklists
          </span>
        </Segment>
      );
    }

    const selectedBlacklistId =
      editingScenario.parameters[ScenarioConstants.BLACKLIST_ID];

    const { selectorLabel, blacklistsOption } =
      this.getViewModeAttributes(selectedBlacklistId);
    return (
      <Segment>
        <LoadingWrapper loading={blacklistsLoading} styleProps={{ height: 80 }}>
          <span className={styles.dropdownLabel}>{selectorLabel}</span>
          <Icon name="list alternate outline" />
          {viewOnly ? (
            <>{blacklistsOption}</>
          ) : (
            <Dropdown
              placeholder="No blacklist currently selected"
              className={styles.blacklistDropdown}
              search
              loading={blacklistsLoading}
              value={selectedBlacklistId}
              options={blacklistsOptions}
              onChange={this.handleBlackListDropdownChange}
            />
          )}
        </LoadingWrapper>
      </Segment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScenarioBlacklistSelector);
