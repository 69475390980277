import { FC } from 'react';
import { U21Spacer, U21NoValue } from 'app/shared/u21-ui/components';
import { SidebarFieldValue } from 'app/modules/sidebar/components/SidebarFieldValue';

interface ListComponentProps {
  list?: any[];
  valueKey?: string;
}

export const SidebarFieldListValues: FC<ListComponentProps> = ({
  list,
  valueKey,
}) => {
  if (!list || list.length === 0) {
    return <U21NoValue />;
  }
  return (
    <U21Spacer>
      {list.map((item, ix) => {
        const value = valueKey ? item[valueKey] : item;
        if (!value) return null;
        const key = valueKey ? `${item.id}_${ix}` : `${item.toString()}_${ix}`;
        return <SidebarFieldValue key={key}>{value}</SidebarFieldValue>;
      })}
    </U21Spacer>
  );
};
