import { NetworkLinkProps } from 'app/modules/networkAnalysis/components/NetworkLinks';
import {
  LINK_ANALYSIS_LINK_TYPES,
  LINK_SECTION_MOUNTED,
  LINK_SECTION_UNMOUNTED,
  LINK_TYPE_TO_ICON,
  TRANSACTIONS_SECTION_HASH,
} from 'app/modules/networkAnalysis/constants';
import {
  U21Button,
  U21Chip,
  U21Section,
  U21Spacer,
  U21Table,
  U21TableColumn,
  U21Tooltip,
  U21Typography,
  U21TypographyIcon,
} from 'app/shared/u21-ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import pluralize from 'pluralize';
import { StyledIconCircleCheck } from 'app/modules/networkAnalysis/components/LinkSection';
import { IconCopy, IconLink } from '@u21/tabler-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  EntityTransaction,
  TransactionLinkTableRow,
} from 'app/modules/networkAnalysis/types';
import {
  formatTxnAmount,
  makeTransactionsLinksTableData,
} from 'app/modules/networkAnalysis/helpers';
import { LINKED_ENTITIES_COLUMNS } from 'app/modules/networkAnalysis/columns';
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { toggleSidebar } from 'app/modules/sidebar/slice';
import { useDispatch } from 'react-redux';

interface Props extends NetworkLinkProps {
  transactions: EntityTransaction[];
}

export const TransactionsSection = ({
  data,
  filteredData,
  dispatch,
  transactions,
  expandedSections,
  setExpandedSections,
}: Props) => {
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    dispatch({
      type: LINK_SECTION_MOUNTED,
      payload: { [TRANSACTIONS_SECTION_HASH]: sectionRef },
    });
    return () => {
      dispatch({
        type: LINK_SECTION_UNMOUNTED,
        payload: { sectionHash: TRANSACTIONS_SECTION_HASH },
      });
    };
  }, [dispatch]);

  const [copied, setCopied] = useState(false);
  const { pathname } = useLocation();
  const history = useHistory();

  const linkedEntitiesLength = useMemo(
    () => transactions.length,
    [transactions],
  );

  const tableData = useMemo(
    () => makeTransactionsLinksTableData(transactions, data, filteredData),
    [transactions, data, filteredData],
  );

  const reduxDispatch = useDispatch();

  return (
    <div ref={sectionRef}>
      <U21Section
        title={
          <U21Spacer horizontal align="center">
            <U21TypographyIcon variant="h4">
              {LINK_TYPE_TO_ICON[LINK_ANALYSIS_LINK_TYPES.TRANSACTION]}
            </U21TypographyIcon>
            <span>{LINK_ANALYSIS_LINK_TYPES.TRANSACTION}</span>
            <U21Chip
              color={linkedEntitiesLength > 100 ? 'error' : 'warning'}
              variant="ghost"
            >
              {pluralize('Transaction', linkedEntitiesLength, true)}
            </U21Chip>
          </U21Spacer>
        }
        collapsible
        collapsed={!expandedSections.has(TRANSACTIONS_SECTION_HASH)}
        onToggleCollapse={(c) =>
          setExpandedSections((prev) => {
            const newSet = new Set(prev);
            newSet[c ? 'delete' : 'add'](TRANSACTIONS_SECTION_HASH);
            return newSet;
          })
        }
        action={
          <U21Tooltip
            tooltip={
              copied ? (
                <U21Typography
                  color="inherit"
                  icon={<StyledIconCircleCheck />}
                  variant="inherit"
                >
                  Copied!
                </U21Typography>
              ) : (
                <U21Typography
                  color="inherit"
                  icon={<IconCopy />}
                  variant="inherit"
                >
                  Copy section URL
                </U21Typography>
              )
            }
          >
            <span>
              <CopyToClipboard
                onCopy={() => {
                  history.replace(`${pathname}#${TRANSACTIONS_SECTION_HASH}`);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 2000);
                }}
                text={`${window.location.origin}${pathname}#${TRANSACTIONS_SECTION_HASH}`}
              >
                <U21Button
                  icon={<IconLink />}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  aria-label="Copy link"
                />
              </CopyToClipboard>
            </span>
          </U21Tooltip>
        }
      >
        <U21Table<TransactionLinkTableRow>
          data={tableData}
          columns={[
            {
              id: 'amount',
              accessor: (row) => formatTxnAmount(row.amount),
              Header: 'Amount',
            },
            ...(LINKED_ENTITIES_COLUMNS as U21TableColumn<TransactionLinkTableRow>[]),
          ]}
          onRowClick={(_, { id }) =>
            reduxDispatch(
              toggleSidebar({
                type: SidebarComponentTypes.ENTITY,
                data: {
                  id,
                },
              }),
            )
          }
          getRowID={({ rowId }: TransactionLinkTableRow) => rowId}
        />
      </U21Section>
    </div>
  );
};
