import { IconEye, IconEyeOff } from '@u21/tabler-icons';
import {
  DataSettingFieldType,
  DataSettingsKeyType,
  FormattedData,
  FormattedDataCensorshipStatus,
  OrgDataSettingsConfig,
  Unit21DataClassifier,
} from 'app/modules/dataSettings/responses';
import {
  getFormattedValue,
  keyPathToLabel,
} from 'app/modules/dataSettings/utils';
import {
  U21Chip,
  U21Typography,
  U21DataDisplay,
  U21DataDisplayAnyProps,
  U21DataDisplayDateProps,
  U21DataDisplayDatetimeProps,
  U21DataDisplayEnumProps,
  U21DataDisplayLinkProps,
  U21DataDisplayListProps,
  U21DataDisplayNumberProps,
  U21DataDisplayTextProps,
  U21DataDisplayVariant,
  U21NoValue,
} from 'app/shared/u21-ui/components';
import {
  forwardRef,
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { createSentryError } from 'app/shared/utils/sentry';
import { useSelector } from 'react-redux';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { PerspectiveChip } from 'app/modules/dataSettings/shared/PerspectiveChip';
import isURL from 'validator/lib/isURL';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import { selectDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';

export const LIST_DISPLAY_FUNC = (value: string) => (
  <U21Chip key={value} variant="outlined">
    {value}
  </U21Chip>
);

const AMOUNT_KEYS = new Set([
  'amount',
  'received_amount',
  'sent_amount',
  'fee',
  'external_fee',
]);

interface Props {
  formattedData: FormattedData;
  dataSetting?: OrgDataSettingsConfig;
  negative?: boolean;
  dataDisplayProps?: {
    [U21DataDisplayVariant.ENUM]?: U21DataDisplayEnumProps['componentProps'];
    [U21DataDisplayVariant.NUMBER]?: U21DataDisplayNumberProps['componentProps'];
    [U21DataDisplayVariant.DATE]?: U21DataDisplayDateProps['componentProps'];
    [U21DataDisplayVariant.DATE_TIME]?: U21DataDisplayDatetimeProps['componentProps'];
    [U21DataDisplayVariant.TEXT]?: U21DataDisplayTextProps['componentProps'];
    [U21DataDisplayVariant.LIST]?: U21DataDisplayListProps<any>['componentProps'];
    [U21DataDisplayVariant.LINK]?: U21DataDisplayLinkProps['componentProps'];
    [U21DataDisplayVariant.ANY]?: U21DataDisplayAnyProps['componentProps'];
  };
}

export const DataSettingDataDisplay = ({
  dataDisplayProps,
  formattedData,
  dataSetting,
  negative = false,
}: Props) => {
  const dataSettingIdData = dataSetting
    ? formattedData[dataSetting.id]
    : undefined;

  const { is_parseable: isParsable, censorship } = dataSettingIdData ?? {};

  // if formatted data is empty or we can't find a dataSetting with the given ID
  const missingBaseData = isEmpty(formattedData) || !dataSetting;

  useEffect(() => {
    // if we have the base data but a data setting id is missing in formatted_data
    // we should raise a sentry error because
    // something is wrong in the backend we should know about
    if (!missingBaseData && !dataSettingIdData) {
      createSentryError({
        error: 'Data setting ID missing in `formatted_data`',
        info: `Data setting ID: ${dataSetting.id}.`,
      });
    }
  }, [missingBaseData, dataSettingIdData, dataSetting]);

  const value = dataSettingIdData
    ? getFormattedValue(dataSettingIdData)
    : undefined;

  // const [jsonPreview, setJsonPreview] = useState<boolean>(false);
  const formatAmount = useSelector(selectFormatAmount);
  const dataSettingsByKey = useSelector(selectDataSettingsByNativeKey);

  if (!dataSetting) {
    return (
      <U21DataDisplay
        variant="ANY"
        value={null}
        componentProps={dataDisplayProps?.ANY}
      />
    );
  }
  const isAmount =
    dataSetting?.key_type === DataSettingsKeyType.NATIVE &&
    AMOUNT_KEYS.has(dataSetting.native_key);

  const dataSettingDataType = dataSetting.data_type;
  if (
    dataSettingDataType === DataSettingFieldType.ENTITY_REFERENCE ||
    dataSettingDataType === DataSettingFieldType.INSTRUMENT_REFERENCE
  ) {
    return (
      <PerspectiveChip
        dataSetting={dataSetting}
        formattedDataItem={formattedData[dataSetting.id]}
      />
    );
  }

  let hyperlink;
  if (dataSetting?.rendering_options?.is_url) {
    hyperlink = encodeURI(
      `${dataSetting?.rendering_options?.url_prefix || ''}${value}${
        dataSetting?.rendering_options?.url_postfix || ''
      }`,
    );
    const validatorOptions = {
      protocols: ['https'],
      require_protocol: true,
      require_valid_protocol: true,
      disallow_auth: true,
    };
    if (!isURL(hyperlink, validatorOptions)) {
      hyperlink = null;
    }
  }

  if (dataSettingDataType === DataSettingFieldType.JSON) {
    if (!value) {
      return <U21NoValue />;
    }

    return (
      <U21DataDisplay
        title={dataSetting ? keyPathToLabel(dataSetting) : ''}
        subtitle={dataSetting?.description}
        variant="JSON"
        value={value}
      />
    );
  }
  return (
    <CensorshipWrapper censorship={censorship}>
      {(() => {
        if (!isParsable) {
          return (
            <U21DataDisplay
              componentProps={dataDisplayProps?.ANY}
              variant="ANY"
              value={value}
            />
          );
        }

        // --- ENUMS ---
        if (dataSettingDataType === DataSettingFieldType.ENUM) {
          if (!value) {
            return (
              <U21DataDisplay
                componentProps={dataDisplayProps?.ENUM}
                variant="ENUM"
                value={value}
              />
            );
          }

          const color =
            dataSetting.rendering_options?.enum_mapping?.[value]?.color;
          const description =
            dataSetting.rendering_options?.enum_mapping?.[value]?.description;
          if (color) {
            return (
              <U21DynamicColorChip color={color} tooltip={description}>
                {value}
              </U21DynamicColorChip>
            );
          } else if (dataSetting.key_type === DataSettingsKeyType.NATIVE) {
            if (
              (dataSetting.unit21_data_classifier ===
                Unit21DataClassifier.EVENT &&
                dataSetting.native_key === 'type') ||
              (dataSetting.unit21_data_classifier ===
                Unit21DataClassifier.ACTION_EVENT &&
                dataSetting.native_key === 'action_type')
            ) {
              return (
                <U21DynamicColorChip tooltip={description}>
                  {value}
                </U21DynamicColorChip>
              );
            } else if (
              dataSetting.unit21_data_classifier ===
                Unit21DataClassifier.ENTITY &&
              dataSetting.native_key === 'entity_type'
            ) {
              return (
                <U21Chip
                  color={value === 'USER' ? 'warning' : 'info'}
                  tooltip={description}
                >
                  {value}
                </U21Chip>
              );
            }
          }
          return (
            <U21DataDisplay
              componentProps={{
                tooltip: description,
                ...dataDisplayProps?.ENUM,
              }}
              variant="ENUM"
              value={value}
            />
          );
        }

        // --- URLS ---
        if (
          (dataSettingDataType === DataSettingFieldType.NUMBER ||
            dataSettingDataType === DataSettingFieldType.TEXT) &&
          dataSetting?.rendering_options?.is_url
        ) {
          return (
            <U21DataDisplay
              componentProps={dataDisplayProps?.LINK}
              variant="LINK"
              value={value}
              hyperlink={hyperlink}
            />
          );
        }

        // --- NUMBERS ---
        if (dataSettingDataType === DataSettingFieldType.NUMBER) {
          if (isAmount) {
            let currencyCodeProps = '';
            if (
              dataSetting.key_type === DataSettingsKeyType.NATIVE &&
              dataSetting.native_key.includes('sent')
            ) {
              const sentCurrencyDataSettingId =
                dataSettingsByKey[Unit21DataClassifier.EVENT]?.sent_currency
                  ?.id;
              currencyCodeProps =
                sentCurrencyDataSettingId &&
                formattedData[sentCurrencyDataSettingId]?.is_parseable
                  ? formattedData[sentCurrencyDataSettingId].formatted_value
                  : '';
            } else if (
              dataSetting.key_type === DataSettingsKeyType.NATIVE &&
              dataSetting.native_key.includes('received')
            ) {
              const sentCurrencyDataSettingId =
                dataSettingsByKey[Unit21DataClassifier.EVENT]?.received_currency
                  ?.id;
              currencyCodeProps =
                sentCurrencyDataSettingId &&
                formattedData[sentCurrencyDataSettingId]?.is_parseable
                  ? formattedData[sentCurrencyDataSettingId].formatted_value
                  : '';
            }
            return (
              <U21DataDisplay
                // since we've added the currency symbol to the value, we need to treat it as a string
                variant="TEXT"
                value={
                  negative
                    ? `-${formatAmount({
                        amount: value,
                        currencyCodeProps,
                        precision: dataSetting?.rendering_options?.precision,
                      })}`
                    : formatAmount({
                        amount: value,
                        currencyCodeProps,
                        precision: dataSetting?.rendering_options?.precision,
                      })
                }
                componentProps={{
                  ...dataDisplayProps?.TEXT,
                  ...(negative ? { color: 'error.main' } : {}),
                }}
              />
            );
          }
          return (
            <U21DataDisplay
              componentProps={dataDisplayProps?.NUMBER}
              variant="NUMBER"
              value={value}
              {...(dataSetting?.rendering_options?.precision !== undefined &&
                dataSetting?.rendering_options?.precision !== null && {
                  options: {
                    minimumFractionDigits: 0,
                    maximumFractionDigits:
                      dataSetting.rendering_options.precision,
                  },
                })}
            />
          );
        }

        // --- DATETIMES ---
        if (dataSettingDataType === DataSettingFieldType.DATE_TIME) {
          return (
            <U21DataDisplay
              componentProps={dataDisplayProps?.DATE_TIME}
              variant="DATE_TIME"
              value={value}
              {...(dataSetting?.rendering_options?.timezone && {
                options: {
                  timeZone: dataSetting.rendering_options.timezone,
                },
              })}
            />
          );
        }

        // --- LISTS ---
        if (dataSettingDataType === DataSettingFieldType.LIST) {
          return (
            <U21DataDisplay
              variant="LIST"
              value={value}
              componentProps={{
                displayFunc:
                  dataSetting.key_type === DataSettingsKeyType.NATIVE &&
                  dataSetting.native_key === 'addresses'
                    ? (val: string) => (
                        <U21Typography variant="body2" key={val}>
                          {val}
                        </U21Typography>
                      )
                    : LIST_DISPLAY_FUNC,
                ...dataDisplayProps?.LIST,
              }}
            />
          );
        }

        // --- REST ---
        return (
          <U21DataDisplay
            componentProps={dataDisplayProps?.[dataSettingDataType]}
            variant={dataSettingDataType}
            value={value}
          />
        );
      })()}
    </CensorshipWrapper>
  );
};

interface CensorshipWrapperProps {
  censorship: FormattedDataCensorshipStatus | undefined;
  children: ReactNode;
}

const CensorshipWrapper = forwardRef<HTMLDivElement, CensorshipWrapperProps>(
  ({ censorship, children }, ref) => {
    const [uncensored, setUncensored] = useState(false);
    const isCensored = useMemo(() => {
      return (
        censorship && censorship !== FormattedDataCensorshipStatus.UNCENSORED
      );
    }, [censorship]);

    if (!isCensored) {
      return children;
    }

    let icon: ReactElement | undefined;
    if (censorship === FormattedDataCensorshipStatus.UNCENSORABLE) {
      icon = uncensored ? <IconEyeOff /> : <IconEye />;
    }
    return (
      <U21Chip
        icon={icon}
        onClick={
          censorship === FormattedDataCensorshipStatus.UNCENSORABLE
            ? (e) => {
                e.stopPropagation();
                setUncensored((prev) => !prev);
              }
            : undefined
        }
        ref={ref}
      >
        {uncensored ? children : '••••••'}
      </U21Chip>
    );
  },
);
