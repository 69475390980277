import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { post } from 'app/shared/utils/fetchr';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';
import {
  AddRemoveTxnsPayload,
  TxnAnalysisPayload,
} from 'app/modules/txnAnalysis/requests';
import { TXN_ANALYSIS_QUERY_KEYS } from 'app/modules/txnAnalysis/queries/keys';

interface MutationPayload {
  payload: AddRemoveTxnsPayload;
  isRemove: boolean;
}

const addTxns = (payload: AddRemoveTxnsPayload): Promise<void> => {
  return post<void>(`/txn-analysis/add`, payload);
};

const removeTxns = (payload: AddRemoveTxnsPayload): Promise<void> => {
  return post<void>(`/txn-analysis/remove`, payload);
};

export const useAddRemoveTxn = ({
  limit,
  offset,
  ...rest
}: TxnAnalysisPayload) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ payload, isRemove }: MutationPayload) =>
      isRemove ? removeTxns(payload) : addTxns(payload),
    onError: (_: Error, { isRemove }: MutationPayload) => {
      dispatch(
        sendErrorToast(
          `Failed to ${
            isRemove ? 'remove' : 'add'
          } transaction(s). Please try again.`,
        ),
      );
    },
    onSuccess: (_: void, { isRemove }: MutationPayload) => {
      queryClient.invalidateQueries({
        queryKey: TXN_ANALYSIS_QUERY_KEYS.getTxnAnalysisQueryKey({
          limit,
          offset,
          ...rest,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: TXN_ANALYSIS_QUERY_KEYS.getTxnAnalysisStatsQueryKey(rest),
      });
      dispatch(
        sendSuccessToast(
          `Transction(s) successfully ${isRemove ? 'removed' : 'added'}.`,
        ),
      );
    },
  });
};
