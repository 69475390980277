import { ReactElement } from 'react';
import {
  AdminAlertContentResponseAlert,
  AdminAlertHitResponseAlertHit,
  AdminOrgSettingsResponse,
  AdminRuleContentResponseRule,
  AdminRuleExecutionResponseRuleExecution,
  AdminRuleQueryResponseRow,
  AdminRuleScheduleResponseRuleSchedule,
  AdminRuleValidationResponseRow,
} from 'app/modules/rulesAdmin/types/responses';
import {
  IconBellRinging,
  IconCalendarMinus,
  IconCheckupList,
  IconClipboardList,
  IconFlag,
  IconSettings,
  IconZoomQuestion,
} from '@u21/tabler-icons';
import { RuleAdminTypes } from 'app/modules/sidebar/models';
import { TableConfig } from 'app/shared/pagination/models';
import { TableConfigType } from 'app/shared/CustomConfig/models';
import {
  selectAlertContentAdminTableConfig,
  selectAlertHitAdminTableConfig,
  selectOrgSettingsAdminTableConfig,
  selectRuleContentAdminTableConfig,
  selectRuleExecutionAdminTableConfig,
  selectRuleQueryAdminTableConfig,
  selectRuleScheduleAdminTableConfig,
  selectRuleValidationAdminTableConfig,
} from 'app/shared/CustomConfig/selectors';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

export enum ExecutionEngine {
  POSTGRES = 'POSTGRES',
  POSTGRES_READER = 'POSTGRES_READER',
  SNOWFLAKE = 'SNOWFLAKE',
  DELTALAKE = 'DELTALAKE',
}

export const EXECUTION_ENGINE_OPTIONS: U21SelectOptionProps<ExecutionEngine>[] =
  [
    {
      text: 'Postgres',
      value: ExecutionEngine.POSTGRES,
    },
    {
      text: 'Postgres reader',
      value: ExecutionEngine.POSTGRES_READER,
    },
    {
      text: 'Snowflake',
      value: ExecutionEngine.SNOWFLAKE,
    },
    {
      text: 'Delta lake',
      value: ExecutionEngine.DELTALAKE,
    },
  ];

export const EXECUTION_ENGINE_OPTIONS_WITHOUT_SNOWFLAKE: U21SelectOptionProps<ExecutionEngine>[] =
  [
    {
      text: 'Postgres',
      value: ExecutionEngine.POSTGRES,
    },
    {
      text: 'Postgres reader',
      value: ExecutionEngine.POSTGRES_READER,
    },
  ];

export enum ExecutionType {
  MANUAL = 'MANUAL',
  MAKEUP = 'MAKEUP',
  UPLOAD = 'UPLOAD',
  SCHEDULE = 'SCHEDULE',
}

export const EXECUTION_TYPE_OPTIONS: U21SelectOptionProps<ExecutionType>[] = [
  {
    text: 'Manual',
    value: ExecutionType.MANUAL,
  },
  {
    text: 'Makeup',
    value: ExecutionType.MAKEUP,
  },
  {
    text: 'Upload',
    value: ExecutionType.UPLOAD,
  },
  {
    text: 'Schedule',
    value: ExecutionType.SCHEDULE,
  },
];

export enum ScheduleType {
  MAKEUP = 'MAKEUP',
  SCHEDULE = 'SCHEDULE',
}

export const SCHEDULE_TYPE_OPTIONS: U21SelectOptionProps<ScheduleType>[] = [
  {
    text: 'Makeup',
    value: ScheduleType.MAKEUP,
  },
  {
    text: 'Schedule',
    value: ScheduleType.SCHEDULE,
  },
];

export enum ScheduleStatus {
  WAITING = 'WAITING',
  STARTED = 'STARTED',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
  ERROR_EXPECTED = 'ERROR_EXPECTED',
}

export const SCHEDULE_STATUS_OPTIONS: U21SelectOptionProps<ScheduleStatus>[] = [
  {
    text: 'Waiting',
    value: ScheduleStatus.WAITING,
  },
  {
    text: 'Started',
    value: ScheduleStatus.STARTED,
  },
  {
    text: 'Complete',
    value: ScheduleStatus.COMPLETE,
  },
  {
    text: 'Error',
    value: ScheduleStatus.ERROR,
  },
  {
    text: 'Cancelled',
    value: ScheduleStatus.CANCELLED,
  },
  {
    text: 'Error Expected',
    value: ScheduleStatus.ERROR_EXPECTED,
  },
];

export enum ValidationStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  GENERATING_VALIDATION_ALERTS = 'GENERATING_VALIDATION_ALERTS',
  QUERY_TIMEOUT = 'QUERY_TIMEOUT',
  FAILED = 'FAILED',
}

export const VALIDATION_STATUS_OPTIONS: U21SelectOptionProps<ValidationStatus>[] =
  [
    {
      text: 'Pending',
      value: ValidationStatus.PENDING,
    },
    {
      text: 'Running',
      value: ValidationStatus.RUNNING,
    },
    {
      text: 'Complete',
      value: ValidationStatus.COMPLETE,
    },
    {
      text: 'Generating validation alerts',
      value: ValidationStatus.GENERATING_VALIDATION_ALERTS,
    },
    {
      text: 'Query timeout',
      value: ValidationStatus.QUERY_TIMEOUT,
    },
    {
      text: 'Failed',
      value: ValidationStatus.FAILED,
    },
  ];

export enum AlertStatus {
  OPEN = 'OPEN',
  OPEN_SHADOW = 'OPEN_SHADOW',
  CLOSED = 'CLOSED',
  RULE_VALIDATION = 'RULE_VALIDATION',
}

export const ALERT_STATUS_OPTIONS: U21SelectOptionProps<AlertStatus>[] = [
  {
    text: 'Open',
    value: AlertStatus.OPEN,
  },
  {
    text: 'Open shadow',
    value: AlertStatus.OPEN_SHADOW,
  },
  {
    text: 'Closed',
    value: AlertStatus.CLOSED,
  },
  {
    text: 'Rule validation',
    value: AlertStatus.RULE_VALIDATION,
  },
];

export enum AlertType {
  TM = 'TM',
  KYC = 'KYC',
  CAR = 'CAR',
  CHAINALYSIS = 'CHAINALYSIS',
  MANUAL = 'MANUAL',
}

export const ALERT_TYPE_OPTIONS: U21SelectOptionProps<AlertType>[] = [
  {
    text: 'Transaction monitoring (TM)',
    value: AlertType.TM,
  },
  {
    text: 'Know your customer (KYC)',
    value: AlertType.KYC,
  },
  {
    text: 'Continuing activity reports (CAR)',
    value: AlertType.CAR,
  },
  {
    text: 'Chainalysis',
    value: AlertType.CHAINALYSIS,
  },
  {
    text: 'Manual',
    value: AlertType.MANUAL,
  },
];

export type RuleAdminResponseType =
  | AdminRuleValidationResponseRow
  | AdminRuleExecutionResponseRuleExecution
  | AdminRuleQueryResponseRow
  | AdminRuleContentResponseRule
  | AdminRuleScheduleResponseRuleSchedule
  | AdminAlertContentResponseAlert
  | AdminAlertHitResponseAlertHit
  | AdminOrgSettingsResponse;

interface RuleAdminInfo {
  title: string;
  icon: ReactElement;
  tableConfigType:
    | TableConfigType.RULE_ADMIN_CONTENT_TABLE
    | TableConfigType.RULE_ADMIN_EXECUTION_TABLE
    | TableConfigType.RULE_ADMIN_QUERY_TABLE
    | TableConfigType.RULE_ADMIN_VALIDATION_TABLE
    | TableConfigType.RULE_ADMIN_SCHEDULE_TABLE
    | TableConfigType.RULE_ADMIN_ALERT_CONTENT_TABLE
    | TableConfigType.RULE_ADMIN_ALERT_HIT_TABLE
    | TableConfigType.RULE_ADMIN_ORG_SETTINGS_TABLE;
  tableConfigSelector: (state: RootState) => TableConfig[];
}

export const RULE_ADMIN_INFO_MAP: Record<RuleAdminTypes, RuleAdminInfo> = {
  [RuleAdminTypes.RULE_CONTENT]: {
    title: 'Rule',
    icon: <IconClipboardList />,
    tableConfigType: TableConfigType.RULE_ADMIN_CONTENT_TABLE,
    tableConfigSelector: selectRuleContentAdminTableConfig,
  },
  [RuleAdminTypes.RULE_QUERY]: {
    title: 'Rule Query',
    icon: <IconZoomQuestion />,
    tableConfigType: TableConfigType.RULE_ADMIN_QUERY_TABLE,
    tableConfigSelector: selectRuleQueryAdminTableConfig,
  },
  [RuleAdminTypes.RULE_EXECUTION]: {
    title: 'Rule Execution',
    icon: <IconSettings />,
    tableConfigType: TableConfigType.RULE_ADMIN_EXECUTION_TABLE,
    tableConfigSelector: selectRuleExecutionAdminTableConfig,
  },
  [RuleAdminTypes.RULE_VALIDATION]: {
    title: 'Rule Validation',
    icon: <IconCheckupList />,
    tableConfigType: TableConfigType.RULE_ADMIN_VALIDATION_TABLE,
    tableConfigSelector: selectRuleValidationAdminTableConfig,
  },
  [RuleAdminTypes.RULE_SCHEDULE]: {
    title: 'Rule Schedule',
    icon: <IconCalendarMinus />,
    tableConfigType: TableConfigType.RULE_ADMIN_SCHEDULE_TABLE,
    tableConfigSelector: selectRuleScheduleAdminTableConfig,
  },
  [RuleAdminTypes.ALERT_CONTENT]: {
    title: 'Alert Content',
    icon: <IconBellRinging />,
    tableConfigType: TableConfigType.RULE_ADMIN_ALERT_CONTENT_TABLE,
    tableConfigSelector: selectAlertContentAdminTableConfig,
  },
  [RuleAdminTypes.ALERT_HIT]: {
    title: 'Alert Hit',
    icon: <IconFlag />,
    tableConfigType: TableConfigType.RULE_ADMIN_ALERT_HIT_TABLE,
    tableConfigSelector: selectAlertHitAdminTableConfig,
  },
  [RuleAdminTypes.ORG_SETTINGS]: {
    title: 'Org Settings',
    icon: <IconSettings />,
    tableConfigType: TableConfigType.RULE_ADMIN_ORG_SETTINGS_TABLE,
    tableConfigSelector: selectOrgSettingsAdminTableConfig,
  },
};

export enum RulesAdminModalType {
  NONE = '',
  UPDATE_SCHEDULE_STATUS = 'UPDATE_SCHEDULE_STATUS',
  UPDATE_RETRY_COUNT = 'UPDATE_RETRY_COUNT',
  UPDATE_TIMEOUT = 'UPDATE_TIMEOUT',
  UPDATE_VALIDATION_STATE = 'UPDATE_VALIDATION_STATE',
}

type RulesAdminWriteConfig =
  | {
      title: string;
      desc: string;
      type: 'dropdown';
      options: U21SelectOptionProps[];
      label: string;
    }
  | {
      title: string;
      desc: string;
      type: 'input';
      label: string;
    }
  | {
      title: string;
      desc: string;
      type: 'buttonGroup';
      positiveLabel: string;
      negativeLabel: string;
    };

export const RULE_ADMIN_WRITE_CONFIG: Record<
  RulesAdminModalType,
  RulesAdminWriteConfig
> = {
  [RulesAdminModalType.NONE]: {
    title: '',
    desc: '',
    type: 'dropdown',
    options: [],
    label: '',
  },
  [RulesAdminModalType.UPDATE_SCHEDULE_STATUS]: {
    title: 'Set Schedule Status',
    desc: 'Set the schedule status for the selected rule schedules:',
    type: 'dropdown',
    options: SCHEDULE_STATUS_OPTIONS,
    label: 'Schedule status',
  },
  [RulesAdminModalType.UPDATE_RETRY_COUNT]: {
    title: 'Set Retry Count',
    desc: 'Set the retry count for the selected rule schedules:',
    type: 'input',
    label: 'Retry count',
  },
  [RulesAdminModalType.UPDATE_TIMEOUT]: {
    title: 'Set Timeout',
    desc: 'Set the timeout for the selected rules:',
    type: 'input',
    label: 'Timeout (in Minutes)',
  },
  [RulesAdminModalType.UPDATE_VALIDATION_STATE]: {
    title: 'Set Validation State',
    desc: 'Set whether rules are synchronous for the selected rules:',
    type: 'dropdown',
    options: VALIDATION_STATUS_OPTIONS,
    label: 'Validation status',
  },
};

export interface RulesAdminInitialState {
  rulesAdminModalType: RulesAdminModalType;
}
