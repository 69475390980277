import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from 'app/shared/utils/date';
import { IconInfoCircle } from '@u21/tabler-icons';
import { U21Alert, U21Button, U21Tooltip } from 'app/shared/u21-ui/components';

import {
  FEATURE_CURRENT_VERSION,
  GaFeatureTypes,
} from 'app/shared/components/GaBanner/constants';
import {
  toggleHideGABanner,
  toggleDisableNewFeature,
} from 'app/shared/components/GaBanner/sliceGaBanner';
import {
  selectGaBannerHidden,
  selectNewFeatureGADate,
} from 'app/shared/components/GaBanner/selector';
import { HTMLProps, ReactNode } from 'react';
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { getDOMProps } from 'app/shared/utils/react';

interface NewFeatureAlertProps
  extends Omit<HTMLProps<HTMLDivElement>, 'size' | 'title'> {
  featureName: GaFeatureTypes;
  displayName: string;
  description: ReactNode;
  gaDateFeatureFlag: FeatureFlag;
  variant?: 'small' | 'normal';
}

export const NewFeatureAlert = ({
  featureName,
  displayName,
  description,
  gaDateFeatureFlag,
  variant = 'normal',
  ...rest
}: NewFeatureAlertProps) => {
  const dispatch = useDispatch();
  const gaBannerHidden = useSelector((state) =>
    selectGaBannerHidden(state, featureName, gaDateFeatureFlag),
  );

  // GA date feature flag
  const gaDate = useSelector((state) =>
    selectNewFeatureGADate(state, gaDateFeatureFlag),
  );

  if (!gaDate) {
    return null;
  }

  const goToOldButton = (
    <U21Button
      aria-label={`disable new ${featureName} v${FEATURE_CURRENT_VERSION[featureName]}`}
      color="primary"
      onClick={() =>
        dispatch(
          toggleDisableNewFeature({
            featureName,
            featureDisabled: true,
          }),
        )
      }
      variant="text"
    >
      here
    </U21Button>
  );

  return (
    <U21Alert
      hidden={gaBannerHidden}
      onClose={() =>
        dispatch(toggleHideGABanner({ featureName, hideBanner: true }))
      }
      severity="info"
      closeButtonProps={{ 'aria-label': `Close ${displayName} banner` }}
      title={
        variant === 'normal'
          ? `Welcome to Unit21's new ${displayName} experience.`
          : null
      }
      {...getDOMProps(rest)}
    >
      {variant === 'normal' ? (
        <>
          <div>{description}</div>
          <br />
          <div>
            You may go back to the old experience by clicking {goToOldButton}.
            Please note that the old experience will no longer be available from{' '}
            <strong>{formatDate(new Date(gaDate))}</strong>.
          </div>
        </>
      ) : (
        <div>
          We revamped our&nbsp;
          <U21Tooltip tooltip={description}>
            <strong>{displayName}</strong>
          </U21Tooltip>
          ! You can opt-out&nbsp;
          {goToOldButton}
          &nbsp;until <strong>{formatDate(new Date(gaDate))}</strong>.
        </div>
      )}
    </U21Alert>
  );
};

interface NewFeatureGaToggleButtonProps {
  featureName: GaFeatureTypes;
  gaDateFeatureFlag: FeatureFlag;
}
export const NewFeatureGaToggleButton = ({
  featureName,
  gaDateFeatureFlag,
}: NewFeatureGaToggleButtonProps) => {
  const dispatch = useDispatch();
  const gaBannerHidden = useSelector((state) =>
    selectGaBannerHidden(state, featureName, gaDateFeatureFlag),
  );

  // GA date feature flag
  const gaDate = useSelector((state) =>
    selectNewFeatureGADate(state, gaDateFeatureFlag),
  );

  if (!gaDate) {
    return null;
  }
  return (
    <U21Button
      aria-label={`toggle GA banner ${featureName}`}
      color="info"
      icon={<IconInfoCircle />}
      onClick={(e) => {
        // prevent propagating click to collapsible sections
        e.stopPropagation();
        dispatch(
          toggleHideGABanner({ featureName, hideBanner: !gaBannerHidden }),
        );
      }}
      size="small"
    />
  );
};
