// Models
import { TableConfig } from 'app/shared/pagination/models';
import { ObjectLabelModel } from 'app/shared/models';
import { Blacklist } from 'app/modules/blacklists/models';

export const BLACKLIST_ITEMS_LIMIT = 25;

export const BLACKLIST_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'type',
    label: 'Type',
    type: 'label',
  },
  {
    key: 'status',
    label: 'Status',
    type: 'label',
  },
  {
    key: 'u21_global',
    label: 'Global',
  },
];

export const BLACKLIST_ITEMS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'value',
    label: 'Value',
  },
  {
    key: 'created_at',
    label: 'Date Added',
    type: 'datetime',
  },
  {
    key: 'status',
    label: 'Status',
    type: 'label',
  },
  {
    key: 'deactivate',
    label: '',
  },
];

export const IP_BLACKLIST_ITEMS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'source',
    label: 'Source',
  },
].concat([...BLACKLIST_ITEMS_TABLE_CONFIG]);

export const USER_BLACKLIST_ITEMS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'content',
    label: 'Content',
    width: 8,
  },
  {
    key: 'status',
    label: 'Status',
    type: 'label',
  },
  {
    key: 'deactivate',
    label: '',
  },
];

export const BUSINESS_BLACKLIST_ITEMS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'business_name',
    label: 'Business Name',
  },
  {
    key: 'corporate_tax_id',
    label: 'Corporate Tax ID',
  },
  {
    key: 'street',
    label: 'Street',
  },
  {
    key: 'city',
    label: 'City',
  },
  {
    key: 'state',
    label: 'State',
  },
  {
    key: 'country',
    label: 'Country',
  },
  {
    key: 'zip_code',
    label: 'Zip Code',
  },
  {
    key: 'created_at',
    label: 'Date Added',
    type: 'datetime',
  },
  {
    key: 'status',
    label: 'Status',
    type: 'label',
  },
  {
    key: 'deactivate',
    label: '',
  },
];

export const BLACKLIST_TYPE_OPTIONS = [
  {
    text: 'String',
    value: 'STRING',
  },
  {
    text: 'INET',
    value: 'IP_INET',
  },
  {
    text: 'CIDR',
    value: 'IP_CIDR',
  },
  {
    text: 'User',
    value: 'USER',
  },
  {
    text: 'Business',
    value: 'BUSINESS',
  },
];

export const BLACKLIST_LABELS: { [key: string]: ObjectLabelModel } = {
  active: {
    content: 'ACTIVE',
    color: 'teal',
    icon: 'play',
  },
  inactive: {
    content: 'INACTIVE',
    color: 'red',
    icon: 'pause',
  },
};

export const INITIAL_BLACKLIST: Blacklist = {
  id: -1,
  name: '',
  description: '',
  type: 'STRING',
  status: 'INACTIVE',
  use_as_whitelist: false,
  u21_global: false,
  rules: [],
};
