import { useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { U21ContentDisplay, U21MenuButton } from 'app/shared/u21-ui/components';

import { ReassignAgentMenuItem } from 'app/modules/alerts/components/ReassignAgentMenuItem';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Selectors
import {
  selectCanReassignInvestigations,
  selectEscalateInvestigationsLoading,
  selectInvestigation,
} from 'app/modules/investigations/selectors';

// Actions
import { retrieveCase } from 'app/modules/casesOld/actions';
import { reassignInvestigations } from 'app/modules/investigations/actions';
import { reassignInvestigationsThunk } from 'app/modules/investigations/sliceInvestigations';
import { IconBriefcaseArrow } from 'app/shared/components/Icons/IconBriefcaseArrow';
import { IconFlagArrow } from 'app/shared/components/Icons/IconFlagArrow';

interface OwnProps {
  type: InvestigationType;
  sidebar?: boolean;
}

export const Reassign = ({ type, sidebar = false }: OwnProps) => {
  const dispatch = useDispatch();

  const investigation = useSelector(selectInvestigation(type, sidebar));
  const assignedToName = investigation.assigned_to_name;

  const escalationLoading = useSelector(
    selectEscalateInvestigationsLoading(type),
  );

  const canReassignInvestigations = useSelector(
    selectCanReassignInvestigations(type),
  );

  const disableReassignment = useMemo(() => {
    const { status } = investigation;
    return status === 'CLOSED' || status === 'IN_REVIEW';
  }, [investigation]);

  const handleReassignment = (agentID: number) => {
    if (type === InvestigationType.ALERT) {
      dispatch(
        reassignInvestigations({
          alert_ids: [investigation.id],
          agent_id: agentID,
        }),
      );
    } else {
      dispatch(
        reassignInvestigationsThunk({
          payload: {
            agent_id: agentID,
            object_ids: [investigation.id],
            object_type: type,
          },
          onSuccess: () => {
            dispatch(retrieveCase(investigation.id));
          },
        }),
      );
    }
  };

  return (
    <>
      <U21ContentDisplay title="Assigned to">
        {assignedToName || 'Unassigned'}
      </U21ContentDisplay>
      {canReassignInvestigations && (
        <div>
          <U21MenuButton
            buttonProps={{
              startIcon:
                type === InvestigationType.ALERT ? (
                  <IconFlagArrow />
                ) : (
                  <IconBriefcaseArrow />
                ),
              color: 'primary',
              disabled: escalationLoading,
            }}
            disabled={disableReassignment}
            items={[
              <ReassignAgentMenuItem
                key="reassign"
                onChange={handleReassignment}
              />,
            ]}
          >
            Re-Assign
          </U21MenuButton>
        </div>
      )}
    </>
  );
};
