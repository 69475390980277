// Models
import { SarInput, SarDropdownOptions } from 'app/modules/fincenSar/models';

// Helpers
import { createState } from 'app/modules/fincenSar/helpers';

const popupContent = `Item 18 - Form of identification. Enter the information used to identify the subject. Select "Other" if the identification
provided does not match the available identification types
and describe the identification in the associated text field.
“Other" identification could include such things as an entity’s
business license or incorporation documents, corporate ID
cards, local government ID cards, etc. Do not include
formatting such as hyphens or periods when entering the
identification number. Select the appropriate state and
country from the drop-down list if the identification issuer
was a U.S., Canadian, or Mexican state, territory, or
province. If the identification was issued by a country other
than U.S., Canada, or Mexico, select the appropriate country
from the drop-down list and leave the state blank. Record
the name and nature of the issuer in Part V. Enter all
identification data that is available. Check “Unknown” if all
identification information for the subject is not known.
Multiple known forms of identification associated with the
subject should be identified. All identifying numbers must be
entered as a single text string without formatting or special
characters such as hyphens or periods. An identifying
number in the format NNN-NN-NNNN would be entered as
NNNNNNNNN. Such numbers include alien registration,
CRD, CUSIP, driver’s license, state identification, ElN, IARD,
lTlN, passport, RSSD, SEC ID, and SSN.`;

// ================ 18. Form of ID for subject ==================
const idTypeOptions = [
  {
    key: 'drivers_license',
    value: 'drivers_license',
    text: `Driver's license/State Id`,
  },
  {
    key: 'passport',
    value: 'passport',
    text: `Passport`,
  },
  {
    key: 'alien_registration',
    value: 'alien_registration',
    text: `Alien registration`,
  },
  {
    key: 'other',
    value: 'other',
    text: `Other`,
  },
];

export const idType: SarDropdownOptions = {
  title: `Type`,
  name: '18_idType',
  initialValue: '',
  options: idTypeOptions,
  popupContent,
};

export const idTypeOther: SarInput = {
  title: 'Other',
  name: `18z_OtherPartyIdentificationTypeText`,
  initialValue: '',
  popupContent,
};

export const idNumber: SarInput = {
  title: `Number`,
  name: `18_idNumber`,
  maxLen: 24,
  initialValue: '',
  popupContent,
};

export const country: SarInput = {
  title: `Country`,
  name: '18_country',
  initialValue: '',
  popupContent,
};

export const state: SarInput = {
  title: `Issuing State`,
  name: '18_state',
  initialValue: '',
  popupContent,
};
// ================================================================

const allState = [idType, idTypeOther, idNumber, country, state];

const restOfState = {
  disabled: false,
};

export const initialState = { ...createState(allState), ...restOfState };
