import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';

// Models
import {
  SarActionTypes,
  CreateSarPayload,
  RetrieveSarPayload,
  SarDetails,
  SarFiltersRequest,
  RetrieveSarListPayload,
  ArchiveSarPayload,
  SarSummary,
} from 'app/modules/fincenSar/models';

export const archiveSar = (payload: ArchiveSarPayload) => {
  return createAction(SarActionTypes.ARCHIVE_SAR, payload);
};

export const archiveSarSuccess = (payload: SarSummary) => {
  return createAction(SarActionTypes.ARCHIVE_SAR_SUCCESS, payload);
};

export const createSar = (payload: CreateSarPayload) => {
  return createAction(SarActionTypes.CREATE_SAR, payload);
};

export const createNewSar = (
  payload: Omit<CreateSarPayload, 'content' | 'report_type'>,
) => {
  return createAction(SarActionTypes.CREATE_NEW_SAR, payload);
};

// note: this payload is not used to fill in any state
export const createSarSuccess = (payload: SarDetails) => {
  return createAction(SarActionTypes.CREATE_SAR_SUCCESS, payload);
};

export const retrieveSidebarSar = (payload: RetrieveSarPayload) => {
  return createAction(SarActionTypes.RETRIEVE_SIDEBAR_SAR, payload);
};

export const retrieveSidebarSarSuccess = (payload: SarDetails) => {
  return createAction(SarActionTypes.RETRIEVE_SIDEBAR_SAR_SUCCESS, payload);
};

export const retrieveSarList = (payload: SarFiltersRequest) => {
  return createAction(SarActionTypes.RETRIEVE_SAR_LIST, payload);
};

export const retrieveSarListSuccess = (payload: RetrieveSarListPayload) => {
  return createAction(SarActionTypes.RETRIEVE_SAR_LIST_SUCCESS, payload);
};

export const reassignSarSuccess = (payload: RetrieveSarListPayload) => {
  return createAction(SarActionTypes.REASSIGN_SAR_SUCCESS, payload);
};

export const editSarReviewerSucess = (payload: SarSummary) => {
  return createAction(SarActionTypes.EDIT_SAR_REVIEWER_SUCCESS, payload);
};

const SarActionsList = {
  createSar,
  createSarSuccess,
  retrieveSidebarSar,
  retrieveSidebarSarSuccess,
  retrieveSarList,
  retrieveSarListSuccess,
  reassignSarSuccess,
  archiveSarSuccess,
  editSarReviewerSucess,
};

type SarActions = ActionsUnion<typeof SarActionsList>;
export default SarActions;
