import { FC, ReactElement } from 'react';
import styled from 'styled-components';
import {
  U21Chip,
  U21Spacer,
  U21Typography,
} from 'app/shared/u21-ui/components';

interface OwnProps {
  count: number;
  title: string;
  icon?: ReactElement;
}

export const SidebarTitleIcon: FC<OwnProps> = ({ count = 0, title, icon }) => {
  return (
    <Container horizontal>
      <U21Typography variant="subtitle1" icon={icon}>
        {title}
      </U21Typography>
      <U21Chip>{count}</U21Chip>
    </Container>
  );
};

const Container = styled(U21Spacer)`
  margin: 8px 0;
`;
