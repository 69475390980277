// Redux
import { Reducer } from 'redux';
import produce, { Draft } from 'immer';

// Models
import { AgentsState, AgentsActionTypes } from 'app/modules/agentsOld/models';

// Actions
import { AgentActions } from 'app/modules/agentsOld/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

const initialState: Readonly<AgentsState> = {
  paginatedAgents: {
    agents: [],
    count: 0,
  },
  // fields the agent is allowed to uncensor
  uncensorFields: [],
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer: Reducer<AgentsState> = (
  state = initialState,
  action: AgentActions,
) => {
  return produce(state, (draft: Draft<AgentsState>) => {
    switch (action.type) {
      case AgentsActionTypes.RETRIEVE_PAGINATED_AGENTS_SUCCESS:
        draft.paginatedAgents = action.payload;
        return;

      case AgentsActionTypes.ADD_AGENTS_TO_PAGINATED_TABLE:
        action.payload.forEach((newAgent) => {
          const agentIx = draft.paginatedAgents.agents.findIndex(
            (agent) => agent.id === newAgent.id,
          );
          if (agentIx < 0) {
            draft.paginatedAgents.agents.push(newAgent);
            draft.paginatedAgents.count += 1;
          } else {
            draft.paginatedAgents.agents[agentIx] = newAgent;
          }
        });
        return;

      case AgentsActionTypes.REMOVE_AGENTS_FROM_PAGINATED_TABLE: {
        const removedAgentIds = action.payload;
        draft.paginatedAgents.agents = draft.paginatedAgents.agents.filter(
          (agent) => !removedAgentIds.includes(agent.id),
        );
        draft.paginatedAgents.count -= removedAgentIds.length;
        return;
      }

      case AgentsActionTypes.RETRIEVE_UNCENSOR_FIELDS_SUCCESS:
        draft.uncensorFields = action.payload;
        return;

      default:
        return;
    }
  });
};

export { reducer as agentsReducer, initialState };
