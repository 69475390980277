import { NotesActionTypes } from 'app/modules/notes/models';
import { CasesActionTypes } from 'app/modules/casesOld/models';

export const selectNotes = (state: RootState) => state.notes.notes || [];

// Loading
export const selectNotesLoading = (state: RootState) =>
  Boolean(
    state.loading[NotesActionTypes.RETRIEVE_NOTES] ||
      state.loading[CasesActionTypes.RETRIEVE_CASE],
  );

export const selectAddNoteLoading = (state: RootState) =>
  Boolean(state.loading[NotesActionTypes.ADD_NOTE]);

export const selectDeleteNoteLoading = (state: RootState) =>
  Boolean(state.loading[NotesActionTypes.DELETE_NOTE]);

export const selectEditorState = (state: RootState) => state.notes.editorState;
