import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  retrieveSidebarAlertTransactions as retrieveSidebarAlertTransactionsAction,
  retrieveSidebarAlertTransactionsSuccess,
} from 'app/modules/alerts/actions';

// API
import { getAlertTransactions as retrieveAPI } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.RETRIEVE_SIDEBAR_ALERT_TRANSACTIONS;
function* retrieveSidebarAlertTransactions({
  payload,
}: ReturnType<typeof retrieveSidebarAlertTransactionsAction>) {
  // Note: hash_key here refers to alert_id, overloading the variable name
  // because EventTable calls the txn pagination API with hash_key
  const {
    offset,
    limit,
    sort_column: sortColumn = '',
    sort_direction: sortDirection = '',
  } = payload;
  const newPayload = {
    offset,
    limit,
    sort_column: sortColumn,
    sort_direction: sortDirection,
  };
  const config = {
    rootAction,
    request: call(retrieveAPI, payload.hash_key, newPayload),
    success: function* onSuccess(result) {
      yield put(retrieveSidebarAlertTransactionsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSidebarAlertTransactions() {
  yield takeLatest(rootAction, retrieveSidebarAlertTransactions);
}
