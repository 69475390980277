import moment from 'moment';

export const ACTIVE_STATUSES = ['ACTIVE', 'OPEN'];
export const INACTIVE_STATUSES = ['CLOSED', 'INACTIVE'];

export const AMOUNT_FORMAT: string = '0,0.00';

export const STANDARD_NUMBER_FORMAT: string = '0,0';

export const LARGE_AMOUNT_FORMAT: string = '0a';

export const SHORT_DECIMAL_FORMAT = '0.00e+0';

// currently in use with moment.js - incompatible with date-fns
export const BE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
// format string passed to date-fns format function - replaces BE_DATE_FORMAT
export const BE_DATE_FORMAT_FNS = 'yyyy-MM-dd HH:mm:ss';
export const DATE_FORMAT_STR = 'DD MMM YYYY';

export const DEFAULT_ID = -1;

export const MUSTACHE_MATCHER_REGEX = /{{\s*[$\w.]+\s*}}/g;

export const DEFAULT_EXEC_START_DATE_MONTHS_AGO = 3;

// format of value returned from <InputField/> component when type is `datetimepicker`
export const INPUT_FIELD_DATETIME_VALUE_FORMAT = 'DD MMM YYYY HH:mm';

export const TXN_DATETIME_FORMAT = 'MMM D, YYYY h:mm:ss A';
export const DATETIME_FORMAT_STR = 'D MMM YYYY, ddd, LT';
export const PRESET_DATE_WITH_TIME_IN_UTC = moment
  .utc()
  .subtract(DEFAULT_EXEC_START_DATE_MONTHS_AGO, 'months')
  .hours(0)
  .minutes(0)
  .format(INPUT_FIELD_DATETIME_VALUE_FORMAT);
export const ONE_DAY_FROM_NOW = moment().add(1, 'days').format(DATE_FORMAT_STR);

export const DATE_FORMAT_MILITARY_TIME = 'DD MMM YYYY H:mm';

export const INFINITE_SCROLL_LIMIT = 10;

export const ALERT_QUEUE_RULE_STATUSES = ['ACTIVE'];

export const OFFSET_DEFAULT = 1;

export const PAGE_LIMIT_DEFAULT = 25;

export const TEXT_PLACEHOLDER_DELAY_MS = 300;

export const ARRAY_SIZE_LIMIT = 15;

// default loading height of all tab panes to be consistent
export const DEFAULT_TAB_LOADING_HEIGHT = 400;

export const CENSORED_FIELD = '******';

export const CUSTOM_DATA_SEPARATOR = '......';

export const PAGE_LIMIT_OPTIONS = [
  { key: 10, value: 10, text: '10 Rows' },
  { key: 25, value: 25, text: '25 Rows' },
  { key: 50, value: 50, text: '50 Rows' },
  { key: 100, value: 100, text: '100 Rows' },
];

export const EXTENDED_PAGE_LIMIT_OPTIONS = [
  { key: 500, value: 500, text: '500 Rows' },
];

export const MAX_PAGINATION_PAGE_COUNT = 300;

export const FILE_EXPORTS_SAR_FILING_MAP = {
  agent_ids: 'Created By',
  statuses: 'Selected Statuses',
  created_at_start: 'Date Created',
  created_at_end: 'End Date',
  updated_at_start: 'Date Updated',
  updated_at_end: 'End Updated Date',
  filed_at_start: 'Date Filed',
  filed_at_end: 'End Filed Date',
  submitted: 'Submitted',
  sources: 'Sources',
  phrase: 'Search Phrase',
  country: 'Country',
  state: 'State',
  city: 'City',
};

export const FILE_EXPORTS_ALERT_FILING_MAP = {
  agent_ids: 'Assigned To',
  statuses: 'Selected Statuses',
  tag_ids: 'Tag IDs',
  alert_ids: 'Alert IDs',
  rule_ids: 'Rule IDs',
  start_date: 'Date Created',
  end_date: 'End Date',
  minimum_amount: 'Minimum Amount',
  maximum_amount: 'Maximum Amount',
  alert_types: 'Alert Types',
  sources: 'Sources',
  team_ids: 'Teams',
  disposition_start_date: 'Disposition Start Date',
  disposition_end_date: 'Disposition End Date',
  entity_ids: 'Entity IDs',
  phrase: 'Search Phrase',
  alerting_org_ids: 'Alerting Org IDs',
  alert_queue_ids: 'Alert Queue IDs',
  disposition: 'Disposition',
  subdisposition_ids: 'Subdisposition IDs',
  subdisposition_option_ids: 'Subdisposition Option IDs',
};

export const FILE_EXPORTS_CASE_FILING_MAP = {
  agent_ids: 'Created By',
  statuses: 'Selected Statuses',
  tag_ids: 'Tag IDs',
  case_ids: 'Case IDs',
  rule_ids: 'Rule IDs',
  start_date: 'Date Created',
  end_date: 'End Date',
  minimum_amount: 'Minimum Amount',
  maximum_amount: 'Maximum Amount',
  team_ids: 'Teams',
  sources: 'Sources',
  phrase: 'Search Phrase',
  entity_ids: 'Entity IDs',
  disposition: 'Disposition',
  subdisposition_ids: 'Subdisposition IDs',
  subdisposition_option_ids: 'Subdisposition Option IDs',
  disposition_start_date: 'Disposition Start Date',
  disposition_end_date: 'Disposition End Date',
};

export const FILE_EXPORTS_RULE_FILING_MAP = {
  agent_ids: 'Created By',
  statuses: 'Selected Statuses',
  tag_ids: 'Tag IDs',
  rule_ids: 'Rule IDs',
  start_date: 'Date Created',
  end_date: 'End Date',
  sources: 'Sources',
  phrase: 'Search Phrase',
};

// for determining whether or not to enable generating mock data when creating a new org.In prod envs(not including sandbox), we want to disable mock data generation so their data is not impacted
export const SANDBOX_DEV_STG_ENV_LIST = [
  'https://stg-lumos.unit21.com',
  'https://sandbox1.unit21.com',
  'https://sandbox2.unit21.com',
  'https://chime-sandbox1.unit21.com',
  'https://dev-lumos.unit21.com',
  'http://demo.unit21.com:3000',
  'https://sales.unit21.com',
];
