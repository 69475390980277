import Cookies from 'js-cookie';
import BaseAuthService from 'app/modules/session/authServices/baseAuthService';

// Constants
import { AUTH_CONFIG } from 'app/modules/session/auth0Config';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

// Utils
import routes from 'app/shared/utils/routes';
import { clearSessionStorage } from 'app/shared/utils/sessionStorage';
import { heapLogout } from 'app/shared/utils/heap';

// Types
import { RefreshJWTResponse } from 'app/modules/session/responses';

const ONE_MINUTE_IN_SECONDS = 60;
const MILLISECONDS_CONVERSION = 1000;
const MAGIC_LINK_SESSION = 'magic_link_session';

export class Unit21Auth extends BaseAuthService {
  tokenRenewalTimeout;

  tokenPingInterval = -1;

  provider = 'unit21';

  expiresAt = -1;

  hasRefreshedToken = false;

  loadingFlags = false;

  public renewSession = async () => {
    try {
      const response = await fetch(
        `${AUTH_CONFIG.apiBaseUrl}${routes.patronus.agentsRefreshJwt}`,
      );

      if (!response.ok) {
        await this.logout();
        return;
      }

      const data = await response.json();
      this.loginSuccess(data);

      this.hasRefreshedToken = true;
    } catch {
      await this.logout();
    }
  };

  public login = this.renewSession;

  // eslint-disable-next-line class-methods-use-this
  public isAuthenticated = () => {
    return Boolean(Cookies.get(MAGIC_LINK_SESSION));
  };

  public loginSuccess = (authResponse: RefreshJWTResponse) => {
    const {
      jwt,
      jwt_expires_at: jwtExpiresAt,
      refresh_token_expires: refreshTokenExpires,
    } = authResponse;

    if (this.isAuthenticated()) {
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      Cookies.remove(MAGIC_LINK_SESSION);
    }

    localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, jwt);

    Cookies.set(MAGIC_LINK_SESSION, true, {
      expires: new Date(refreshTokenExpires * MILLISECONDS_CONVERSION),
    });

    this.expiresAt = jwtExpiresAt;
    this.scheduleRenewal();
    this.scheduleTokenPing();
    this.scheduleSleepCheck();
  };

  public logout = async (showLogoutModal: boolean = false) => {
    clearTimeout(this.tokenRenewalTimeout);
    clearInterval(this.tokenPingInterval);
    clearInterval(this.sleepCheckInternal);
    clearSessionStorage();

    const accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);
    if (accessToken) {
      try {
        await fetch(`${AUTH_CONFIG.apiBaseUrl}${routes.patronus.logout}`, {
          method: 'POST',
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      } catch {}
    }

    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
    Cookies.remove(MAGIC_LINK_SESSION);

    // Analytics
    heapLogout();

    const params = showLogoutModal ? '?logout=true' : '';
    window.location.replace(`/agent-login${params}`);
  };

  private scheduleRenewal = () => {
    // one min before the toke expires
    const timeout /* seconds */ =
      this.expiresAt - new Date().getTime() / 1000 - ONE_MINUTE_IN_SECONDS;

    if (timeout > 0) {
      if (this.tokenRenewalTimeout) {
        clearTimeout(this.tokenRenewalTimeout);
      }

      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewSession();
      }, timeout * MILLISECONDS_CONVERSION); // *1000 because setTimeout takes milliseconds
    }
  };
}
