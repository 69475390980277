import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { IconArrowRight } from '@u21/tabler-icons';

import { U21Divider } from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

import { SidebarTitle } from 'app/modules/sidebar/components/SidebarTitle';
import { SidebarDescription } from 'app/modules/sidebar/components/SidebarDescription';
import { SidebarFincenSARInfo } from 'app/modules/sidebar/components/SidebarFincenSARInfo';

import { SidebarAssociatedItems } from 'app/modules/sidebar/components/SidebarAssociatedItems';
import { SidebarComments } from 'app/modules/sidebar/components/SidebarComments';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';

// Actions
import { retrieveSidebarSar } from 'app/modules/fincenSar/actions';

// Models
import { SARSidebarDefinition } from 'app/modules/sidebar/models';

// Utils
import { selectSidebarSar } from 'app/modules/fincenSar/selectors';

// Constants
import { REPORT_TYPE_PATH_MAP } from 'app/modules/fincenSar/constants/shared';

// Contexts
import { SidebarStyleContext } from 'app/modules/sidebar/contexts';
import { StatusPill } from 'app/modules/fincenSarNew/components/StatusPill';
import { OldFeatureBanner } from 'app/modules/sidebar/components/GABanner';

interface OwnProps {
  data: SARSidebarDefinition['data'];
}

const FILINGS_SIDEBAR_STYLE = { fieldLabel: { minWidth: '140px' } };

export const SidebarSAR = ({ data }: OwnProps) => {
  const { id: filingID, hideActionButton } = data;
  const dispatch = useDispatch();
  const {
    report_type: reportType,
    comments,
    status,
  } = useSelector(selectSidebarSar);

  useEffect(() => {
    dispatch(
      retrieveSidebarSar({
        id: String(filingID),
      }),
    );
  }, [filingID, dispatch]);

  return (
    <U21SideMenu
      title="Filing Details"
      actionButtonProps={
        hideActionButton
          ? undefined
          : {
              children: "Go to filing's page",
              endIcon: <IconArrowRight />,
              to: REPORT_TYPE_PATH_MAP[reportType].replace(
                ':id',
                String(filingID),
              ),
            }
      }
    >
      <OldFeatureBanner type="FILING_SIDEBAR_REFRESH" />
      <SidebarStyleContext.Provider value={FILINGS_SIDEBAR_STYLE}>
        <SidebarTitle />
        <SidebarDescription />
        <StyledItem style={{ display: 'flex', alignItems: 'center' }}>
          <SidebarFieldLabel>Status</SidebarFieldLabel>
          <StatusPill status={status} />
        </StyledItem>
        <SidebarFincenSARInfo />
        <U21Divider horizontal />
        <SidebarAssociatedItems />
        <U21Divider horizontal />
        <SidebarComments comments={comments} />
      </SidebarStyleContext.Provider>
    </U21SideMenu>
  );
};
