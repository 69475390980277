// Components
import { SidebarTitleIcon } from 'app/modules/sidebar/components/SidebarTitleIcon';
import {
  U21Chip,
  U21ShowMoreList,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Models
import { RuleSummary } from 'app/modules/rules/models';

// Utils
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { IconScale } from '@u21/tabler-icons';

export const SidebarAssociatedRules = ({ rules }: { rules: RuleSummary[] }) => {
  return (
    <div>
      <SidebarTitleIcon
        count={rules.length}
        title="Rules Triggered"
        icon={<IconScale />}
      />
      {rules.length ? (
        <U21ShowMoreList
          displayFunc={(i) => (
            <U21Chip
              key={i.id}
              icon={<IconScale />}
              to={ROUTES_MAP.detectionModelsId.path.replace(
                ':id',
                String(i.id),
              )}
            >
              {i.title}
            </U21Chip>
          )}
          value={rules}
        />
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No rules triggered
        </U21Typography>
      )}
    </div>
  );
};
