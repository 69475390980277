// Models
import { AgentStatus } from 'app/modules/agents/models';

// Responses
import {
  FullAgentResponse,
  NotificationEventGroups,
} from 'app/modules/agents/responses';

export const INITIAL_AGENT_DETAILS: FullAgentResponse = {
  created_at: '',
  email: '',
  full_name: '',
  id: -1,
  org_name: '',
  picture: '',
  status: AgentStatus.INACTIVE,
  is_default_login: true,
  default_org_display_name: '',
  default_org_id: 0,
  is_reviewer: false,
  teams: [],
  tags: [],
  can_impersonate: false,
  org_id: 0,
  recently_merged: undefined,
  teams_permissions: {},
  accessible_queues: [],
};

export const NOTIFICATION_EVENT_GROUP_ORDER = [
  NotificationEventGroups.ALERTS,
  NotificationEventGroups.CASES,
  NotificationEventGroups.SARS,
  NotificationEventGroups.RULES,
  NotificationEventGroups.DEADLINES,
  NotificationEventGroups.GENERAL,
  NotificationEventGroups.GLOBAL_TABLE_SETTINGS,
];

export const NOTIFICATION_EVENT_GROUP_LABELS: Record<
  NotificationEventGroups,
  string
> = {
  [NotificationEventGroups.ALERTS]: 'Alert',
  [NotificationEventGroups.CASES]: 'Case',
  [NotificationEventGroups.DEADLINES]: 'Deadline',
  [NotificationEventGroups.GENERAL]: 'General',
  [NotificationEventGroups.MISC]: 'Miscellaneous',
  [NotificationEventGroups.RULES]: 'Rule',
  [NotificationEventGroups.SARS]: 'SAR',
  [NotificationEventGroups.GLOBAL_TABLE_SETTINGS]: 'Global Table Settings',
};

export const MAX_PROFILE_PIC_SIZE = 5 * 1024 * 1024;
