import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

// Components
import { IconClipboardList } from '@u21/tabler-icons';

import { U21ContentDisplay, U21MenuButton } from 'app/shared/u21-ui/components';
import { U21QueueSelect } from 'app/shared/u21-ui/components/dashboard';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Selectors
import {
  selectInvestigation,
  selectCanRequeueInvestigations,
  selectEscalateInvestigationsLoading,
} from 'app/modules/investigations/selectors';

// Actions
import { requeueInvestigationsThunk } from 'app/modules/investigations/sliceInvestigations';
import { retrieveAlertDetails } from 'app/modules/alerts/actions';
import { retrieveCase } from 'app/modules/casesOld/actions';

import { INVESTIGATION_TYPE_TO_QUEUE_TYPE } from 'app/modules/queues/constants';

interface OwnProps {
  type: InvestigationType;
  sidebar?: boolean;
}

export const Requeue = ({ type, sidebar = false }: OwnProps) => {
  const dispatch = useDispatch();

  const investigation = useSelector(selectInvestigation(type, sidebar));
  const escalationLoading = useSelector(
    selectEscalateInvestigationsLoading(type),
  );
  const canViewRequeueButton = useSelector(
    selectCanRequeueInvestigations(type),
  );

  const handleRequeue = (queueId: number) => {
    dispatch(
      requeueInvestigationsThunk({
        payload: {
          investigation_queue_id: queueId,
          object_ids: [investigation.id],
          object_type: type,
        },
        onSuccess: () => {
          if (type === InvestigationType.ALERT) {
            dispatch(retrieveAlertDetails(investigation.id));
          } else {
            dispatch(retrieveCase(investigation.id));
          }
        },
      }),
    );
  };

  const queueType = INVESTIGATION_TYPE_TO_QUEUE_TYPE[type];

  const requeueButton = canViewRequeueButton ? (
    <div>
      <U21MenuButton
        buttonProps={{
          startIcon: <IconClipboardList />,
          color: 'primary',
          disabled: escalationLoading,
        }}
        disabled={
          investigation.status === 'CLOSED' ||
          investigation.status === 'IN_REVIEW'
        }
        items={[
          <SearchContainer key="queueSelection">
            <StyledU21QueueSelect
              autoFocus
              onChange={handleRequeue}
              queueType={queueType}
            />
          </SearchContainer>,
        ]}
      >
        Change Queue
      </U21MenuButton>
    </div>
  ) : null;

  return (
    <>
      <U21ContentDisplay title="Queue">
        {investigation['queue.title'] || 'No queue'}
      </U21ContentDisplay>
      {requeueButton}
    </>
  );
};

const SearchContainer = styled.div`
  padding: 8px;
`;

const StyledU21QueueSelect = styled(U21QueueSelect)`
  width: 300px;
`;
