import { createSelector } from 'reselect';
import { selectBlacklists } from 'app/modules/blacklists/selectors';
import { cloneDeep, get } from 'lodash';

// Selectors
import { selectQueryBuilderConfig } from 'app/modules/orgSettings/selectors';

// Models
import { RulesActionTypes, ScenarioConstants } from 'app/modules/rules/models';
import { DropdownItemProps } from 'semantic-ui-react';

// Helpers
import {
  getFieldNamesFromKey,
  ONLY_USERS_FIELD_KEY,
} from 'app/modules/rules/helpers';
import { combineRootActionWithId } from 'app/shared/helpers';

// Constants
import { AnyObjectType } from 'app/shared/models';
import { DetectionModel } from 'app/modules/detectionModels/models';

export const selectRules = (state: RootState) => {
  return (
    state.rules.paginatedRules || {
      rules: [],
      count: 0,
    }
  );
};

export const selectComponentRules = (state: RootState) => {
  return state.rules.componentRules;
};

export const selectPaginatedAlerts = (state: RootState) => {
  return state.rules.paginatedAlerts;
};

export const selectAlertById = (state: RootState, id: string) => {
  return state.rules.alertsById[id] || {};
};

export const selectValidationRules = (state: RootState) => {
  return state.rules.validationRules;
};

export const selectEditingRule = (state: RootState) => {
  return (
    state.rules.editingRule || {
      parameters: {},
    }
  );
};

const selectedBlacklistType = createSelector(
  selectEditingRule,
  selectBlacklists,
  (editingRule, blacklists) => {
    const blacklistId = editingRule.parameters[ScenarioConstants.BLACKLIST_ID];
    return blacklists.find((blacklist) => blacklist.id === blacklistId)?.type;
  },
);

export const selectRuleValidationFileExports = (state: RootState) =>
  state.rules.validationRuleFileExports || {
    file_exports: [],
    count: 0,
  };

export const selectWhitelistedEntities = (state: RootState) =>
  state.rules.currentRule?.paginated_whitelisted_entities || {
    whitelisted_entities: [],
    count: 0,
  };

export const selectGlobalWhitelistedEntities = (state: RootState) =>
  state.rules.currentRule?.paginated_global_whitelisted_entities || {
    whitelisted_entities: [],
    count: 0,
  };

function isBaseForAmbiguousModel(tableName: string): string | undefined {
  const basesForAmbiguousModels = [
    'txn_instrument',
    'entity',
    'txn_event',
    'action_event',
  ];
  return basesForAmbiguousModels.find((model) => tableName.includes(model));
}

export function getEmbeddedFilterFields(
  qbConfigFields: AnyObjectType,
  fieldName: string,
  tableName: string,
  baseTable: string | undefined,
  tableFieldName: string | undefined,
) {
  const field = cloneDeep(get(qbConfigFields, fieldName) || {});
  const baseForAmbiguousModel = baseTable || isBaseForAmbiguousModel(tableName);
  if ((field.ambiguous === true || baseTable) && baseForAmbiguousModel) {
    const newSubfields = {
      [baseForAmbiguousModel]: field.subfields[baseForAmbiguousModel],
    };
    field.subfields = newSubfields;
  }
  // Filtering out any fields that aren't on the entity table as we are
  // doing raw sql queries where we can't use the ORM attributes
  if (tableFieldName === ONLY_USERS_FIELD_KEY) {
    const entityTableColumns = new Set([
      'created_at',
      'updated_at',
      'status',
      'registered_at',
      'first_name',
      'last_name',
      'date_of_birth',
      'ssn',
      'gender',
      'name',
      'type',
    ]);
    const fieldsToReturn = Object.keys(field?.subfields ?? {}).filter(
      (key) => entityTableColumns.has(key) || key.startsWith('custom_data'),
    );
    const subfields = {};
    for (const key of fieldsToReturn) {
      subfields[key] = field.subfields[key];
    }
    field.subfields = subfields;
  }
  return field;
}

export const selectEmbeddedFilterFieldConfig = (
  state: RootState,
  fieldKey: string,
  isSequenceRule: boolean, // TODO: remove once sequence scenarios support other tables in embedded filters
  baseTable: string | undefined,
  rule: DetectionModel,
) => {
  const qbConfig = rule.override_qbc || selectQueryBuilderConfig(state);
  const blacklistType = selectedBlacklistType(state);
  const fieldNames = getFieldNamesFromKey(fieldKey, blacklistType);
  const tableAndFieldName = fieldKey.replace(/_\d+$/, '');
  const [tableName, tableFieldName] = tableAndFieldName.split('.');
  const ambiguousFields = [
    'ip_address',
    'client_fingerprint',
    'address',
    'geolocation',
  ];
  if (
    fieldKey !== `entity.${ONLY_USERS_FIELD_KEY}` &&
    isBaseForAmbiguousModel(tableName) &&
    !isSequenceRule
  ) {
    fieldNames.push(...ambiguousFields);
  }

  const mappedFields = fieldNames.map((fieldName) => ({
    [fieldName]: getEmbeddedFilterFields(
      qbConfig.fields,
      fieldName,
      tableName,
      baseTable,
      tableFieldName,
    ),
  }));
  return Object.assign({}, ...mappedFields);
};

export const selectRulesLoading = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.RETRIEVE_RULES]);

export const selectRuleValidationFileExportLoading = (state: RootState) =>
  Boolean(
    state.loading[RulesActionTypes.RETRIEVE_RULE_VALIDATION_FILE_EXPORTS],
  );

export const selectRetrieveValidationRulesLoading = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.RETRIEVE_VALIDATION_RULES]);

export const selectRetrievePaginatedAlertsLoading = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.RETRIEVE_PAGINATED_ALERTS]);

export const selectAlertLoading = (state: RootState, id: string) => {
  // for complex loading state
  const formattedAction = combineRootActionWithId(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT,
    id,
  );
  return Boolean(state.loading[formattedAction]);
};

export const selectRetrieveValidationRuleAlertEntitiesLoading = (
  state: RootState,
  id: string,
) => {
  // for complex loading state
  const formattedAction = combineRootActionWithId(
    RulesActionTypes.RETRIEVE_RULE_VALIDATION_ALERT_ENTITIES,
    id,
  );
  return Boolean(state.loading[formattedAction]);
};

export const selectRetrieveWhitelistedEntitiesLoading = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.RETRIEVE_WHITELISTED_ENTITIES]);

export const selectWhitelistEntitiesForRuleLoading = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.WHITELIST_ENTITIES_FOR_RULE]);

export const selectRemoveWhitelistEntitiesForRuleLoading = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.REMOVE_WHITELIST_ENTITIES_FOR_RULE]);

export const selectLaunchingRule = (state: RootState) =>
  Boolean(state.loading[RulesActionTypes.DEPLOY_SHADOW_RULE]);

export const selectRulesDropdownOptions = createSelector(
  selectComponentRules,
  (rules): DropdownItemProps[] => {
    const formattedOptions = [] as DropdownItemProps[];
    rules.rules.forEach((rule) =>
      formattedOptions.push({
        key: rule.id,
        text: `#${rule.id} - ${rule.title}`,
        value: rule.id,
      }),
    );
    return formattedOptions;
  },
);
