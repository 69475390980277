// Components
import { PriorActivity } from 'app/modules/alerts/components/AlertDetails/PriorActivity';
import { TransactionActivity } from 'app/modules/alerts/components/AlertDetails/TransactionActivity';
import { AlertScore } from 'app/modules/alertScore/components/AlertScore';
import { AlertScoreTabLabel } from 'app/modules/alertScore/components/AlertScoreTabLabel';

// Types
import {
  AlertDetailsTab,
  AlertType,
  AdditionalFilters,
} from 'app/modules/alerts/types';
import { ALL_TYPES } from 'app/modules/alerts/components/AlertDetails/Tabs/constants';

// Models
import { FeatureFlag } from 'app/shared/featureFlags/models';
import { ALERT_SCORE_TAB } from 'app/modules/alertScore/constants';
import { TxnAnalysisTab } from 'app/modules/txnAnalysis/components/TxnAnalysisTab';
import { TxnAnalysisType } from 'app/modules/txnAnalysis/constants';
import { U21NewFeatureLabel, U21Spacer } from 'app/shared/u21-ui/components';

export const transactionAlertTabs: AlertDetailsTab[] = [
  {
    label: 'Prior Activity',
    path: 'prior-activity',
    component: <PriorActivity />,
    visibleOn: {
      types: [
        AlertType.TM,
        AlertType.CAR,
        AlertType.MANUAL,
        AlertType.KYC,
        AlertType.WATCHLIST,
      ],
      filters: [AdditionalFilters.INTUIT],
    },
  },
  {
    label: (
      <U21Spacer key="transaction-analysis" horizontal>
        <span>Transaction Analysis</span>
        <U21NewFeatureLabel />
      </U21Spacer>
    ),

    path: 'txn-analysis',
    component: <TxnAnalysisTab type={TxnAnalysisType.ALERT} />,
    hidden: false,
    visibleOn: {
      filters: [AdditionalFilters.INTUIT],
      types: [
        AlertType.TM,
        AlertType.CAR,
        AlertType.MANUAL,
        AlertType.WATCHLIST,
      ],
    },
  },
  {
    label: 'Transaction Activity',
    path: 'transaction-activity',
    component: <TransactionActivity />,
    visibleOn: {
      types: [AlertType.TM, AlertType.CAR, AlertType.MANUAL],
      filters: [AdditionalFilters.INTUIT],
    },
  },
  {
    label: <AlertScoreTabLabel />,
    labelString: 'Alert Score',
    path: ALERT_SCORE_TAB,
    component: <AlertScore />,
    visibleOn: {
      types: ALL_TYPES,
      flags: [FeatureFlag.ALERT_SCORE],
    },
  },
];
