import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ShortTxnResponse } from 'app/modules/transactions/types';

import { getTagDisplayName } from 'app/modules/tags/utils';

import { IconBriefcase, IconFlag, IconTag } from '@u21/tabler-icons';
import {
  U21TableBodyCellProps,
  U21TableColumn,
  U21Tooltip,
  U21Spacer,
  U21ShowMoreList,
  U21NoValue,
} from 'app/shared/u21-ui/components';
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { TxnDocumentChipList } from 'app/modules/transactions/components/TxnDocumentChipList';
import styled from 'styled-components';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { getDataSettingIdsByFlow } from 'app/modules/transactions/utils';
import { FormattedData } from 'app/modules/dataSettings/responses';
import { TransactionRiskScore } from 'app/modules/transactionScore/components/TransactionRiskScore';

export type TransactionTableColumn = U21TableColumn<ShortTxnResponse, any>;

const IP_ADDRESS_COLUMN: TransactionTableColumn = {
  id: 'ip_address.ip_address',
  accessor: (row) => row['ip_address.ip_address'],
};

const RECEIVER_INSTRUMENT_TYPE: TransactionTableColumn = {
  id: 'receiver_instrument.instrument_type',
  accessor: (row) => row['receiver_instrument.instrument_type'],
};

export const FlowColumnChip = ({
  formattedData,
  id,
}: {
  formattedData: FormattedData;
  id: string;
}) => {
  const dataSetting = useSelector(selectDataSettingsById)[id];
  return (
    <DataSettingDataDisplay
      key={id}
      formattedData={formattedData}
      dataSetting={dataSetting}
    />
  );
};

export const OUTBOUND_COLUMN: TransactionTableColumn = {
  Header: 'Sending',
  id: 'outbound_flow',
  Cell: (props: U21TableBodyCellProps<ShortTxnResponse, string>) => {
    const { row } = props;
    const rowData = getDataSettingIdsByFlow(row.formatted_data, 'OUTBOUND');
    return (
      <StyledFlowMoreList
        displayFunc={(id) => {
          return (
            <FlowColumnChip
              key={id}
              formattedData={row.formatted_data}
              id={id}
            />
          );
        }}
        onLessClick={(e) => e.stopPropagation()}
        onMoreClick={(e) => e.stopPropagation()}
        value={rowData}
      />
    );
  },
};

export const INBOUND_COLUMN: TransactionTableColumn = {
  Header: 'Receiving',
  id: 'inbound_flow',
  Cell: (props: U21TableBodyCellProps<ShortTxnResponse, string>) => {
    const { row } = props;
    const rowData = getDataSettingIdsByFlow(row.formatted_data, 'INBOUND');
    return (
      <StyledFlowMoreList
        displayFunc={(id) => {
          return (
            <FlowColumnChip
              key={id}
              formattedData={row.formatted_data}
              id={id}
            />
          );
        }}
        onLessClick={(e) => e.stopPropagation()}
        onMoreClick={(e) => e.stopPropagation()}
        value={rowData}
      />
    );
  },
};

export const OTHER_FLOW_COLUMN: TransactionTableColumn = {
  Header: 'Other directionality',
  id: 'other_flow',
  Cell: (props: U21TableBodyCellProps<ShortTxnResponse, string>) => {
    const { row } = props;
    const rowData = getDataSettingIdsByFlow(row.formatted_data, 'OTHER');

    return (
      <StyledFlowMoreList
        displayFunc={(id) => {
          return (
            <FlowColumnChip
              key={id}
              formattedData={row.formatted_data}
              id={id}
            />
          );
        }}
        onLessClick={(e) => e.stopPropagation()}
        onMoreClick={(e) => e.stopPropagation()}
        value={rowData}
      />
    );
  },
};

const SENDER_INSTRUMENT_TYPE: TransactionTableColumn = {
  id: 'sender_instrument.instrument_type',
  accessor: (row) => row['sender_instrument.instrument_type'],
};

const TAGS_COLUMN: TransactionTableColumn = {
  id: 'tags',
  accessor: (row) => row.tags.map((tag) => getTagDisplayName(tag)),
  cellProps: {
    getShowLessText: () => 'Show fewer tags',
    getShowMoreText: (length: number) => `Show all ${length} tags`,
  },
  headerIcon: <IconTag />,
  type: 'list',
  disableSortBy: true,
};

export const ASSOCIATED_LINKS: TransactionTableColumn = {
  id: 'associated_links',
  Header: 'Associated links',
  accessor: (row) => (
    <U21Spacer horizontal>
      {row.has_alerts && (
        <U21Tooltip tooltip="This transaction has been flagged in an alert">
          <IconFlag />
        </U21Tooltip>
      )}
      {row.in_case && (
        <U21Tooltip tooltip="This transaction is associated with the current case">
          <IconBriefcase />
        </U21Tooltip>
      )}
    </U21Spacer>
  ),
  disableSortBy: true,
};

const DOCUMENT_COLUMN: TransactionTableColumn = {
  id: 'documents',
  disableSortBy: true,
  Cell: (
    props: U21TableBodyCellProps<ShortTxnResponse, FullAttachmentResponse[]>,
  ) => {
    const { row } = props;
    if (isEmpty(row.documents)) {
      return <U21NoValue />;
    }
    return <TxnDocumentChipList txnEvent={row} />;
  },
};

const U21_RISK_SCORE_COLUMN: TransactionTableColumn = {
  id: 'u21_risk_score',
  Cell: (
    props: U21TableBodyCellProps<ShortTxnResponse, FullAttachmentResponse[]>,
  ) => {
    const { row } = props;
    if (row.u21_risk_score === null) {
      return <U21NoValue />;
    }
    return (
      <TransactionRiskScore
        eventID={row.external_id}
        score={row.u21_risk_score}
      />
    );
  },
};

export const TRANSACTION_COLUMN_CONFIG = {
  [U21_RISK_SCORE_COLUMN.id]: U21_RISK_SCORE_COLUMN,
  [DOCUMENT_COLUMN.id]: DOCUMENT_COLUMN,
  [IP_ADDRESS_COLUMN.id]: IP_ADDRESS_COLUMN,
  [RECEIVER_INSTRUMENT_TYPE.id]: RECEIVER_INSTRUMENT_TYPE,
  [SENDER_INSTRUMENT_TYPE.id]: SENDER_INSTRUMENT_TYPE,
  [TAGS_COLUMN.id]: TAGS_COLUMN,
  [OUTBOUND_COLUMN.id]: OUTBOUND_COLUMN,
  [INBOUND_COLUMN.id]: INBOUND_COLUMN,
  [OTHER_FLOW_COLUMN.id]: OTHER_FLOW_COLUMN,
};

const StyledFlowMoreList = styled(U21ShowMoreList<string>)`
  width: 100%;
`;
