// Models
import {
  SessionAgent,
  SessionActionTypes,
  AuthClient,
  EmailTokenExchangeType,
  SessionRelevantOrgs,
} from 'app/modules/session/models';
import { OrgsActionTypes } from 'app/modules/orgs/models';
import { PermissionSummary } from 'app/modules/permissions/models';

// Utils
import makeActions from 'app/shared/sagas/make-actions';
import { ActionsUnion, createAction } from 'app/shared/utils/action-helpers';
import { OrgSettingsResponse } from 'app/modules/orgSettings/responses';

export const startSession = (payload: AuthClient) =>
  createAction(SessionActionTypes.START_SESSION, payload);

export const startSessionError = () =>
  createAction(SessionActionTypes.START_SESSION_ERROR);

export const startSessionSuccess = (payload: SessionAgent) =>
  createAction(SessionActionTypes.START_SESSION_SUCCESS, payload);

export const tokenPing = () => createAction(SessionActionTypes.TOKEN_PING);
export const tokenPingSuccess = (result: boolean) => {
  return createAction(SessionActionTypes.TOKEN_PING_SUCCESS, result);
};

export const setLastRequestDate = (payload: Date) => {
  return createAction(SessionActionTypes.SET_LAST_REQUEST_DATE, payload);
};

const retrieveIDPActions = makeActions(SessionActionTypes.RETRIEVE_IDP);
export const retrieveIDP = retrieveIDPActions.root;
export const retrieveIDPPending = retrieveIDPActions.pending;
export const retrieveIDPSuccess = (payload) =>
  createAction(SessionActionTypes.RETRIEVE_IDP_SUCCESS, payload);
export const retrieveIDPError = retrieveIDPActions.error;

export const retrieveActionTriggers = () =>
  createAction(SessionActionTypes.RETRIEVE_ACTION_TRIGGERS);

export const retrieveActionTriggersSuccess = (
  result, // type me
) => createAction(SessionActionTypes.RETRIEVE_ACTION_TRIGGERS_SUCCESS, result);

export const retrieveRelevantOrgs = () => {
  return createAction(OrgsActionTypes.RETRIEVE_RELEVANT_ORGS);
};
export const retrieveRelevantOrgsSuccess = (payload: SessionRelevantOrgs) => {
  return createAction(OrgsActionTypes.RETRIEVE_RELEVANT_ORGS_SUCCESS, payload);
};

export const setOrgSarsSettings = (settings) =>
  createAction(SessionActionTypes.SET_ORG_SARS_SETTINGS, settings);

export const setOrgSarsSettingsPending = () =>
  createAction(SessionActionTypes.SET_ORG_SARS_SETTINGS_PENDING);

export const setOrgSarsSettingsError = () =>
  createAction(SessionActionTypes.SET_ORG_SARS_SETTINGS_ERROR);

export const setOrgSarsSettingsSuccess = (result: OrgSettingsResponse) =>
  createAction(SessionActionTypes.SET_ORG_SARS_SETTINGS_SUCCESS, result);

export const setSessionAgentPermissions = (payload: PermissionSummary[]) =>
  createAction(SessionActionTypes.SET_SESSION_AGENT_PERMISSIONS, payload);

export const initIntercom = () =>
  createAction(SessionActionTypes.INIT_INTERCOM);

export const sendAuthEmail = (email: string) =>
  createAction(SessionActionTypes.SEND_AUTH_EMAIL, email);

export const emailTokenExchange = (payload: EmailTokenExchangeType) =>
  createAction(SessionActionTypes.EMAIL_TOKEN_EXCHANGE, payload);

export const authEmailSendSuccess = () =>
  createAction(SessionActionTypes.UPDATE_AUTH_EMAIL_SENT, true);

export const updateSessionAgentNameSuccess = (name: string) =>
  createAction(SessionActionTypes.UPDATE_SESSION_AGENT_NAME_SUCCESS, name);

export const updateSessionAgentPictureSuccess = (picture: string) =>
  createAction(
    SessionActionTypes.UPDATE_SESSION_AGENT_PICTURE_SUCCESS,
    picture,
  );

const SessionActionsList = {
  startSession,
  startSessionSuccess,
  retrieveIDPSuccess,
  retrieveRelevantOrgs,
  retrieveRelevantOrgsSuccess,
  setOrgSarsSettings,
  setOrgSarsSettingsPending,
  setOrgSarsSettingsError,
  setSessionAgentPermissions,
  tokenPing,
  tokenPingSuccess,
  retrieveActionTriggers,
  retrieveActionTriggersSuccess,
  setLastRequestDate,
  initIntercom,
  sendAuthEmail,
  emailTokenExchange,
  authEmailSendSuccess,
  updateSessionAgentNameSuccess,
  updateSessionAgentPictureSuccess,
};
type SessionActions = ActionsUnion<typeof SessionActionsList>;

export default SessionActions;
