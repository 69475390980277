import { ReportType } from 'app/modules/fincenSar/models';
import { SyntheticEvent } from 'react';

import { REPORT_TYPE_DISPLAY_TEXT_MAP } from 'app/modules/fincenSar/constants/shared';

import { selectFincenCtrFilingEnabled } from 'app/shared/featureFlags/selectors';
import { useSelector } from 'react-redux';

import { U21Select } from 'app/shared/u21-ui/components';

interface Props {
  onChange: (value: string | undefined, e: SyntheticEvent) => void;
  value: string | undefined;
}

export const FilingTypeFilter = (props: Props) => {
  const { onChange, value } = props;
  const hasFincenCtrEnabled = useSelector(selectFincenCtrFilingEnabled);
  return (
    <U21Select
      backdrop
      onChange={onChange}
      options={[
        {
          text: REPORT_TYPE_DISPLAY_TEXT_MAP[ReportType.FINCEN_SAR],
          value: ReportType.FINCEN_SAR,
        },
        {
          text: REPORT_TYPE_DISPLAY_TEXT_MAP[ReportType.GOAML_STR],
          value: ReportType.GOAML_STR,
        },
        ...(hasFincenCtrEnabled
          ? [
              {
                text: REPORT_TYPE_DISPLAY_TEXT_MAP[ReportType.FINCEN_CTR],
                value: ReportType.FINCEN_CTR,
              },
            ]
          : []),
      ]}
      value={value}
    />
  );
};
