import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  triggerAlertAction as TriggerAlertActionAction,
  retrieveAlertAuditTrail,
  triggerAlertActionSuccess,
} from 'app/modules/alerts/actions';
import { retrieveActionTriggers } from 'app/modules/session/actions';

// API
import { triggerAction as triggerActionApi } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.TRIGGER_ALERT_ACTION;
function* triggerAlertAction({
  payload,
}: ReturnType<typeof TriggerAlertActionAction>) {
  const config = {
    rootAction,
    request: call(triggerActionApi, payload),
    errorToastMessage: 'Alert Action Failed',
    successToastMessage: `Alert action submitted successfully`,
    success: function* onSuccess(result) {
      yield put(retrieveAlertAuditTrail(result.id));
      yield put(triggerAlertActionSuccess(result));
    },
    error: function* onError() {
      // if we have a button error reload the buttons on the assumption that state got out of sync
      yield put(retrieveActionTriggers());
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchTriggerAlertAction() {
  yield takeLatest(rootAction, triggerAlertAction);
}
