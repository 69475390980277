import { useSelector } from 'react-redux';

// Components
import { SidebarLabelValue } from 'app/modules/sidebar/components/SidebarLabelValue';

// Selectors
import { selectSidebarObject } from 'app/modules/sidebar/selectors';

export const SidebarDescription = () => {
  const sidebarObject = useSelector(selectSidebarObject);
  const descriptionString = sidebarObject.description || 'No description';

  return <SidebarLabelValue label="Description" value={descriptionString} />;
};
