import { AlertSidebarDefinition } from 'app/modules/sidebar/models';
import { useGetAlert } from 'app/modules/alerts/queries/useGetAlert';
import {
  IconArrowRight,
  IconClipboardList,
  IconFlag,
  IconScale,
  IconTag,
} from '@u21/tabler-icons';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  U21QueueSelect,
  U21SideMenu,
} from 'app/shared/u21-ui/components/dashboard';
import {
  U21Button,
  U21Chip,
  U21ContentDisplay,
  U21HelpTooltip,
  U21MenuButton,
  U21NoData,
  U21ShowMoreList,
  U21Spacer,
  U21Subsection,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { InvestigationType } from 'app/modules/investigations/models';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanReassignInvestigations,
  selectCanRequeueInvestigations,
  selectReassignInvestigationsLoading,
  selectRequeueInvestigationsLoading,
} from 'app/modules/investigations/selectors';
import { IconFlagArrow } from 'app/shared/components/Icons/IconFlagArrow';
import { ReassignAgentMenuItem } from 'app/modules/alerts/components/ReassignAgentMenuItem';
import {
  reassignInvestigationsThunk,
  requeueInvestigationsThunk,
} from 'app/modules/investigations/sliceInvestigations';
import { useQueryClient } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import styled from 'styled-components';
import { QueueType } from 'app/modules/queues/models';
import { AlertSource, AlertType } from 'app/modules/alerts/types';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import {
  ALERT_SCORE_TOOLTIP_NO_RISK,
  ALERT_SCORE_TOOLTIP_RISK,
} from 'app/modules/alerts/constants';
import { AlertScoreLabel } from 'app/modules/investigations/components/SummaryCard/AlertScoreLabel';
import { formatDatetime } from 'app/shared/utils/date';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';
import { selectHasReadRulesPermission } from 'app/modules/session/selectors';
import { useGetAlertTransactions } from 'app/modules/alerts/queries/useGetAlertTransactions';
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { SidebarCustomDataNew } from 'app/modules/sidebar/components/SidebarCustomDataNew';
import { SidebarAssociatedEntities } from 'app/modules/sidebar/components/SidebarAssociatedEntities';
import { useGetAlertEntities } from 'app/modules/alerts/queries/useGetAlertEntities';
import { SidebarAssociatedTransactions } from 'app/modules/sidebar/components/SidebarAssociatedTransactions';
import { SidebarAssociatedActionEvents } from 'app/modules/sidebar/components/SidebarAssociatedActionEvents';
import { useGetAlertActionEvents } from 'app/modules/alerts/queries/useGetAlertActionEvents';
import { getTagDisplayName } from 'app/modules/tags/utils';
import { DispositionChip } from 'app/modules/investigations/components/DispositionChip';
import { StatusChip } from 'app/modules/investigations/components/StatusChip';
import {
  GAToggleButton,
  NewFeatureBanner,
} from 'app/modules/sidebar/components/GABanner';
import { ArticleDeadlineChip } from 'app/modules/deadlines/components/ArticleDeadlineChip';
import { retrieveAlertDetails } from 'app/modules/alerts/actions';

interface Props {
  data: AlertSidebarDefinition['data'];
}

export const SidebarAlertRefresh = (props: Props) => {
  const {
    data: {
      alert: { id },
      hideActionButton,
    },
  } = props;

  const dispatch = useDispatch();
  const formatAmount = useSelector(selectFormatAmount);
  const reassignLoading = useSelector(
    selectReassignInvestigationsLoading(InvestigationType.ALERT),
  );
  const requeueLoading = useSelector(
    selectRequeueInvestigationsLoading(InvestigationType.ALERT),
  );
  const canReassignAlerts = useSelector(
    selectCanReassignInvestigations(InvestigationType.ALERT),
  );
  const canRequeueAlerts = useSelector(
    selectCanRequeueInvestigations(InvestigationType.ALERT),
  );
  const hasReadRulesPermission = useSelector(selectHasReadRulesPermission);

  const queryClient = useQueryClient();
  const { alert, isError, isLoading, refetch } = useGetAlert(id);

  const {
    data: { count: entitiesCount, entities } = { count: 0, entities: [] },
  } = useGetAlertEntities({
    alertId: String(id),
    ...DEFAULT_PAGINATION_PAYLOAD,
    sort_column: 'created_at',
    sort_direction: 'descending',
  });

  const { data: transactions = [] } = useGetAlertTransactions(id, {
    ...DEFAULT_PAGINATION_PAYLOAD,
    sort_column: 'event_time',
    sort_direction: 'descending',
  });

  const {
    data: { count: actionEventsCount, events: actionEvents } = {
      count: 0,
      events: [],
    },
  } = useGetAlertActionEvents(id, {
    ...DEFAULT_PAGINATION_PAYLOAD,
    sort_column: 'event_time',
    sort_direction: 'descending',
  });

  return (
    <U21SideMenu
      actionButtonProps={
        hideActionButton
          ? undefined
          : {
              children: 'Go to Alert Page',
              endIcon: <IconArrowRight />,
              to: ROUTES_MAP.alertsId.path.replace(':id', String(id)),
            }
      }
      loading={isLoading}
      noPadding
      title={
        <U21Spacer horizontal>
          <U21Typography variant="h4">Alert Details</U21Typography>
          <GAToggleButton type="ALERT_SIDEBAR_REFRESH" />
        </U21Spacer>
      }
    >
      <NewFeatureBanner type="ALERT_SIDEBAR_REFRESH" />
      {(() => {
        // loading handled by U21SideMenu
        if (isLoading) {
          return null;
        }
        if (isError) {
          return (
            <U21NoData>
              Failed to load alert. Please{' '}
              <U21Button
                color="primary"
                onClick={() => refetch()}
                variant="text"
              >
                try loading
              </U21Button>{' '}
              again.
            </U21NoData>
          );
        }

        if (!alert) {
          return null;
        }

        return (
          <U21Spacer spacing={0.5}>
            <EscalationContainer>
              <U21ContentDisplay title="Assigned to">
                {alert.assigned_to_name || 'Unassigned'}
              </U21ContentDisplay>
              {canReassignAlerts && (
                <div>
                  <U21MenuButton
                    buttonProps={{
                      startIcon: <IconFlagArrow />,
                      color: 'primary',
                      loading: reassignLoading,
                    }}
                    items={[
                      <ReassignAgentMenuItem
                        key="reassign"
                        onChange={(agentID: number) =>
                          dispatch(
                            reassignInvestigationsThunk({
                              payload: {
                                agent_id: agentID,
                                object_ids: [id],
                                object_type: InvestigationType.ALERT,
                              },
                              onSuccess: () => {
                                // retrieve latest alert info to update the table data w/ new assignee
                                dispatch(retrieveAlertDetails(id));
                                queryClient.invalidateQueries({
                                  queryKey: ALERT_QUERY_KEYS.getAlert(id),
                                });
                              },
                            }),
                          )
                        }
                      />,
                    ]}
                  >
                    Re-Assign
                  </U21MenuButton>
                </div>
              )}
              <U21ContentDisplay title="Queue">
                {alert['queue.title'] || 'No queue'}
              </U21ContentDisplay>
              {canRequeueAlerts && (
                <div>
                  <U21MenuButton
                    buttonProps={{
                      startIcon: <IconClipboardList />,
                      color: 'primary',
                      loading: requeueLoading,
                      disabled:
                        alert.status === 'CLOSED' ||
                        alert.status === 'IN_REVIEW',
                    }}
                    items={[
                      <SearchContainer key="queueSelection">
                        <StyledU21QueueSelect
                          autoFocus
                          onChange={(queueId: number) => {
                            dispatch(
                              requeueInvestigationsThunk({
                                payload: {
                                  investigation_queue_id: queueId,
                                  object_ids: [id],
                                  object_type: InvestigationType.ALERT,
                                },
                                onSuccess: () => {
                                  // retrieve latest alert info to update the table data w/ new queue
                                  dispatch(retrieveAlertDetails(id));
                                  queryClient.invalidateQueries({
                                    queryKey: ALERT_QUERY_KEYS.getAlert(id),
                                  });
                                },
                              }),
                            );
                          }}
                          queueType={QueueType.ALERT_QUEUE}
                        />
                      </SearchContainer>,
                    ]}
                  >
                    Change Queue
                  </U21MenuButton>
                </div>
              )}
            </EscalationContainer>
            <U21Subsection
              shaded
              title={<Span>{alert.title}</Span>}
              titleIcon={<IconFlag />}
            >
              <SidebarDataRow
                label="Description"
                showEmptyFields
                value={alert.description}
              />
              <SidebarDataRow
                label="Status"
                showEmptyFields
                value={<StatusChip status={alert.status} />}
              />
              <SidebarDataRow
                label="Deadline"
                showEmptyFields
                value={
                  alert.status === 'OPEN' ? (
                    <ArticleDeadlineChip
                      deadline={alert.deadline}
                      deadlineConfig={alert.deadline_config}
                      onEditSuccess={() => {
                        queryClient.invalidateQueries({
                          queryKey: ALERT_QUERY_KEYS.getAlert(id),
                        });
                      }}
                    />
                  ) : null
                }
              />
              {alert.alert_score && (
                <SidebarDataRow
                  label="Alert score"
                  showEmptyFields
                  value={
                    <U21Spacer horizontal>
                      <AlertScoreLabel alertScore={alert.alert_score} />
                      <U21HelpTooltip
                        help={
                          alert.alert_score.risk
                            ? ALERT_SCORE_TOOLTIP_RISK
                            : ALERT_SCORE_TOOLTIP_NO_RISK
                        }
                        tooltipProps={{ placement: 'top' }}
                      />
                    </U21Spacer>
                  }
                />
              )}
              <SidebarDataRow
                label="Disposition"
                showEmptyFields
                value={
                  alert.disposition ? (
                    <DispositionChip disposition={alert.disposition} />
                  ) : null
                }
              />
              <SidebarDataRow
                label="Flagged at"
                showEmptyFields
                value={formatDatetime(alert.created_at)}
              />
              {transactions.length > 0 && (
                <SidebarDataRow
                  label="Flagged amount"
                  showEmptyFields
                  value={formatAmount({
                    amount: alert.amount_volume,
                    currencyCodeProps: alert.currency,
                  })}
                />
              )}
              <SidebarDataRow
                label="Rules triggered"
                showEmptyFields
                value={
                  alert.rules.length ? (
                    <U21ShowMoreList
                      displayFunc={(i) => (
                        <U21Chip
                          key={i.id}
                          icon={<IconScale />}
                          to={
                            hasReadRulesPermission
                              ? ROUTES_MAP.detectionModelsId.path.replace(
                                  ':id',
                                  String(i.id),
                                )
                              : undefined
                          }
                        >
                          {i.title}
                        </U21Chip>
                      )}
                      value={alert.rules}
                    />
                  ) : null
                }
              />
              <SidebarDataRow
                label="Tags"
                showEmptyFields
                value={
                  alert.tags.length ? (
                    <U21ShowMoreList
                      displayFunc={(i) => (
                        <U21Chip key={i.id} icon={<IconTag />}>
                          {getTagDisplayName(i)}
                        </U21Chip>
                      )}
                      value={alert.tags}
                    />
                  ) : null
                }
              />
            </U21Subsection>
            {(alert.alert_type === AlertType.MANUAL ||
              alert.object_source === AlertSource.EXTERNAL) && (
              <SidebarCustomDataNew data={alert.formatted_data} />
            )}
            <SidebarAssociatedEntities
              count={entitiesCount}
              entities={entities}
            />
            <SidebarAssociatedTransactions
              count={transactions.length}
              transactions={transactions}
            />
            <SidebarAssociatedActionEvents
              count={actionEventsCount}
              actionEvents={actionEvents}
            />
            <SidebarCommentsCollapsible comments={alert.comments} />
          </U21Spacer>
        );
      })()}
    </U21SideMenu>
  );
};

const EscalationContainer = styled(U21Spacer)`
  padding: 0 16px;
`;

const SearchContainer = styled.div`
  padding: 8px;
`;

const StyledU21QueueSelect = styled(U21QueueSelect)`
  width: 300px;
`;

const Span = styled.span`
  text-transform: none;
`;
