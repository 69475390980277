import {
  editOrgKytConfig as editOrgKytConfigAction,
  editOrgKytConfigSuccess,
} from 'app/modules/admin/actions';
import { AdminActionTypes } from 'app/modules/admin/models';
import { editOrgKytConfig as editOrgKytConfigApi } from 'app/shared/api/admin';
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

const rootAction = AdminActionTypes.EDIT_KYT_CONFIG;
function* editOrgKytConfig({
  payload,
}: ReturnType<typeof editOrgKytConfigAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to edit the org kyt config.',
    successToastMessage: `KYT Config edited successfully.`,
    request: call(editOrgKytConfigApi, payload),
    success: function* onSuccess(result) {
      yield put(editOrgKytConfigSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, editOrgKytConfig);
}
