export const notesConfig: any = {
  default: {
    titleGenerator: (authorName) => `${authorName} added a note`,
  },
};
export const toolbar: any = {
  options: ['inline', 'list', 'colorPicker', 'emoji'],
  inline: {
    options: ['bold', 'italic', 'underline'],
  },
  list: {
    options: ['unordered', 'ordered'],
  },
};
