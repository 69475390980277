import { useSelector } from 'react-redux';
import styled from 'styled-components';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';

// Components
import {
  U21NoData,
  U21Section,
  U21Spacer,
  U21ContentDisplay,
} from 'app/shared/u21-ui/components';
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';

// Utils
import { isDarkWebAlert } from 'app/modules/alerts/components/AlertDetails/DarkWebSummary/utils';
import { formatDate } from 'app/shared/utils/date';

import { selectFormatAmount } from 'app/modules/orgSettings/selectors';

export const DarkWebSummary = () => {
  const alert = useSelector(selectAlert);
  const formatAmount = useSelector(selectFormatAmount);

  if (!isDarkWebAlert(alert)) {
    return null;
  }
  const { custom_data: customData } = alert;

  return (
    <U21Section title="Flagged Check">
      <U21Spacer spacing={1}>
        <U21Spacer>
          <U21ContentDisplay title="Check amount">
            {customData?.cheque_amount
              ? formatAmount({
                  amount: customData?.cheque_amount,
                  currencyCodeProps: alert.currency,
                })
              : 'Unknown'}
          </U21ContentDisplay>
          <U21ContentDisplay title="Check post date">
            {formatDate(customData?.cheque_post_date ?? 'Unknown')}
          </U21ContentDisplay>
          <U21ContentDisplay title="Entity name">
            {customData?.entity_name ?? 'Unknown'}
          </U21ContentDisplay>
        </U21Spacer>
        {customData?.cheque_attachment ? (
          <DocumentSpacer>
            <DocumentDisplay file={customData.cheque_attachment} />
          </DocumentSpacer>
        ) : (
          <U21NoData />
        )}
      </U21Spacer>
    </U21Section>
  );
};

const DocumentSpacer = styled(U21Spacer)`
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  min-height: 300px;
`;
