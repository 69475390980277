import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components
import { TabLabel } from 'app/modules/investigations/components/InvestigationTabs/TabLabel';

// Selectors
import { selectAlertAssociatedEntities } from 'app/modules/alerts/selectors';
import {
  selectEntitiesLinkAnalysisMetadataLoading,
  selectEntityIdsWithPIIOverlap,
} from 'app/modules/entities/selectors';

// Actions
import {
  retrieveEntitiesLinkAnalysisMetadata,
  resetEntitiesLinkAnalysisMetadata,
} from 'app/modules/entities/actions';
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';

const MAX_ENTITY_IDS_FOR_METADATA = 10;

export const NetworkAnalysisLabel = () => {
  const dispatch = useDispatch();

  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const associatedEntities = useSelector(selectAlertAssociatedEntities);
  const metadataLoading = useSelector(
    selectEntitiesLinkAnalysisMetadataLoading,
  );

  const entityIDsWithPIIOverlap = useSelector(selectEntityIdsWithPIIOverlap);
  const numRelevantEntities = entityIDsWithPIIOverlap.size;

  useEffect(() => {
    return () => {
      dispatch(resetEntitiesLinkAnalysisMetadata());
    };
  }, [dispatch]);

  useEffect(() => {
    if (hasReadEntitiesPermission && associatedEntities.length > 0) {
      dispatch(
        retrieveEntitiesLinkAnalysisMetadata({
          entity_ids: associatedEntities
            .map(({ id }) => id)
            .slice(0, MAX_ENTITY_IDS_FOR_METADATA),
        }),
      );
    }
  }, [associatedEntities, dispatch, hasReadEntitiesPermission]);

  const badge = !metadataLoading && numRelevantEntities ? '!' : '';
  const tooltip =
    !metadataLoading && numRelevantEntities
      ? `${numRelevantEntities} ${
          numRelevantEntities === 1 ? 'entity' : 'entiies'
        } with PII overlap`
      : undefined;

  return (
    <TabLabel
      label="Network analysis"
      badge={badge}
      badgeProps={{
        color: 'warning',
      }}
      tooltip={tooltip}
    />
  );
};
