import { PaginationPayload } from 'app/shared/pagination/models';
import { TxnMetadataPayload } from 'app/modules/fraudDashboard/requests';

export enum FraudLossVictim {
  INSTITUTION = 'INSTITUTION',
  CUSTOMER = 'CUSTOMER',
  SPLIT = 'SPLIT',
}

// Update to be blocked reasons
export type FraudType = string;

export interface GetFraudLossPayload {
  fraud_types?: FraudType[];
  fraud_victims?: FraudLossVictim[];
  start_time?: string;
  end_time?: string;
  event_external_ids?: string[];
  article_ids?: number[];

  // Used for grouping
  article_type?: FraudLossArticleType;
}

export type FraudLossArticleType = 'ALERT' | 'CASE' | 'RULE' | 'SAR_FILING';

export interface GetFraudLossTablePayload
  extends PaginationPayload,
    GetFraudLossPayload {}

export type LossOverTimeGrouping = 'day' | 'week' | 'month';

export interface GetFraudLossOverTimePayload extends GetFraudLossPayload {
  grouping: LossOverTimeGrouping;
}

export enum FraudLossType {
  RECOVERED = 'RECOVERED',
  LOST = 'LOST',
}
export interface FraudLossFormState {
  rows: TxnMetadataPayload[];
  batchUpdates: {
    fraudType: FraudType | undefined;
    fraudVictim: FraudLossVictim | undefined;
    lossType: FraudLossType | undefined;
  };
}
