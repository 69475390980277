// Utils
import {
  IpsFromEntity,
  IpsFromEvent,
  RetrieveSarAuditTrailResponse,
  RetrieveSarResponse,
  SARAttachmentResponse,
  UpdateSarResponse,
  ValidateSarResponse,
  SarNarrativeTemplate,
  ArchiveSarResponse,
} from 'app/modules/fincenSarNew/response';
import {
  UpdateSarPayload,
  SarXlsxCsvExportPayload,
  SarSubmitPayload,
  DuplicateSarPayload,
  ArchivePayload,
  ChangeSarQueuePayload,
  EditSarReviewerPayload,
} from 'app/modules/fincenSarNew/requests';
import {
  destroy,
  get,
  post,
  put,
  upload,
  downloadFile,
} from 'app/shared/utils/fetchr';
import { ShortSARFilingResponse } from 'app/modules/fincenSar/types';

export const retrieveSar = (sarId: string): Promise<RetrieveSarResponse> => {
  return get(`/sars/${sarId}`);
};

export const updateSar = (
  id: string,
  payload: UpdateSarPayload,
): Promise<UpdateSarResponse> => {
  return put(`/sars/${id}/edit`, payload);
};

export const validateSar = (id: string): Promise<ValidateSarResponse> => {
  return post(`/sars/${id}/validate`);
};

export const retrieveSarAuditTrail = (
  sarId: string,
): Promise<RetrieveSarAuditTrailResponse> => {
  return get(`/sars/${sarId}/audit-trail`);
};

export const ipsFromEntity = (sarId: string): Promise<IpsFromEntity> => {
  return post(`/sars/${sarId}/entities/import-ips`);
};

export const ipsFromEvent = (sarId: string): Promise<IpsFromEvent> => {
  return post(`/sars/${sarId}/events/import-ips`);
};

export const uploadAttachment = (
  id: string,
  file: File,
): Promise<SARAttachmentResponse> => {
  const formData = new FormData();
  formData.append('file', file);
  return upload(`/sars/${id}/attachment`, formData);
};

export const deleteAttachment = (
  id: string,
): Promise<Record<string, never>> => {
  return destroy(`/sars/${id}/attachment`);
};

export const downloadAttachment = (id: number, fileName: string) => {
  return downloadFile(`/attachments/download/${id}`, fileName);
};

export const getNarrativeTemplates = (): Promise<SarNarrativeTemplate[]> => {
  return get('/sars/narrative/list');
};

export const exportXlsxOrCsv = (
  payload: SarXlsxCsvExportPayload,
): Promise<Record<string, never>> => {
  return post('/sars/single-email-xlsx', payload);
};

export const archiveSar = (
  payload: ArchivePayload,
): Promise<ArchiveSarResponse> => {
  return post('/sars/archive', payload);
};

export const returnToEdit = (
  sarId: number,
): Promise<ShortSARFilingResponse> => {
  return post(`/sars/${sarId}/unlock`);
};

export const readyForReview = (
  sarId: number,
): Promise<ShortSARFilingResponse> => {
  return post(`/sars/${sarId}/lock`);
};

export const submitSar = (
  payload: SarSubmitPayload,
): Promise<RetrieveSarResponse> => {
  return post(`/sars/${payload.id}/submit`, payload);
};

export const duplicateSar = (
  payload: DuplicateSarPayload,
): Promise<RetrieveSarResponse> => {
  return post('/sars/duplicate', payload);
};

export const changeSarQueue = (
  payload: ChangeSarQueuePayload,
): Promise<{ filings: ShortSARFilingResponse[] }> => {
  return post('/sars/requeue', payload);
};

export const editSarReviewerApi = (
  id: number,
  payload: EditSarReviewerPayload,
): Promise<ShortSARFilingResponse> => {
  return put(`/sars/${id}/edit`, payload);
};
