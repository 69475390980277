import { OrgDataSettingsConfig } from 'app/modules/dataSettings/responses';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';
import { SidebarStyleContext } from 'app/modules/sidebar/contexts';
import {
  U21Typography,
  U21TypographyProps,
} from 'app/shared/u21-ui/components';
import { ReactNode, useContext } from 'react';
import styled from 'styled-components';

type DataSettingDataLabelProps = Omit<U21TypographyProps, 'ref'> & {
  dataSetting?: OrgDataSettingsConfig;
};

export const DataSettingDataLabel = ({
  dataSetting,
  ...rest
}: DataSettingDataLabelProps) => {
  if (!dataSetting) {
    return null;
  }
  const label = keyPathToLabel(dataSetting);
  return (
    <U21Typography tooltip={formatDescription(dataSetting)} {...rest}>
      {label}
    </U21Typography>
  );
};

const formatDescription = (dataSetting: OrgDataSettingsConfig): ReactNode => {
  if (!dataSetting.user_facing_label) {
    return dataSetting.description;
  }
  const originalKey = keyPathToLabel(dataSetting, true);
  if (!dataSetting.description) {
    return `Original key: ${originalKey}`;
  }
  return (
    <>
      <U21Typography color="white" variant="body2">
        Original key: {originalKey}
      </U21Typography>
      <U21Typography color="white" variant="body2">
        Description: {dataSetting.description}
      </U21Typography>
    </>
  );
};

export const SidebarDataSettingDataLabel = ({
  dataSetting,
}: {
  dataSetting?: OrgDataSettingsConfig;
}) => {
  const {
    fieldLabel: { minWidth },
  } = useContext(SidebarStyleContext);

  return (
    <StyledDataSettingDataLabel
      dataSetting={dataSetting}
      variant="caption"
      color="text.secondary"
      ellipsis
      $minWidth={minWidth}
    />
  );
};

const StyledDataSettingDataLabel = styled(DataSettingDataLabel)<{
  $minWidth: string;
}>`
  width: ${(props) => props.$minWidth};
  min-width: ${(props) => props.$minWidth};
  margin-top: 2px;
  margin-right: 4px;
`;
