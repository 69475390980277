// Models
import { SarInput } from 'app/modules/fincenSar/models';

// Helpers
import { createState, createUnknownName } from 'app/modules/fincenSar/helpers';

const popupContent = `Item 27 - Financial institution TIN and account number(s)
affected that are related to the subject. Enter information
about any accounts involved in the suspicious activity that
are related to the subject. An account is related to a subject
if the subject owns the account, has control over the
account, or conducted activity in or through an account the
subject does not own or control. Check "No known account
involved" if no account related to the subject is involved in
the suspicious activity. Check "Non-U.S. Financial
Institution" if the account is located at a foreign financial
institution. Check "Closed" if the account is closed. Record
all account numbers involved in the suspicious activity that
are related to the subject even if the TIN or name of the
financial institution is unknown. When there are multiple Part
III institutions recorded in the SAR and the TIN of a financial
institution associated with account numbers is unknown,
record the institution name and the associated account
number(s) in Part V (example: “The account number
123654987 is held at First Federal Bank.”). If the filer is
aware that the subject has an account involved in the
suspicious activity with a financial institution listed in Part III
but the account number is unknown, record the financial
institution TIN but leave the associated account number field
blank. Explain the missing account number in Part V. The
subject’s relationship to any account must be clearly defined
in Part V unless the subject is the owner of the account
(example: “The subject made unauthorized purchases using
a stolen debit card for account 3456789 owned by his
aunt.”).`;

// ================ *27 Financial institution TIN and account number(s) affected that are related to subject ==================
export const TIN: SarInput = {
  title: `Institution TIN`,
  name: `27_tin`,
  initialValue: '',
  popupContent,
};

export const TINUnknown = {
  title: `Non-US Financial Institution`,
  name: createUnknownName(TIN.name),
  initialValue: false,
};

export const accountNumber: SarInput = {
  title: `Account Number`,
  name: `27_accountNumber`,
  initialValue: '',
  popupContent,
};

export const accountNumberClosed = {
  title: `Closed? Yes`,
  name: `27_accountNumberClosed`,
  initialValue: false,
  popupContent,
};

export const accountNumbers = {
  initialValue: {
    [accountNumber.name]: accountNumber.initialValue,
    [accountNumberClosed.name]: accountNumberClosed.initialValue,
  },
  name: `27_accountNumbers`,
};
// ================================================================

const allState = [TIN, TINUnknown];

const restOfState = {
  disabled: false,
  [accountNumbers.name]: [accountNumbers.initialValue],
};

export const initialState = Object.freeze({
  ...createState(allState),
  ...restOfState,
});
