import { useSelector } from 'react-redux';

// Components
import { IconArrowBarToLeft, IconArrowBarToRight } from '@u21/tabler-icons';
import { ReactComponent as Unit21Icon } from 'app/shared/components/logos/icon.svg';
import { Unit21Logo } from 'app/shared/components/logos/Unit21Logo';
import SidebarItem from 'app/shared/u21-ui/components/SidebarItem';

// Constants
import { AppContext, AppContextState } from 'app/shared/providers/AppProvider';

// Models
import { SidenavConfig } from 'app/modules/navigator/models';

// Selectors
import { selectAgent, selectNavConfig } from 'app/modules/session/selectors';

// Styles
import styled from 'styled-components';

// Utils
import { getA11yClickProps } from 'app/shared/utils/a11y';

interface StyleProps {
  $miniNav: boolean;
}

const Leftnav = () => {
  const agent = useSelector(selectAgent);
  const navConfig = useSelector(selectNavConfig);

  const renderMenuItem = (
    i: SidenavConfig,
    level: number,
    isMiniNav: boolean,
  ) => {
    const { tablerIcon: TablerIconSvg } = i;
    return (
      <SidebarItem
        key={i.label}
        level={level}
        icon={<TablerIconSvg />}
        href={i.link as string}
        title={isMiniNav ? undefined : i.label}
        disabled={i.disabled}
        groupFirst={i.marker === 'first'}
        newLabel={i.newLabel}
      >
        {Array.isArray(i.link) &&
          i.link.map((child) => renderMenuItem(child, level + 1, isMiniNav))}
      </SidebarItem>
    );
  };
  if (!agent) {
    return null;
  }

  return (
    <AppContext.Consumer>
      {({ isThick, toggleIsThick }: AppContextState) => (
        <RootStyle $miniNav={!isThick}>
          <LogoBox $miniNav={!isThick} {...getA11yClickProps(toggleIsThick)}>
            {isThick ? <StyledUnit21Logo /> : <StyledUnit21Icon />}
          </LogoBox>

          <MenuContent>
            {navConfig
              .reduce(
                (acc, item) => [
                  ...acc,
                  ...(!isThick && Array.isArray(item.link)
                    ? item.link
                    : [item]),
                ],
                [],
              )
              .map((i) => renderMenuItem(i, 0, !isThick))}
          </MenuContent>

          <BottomSection>
            <SidebarItem
              onClick={() => toggleIsThick()}
              icon={!isThick ? <IconArrowBarToRight /> : <IconArrowBarToLeft />}
              title={(isThick && 'Minimize') || undefined}
            />
          </BottomSection>
        </RootStyle>
      )}
    </AppContext.Consumer>
  );
};

export default Leftnav;

const RootStyle = styled.div<StyleProps>`
  background: ${(props) => props.theme.palette.background.paper};
  border-right: 1px solid ${(props) => props.theme.palette.grey[500_24]};
  grid-area: LeftNav;
  width: ${(props) => (props.$miniNav ? '60px' : '180px')};
  transition: ${(props) => props.theme.transitions.create(['all'])};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* hide this component in pdf exports */
  @media print {
    display: none;
  }
`;

const MenuContent = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  margin-right: -10px;
  flex: 1;
`;

const BottomSection = styled.div`
  padding: 8px 0;
  border-top: 1px solid ${(props) => props.theme.palette.grey[500_24]};
`;

const LogoBox = styled.div<StyleProps>`
  height: 60px;
  display: flex;
  padding-top: 16px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: ${(props) =>
    props.$miniNav
      ? props.theme.palette.error.main
      : props.theme.palette.grey[800]};
`;

const StyledUnit21Logo = styled(Unit21Logo)`
  width: 70%;
`;
const StyledUnit21Icon = styled(Unit21Icon)`
  height: 40%;
`;
