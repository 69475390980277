// Models
import { TableConfig } from 'app/shared/pagination/models';

// Components
import {
  IconCircleCheck,
  IconCircleX,
  IconChevronsUp,
  IconBriefcase,
  IconFlag,
  IconClipboardList,
  IconTag,
  IconUserCheck,
  IconUserX,
  IconChecks,
  IconBellOff,
  IconBell,
  IconExternalLink,
  IconArrowRight,
  IconBuildingBank,
  IconScale,
  IconAlertTriangle,
  IconNotes,
  IconInfoCircle,
  IconCircle,
} from '@u21/tabler-icons';
import { IconFlagArrow } from 'app/shared/components/Icons/IconFlagArrow';

// Helpers
import { getButtonColor } from 'app/modules/workflows/helpers';
import { WorkflowTemplate } from 'app/modules/workflows/models';

export const DEFAULT_WORKFLOWS_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'workflow_button',
    type: 'workflow_button',
    label: 'Action Button',
    maxWidth: 300,
    sortable: false,
  },
  {
    key: 'description',
    label: 'Description',
    sortable: false,
  },
  {
    type: 'datetime',
    key: 'created_at',
    label: 'Created at',
    sortable: false,
  },
  {
    key: 'created_by',
    label: 'Created by',
    sortable: false,
  },
];

export const BUTTON_ICON_OPTIONS = [
  {
    icon: <IconCircleCheck />,
    text: 'Approve',
    value: 'approve',
  },
  {
    icon: <IconCircleX />,
    text: 'Decline',
    value: 'decline',
  },
  {
    icon: <IconChevronsUp />,
    text: 'Escalate',
    value: 'escalate',
  },
  {
    icon: <IconBriefcase />,
    text: 'Case',
    value: 'case',
  },
  {
    icon: <IconFlag />,
    text: 'Alert',
    value: 'alert',
  },
  {
    icon: <IconFlagArrow />,
    text: 'Re-assign',
    value: 're-assign',
  },
  {
    icon: <IconClipboardList />,
    text: 'Queue',
    value: 'queue',
  },
  {
    icon: <IconTag />,
    text: 'Tag',
    value: 'tag',
  },
  {
    icon: <IconUserCheck />,
    text: 'Whitelist',
    value: 'whitelist',
  },
  {
    icon: <IconUserX />,
    text: 'Blacklist',
    value: 'blacklist',
  },
  {
    icon: <IconChecks />,
    text: 'Bulk Action',
    value: 'bulk-action',
  },
  {
    icon: <IconBellOff />,
    text: 'Snooze',
    value: 'snooze',
  },
  {
    icon: <IconBell />,
    text: 'Bell',
    value: 'bell',
  },
  {
    icon: <IconExternalLink />,
    text: 'External',
    value: 'external',
  },
  {
    icon: <IconArrowRight />,
    text: 'Arrow',
    value: 'arrow',
  },
  {
    icon: <IconBuildingBank />,
    text: 'Report Filing',
    value: 'report-filing',
  },
  {
    icon: <IconScale />,
    text: 'Detection Model',
    value: 'detection-model',
  },
  {
    icon: <IconAlertTriangle />,
    text: 'Risk Score',
    value: 'risk-score',
  },
  {
    icon: <IconNotes />,
    text: 'Note',
    value: 'note',
  },
  {
    icon: <IconInfoCircle />,
    text: 'Info',
    value: 'info',
  },
];

export type IconOptions = (typeof BUTTON_ICON_OPTIONS)[number]['value'];

export const BUTTON_ICON_MAPPING = BUTTON_ICON_OPTIONS.reduce(
  (acc, i) => ({ ...acc, [i.value]: i.icon }),
  {},
);

export const BUTTON_COLOR_OPTIONS = [
  {
    icon: (
      <IconCircle
        color={getButtonColor('green').background}
        fill={getButtonColor('green').background}
      />
    ),
    text: 'Green',
    value: 'green',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('yellow').background}
        fill={getButtonColor('yellow').background}
      />
    ),
    text: 'Yellow',
    value: 'yellow',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('red').background}
        fill={getButtonColor('red').background}
      />
    ),
    text: 'Red',
    value: 'red',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('orange').background}
        fill={getButtonColor('orange').background}
      />
    ),
    text: 'Orange',
    value: 'orange',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('blue').background}
        fill={getButtonColor('blue').background}
      />
    ),
    text: 'Blue',
    value: 'blue',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('black').background}
        fill={getButtonColor('black').background}
      />
    ),
    text: 'Black',
    value: 'black',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('grey').background}
        fill={getButtonColor('grey').background}
      />
    ),
    text: 'Grey',
    value: 'grey',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('brown').background}
        fill={getButtonColor('brown').background}
      />
    ),
    text: 'Brown',
    value: 'brown',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('olive').background}
        fill={getButtonColor('olive').background}
      />
    ),
    text: 'Olive',
    value: 'olive',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('purple').background}
        fill={getButtonColor('purple').background}
      />
    ),
    text: 'Purple',
    value: 'purple',
  },
  {
    icon: (
      <IconCircle
        color={getButtonColor('teal').background}
        fill={getButtonColor('teal').background}
      />
    ),
    text: 'Teal',
    value: 'teal',
  },
];

export const DEFAULT_WORKFLOW_TEMPLATES_TABLE_CONFIG: TableConfig[] = [
  {
    key: 'title',
    label: 'Template',
    sortable: false,
    type: 'text_bold',
  },
  {
    key: 'description',
    label: 'Description',
    sortable: false,
  },
  {
    key: 'workflow_template_button',
    type: 'workflow_template',
    label: '',
    sortable: false,
  },
];

export enum TemplateIds {
  NEW = 'new',
  QUEUE = 'queue',
  RESOLUTION = 'resolution',
  CASE = 'case',
}

export const WORKFLOW_TEMPLATES: WorkflowTemplate[] = [
  {
    id: TemplateIds.NEW,
    title: 'Create your own alert workflow',
    description: 'Start building your own scenario from scratch',
    sortable: false,
  },
  {
    id: TemplateIds.QUEUE,
    title: 'Queue Escalation',
    description: 'Send this alert to another queue or another agent',
    sortable: false,
  },
  {
    id: TemplateIds.RESOLUTION,
    title: 'Alert Resolution',
    description: 'Resolve and close this alert',
    sortable: false,
  },
];

export const CASE_CREATE_TEMPLATE: WorkflowTemplate = {
  id: TemplateIds.CASE,
  title: 'Case Creation',
  description: 'Create a case from this alert',
  sortable: false,
};
