import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AdminActionTypes, OrgDetails } from 'app/modules/admin/models';

// Actions
import {
  editOrg as editOrgAction,
  editOrgSuccess,
} from 'app/modules/admin/actions';

// API
import { editOrg as editOrgApi } from 'app/shared/api/admin';
import { sendErrorToast } from 'app/shared/toasts/actions';

const rootAction = AdminActionTypes.EDIT_ORG;
function* editOrg({ payload }: ReturnType<typeof editOrgAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to edit the org.',
    successToastMessage: `Org features edited successfully.`,
    request: call(editOrgApi, payload),
    success: function* onSuccess(result: OrgDetails) {
      yield put(editOrgSuccess(result));
      if (result.org_features_failed?.length)
        yield put(
          sendErrorToast(
            `Failed to update the following org features: ${result.org_features_failed.join(
              ', ',
            )}`,
          ),
        );
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, editOrg);
}
