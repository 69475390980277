import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { SidebarLabelValue } from 'app/modules/sidebar/components/SidebarLabelValue';

// Selectors
import { selectSidebarAlert } from 'app/modules/alerts/selectors';
import { selectFormatAmount } from 'app/modules/orgSettings/selectors';

// Utils
import { formatDatetime } from 'app/shared/utils/date';

export const SidebarAlertInfo = () => {
  const sidebarAlert = useSelector(selectSidebarAlert);
  const formatAmount = useSelector(selectFormatAmount);
  const hasTransactionEvents =
    sidebarAlert.events && sidebarAlert.events.length >= 1;

  const flaggedAt = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Flagged at"
        value={formatDatetime(sidebarAlert.created_at)}
      />
    );
  }, [sidebarAlert.created_at]);

  const flaggedAmount = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Flagged amount"
        value={formatAmount({
          amount: sidebarAlert.amount_volume,
          currencyCodeProps: sidebarAlert.currency,
        })}
      />
    );
  }, [sidebarAlert.amount_volume, sidebarAlert.currency, formatAmount]);

  return (
    <>
      {flaggedAt}
      {hasTransactionEvents && flaggedAmount}
    </>
  );
};
