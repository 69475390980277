import { createSelector } from '@reduxjs/toolkit';
import {
  DataSettingColumnMetadata,
  OrgTableColumnKeyType,
  OrgTableConfig,
  OrgTableConfigResponse,
  OrgTableTypeClassifier,
  Unit21ColumnMetadata,
} from 'app/modules/tableSettings/responses';
import { name } from 'app/modules/tableSettings/sliceTableSettings';
import { selectDataSettingsById } from 'app/modules/dataSettings/selectors';
import {
  INTUIT_ENTITY_TABLE_KEY_LABEL_MAP,
  ASCENDANT_TXN_EVENT_TABLE_KEY_LABEL_MAP,
  CHIME_TXN_EVENT_TABLE_KEY_LABEL_MAP,
  ORG_TABLE_TYPE_TO_U21_DATA_CLASSIFIER,
  DATA_SETTINGS_NOT_ON_TABLES,
  CLASSIFIER_TO_LABEL_MAP,
  CLASSIFIER_TO_COLUMNS,
  CLASSIFIER_TO_DESCRIPTION_MAP,
} from 'app/modules/tableSettings/constants';
import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';
import {
  DataSettingsKeyType,
  OrgDataSettingsConfig,
} from 'app/modules/dataSettings/responses';
import { selectAgent } from 'app/modules/session/selectors';
import { SessionAgent } from 'app/modules/session/models';
import { selectFeatureFlags } from 'app/shared/featureFlags/selectors';
import { FeatureFlag, FeatureFlags } from 'app/shared/featureFlags/models';
import { ALERT_SCORE_COLUMN } from 'app/modules/alerts/columns';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';
import { FrontendColumnDefinition } from 'app/modules/tableSettings/types';
import { isEmpty } from 'lodash';
import { ENTITY_IDS_COLUMN } from 'app/modules/alerts/components/AlertDetails/PriorActivity/columns';

export const selectUpdateTableSettings = (state: RootState) =>
  state[name].updateTableSettings;

const filterOrgCustomKeys = (key: string, orgName: string): boolean => {
  // these are keys that are specific to certain orgs that we don't want to render
  // unless that the logged in user is from that org
  const intuitKey = Boolean(INTUIT_ENTITY_TABLE_KEY_LABEL_MAP[key]);
  const ascendantKey = Boolean(ASCENDANT_TXN_EVENT_TABLE_KEY_LABEL_MAP[key]);
  const chimeKey = Boolean(CHIME_TXN_EVENT_TABLE_KEY_LABEL_MAP[key]);
  return (
    !(ascendantKey || chimeKey || intuitKey) ||
    (intuitKey && orgName === 'intuit') ||
    (ascendantKey && orgName === 'ascendant') ||
    (chimeKey && orgName === 'chime')
  );
};

export const selectTableSettings = (state: RootState) =>
  state[name].tableSettings;

export const selectAgentTableSettings = (state: RootState) =>
  state[name].agentTableSettings;

const settingsByClassifier = (
  tableSettings: AsyncStatus<OrgTableConfigResponse>,
) =>
  tableSettings.status === 'COMPLETE' &&
  tableSettings.data.reduce((acc, config) => {
    acc[config.type_classifier] = config;
    return acc;
  }, {});

export const selectTableSettingsByClassifier = createSelector(
  selectTableSettings,
  settingsByClassifier,
);

export const selectAgentTableSettingsByClassifier = createSelector(
  selectAgentTableSettings,
  settingsByClassifier,
);

export const selectMergedTableSettingsByClassifier = createSelector(
  selectTableSettingsByClassifier,
  selectAgentTableSettingsByClassifier,
  (orgTableSettings, agentTableSettings) => {
    return (
      agentTableSettings &&
      orgTableSettings &&
      Object.values(OrgTableTypeClassifier).reduce((acc, classifier) => {
        const orgConfig = orgTableSettings[classifier];
        const agentConfig = agentTableSettings[classifier];
        acc[classifier] =
          agentConfig && agentConfig.id ? agentConfig : orgConfig;
        return acc;
      }, {})
    );
  },
);

const getLabelForU21Column = (
  classifier: OrgTableTypeClassifier,
  column: Unit21ColumnMetadata,
): string => {
  const labelMap = CLASSIFIER_TO_LABEL_MAP[classifier];
  return labelMap[column.key] || '';
};

const makeFormData = (
  configsByClassifier: Record<OrgTableTypeClassifier, OrgTableConfig>,
  dataSettingsById: Record<number, OrgDataSettingsConfig>,
  classifier: OrgTableTypeClassifier,
  featureFlags: FeatureFlags,
  agent: SessionAgent,
) => {
  if (!configsByClassifier || isEmpty(dataSettingsById)) {
    return undefined;
  }
  const { org_name: orgName } = agent;
  const config = configsByClassifier[classifier];
  const columns = config.columns || [];

  const { seenNativeKeys, seenDataSettingIds, definition } = columns.reduce<{
    seenNativeKeys: Set<string>;
    seenDataSettingIds: Set<number>;
    definition: FrontendColumnDefinition[];
  }>(
    (acc, column) => {
      if (column.key_type === OrgTableColumnKeyType.UNIT21) {
        acc.seenNativeKeys.add(column.key);
      } else {
        acc.seenDataSettingIds.add(column.data_setting_id);
      }
      acc.definition.push({
        ...column,
        label:
          column.key_type === OrgTableColumnKeyType.UNIT21
            ? getLabelForU21Column(classifier, column)
            : keyPathToLabel(dataSettingsById[column.data_setting_id]),
        hidden: false,
        description:
          column.key_type === OrgTableColumnKeyType.DATA_SETTING
            ? dataSettingsById[column.data_setting_id].description
            : CLASSIFIER_TO_DESCRIPTION_MAP[classifier][column.key],
      });
      return acc;
    },
    {
      seenNativeKeys: new Set(),
      seenDataSettingIds: new Set(),
      definition: [],
    },
  );

  const hiddenUnit21Columns: FrontendColumnDefinition<Unit21ColumnMetadata>[] =
    CLASSIFIER_TO_COLUMNS[classifier].filter(
      (column) => !seenNativeKeys.has(column.key),
    );

  const hiddenDataSettingColumns = Object.values(dataSettingsById).reduce<
    FrontendColumnDefinition<DataSettingColumnMetadata>[]
  >((acc, dataSetting) => {
    if (
      dataSetting.unit21_data_classifier ===
        ORG_TABLE_TYPE_TO_U21_DATA_CLASSIFIER[classifier] &&
      !seenDataSettingIds.has(dataSetting.id) &&
      (dataSetting.key_type === DataSettingsKeyType.CUSTOM ||
        !DATA_SETTINGS_NOT_ON_TABLES[classifier].has(dataSetting.native_key))
    ) {
      acc.push({
        hidden: true,
        data_setting_id: dataSetting.id,
        label: dataSetting.user_facing_label || keyPathToLabel(dataSetting),
        key_type: OrgTableColumnKeyType.DATA_SETTING,
        description: dataSetting.description,
      });
    }
    return acc;
  }, []);

  const hiddenFields = [
    ...hiddenUnit21Columns,
    ...hiddenDataSettingColumns,
    // this is valid ES14
    // @ts-ignore
  ].sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    return 1;
  });

  return {
    type: classifier,
    id: config.id,
    definition: [...definition, ...hiddenFields].filter(
      (def) =>
        def.key_type === OrgTableColumnKeyType.DATA_SETTING ||
        // this is basically a bunch of ugly filtering logic to remove
        // certain columns depending on feature flags, org-specific keys, etc.
        // This column is no longer returned by the API. Hard-code to now show it for now.
        // TODO: Remove this check once we remove the column from the org table config
        // https://app.shortcut.com/unit21/story/69439/remove-entity-ids-column-from-all-alert-table-configs
        (def.key !== ENTITY_IDS_COLUMN.id &&
          (def.key !== ALERT_SCORE_COLUMN.id ||
            featureFlags[FeatureFlag.ALERT_SCORE]) &&
          (classifier !== OrgTableTypeClassifier.EVENT ||
            filterOrgCustomKeys(def.key, orgName)) &&
          // need to remove column from table configs
          def.key !== 'device_id'),
    ),
  };
};

export const selectFormData = createSelector(
  selectTableSettingsByClassifier,
  selectDataSettingsById,
  (_, classifier: OrgTableTypeClassifier) => classifier,
  selectFeatureFlags,
  selectAgent,
  makeFormData,
);

export const selectAgentFormData = createSelector(
  selectAgentTableSettingsByClassifier,
  selectDataSettingsById,
  (_, classifier: OrgTableTypeClassifier) => classifier,
  selectFeatureFlags,
  selectAgent,
  makeFormData,
);

export const selectIsAgentTableSettingDistinct = createSelector(
  selectTableSettingsByClassifier,
  selectAgentTableSettingsByClassifier,
  (_: RootState, classifier: OrgTableTypeClassifier) => classifier,
  (orgSettingsByClassifier, agentSettingsByClassifier, classifier) => {
    if (!orgSettingsByClassifier || !agentSettingsByClassifier) {
      return false;
    }

    return (
      orgSettingsByClassifier[classifier]?.id !==
      agentSettingsByClassifier[classifier]?.id
    );
  },
);
