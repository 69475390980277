import { useEffect, useState, useCallback, ChangeEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store/storeHooks';

// Actions
import { hideSidebar } from 'app/modules/sidebar/slice';
import {
  saveCustomConfig,
  saveLocalTableConfig,
} from 'app/shared/CustomConfig/actions';
import {
  resetAgentTableSettingsThunk,
  updateAgentTableSettingsThunk,
} from 'app/modules/tableSettings/sliceTableSettings';

// Components
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';
import SidebarTableConfigItem, {
  getColumnId,
} from 'app/modules/sidebar/components/SidebarTableConfigItem';

// Models
import {
  CustomTableConfigPayload,
  TableConfigType,
} from 'app/shared/CustomConfig/models';
import { TableConfig } from 'app/shared/pagination/models';
import { TABLE_CONFIG_TYPE_TO_U21_DATA_CLASSIFIER } from 'app/modules/tableSettings/constants';

// Selectors
import { selectTableConfigs } from 'app/shared/CustomConfig/selectors';
import { TableConfigSidebarDefinition } from 'app/modules/sidebar/models';
import {
  selectAgentFormData,
  selectAgentTableSettings,
  selectIsAgentTableSettingDistinct,
} from 'app/modules/tableSettings/selectors';
import {
  U21Alert,
  U21Button,
  U21Spacer,
  U21TextField,
} from 'app/shared/u21-ui/components';
import { selectAgent } from 'app/modules/session/selectors';
import { FrontendColumnDefinition } from 'app/modules/tableSettings/types';

const portal: HTMLDivElement = document.createElement('div');
document.body.appendChild(portal);

interface OwnProps {
  data: TableConfigSidebarDefinition['data'];
}

// temp. stuff to deal with both types
const isOldTableConfig = (arr: any): arr is TableConfig[] => !arr[0].key_type;

export const SidebarTableConfig = ({ data }: OwnProps) => {
  const dispatch = useAppDispatch();
  const [columns, setColumns] = useState<
    TableConfig[] | FrontendColumnDefinition[]
  >([]);
  const [initialColumns, setInitialColumns] = useState<string>('');
  const tableConfigs = useSelector(selectTableConfigs);
  const agentTableSettings = useSelector(selectAgentTableSettings);
  const mappedType = TABLE_CONFIG_TYPE_TO_U21_DATA_CLASSIFIER[data];
  const tableViewConfigFormData = useSelector(
    (state: RootState) => mappedType && selectAgentFormData(state, mappedType),
  );
  const isAgentTableSettingDistinct = useSelector(
    (state: RootState) =>
      mappedType && selectIsAgentTableSettingDistinct(state, mappedType),
  );
  const agent = useSelector(selectAgent);

  const isLocalTable =
    data === TableConfigType.RULE_ADMIN_CONTENT_TABLE ||
    data === TableConfigType.RULE_ADMIN_EXECUTION_TABLE ||
    data === TableConfigType.RULE_ADMIN_QUERY_TABLE ||
    data === TableConfigType.RULE_ADMIN_VALIDATION_TABLE ||
    data === TableConfigType.RULE_ADMIN_SCHEDULE_TABLE ||
    data === TableConfigType.DATA_SETTINGS;

  // Reload table definition if table type changes
  useEffect(() => {
    if (data && tableConfigs) {
      if (tableViewConfigFormData) {
        const cols = tableViewConfigFormData.definition;
        setColumns([...cols]);
        setInitialColumns(JSON.stringify(cols));
        return;
      }
      const cols: TableConfig[] = tableConfigs[data]?.definition || [];
      setColumns([...cols]);
      setInitialColumns(JSON.stringify(cols));
    }
  }, [data, tableConfigs, tableViewConfigFormData]);

  const handleSave = () => {
    if (JSON.stringify(columns) !== initialColumns) {
      if (isOldTableConfig(columns)) {
        const payload: CustomTableConfigPayload = {
          definition: [...columns],
          type: data,
        };
        dispatch(
          isLocalTable
            ? saveLocalTableConfig(payload)
            : saveCustomConfig(payload),
        );
      } else if (tableViewConfigFormData) {
        const payload = {
          type: tableViewConfigFormData.type,
          // we know the definition is correct based on the feature flag
          definition: (columns as FrontendColumnDefinition[])
            .filter((c) => !c.hidden)
            .map(({ hidden, label, description, ...rest }) => rest),
        };
        dispatch(updateAgentTableSettingsThunk(payload));
      }
      setColumnSearch(undefined);
    }
    dispatch(hideSidebar());
  };

  const resetToDefaultsEnabled = useMemo(
    () => tableViewConfigFormData?.id && isAgentTableSettingDistinct,
    [tableViewConfigFormData, isAgentTableSettingDistinct],
  );

  const handleReset = async () => {
    if (!resetToDefaultsEnabled) {
      return;
    }

    // Wait for the API response before closing the sidebar
    try {
      await dispatch(
        resetAgentTableSettingsThunk({
          id: tableViewConfigFormData.id,
        }),
      ).unwrap();
    } catch (e) {}

    dispatch(hideSidebar());
  };

  // Toggle column visibility by setting optional hidden field of TableConfig
  const toggleColumns = useCallback(
    (checked: boolean, ev: ChangeEvent<HTMLInputElement>) => {
      setColumns((newColumns) => {
        if (isOldTableConfig(newColumns)) {
          return newColumns.map((column) => ({
            ...column,
            hidden:
              column.key === ev.target.name
                ? !column.hidden
                : Boolean(column.hidden),
          }));
        }
        return newColumns.map((column) => ({
          ...column,
          hidden:
            getColumnId(column) === ev.target.name ? !checked : column.hidden,
        }));
      });
    },
    [setColumns],
  );

  // Swap list items on drag & drop
  const onDragEnd = (results: DropResult) => {
    const { source, destination } = results;
    if (destination) {
      const [removed] = columns.splice(source.index, 1);
      // @ts-ignore temporary fun with union arrays
      columns.splice(destination.index, 0, removed);
      // @ts-ignore
      setColumns([...columns]);
    }
  };

  const resetButton = mappedType && (
    <U21Button
      variant="outlined"
      onClick={handleReset}
      disabled={!resetToDefaultsEnabled}
      tooltip={
        !resetToDefaultsEnabled
          ? undefined
          : `You (${agent.full_name}) are currently on the default table setting`
      }
    >
      Reset to Default
    </U21Button>
  );

  const [columnSearch, setColumnSearch] = useState<string | undefined>(
    undefined,
  );

  return (
    <U21SideMenu
      actionButtonProps={{ children: 'Save' }}
      onAction={handleSave}
      footerLeftContent={resetButton || undefined}
      title="Choose Columns"
      loading={agentTableSettings.status === 'LOADING'}
    >
      <U21Spacer>
        {mappedType && (
          <U21Alert severity="info">
            This configures the table&apos;s settings for{' '}
            {agent.full_name || agent.email} only. Click &quot;Reset to
            Default&quot; to return to your organization&apos;s default table
            settings.
          </U21Alert>
        )}
        {mappedType && (
          <U21TextField
            onChange={(v: string | undefined) => setColumnSearch(v)}
            placeholder="Search columns"
            autoFocus
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => {
              return (
                <ul ref={provided.innerRef}>
                  {columns.map(
                    (
                      column: TableConfig | FrontendColumnDefinition,
                      index: number,
                    ) => {
                      const key = getColumnId(column);
                      return !columnSearch ||
                        column.label
                          .toLowerCase()
                          .includes(columnSearch.toLowerCase()) ||
                        column.description
                          ?.toLowerCase()
                          .includes(columnSearch.toLowerCase()) ? (
                        <Draggable key={key} draggableId={key} index={index}>
                          {(providedConfigItem, snapshot) => (
                            <SidebarTableConfigItem
                              provided={providedConfigItem}
                              snapshot={snapshot}
                              column={column}
                              toggleColumns={toggleColumns}
                              portal={portal}
                            />
                          )}
                        </Draggable>
                      ) : null;
                    },
                  )}
                  {provided.placeholder}
                </ul>
              );
            }}
          </Droppable>
        </DragDropContext>
      </U21Spacer>
    </U21SideMenu>
  );
};
