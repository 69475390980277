import { FC, ReactNode, useContext } from 'react';
import styled from 'styled-components';

import { U21Typography, U21_NO_VALUE } from 'app/shared/u21-ui/components';

import { SidebarStyleContext } from 'app/modules/sidebar/contexts';

// Constants

interface OwnProps {
  children?: string | number | ReactNode;
}

export const SidebarFieldLabel: FC<OwnProps> = ({ children }) => {
  const {
    fieldLabel: { minWidth },
  } = useContext(SidebarStyleContext);

  return (
    <StyledU21TypographyLabel
      variant="caption"
      color="text.secondary"
      ellipsis
      $minWidth={minWidth}
    >
      {children || U21_NO_VALUE}
    </StyledU21TypographyLabel>
  );
};

const StyledU21TypographyLabel = styled(U21Typography)<{ $minWidth: string }>`
  width: ${(props) => props.$minWidth};
  min-width: ${(props) => props.$minWidth};
  margin-top: 2px;
  margin-right: 4px;
`;
