// Components
import { U21Divider } from 'app/shared/u21-ui/components';
import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

// Models
import { FilingsBatchDefinition } from 'app/modules/sidebar/models';

// Contexts
import { SidebarStyleContext } from 'app/modules/sidebar/contexts';
import { useFetchFilingsGrouped } from 'app/modules/fincenCtr/queries/useFetchFilingsGrouped';
import { ReportType } from 'app/modules/fincenSar/models';
import { IconDownload } from '@u21/tabler-icons';
import { formatDate } from 'app/shared/utils/date';
import { SidebarLabelValue } from 'app/modules/sidebar/components/SidebarLabelValue';
import { useDownloadFilingsBatch } from 'app/modules/fincenCtr/queries/useDownloadFilingsBatch';
import { useSelector } from 'react-redux';
import { selectFilingPageListFilters } from 'app/modules/fincenCtr/selectors';

interface OwnProps {
  data: FilingsBatchDefinition['data'];
}

const FILINGS_SIDEBAR_STYLE = { fieldLabel: { minWidth: '140px' } };

export const SidebarFincenCtr = ({
  data: { id: batchId, pageType },
}: OwnProps) => {
  const filingListFilters = useSelector(selectFilingPageListFilters(pageType));
  const { data: batchData, isLoading } = useFetchFilingsGrouped({
    limit: 1,
    offset: 1,
    report_type: ReportType.FINCEN_CTR,
    filters: {
      ...filingListFilters,
      batch_id: batchId,
    },
  });

  const { mutate: downloadFiling, isPending: downloadingBatch } =
    useDownloadFilingsBatch(batchId);

  const filingInfo = batchData?.filings?.[0];

  const {
    total,
    earliest,
    latest,
    accepted,
    rejected,
    created_by: createdBy,
  } = filingInfo ?? {};

  return (
    <U21SideMenu
      title={`Batch ${batchId}`}
      actionButtonProps={{
        children: 'Download Batch',
        endIcon: <IconDownload />,
        loading: downloadingBatch,
      }}
      onAction={() => downloadFiling()}
      loading={isLoading}
    >
      <SidebarStyleContext.Provider value={FILINGS_SIDEBAR_STYLE}>
        <SidebarLabelValue label="Total filings" value={total ?? null} />
        <SidebarLabelValue label="Accepted filings" value={accepted ?? null} />
        <SidebarLabelValue label="Rejected filings" value={rejected ?? null} />
        <SidebarLabelValue
          label="Date range"
          value={`${earliest ? formatDate(earliest) : 'N/A'}-${
            latest ? formatDate(latest) : 'N/A'
          }`}
        />
        <SidebarLabelValue label="Created by" value={createdBy ?? null} />
        <U21Divider horizontal />
      </SidebarStyleContext.Provider>
    </U21SideMenu>
  );
};
