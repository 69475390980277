// Models
import { AgentsActionTypes } from 'app/modules/agentsOld/models';

export const selectPaginatedAgents = (state: RootState) => {
  return (
    state.agents.paginatedAgents || {
      agents: [],
      count: 0,
    }
  );
};

// Loading
export const selectPaginatedAgentsLoading = (state: RootState) =>
  Boolean(state.loading[AgentsActionTypes.RETRIEVE_PAGINATED_AGENTS]);

export const selectImpersonationLoading = (state: RootState) =>
  Boolean(state.loading[AgentsActionTypes.START_IMPERSONATION]);

export const selectUncensorFields = (state: RootState) =>
  state.agents.uncensorFields || [];
