import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Actions
import { SettingsActionTypes } from 'app/modules/settings/models';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Models
import {
  deleteSarNarrative as deleteSarNarrativeAction,
  deleteSarNarrativeSuccess,
} from 'app/modules/settings/actions';

// APIs
import { deleteSarNarrative as deleteSarNarrativeApi } from 'app/shared/api/settings';

const rootAction = SettingsActionTypes.DELETE_SAR_NARRATIVE;
function* deleteSarNarrative({
  payload,
}: ReturnType<typeof deleteSarNarrativeAction>) {
  const config = {
    rootAction,
    request: call(deleteSarNarrativeApi, payload),
    success: function* onSuccess() {
      yield put(deleteSarNarrativeSuccess(payload));
      yield put(sendSuccessToast('Narrative deleted successfully.'));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchDeleteSarNarrative() {
  yield takeLatest(rootAction, deleteSarNarrative);
}
