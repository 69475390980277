import { ShortEntityResponse } from 'app/modules/entities/types';
import { FieldInfo } from 'app/modules/watchlists/models';

// export const TOKEN: Readonly<string> = '-';

export const LEFT_FIELD: Readonly<string> = 'LEFT';
export const RIGHT_FIELD: Readonly<string> = 'RIGHT';

export const ENTITY: Readonly<string> = 'ENTITY';
export const WATCHLIST: Readonly<string> = 'WATCHLIST';
export const FIELD_SOURCE: Readonly<string> = 'FIELD_SOURCE';
export const FIELD_GROUP: Readonly<string> = 'FIELD_GROUP';

export const INITIAL_FIELD_INFO: Readonly<FieldInfo> = {
  fields: [],
  entityUnusedFields: [],
  watchlistUnusedFields: [],
  formattedData: {},
};

export const INITIAL_WATCHLIST_ENTITY: Readonly<ShortEntityResponse> = {
  created_at: '2023-12-11T13:50:01+00:00',
  risk_score: 0,
  custom_data: { internal_notes: 'Reviewed by Jane Doe on 31 June 2019' },
  display_status: 'ALLOWED',
  entity_type: 'USER',
  external_id: "John Doe's external ID",
  first_name: 'Vladimir',
  formatted_data: {},
  id: '911',
  internal_entity_type: 'person',
  last_name: 'Doe',
  middle_name: '',
  name: "John Doe's business name",
  name_readable: 'John Doe',
  registered_at: '2019-11-02T05:40:26+00:00',
  status: 'active',
  type: 'USER',
  user_name: 'John Doe',
  org_name: 'unit21_customer_name',
};

export enum AdverseMediaRiskClassification {
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
  LOW = 'LOW',
}

export const NEWS_CATEGORY_OTHERS: Readonly<string> = 'Others';
