// components
import { TimelineEvent } from 'react-event-timeline';

// types
import { AuditServiceEntry } from 'app/modules/auditService/types';
import { formatDatetime } from 'app/shared/utils/date';
import { AUDIT_TRAIL_ACTION_TO_CONTENT } from 'app/modules/auditService/constants/auditTrailStrings';

interface Props extends AuditServiceEntry {}

export const AuditTrailEntry = ({
  action,
  actor,
  asset,
  details,
  createdAt,
}: Props) => {
  const timestamp = formatDatetime(createdAt);
  const Content = AUDIT_TRAIL_ACTION_TO_CONTENT[action];
  return Content ? (
    <TimelineEvent title={timestamp}>
      <Content actor={actor} asset={asset} details={details} />
    </TimelineEvent>
  ) : null;
};
