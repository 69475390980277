// Components
import { IconLock } from '@u21/tabler-icons';
import { SidebarTitleIcon } from 'app/modules/sidebar/components/SidebarTitleIcon';
import {
  DataProps,
  SidebarExternalLinkList,
} from 'app/modules/sidebar/components/SidebarExternalLinkList';
import { U21DynamicColorChip } from 'app/shared/u21-ui/components/dashboard';
import {
  U21Subsection,
  U21TitleCountLabel,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Models
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

// Helpers
import { formatDate, formatTime } from 'app/shared/utils/date';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectHasReadEventsPermission } from 'app/modules/session/selectors';

export const SidebarAssociatedActionEventsOld = ({
  actionEvents,
}: {
  actionEvents: ShortActionEventResponse[];
}) => {
  return (
    <div>
      <SidebarTitleIcon
        count={actionEvents.length}
        title="Flagged Action Events"
        icon={<IconLock />}
      />
      {actionEvents.length ? (
        <SidebarExternalLinkList
          list={actionEvents.map((actionEvent) => ({
            label: (
              <>
                {formatDate(actionEvent.event_time)}
                <br />
                {formatTime(actionEvent.event_time)}
              </>
            ),
            value: actionEvent.action_type ? (
              <U21DynamicColorChip>
                {actionEvent.action_type}
              </U21DynamicColorChip>
            ) : null,
            type: 'label',
            id: actionEvent.id,
            route: ROUTES_MAP.dataExplorerActionEventsId.path.replace(
              ':id',
              String(actionEvent.id),
            ),
          }))}
        />
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No flagged action events
        </U21Typography>
      )}
    </div>
  );
};

interface Props {
  actionEvents: ShortActionEventResponse[];
  count: number | null;
}

export const SidebarAssociatedActionEvents = ({
  actionEvents,
  count,
}: Props) => {
  const hasReadEventsPermission = useSelector(selectHasReadEventsPermission);
  const list = useMemo(
    () =>
      actionEvents.map<DataProps>((i) => ({
        label: (
          <>
            {formatDate(i.event_time)}
            <br />
            {formatTime(i.event_time)}
          </>
        ),
        value: i.action_type ? (
          <U21DynamicColorChip>{i.action_type}</U21DynamicColorChip>
        ) : null,
        type: 'label',
        id: i.id,
        route: hasReadEventsPermission
          ? ROUTES_MAP.dataExplorerActionEventsId.path.replace(
              ':id',
              String(i.id),
            )
          : undefined,
      })),
    [actionEvents, hasReadEventsPermission],
  );
  return (
    <U21Subsection
      aria-label="Flagged Action Events"
      collapsible
      collapsed
      shaded
      title={
        <U21TitleCountLabel count={count} label="">
          Flagged Action Events
        </U21TitleCountLabel>
      }
      titleIcon={<IconLock />}
    >
      {actionEvents.length ? (
        <SidebarExternalLinkList list={list} />
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No flagged action events
        </U21Typography>
      )}
    </U21Subsection>
  );
};
