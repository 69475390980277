import { EntitySummary } from 'app/modules/entities/models';
import {
  U21Chip,
  U21TableColumn,
  U21TableBodyCellProps,
} from 'app/shared/u21-ui/components';
import { ROUTES_MAP } from 'app/shared/utils/routes';

import { toSentenceCase } from 'app/shared/utils/string';

import { IconArrowRight } from '@u21/tabler-icons';
import { ConsortiumMatchDict } from 'app/modules/entitiesRefresh/responses';
import { EntityRiskScore } from 'app/modules/riskRatings/components/EntityRiskScore';

type EntityTableColumn = U21TableColumn<EntitySummary, any>;

export const RISK_SCORE_COLUMN: EntityTableColumn = {
  id: 'risk_score',
  disableSortBy: true,
  // We do not have the risk score for the entity, we have to fetch it once the column is rendered
  Cell: ({
    row: { external_id: externalId },
  }: U21TableBodyCellProps<EntitySummary>) => {
    return <EntityRiskScore entityExternalId={externalId} />;
  },
};

const STATUS_COLUMN: EntityTableColumn = {
  id: 'status',
  type: 'label',
  accessor: (row) => toSentenceCase(row.status),
  cellProps: (row) => {
    let color;
    if (row.status === 'active') {
      color = 'success';
    } else if (row.status === 'inactive') {
      color = 'error';
    }
    return { color };
  },
};

export const GOTO_COLUMN: EntityTableColumn = {
  id: 'goto',
  type: 'button',
  cellProps: (entity) => ({
    children: <IconArrowRight />,
    to: ROUTES_MAP.entitiesId.path.replace(':id', String(entity.id)),
  }),
  disableSortBy: true,
};

export const ENTITY_COLUMN_CONFIG = {
  [RISK_SCORE_COLUMN.id]: RISK_SCORE_COLUMN,
  [STATUS_COLUMN.id]: STATUS_COLUMN,
};

export const BASE_CONSORTIUM_COLUMNS: U21TableColumn<
  ConsortiumMatchDict,
  any
>[] = [
  {
    id: 'last_seen',
    accessor: 'last_seen',
    Header: 'Last seen',
    type: 'datetime',
  },
  {
    id: 'blocked',
    Header: 'Blocked',
    disableSortBy: true,
    accessor: (row) =>
      row.blocked ? (
        <U21Chip color="error">True</U21Chip>
      ) : (
        <U21Chip color="success">False</U21Chip>
      ),
  },
  {
    id: 'registered_at',
    accessor: 'registered_at',
    Header: 'Registered at',
    type: 'datetime',
  },
  {
    id: 'blocked_reasons',
    accessor: 'blocked_reasons',
    Header: 'Blocked reasons',
    type: 'list',
    cellProps: {
      getShowLessText: () => 'Show fewer reasons',
      getShowMoreText: (length: number) => `Show all ${length} reasons`,
      displayFunc: (reason: string) => <U21Chip key={reason}>{reason}</U21Chip>,
    },
  },
  {
    id: 'last_blocked_at',
    accessor: 'last_blocked_at',
    Header: 'Blocked at',
    type: 'datetime',
  },
  {
    id: 'org_type',
    accessor: 'org_type',
    Header: 'Org type',
    type: 'text',
  },
];
