import { createSelector } from 'reselect';

// Selectors
import { selectSidebarAlert } from 'app/modules/alerts/selectors';
import { selectSidebarCase } from 'app/modules/casesOld/selectors';
import { selectSidebarSar } from 'app/modules/fincenSar/selectors';

// Types
import { FullTagResponse } from 'app/modules/tags/responses';
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { ShortActionEventResponse } from 'app/modules/actionEvents/responses';

// Models
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { AlertScore, AlertSummary } from 'app/modules/alerts/models';
import { CaseSummary } from 'app/modules/casesOld/models';
import { EntitySummary } from 'app/modules/entities/models';
import { RuleSummary } from 'app/modules/rules/models';
import { SarSummary } from 'app/modules/fincenSar/models';

interface SidebarInfoObject {
  // For alerts/cases
  title?: string;
  // For SARs
  name?: string;

  description?: string;

  // For alerts
  assigned_to_name?: string | null;
  // For cases
  assigned_to?: string;

  status?: string;

  // For alerts/cases
  disposition?: string;
  alert_score?: AlertScore;
}

interface SidebarAssociatedItemsObject {
  tags?: FullTagResponse[];
  entities?: EntitySummary[];
  alerts?: AlertSummary[];
  cases?: CaseSummary[];
  sars?: SarSummary[];
  rules?: RuleSummary[];
  events?: (ShortTxnResponse | ShortActionEventResponse)[];
  action_events?: ShortActionEventResponse[];
}

type SidebarObject = SidebarInfoObject & SidebarAssociatedItemsObject;

export const selectSidebarDefinition = (state: RootState) =>
  state.sidebar.definition;

export const selectSidebarOpen = createSelector(
  selectSidebarDefinition,
  (definition) => !!definition,
);

export const selectSidebarObject = createSelector(
  selectSidebarDefinition,
  selectSidebarAlert,
  selectSidebarCase,
  selectSidebarSar,
  (definition, sidebarAlert, sidebarCase, sidebarSar): SidebarObject => {
    const sidebarType = definition?.type;
    if (sidebarType === SidebarComponentTypes.ALERT) {
      return {
        ...sidebarAlert,
        // Alert action events are paginated, only pass the list of ids to the sidebar object
        action_events: sidebarAlert?.action_events?.events,
      };
    }
    if (sidebarType === SidebarComponentTypes.CASE) {
      return sidebarCase;
    }
    if (sidebarType === SidebarComponentTypes.SAR) {
      return sidebarSar;
    }
    return {};
  },
);
