import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/blacklists/models';

// Actions
import {
  addUserBlacklistItem as addUserBlacklistItemAction,
  addUserBlacklistItemSuccess,
} from 'app/modules/blacklists/actions';

// API
import { addUserBlacklistItem as addUserBlacklistItemApi } from 'app/shared/api/blacklists';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = BlacklistActionTypes.ADD_USER_BLACKLIST_ITEM;
function* addUserBlacklistItem({
  payload,
}: ReturnType<typeof addUserBlacklistItemAction>) {
  const config = {
    rootAction,
    request: call(addUserBlacklistItemApi, payload),
    errorToastMessage: 'Error adding blacklist item',
    successToastMessage: 'Blacklist item successfully added',
    success: function* onSuccess(result) {
      yield put(addUserBlacklistItemSuccess(result));
      if (result.blacklist_id) {
        history.push(pathJoin(routes.lumos.blacklists, result.blacklist_id));
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchAddUserBlacklistItem() {
  yield takeLatest(rootAction, addUserBlacklistItem);
}
