// Models
import { QueueDeadlineResponse } from 'app/modules/queues/queries/useSingleQueueDeadlineSummary';
import {
  ChartStack,
  DeadlineState,
  QueueType,
  SeriesPoint,
} from 'app/modules/queues/models';
import { QueueSubtypes } from 'app/modules/alerts/models';
import { QueueDetailsResponse } from 'app/modules/queues/responses';
import { IconClipboardList } from '@u21/tabler-icons';
import { U21SelectOptionProps } from 'app/shared/u21-ui/components';

/** Convert the deadline summary from the BE into the
 * format apexcharts wants it.
 */
export const convertDeadlineResponseToChartData = (
  queueDeadlineSummaryData: QueueDeadlineResponse[],
): ChartStack[] => {
  // Generating stack arrays
  const late = queueDeadlineSummaryData.map((q) => q.deadline_summary.late);
  const warning = queueDeadlineSummaryData.map(
    (q) => q.deadline_summary.warning,
  );
  const okay = queueDeadlineSummaryData.map((q) => q.deadline_summary.okay);
  const noDeadline = queueDeadlineSummaryData.map(
    (q) => q.deadline_summary.no_associated_deadline,
  );

  // Combining into series
  return [
    {
      name: DeadlineState.LATE,
      data: late,
    },
    {
      name: DeadlineState.WARNING,
      data: warning,
    },
    {
      name: DeadlineState.OKAY,
      data: okay,
    },
    {
      name: DeadlineState.NO_DEADLINE,
      data: noDeadline,
    },
  ];
};

export const convertSingleDeadlineResponse = ({
  deadline_summary: { late, warning, okay, no_associated_deadline: noDeadline },
}: QueueDeadlineResponse): SeriesPoint[] => {
  return [
    { x: DeadlineState.LATE, y: late },
    { x: DeadlineState.WARNING, y: warning },
    { x: DeadlineState.OKAY, y: okay },
    {
      x: DeadlineState.NO_DEADLINE,
      y: noDeadline,
    },
  ];
};

export const convertQueues = (
  queueResponse: QueueDetailsResponse[] | undefined,
  queueType: QueueType,
  queueSubtype?: QueueSubtypes,
): QueueDetailsResponse[] => {
  if (!queueResponse) {
    return [];
  }

  return queueType === QueueType.ALERT_QUEUE && queueSubtype
    ? queueResponse.filter(
        (queue) => queue.subtype === queueSubtype || queue.subtype === null,
      )
    : queueResponse;
};

export const convertFormattedQueues = (
  queueResponse: QueueDetailsResponse[] | undefined,
  queueType: QueueType,
  queueSubtype?: QueueSubtypes,
): U21SelectOptionProps[] => {
  return convertQueues(queueResponse, queueType, queueSubtype).map((queue) => ({
    key: queue.id,
    value: queue.id,
    text: queue.title,
    icon: <IconClipboardList />,
  }));
};

export const getQueueTypeDisplayName = (queueType?: `${QueueType}`): string => {
  switch (queueType) {
    case QueueType.ALERT_QUEUE:
      return 'Alerts';
    case QueueType.CASE_QUEUE:
      return 'Cases';
    case QueueType.SAR_QUEUE:
      return 'Fillings';
    default:
      return 'Investigations';
  }
};
