import createSar from 'app/modules/fincenSar/sagas/createSar';
import createNewSar from 'app/modules/fincenSar/sagas/createNewSar';
import retrieveSidebarSar from 'app/modules/fincenSar/sagas/retrieveSidebarSar';
import retrieveSarList from 'app/modules/fincenSar/sagas/retrieveSarList';
import archiveSar from 'app/modules/fincenSar/sagas/archiveSar';

const sagaRegistry = [
  createSar,
  createNewSar,
  retrieveSidebarSar,
  retrieveSarList,
  archiveSar,
];

export default sagaRegistry;
