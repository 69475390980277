import { IconProps } from 'app/shared/components/Icons/models';

export const IconBriefcaseArrow = ({
  size = 20,
  color = 'currentColor',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1429 7.57143V6.42857C21.1429 5.51926 20.7816 4.64719 20.1387 4.0042C19.4957 3.36122 18.6236 3 17.7143 3H13.1429C12.2336 3 11.3615 3.36122 10.7185 4.0042C10.0755 4.64719 9.71429 5.51926 9.71429 6.42857V7.57143H7.42857C5.53502 7.57143 4 9.10645 4 11V21.2857C4 23.1793 5.53502 24.7143 7.42857 24.7143L14.2857 24.7143C14.9169 24.7143 15.4286 24.2026 15.4286 23.5714C15.4286 22.9402 14.9169 22.4286 14.2857 22.4286L7.42857 22.4286C6.79739 22.4286 6.28572 21.9169 6.28572 21.2857V17.2918C6.35821 17.3254 6.4311 17.3583 6.50423 17.3905C7.35843 17.7665 8.35233 18.0923 9.37651 18.3592C11.4146 18.8904 13.6875 19.2173 15.3957 19.205C16.0269 19.2005 16.5349 18.6851 16.5303 18.054C16.5258 17.4228 16.0104 16.9148 15.3793 16.9194C13.9189 16.9299 11.85 16.6418 9.95293 16.1474C9.00955 15.9016 8.13756 15.6121 7.42502 15.2984C6.92491 15.0783 6.54798 14.8655 6.28572 14.6761V11C6.28572 10.3688 6.79739 9.85714 7.42857 9.85714H23.4286C24.0598 9.85714 24.5714 10.3688 24.5714 11V14.424L24.5714 14.4286V15.5714C24.5714 16.2026 25.0831 16.7143 25.7143 16.7143C26.3455 16.7143 26.8571 16.2026 26.8571 15.5714L26.8572 11C26.8572 9.10645 25.3221 7.57143 23.4286 7.57143H21.1429ZM13.1429 5.28571C12.8398 5.28571 12.5491 5.40612 12.3347 5.62045C12.1204 5.83478 12 6.12547 12 6.42857V7.57143H18.8572V6.42857C18.8572 6.12547 18.7367 5.83478 18.5224 5.62045C18.3081 5.40612 18.0174 5.28571 17.7143 5.28571H13.1429Z"
      fill={color}
    />
    <path
      d="M22.6204 18.1919C23.0668 17.7456 23.7904 17.7456 24.2367 18.1919L27.6653 21.6205C28.1116 22.0668 28.1116 22.7904 27.6653 23.2367L24.2367 26.6653C23.7904 27.1116 23.0668 27.1116 22.6204 26.6653C22.1741 26.219 22.1741 25.4953 22.6204 25.049L24.098 23.5714H18.8571C18.226 23.5714 17.7143 23.0598 17.7143 22.4286C17.7143 21.7974 18.226 21.2857 18.8571 21.2857H24.098L22.6204 19.8081C22.1741 19.3618 22.1741 18.6382 22.6204 18.1919Z"
      fill={color}
    />
    <path
      d="M16.5714 14.4286C16.5714 15.0598 16.0598 15.5714 15.4286 15.5714C14.7974 15.5714 14.2857 15.0598 14.2857 14.4286C14.2857 13.7974 14.7974 13.2857 15.4286 13.2857C16.0598 13.2857 16.5714 13.7974 16.5714 14.4286Z"
      fill={color}
    />
  </svg>
);
