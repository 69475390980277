import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  AlertDetails,
  AlertsActionTypes,
  RetrieveAlertEntitiesPayload,
  RetrieveAlertTxnsPayload,
} from 'app/modules/alerts/models';

// Actions
import {
  retrieveAlert as retrieveAlertAction,
  retrieveAlertSuccess,
  retrieveAlertAuditTrail,
  retrieveAlertEntities,
  retrieveAlertTransactions,
} from 'app/modules/alerts/actions';
import { retrieveCustomChecklistSubmission } from 'app/shared/customChecklist/actions';

// API
import { getAlert as getAlertApi } from 'app/shared/api/alerts';

// Constants
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';

const rootAction = AlertsActionTypes.RETRIEVE_ALERT;
export function* retrieveAlert({
  payload,
}: ReturnType<typeof retrieveAlertAction>) {
  const config = {
    rootAction,
    request: call(getAlertApi, payload),
    success: function* onSuccess(result: AlertDetails) {
      yield put(retrieveAlertAuditTrail(payload));
      yield put(retrieveAlertSuccess(result));

      const entitiesPayload: RetrieveAlertEntitiesPayload = {
        alertId: String(result.id),
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      const transactionsPayload: RetrieveAlertTxnsPayload = {
        hash_key: String(result.id),
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      yield put(retrieveAlertEntities(entitiesPayload));
      yield put(retrieveAlertTransactions(transactionsPayload));
      yield put(retrieveCustomChecklistSubmission(result.id));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlert() {
  yield takeLatest(rootAction, retrieveAlert);
}
