import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/blacklists/models';

// Actions
import {
  retrieveBlacklistItems as retrieveBlacklistItemsAction,
  retrieveBlacklistItemsSuccess,
} from 'app/modules/blacklists/actions';

// API
import { retrieveBlacklistItems as retrieveBlacklistItemsApi } from 'app/shared/api/blacklists';

const rootAction = BlacklistActionTypes.RETRIEVE_BLACKLIST_ITEMS;
function* retrieveBlacklistItems({
  payload,
}: ReturnType<typeof retrieveBlacklistItemsAction>) {
  const config = {
    rootAction,
    request: call(retrieveBlacklistItemsApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveBlacklistItemsSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveBlacklistItems() {
  yield takeLatest(rootAction, retrieveBlacklistItems);
}
