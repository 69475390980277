import { upload, post, put, destroy } from 'app/shared/utils/fetchr';
import routes, { pathJoin } from 'app/shared/utils/routes';

// Models
import {
  CaseEditPayload,
  AddDocumentsPayload,
  DeleteDocumentPayload,
  CreateCasePayload,
} from 'app/modules/casesOld/models';
import {
  FullCaseResponse,
  ShortCaseResponse,
} from 'app/modules/casesOld/types';
import {
  SingleFileExportPayload,
  BulkFileExportPayload,
} from 'app/modules/fileExportsOld/models';
import {
  UploadFilesPayload,
  RemoveDocumentFromCaseResponse,
} from 'app/modules/uploads/models';

// Actions
import { removeNullKeys } from 'app/shared/utils/apiUtils';
import { TriggerActionPayload } from 'app/modules/webhooks/models';

export const createCase = (payload: CreateCasePayload) => {
  const { docs, s3_docs: s3Docs, ...body } = payload;
  return post<ShortCaseResponse>(routes.patronus.casesCreate, body);
};

export const updateCase = (payload: CaseEditPayload) => {
  const { id, ...details } = payload;
  return put<FullCaseResponse>(
    routes.patronus.casesEditId.replace(':id', String(id)),
    details,
  );
};

export const addDocumentsToCase = (payload: AddDocumentsPayload) => {
  const { caseId, S3documents, documents } = payload;
  // remove null keys
  const s3Docs = S3documents.map((doc) => removeNullKeys(doc));
  const docs = documents.map((doc) => removeNullKeys(doc));

  return post(pathJoin('/cases', caseId, '/attachments', 'add'), {
    s3_docs: s3Docs,
    docs,
  });
};

export const deleteDocumentFromCase = (payload: DeleteDocumentPayload) => {
  const { caseId, docId } = payload;
  const formattedPath = routes.patronus.casesIdAttachmentsAttachmentId
    .replace(':id', String(caseId))
    .replace(':attachmentId', String(docId));
  return destroy<RemoveDocumentFromCaseResponse>(formattedPath);
};

export const addFiles = (payload: UploadFilesPayload) => {
  const formData = new FormData();

  payload.files?.forEach((S3File, index) => {
    formData.append(`file${index}`, S3File);
  });

  return upload(
    routes.patronus.casesIdAddFiles.replace(':id', payload.id),
    formData,
  );
};

export const triggerAction = (payload: TriggerActionPayload) => {
  const { resourceId, actionId, ...body } = payload;
  return post(
    routes.patronus.casesIdTriggerAction.replace(':id', String(resourceId)),
    { action_trigger_id: actionId, ...body },
  );
};

export const sendSingleCaseXlsxRequest = (payload: SingleFileExportPayload) => {
  return post(routes.patronus.casesSingleEmailXlsx, payload);
};

export const sendBulkCasesXlsxRequest = (payload: BulkFileExportPayload) => {
  return post(routes.patronus.casesBulkXlsx, payload);
};
