import { SyntheticEvent, useMemo } from 'react';

import { selectTransactionDataSettingsByNativeKey } from 'app/modules/dataSettings/selectors';
import { useSelector } from 'react-redux';

import { U21MultiSelect } from 'app/shared/u21-ui/components';

interface OwnProps {
  onChange: (value: string[], e: SyntheticEvent) => void;
  value: string[];
}
export const TxnTypeSelect = ({ onChange, value }: OwnProps) => {
  const transactionCodes = useSelector(selectTransactionDataSettingsByNativeKey)
    ?.type?.enum_set;

  const options = useMemo(
    () => (transactionCodes ?? []).map((i) => ({ text: i, value: i })),
    [transactionCodes],
  );

  return (
    <U21MultiSelect
      backdrop
      onChange={onChange}
      options={options}
      value={value}
    />
  );
};
