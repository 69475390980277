// Models
import { Selector } from 'react-redux';
import { EntityType } from 'app/modules/entities/models';
import { PaginationPayload } from 'app/shared/pagination/models';
import { RuleStatus } from 'app/modules/rules/models';
import { CaseStatus } from 'app/modules/casesOld/models';
import { QueueAccessType } from 'app/modules/queues/models';
import { ElementType } from 'react';

export enum SearchActionTypes {
  ENTITIES_SEARCH = '@@search/ENTITIES_SEARCH',
  ENTITIES_SEARCH_SUCCESS = '@@search/ENTITIES_SEARCH_SUCCESS',

  TEAMS_SEARCH = '@@search/TEAMS_SEARCH',
  TEAMS_SEARCH_SUCCESS = '@@search/TEAMS_SEARCH_SUCCESS',

  CENSORSHIP_FIELDS_SEARCH = '@@search/CENSORSHIP_FIELDS_SEARCH',
  CENSORSHIP_FIELDS_SEARCH_SUCCESS = '@@search/CENSORSHIP_FIELDS_SEARCH_SUCCESS',

  RULES_SEARCH = '@@search/RULES_SEARCH',
  RULES_SEARCH_SUCCESS = '@@search/RULES_SEARCH_SUCCESS',

  ALERTS_SEARCH = '@@search/ALERTS_SEARCH',
  ALERTS_SEARCH_SUCCESS = '@@search/ALERTS_SEARCH_SUCCESS',

  EVENTS_SEARCH = '@@search/EVENTS_SEARCH',
  EVENTS_SEARCH_SUCCESS = '@@search/EVENTS_SEARCH_SUCCESS',
}

export enum SearchTypes {
  ACTION_EVENTS = 'actionEvents',
  ALERTS = 'alerts',
  CASES = 'cases',
  ENTITIES = 'entities',
  INSTRUMENTS = 'instruments',
  RULES = 'rules',
  TEAMS = 'teams',
  TRANSACTIONS = 'events',
}

export interface TypeDropdownOption {
  key: string;
  value: string;
  text: string;
  // to add to the end of the search placeholder so we know what we are searching
  placeholder: string;
}

export interface SearchRequest {
  phrase: string;
  offset: number;
  limit: number;
  type?: string;
  status?: string;
  ids?: (number | string)[];
  external_ids?: string[];
}

export interface RulesSearchRequest extends SearchRequest {
  status?: RuleStatus;
  statuses?: RuleStatus[];
}

export interface CGDOSearchRequest {
  phrase: string;
  offset: number;
  limit: number;
  type?: string;
  status?: string;
  ids?: (number | string)[];
}

export interface EntitySearchRequest extends CGDOSearchRequest {
  external_ids?: string[];
}

export interface PaginatedSearchRequest extends PaginationPayload {
  phrase: string;
}

export interface CensorshipFieldsResult {
  id: number;
  name: string;
  display_name: string;
}

export interface CensorshipFieldsState {
  count: number;
  data: CensorshipFieldsResult[];
}

export interface EntitySearchResponse {
  data: EntitiesSearchResult[];
}

export interface EntitiesSearchResult {
  id: number;
  external_id: string;
  type: EntityType;
  email?: string;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  user_name?: string;
  name?: string;
  name_readable?: string;
  custom_data?: string;
}

interface EntitiesState {
  data: EntitiesSearchResult[];
}

export interface AlertsSearchResult {
  id: number;
  title: string;
  description: string;
  external_id: string;
  queue_id: number | null;
  queue_access_type: `${QueueAccessType}`;
  // not using AlertStatus to avoid a bunch of typing changes elsewhere
  status: string;
}

interface AlertsState {
  data: AlertsSearchResult[];
}

export interface AlertsSearchResponse {
  data: AlertsSearchResult[];
}

export interface CasesSearchResult {
  id: number;
  title: string;
  description: string;
  status: CaseStatus;
  queue_id: number | null;
  queue_access_type: `${QueueAccessType}`;
}

export interface CasesSearchResponse {
  data: CasesSearchResult[];
}

export interface EventsSearchResult {
  id: number;
  external_id: string;
}

interface EventsState {
  data: EventsSearchResult[];
}

interface ActionEventsState {
  data: EventsSearchResult[];
}

export interface EventsSearchResponse {
  data: EventsSearchResult[];
}

export interface InstrumentsSearchResponse {
  data: InstrumentsSearchResult[];
}

export interface InstrumentsSearchResult {
  id: number;
  custom_data: string;
  instrument_type: string;
  name: string;
  source: string;
  status: string;
  external_id: string;
}
export interface RulesSearchResult {
  id: number;
  title: string;
  description: string;
  status: RuleStatus;
  alert_queue_id?: number;
  alert_queue_title?: string;
}

export interface RulesSearchResponse {
  count: number;
  data: RulesSearchResult[];
}

export interface TeamsSearchResult {
  id: number;
  name: string;
  description: string;
  agents_count: number;
}

export interface TeamsSearchResponse {
  count: number;
  data: TeamsSearchResult[];
}

export interface TeamsState {
  count: number;
  data: TeamsSearchResult[];
}

export interface RulesState {
  count: number;
  data: RulesSearchResult[];
}

export interface SearchResultProps {
  description?: string;
  icon: string;
  key: string | number;
  label?: string;
  queueAccessType?: `${QueueAccessType}`;
  queueId?: number | null;
  title: string;
  url: string;
  value: string | number;
}

export interface ResourceDropdownItem {
  icon: ElementType;
  text: string;
  value: SearchTypes;
  type?: string;
  placeholder: string;
  formatSearchResult: (result: any) => SearchResultProps;
  resultsSelector?: Selector<RootState, any>;
  permission: string;
}

export interface SearchState {
  entities: EntitiesState;
  rules: RulesState;
  alerts: AlertsState;
  events: EventsState;
  teams: TeamsState;
  censorshipFields: CensorshipFieldsState;
}

export interface NewSearchState {
  actionEvents: ActionEventsState;

  // Loading
  actionEventsLoading: boolean;
}

export type SearchResponse =
  | AlertsSearchResponse
  | CasesSearchResponse
  | EntitySearchResponse
  | EventsSearchResponse
  | InstrumentsSearchResponse
  | RulesSearchResponse
  | TeamsSearchResponse;

export type SearchResult =
  | AlertsSearchResult
  | CasesSearchResult
  | EntitiesSearchResult
  | EventsSearchResult
  | InstrumentsSearchResult
  | RulesSearchResult
  | TeamsSearchResult;
