import { call, put, select, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  createAlert as createAlertAction,
  createAlertSuccess,
} from 'app/modules/alerts/actions';
import { sendErrorToast, sendSuccessToast } from 'app/shared/toasts/actions';

// API
import {
  createAlert as createAlertApi,
  uploadFile,
} from 'app/shared/api/alerts';

// Utils
import routes from 'app/shared/utils/routes';
import { submitCustomChecklist as submitCustomChecklistApi } from 'app/shared/customChecklist/api';
import { history } from 'app/store/browserHistory';

// Selectors
import {
  selectCustomChecklistContent,
  selectCustomChecklistId,
} from 'app/shared/customChecklist/selectors';
import { selectFilesToUpload } from 'app/modules/uploads/selectors';

const rootAction = AlertsActionTypes.CREATE_ALERT;
function* createAlert({ payload }: ReturnType<typeof createAlertAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to create new alert',
    request: call(createAlertApi, payload),
    success: function* onSuccess(result) {
      const { id } = result;
      // Creating custom checklist on the alert
      const content = yield select(selectCustomChecklistContent);
      const checklistId = yield select(selectCustomChecklistId);
      if (checklistId) {
        // call api directly to ensure this is saved before retrieving an empty value in the CaseWorkspace
        yield call(submitCustomChecklistApi, {
          checklist_id: checklistId,
          article_id: id,
          content,
        });
      }
      // Uploading attachments on the alert
      const attachments = yield select(selectFilesToUpload);
      if (attachments && attachments.length > 0) {
        const addS3FilesPayload = {
          id,
          files: attachments.map((a) => a.binary),
        };
        try {
          yield call(uploadFile, addS3FilesPayload);
        } catch (response) {
          sendErrorToast(
            'Error adding attachments to alert. Please contact Unit21 for support.',
          );
        }
      }

      yield put(sendSuccessToast('Alert created successfully.'));
      yield put(createAlertSuccess(result));
      history.push(routes.lumos.alertsId.replace(':id', String(id)));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchCreateAlert() {
  yield takeLatest(rootAction, createAlert);
}
