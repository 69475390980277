import { InstrumentSummary } from 'app/modules/txnInstruments/models';
import { U21TableColumn, U21Chip } from 'app/shared/u21-ui/components';

import { ROUTES_MAP } from 'app/shared/utils/routes';
import { ConsortiumMatchDict } from 'app/modules/entitiesRefresh/responses';
import { IconArrowRight } from '@u21/tabler-icons';
import { ReactNode } from 'react';

type InstrumentTableColumn = Partial<U21TableColumn<InstrumentSummary>> & {
  id: string;
};

const CLIENT_FINGERPRINTS: InstrumentTableColumn = {
  id: 'client_fingerprints',
  type: 'list',
};

const IP_ADDRESSES: InstrumentTableColumn = {
  id: 'ip_addresses',
  type: 'list',
};

export const GOTO_COLUMN: InstrumentTableColumn = {
  id: 'goto',
  type: 'button',
  cellProps: (instrument) => ({
    children: <IconArrowRight />,
    to: ROUTES_MAP.instrumentsId.path.replace(':id', String(instrument.id)),
  }),
  disableSortBy: true,
};

export const INSTRUMENT_COLUMN_CONFIG = {
  [CLIENT_FINGERPRINTS.id]: CLIENT_FINGERPRINTS,
  [IP_ADDRESSES.id]: IP_ADDRESSES,
};

export const BASE_CONSORTIUM_COLUMNS: U21TableColumn<
  ConsortiumMatchDict,
  ReactNode
>[] = [
  {
    id: 'last_seen',
    accessor: 'last_seen',
    Header: 'Last seen',
    type: 'datetime',
  },
  {
    id: 'blocked',
    Header: 'Blocked',
    disableSortBy: true,
    type: 'label',
    accessor: ({ blocked }) => (blocked ? 'True' : 'False'),
    cellProps: ({ blocked }) => ({
      color: blocked ? 'error' : 'success',
    }),
  },
  {
    id: 'registered_at',
    accessor: 'registered_at',
    Header: 'Registered at',
    type: 'datetime',
  },
  {
    id: 'blocked_reasons',
    accessor: 'blocked_reasons',
    Header: 'Blocked reasons',
    type: 'list',
    cellProps: {
      getShowLessText: () => 'Show fewer reasons',
      getShowMoreText: (length: number) => `Show all ${length} reasons`,
      displayFunc: (reason: string) => <U21Chip key={reason}>{reason}</U21Chip>,
    },
  },
  {
    id: 'last_blocked_at',
    accessor: 'last_blocked_at',
    Header: 'Blocked at',
    type: 'datetime',
  },
  {
    id: 'org_type',
    accessor: 'org_type',
    Header: 'Org type',
    type: 'text',
  },
];
