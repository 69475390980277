import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import {
  NetworkAnalysisResponse,
  LinkedEntity,
} from 'app/modules/networkAnalysis/types';
import {
  ACCEPTED_LINK_TYPES,
  LINK_ANALYSIS_LINK_TYPES,
} from 'app/modules/networkAnalysis/constants';

type EntityLinksResponse = Record<
  string,
  { data: any; id1?: string; id2?: string }
>;

const transformEntityLinkResponse = (
  initialData: EntityLinksResponse,
  baseEntityId: string,
): NetworkAnalysisResponse => {
  const unseenEntities: { id: string; missingLink: string }[] = [];
  const transformedResponse = Object.values(
    initialData,
  ).reduce<NetworkAnalysisResponse>(
    (acc, obj) => {
      const {
        data: { object_type: objectType, type },
      } = obj;
      if (obj.data.id === null) {
        return acc;
      }
      if (objectType === 'NODE') {
        const {
          base_node: ignore1,
          node_id: ignore2,
          object_type: ignore3,
          id,
          ...rest
        } = {
          ...(obj.data as LinkedEntity),
          links: [],
          base_node: '', // For TS-2525
          node_id: '',
          object_type: '',
        };
        acc.entities[obj.data.id] = { ...rest, id: id.toString() };
      } else if (ACCEPTED_LINK_TYPES.has(type)) {
        const {
          id1,
          id2,
          data: { total, sent },
        } = obj;
        if (type === LINK_ANALYSIS_LINK_TYPES.TRANSACTION) {
          const txnId = `Transaction_${id1}_${id2}`;
          let entityId = id1?.replace('$ENTITY_', '') ?? '';
          if (entityId === baseEntityId) {
            entityId = id2?.replace('$ENTITY_', '') ?? '';
          }
          const amount = total * (sent ? -1 : 1);
          acc.transactions[txnId] = {
            id: txnId!,
            amount,
            entity: entityId,
          };
        } else {
          const linkId = id2;
          const entityId = id1?.replace('$ENTITY_', '') ?? '';
          const linkVal: string | number =
            linkId?.replace(
              `${
                type === LINK_ANALYSIS_LINK_TYPES.INSTRUMENT
                  ? '$INSTRUMENT'
                  : type
              }_`,
              '',
            ) ?? '';
          const entities = baseEntityId === entityId ? [] : [entityId];
          if (!acc.links[linkId!]) {
            acc.links[linkId!] = {
              id: linkId!,
              type,
              value: linkVal,
              entities,
            };
          } else if (baseEntityId !== entityId) {
            acc.links[linkId!].entities.push(entityId);
          }
          if (acc.entities[entityId]) {
            acc.entities[entityId].links.push(linkId!);
          } else {
            unseenEntities.push({ id: entityId, missingLink: linkId! });
          }
        }
      }
      return acc;
    },
    { links: {}, entities: {}, transactions: {} },
  );

  for (const { id, missingLink } of unseenEntities) {
    transformedResponse.entities[id]?.links.push(missingLink);
  }
  return transformedResponse;
};

const fetchEntityLinks = async (entityId: string) => {
  const response = await get<EntityLinksResponse>(
    `/entities/transaction-network/${entityId}`,
  );
  return transformEntityLinkResponse(response, entityId);
};

export const useEntityLinks = (entityId: string) => {
  return useQuery({
    queryKey: ['entity-links', entityId],
    queryFn: () => fetchEntityLinks(entityId),
    refetchOnWindowFocus: false,
  });
};
