import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { U21NoValue, U21Typography } from 'app/shared/u21-ui/components';
import { isRenderable } from 'app/shared/utils/react';

interface LabelProps {
  children?: ReactNode;
  // JSON data setting types are caption size because they're wrapped in <pre> tags
  typographyVariant?: 'body2' | 'caption';
}

export const SidebarFieldValue: FC<LabelProps> = ({
  children,
  typographyVariant = 'body2',
}) => {
  if (!isRenderable(children)) {
    return <U21NoValue />;
  }

  return (
    <StyledU21TypographyValue variant={typographyVariant}>
      {children}
    </StyledU21TypographyValue>
  );
};

const StyledU21TypographyValue = styled(U21Typography)`
  word-break: break-all;
  white-space: pre-wrap;
  width: 100%;

  /* so U21Chip ellipsis takes effect */
  p {
    width: 100%;
  }
`;
