// Components
import { U21Spacer } from 'app/shared/u21-ui/components';

import { PriorAlerts } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorAlerts';
import { PriorCases } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorCases';
import { PriorSARs } from 'app/modules/alerts/components/AlertDetails/PriorActivity/PriorSARs';

export const PriorActivity = () => {
  return (
    <U21Spacer spacing={2}>
      <PriorAlerts />
      <PriorCases />
      <PriorSARs />
    </U21Spacer>
  );
};
