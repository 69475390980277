// Saga
import { call, put, takeLatest, select } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { retrieveSar as api } from 'app/shared/api/sars';

// Models
import { SarActionTypes } from 'app/modules/fincenSar/models';

// Actions
import {
  retrieveSidebarSar,
  retrieveSidebarSarSuccess,
} from 'app/modules/fincenSar/actions';

// Selectors
import { selectHasReadSARsPermissions } from 'app/modules/session/selectors';

const rootAction = SarActionTypes.RETRIEVE_SIDEBAR_SAR;
export function* retrieveSar({
  payload,
}: ReturnType<typeof retrieveSidebarSar>) {
  const hasReadSARsPermissions = yield select(selectHasReadSARsPermissions);
  if (!hasReadSARsPermissions) {
    return;
  }

  const config = {
    rootAction,
    request: call(api, payload),
    success: function* onSuccess(result) {
      yield put(retrieveSidebarSarSuccess(result));
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveSar);
}
