import { HTMLProps } from 'react';
import { U21Themes } from 'vendor/material-minimal/models';

import { getDOMProps } from 'app/shared/utils/react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as LogoBlack } from 'app/shared/components/logos/logoBlack.svg';
import { ReactComponent as LogoWhite } from 'app/shared/components/logos/logoWhite.svg';

export const Unit21Logo = (props: HTMLProps<SVGElement>) => {
  const {
    palette: { mode },
  } = useSelector(useTheme);
  const Logo = mode === U21Themes.LIGHT ? LogoBlack : LogoWhite;
  return <Logo {...getDOMProps(props)} />;
};
