import { AUDIT_LIST_QUERY_KEYS } from 'app/modules/auditService/queries/keys';

import { get } from 'app/shared/utils/fetchr';
import { useQuery } from '@tanstack/react-query';
import {
  AuditTrailAssetType,
  AuditServiceEntry,
} from 'app/modules/auditService/types';

export interface AuditServiceResponse {
  rawAssetId: string;
  auditLogs: AuditServiceEntry[];
  rawAssetType: string;
}

const listAuditTrail = (
  assetType: AuditTrailAssetType,
  assetId: string,
): Promise<AuditServiceResponse> =>
  get(`/audit-service/${assetType}/${assetId}/list`);

export const useAuditServiceList = (
  assetType: AuditTrailAssetType,
  assetId: string,
) => {
  return useQuery({
    queryKey: AUDIT_LIST_QUERY_KEYS.list(assetType, assetId),
    queryFn: () => listAuditTrail(assetType, assetId),
    meta: { errorMessage: 'Failed to get audit history.' },
    enabled: assetId !== '',
  });
};
