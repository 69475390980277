import { AsyncStatus } from 'app/shared/types/utils/asyncStatus';

export enum SummaryViewConfigTypeClassifier {
  ENTITY = 'ENTITY',
  INSTRUMENT = 'INSTRUMENT',
  EVENT = 'EVENT',
}

export type SummaryViewConfig = SummaryViewConfigGroup[];

export interface SummaryViewConfigGroup {
  id: string;
  group: string;
  fields: number[];
}

export type SummaryViewConfigs = Record<
  SummaryViewConfigTypeClassifier,
  SummaryViewConfig
>;

export interface SummarySettingsState {
  configs: AsyncStatus<SummaryViewConfigs>;
  initialConfigs?: SummaryViewConfigs;
  selectedClassifier: SummaryViewConfigTypeClassifier;
  selectedGroup?: SummaryViewConfigGroup['id'];
  updateConfigPending: boolean;
}

export type SummaryViewConfigFieldToGroupMap = Record<number, string[]>;
