import { useSelector } from 'react-redux';

// Components
import { U21_NO_VALUE } from 'app/shared/u21-ui/components';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';

// Styles
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';

// Selectors
import { selectSidebarObject } from 'app/modules/sidebar/selectors';

// Utils
import { DispositionChip } from 'app/modules/investigations/components/DispositionChip';

export const SidebarDisposition = () => {
  const { disposition } = useSelector(selectSidebarObject);

  return (
    <StyledItem>
      <SidebarFieldLabel>Disposition</SidebarFieldLabel>
      {disposition ? (
        <DispositionChip disposition={disposition} />
      ) : (
        U21_NO_VALUE
      )}
    </StyledItem>
  );
};
