import { useQuery } from '@tanstack/react-query';

import { post } from 'app/shared/utils/fetchr';
import { TXN_ANALYSIS_QUERY_KEYS } from 'app/modules/txnAnalysis/queries/keys';
import { TxnAnalysisPayload } from 'app/modules/txnAnalysis/requests';
import { TxnStatsResponse } from 'app/modules/transactions/types';

export const retrieveTxnAnalysisStats = (
  payload: TxnAnalysisPayload,
): Promise<TxnStatsResponse> => {
  return post(`/txn-analysis/stats`, payload);
};

export const useGetTxnAnalysisStats = (payload: TxnAnalysisPayload) => {
  return useQuery({
    queryKey: TXN_ANALYSIS_QUERY_KEYS.getTxnAnalysisStatsQueryKey(payload),
    queryFn: () => retrieveTxnAnalysisStats(payload),
    meta: { errorMessage: 'Failed to get transaction analysis statistics.' },
  });
};
