import {
  Filter,
  FilterOption,
  IsOperatorAndValue,
} from 'app/modules/filters/models';
import {
  getAgentFilterOption,
  getEntityFilterOption,
  getQueueFilterOption,
} from 'app/modules/filters/filters';
import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { QueueType } from 'app/modules/queues/models';
import {
  COUNTRY_OPTIONS,
  getStateOptions,
} from 'app/shared/constants/locations';
import { FILING_STATUS_OPTIONS } from 'app/modules/fincenSarNew/status';
import { FincenSarTablePages } from 'app/modules/fincenSar/models';
import { FilingTypeFilter } from 'app/modules/filings/components/FilingTypeFilter';
import { FilingTypeFilterDisplay } from 'app/modules/filings/components/FilingTypeFilterDisplay';

// Note: These keys are tightly coupled with BE implementation
export const SAR_FILING_FILTER_KEYS = {
  BSA_CASE_ID: 'bsa_case_id',
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  ENTITY: 'entity',
  REVIEWER: 'reviewer',
  SUBJECT_COUNTRY: 'subject_country',
  SUBJECT_STATE: 'subject_state',
  SUBJECT_CITY: 'subject_city',
  FILED_AT: 'filed_at',
  LAST_UPDATED_AT: 'last_updated_at',
  QUEUE: 'queue',
  FILING_TYPE: 'filing_type',
  STATUS: 'status',
  SUBMITTED: 'submitted',
} as const;

export const ALL_SAR_FILING_FILTER_OPTIONS: FilterOption[] = [
  {
    key: SAR_FILING_FILTER_KEYS.BSA_CASE_ID,
    label: 'BSA / Case ID',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: SAR_FILING_FILTER_KEYS.CREATED_AT,
    label: 'Created at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: SAR_FILING_FILTER_KEYS.CREATED_BY,
    ...getAgentFilterOption('Created by', false),
  },
  {
    key: SAR_FILING_FILTER_KEYS.ENTITY,
    ...getEntityFilterOption({ useExternalID: true }),
  },
  {
    key: SAR_FILING_FILTER_KEYS.FILED_AT,
    label: 'Filed at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: SAR_FILING_FILTER_KEYS.FILING_TYPE,
    label: 'Filing type',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        Display: ({
          operator,
          value,
        }: Omit<IsOperatorAndValue<string>, 'onChange'>) => {
          return <FilingTypeFilterDisplay operator={operator} value={value} />;
        },
        Input: ({ onChange, value }: IsOperatorAndValue<string>) => {
          return <FilingTypeFilter onChange={onChange} value={value} />;
        },
      },
    },
  },
  {
    key: SAR_FILING_FILTER_KEYS.REVIEWER,
    ...getAgentFilterOption('Reviewer', false),
  },
  {
    key: SAR_FILING_FILTER_KEYS.QUEUE,
    ...getQueueFilterOption(QueueType.SAR_QUEUE),
  },
  {
    key: SAR_FILING_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.MULTI_SELECT,
    customize: {
      [FILTER_OPERATOR.IS_ONE_OF]: {
        inputProps: {
          options: FILING_STATUS_OPTIONS, // We are using the new SARs status
        },
      },
      [FILTER_OPERATOR.IS_NOT_ONE_OF]: { disabled: true },
    },
  },
  {
    key: SAR_FILING_FILTER_KEYS.SUBJECT_CITY,
    label: 'Subject city',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: SAR_FILING_FILTER_KEYS.SUBJECT_COUNTRY,
    label: 'Subject country',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        inputProps: {
          options: COUNTRY_OPTIONS,
        },
      },
    },
  },
  {
    key: SAR_FILING_FILTER_KEYS.SUBJECT_STATE,
    label: 'Subject state',
    type: FILTER_TYPE.SELECT,
  },
  {
    key: SAR_FILING_FILTER_KEYS.SUBMITTED,
    label: 'Submitted',
    type: FILTER_TYPE.BOOLEAN,
  },
  {
    key: SAR_FILING_FILTER_KEYS.LAST_UPDATED_AT,
    label: 'Updated at',
    type: FILTER_TYPE.DATE_RANGE,
  },
];

export const FILING_FILTER_OPTIONS_BY_PAGE: Record<
  FincenSarTablePages,
  FilterOption[]
> = {
  [FincenSarTablePages.MY_FILINGS]: ALL_SAR_FILING_FILTER_OPTIONS.filter(
    (i) => i.key !== SAR_FILING_FILTER_KEYS.REVIEWER,
  ),
  [FincenSarTablePages.QUEUE_DETAILS]: ALL_SAR_FILING_FILTER_OPTIONS.filter(
    (i) => i.key !== SAR_FILING_FILTER_KEYS.QUEUE,
  ),
  [FincenSarTablePages.QUEUED_FILINGS]: ALL_SAR_FILING_FILTER_OPTIONS,
  [FincenSarTablePages.REPORT_FILING]: ALL_SAR_FILING_FILTER_OPTIONS,
  [FincenSarTablePages.QUALITY_CYCLE_SARS]: ALL_SAR_FILING_FILTER_OPTIONS,
};

export const getFilingFilterOptions = (
  options: FilterOption[],
  filters: Filter[],
): FilterOption[] => {
  const country = filters.find(
    (i) => i.key === SAR_FILING_FILTER_KEYS.SUBJECT_COUNTRY,
  );

  // add or remove state depending on whether country exists
  if (country && typeof country.value === 'string') {
    return options.map((i) =>
      i.key === SAR_FILING_FILTER_KEYS.SUBJECT_STATE
        ? {
            ...i,
            type: FILTER_TYPE.SELECT,
            customize: {
              [FILTER_OPERATOR.IS]: {
                inputProps: {
                  options: getStateOptions(String(country.value)),
                },
              },
            },
          }
        : i,
    );
  }
  return options.filter((i) => i.key !== SAR_FILING_FILTER_KEYS.SUBJECT_STATE);
};

export const modifyFilingFilters = (filters: Filter[]): Filter[] => {
  const country = filters.find(
    (i) => i.key === SAR_FILING_FILTER_KEYS.SUBJECT_COUNTRY,
  );
  // remove state if country filter doesn't exist
  if (!country) {
    return filters.filter(
      (i) => i.key !== SAR_FILING_FILTER_KEYS.SUBJECT_STATE,
    );
  }
  return filters;
};
