import { get, post } from 'app/shared/utils/fetchr';
import routes from 'app/shared/utils/routes';
import {
  SessionAgent,
  EmailTokenExchangeType,
} from 'app/modules/session/models';

export const login = (): Promise<SessionAgent> => get(routes.patronus.login);

export const tokenPing = () => get(routes.patronus.tokenPing);

// gives access to google drive files
export const getIDP = () => get(routes.patronus.agentsIdp);
export const refreshIDP = () => post(routes.patronus.googleOauthRefresh);

export const retrieveActionTriggers = () =>
  get(routes.patronus.webhooksActionTriggers);

export const pushOrgSarsSettings = (settings) => {
  return post(routes.patronus.orgsSarsSettings, settings);
};

export const emailTokenExchange = (payload: EmailTokenExchangeType) =>
  post(routes.patronus.agentsExchangeAuthEmailToken, { token: payload.token });
