import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

// Components
import { IconFlag, IconBriefcase, IconBuildingBank } from '@u21/tabler-icons';

// Selectors
import {
  selectSidebarDefinition,
  selectSidebarObject,
} from 'app/modules/sidebar/selectors';

// Models
import { SidebarComponentTypes } from 'app/modules/sidebar/models';
import { U21Typography } from 'app/shared/u21-ui/components';

const TYPE_TO_ICON: { [key in SidebarComponentTypes]?: ReactElement } = {
  [SidebarComponentTypes.ALERT]: <IconFlag />,
  [SidebarComponentTypes.CASE]: <IconBriefcase />,
  [SidebarComponentTypes.SAR]: <IconBuildingBank />,
};

export const SidebarTitle = () => {
  const sidebarObject = useSelector(selectSidebarObject);
  const { type = SidebarComponentTypes.ALERT } =
    useSelector(selectSidebarDefinition) || {};

  const titleString = sidebarObject.title || sidebarObject.name;

  return (
    <U21Typography icon={TYPE_TO_ICON[type]} variant="subtitle2">
      {titleString}
    </U21Typography>
  );
};
