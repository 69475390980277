import { SessionActionTypes } from 'app/modules/session/models';
import { AlertsActionTypes } from 'app/modules/alerts/models';
import { InsightsActionTypes } from 'app/modules/insights/models';

import heapEvents, { sagasToHeapEvent } from 'app/shared/utils/heapEvents';

const unloadedClient: Heap = {
  load: () => {},
  track: () => {},
  identify: () => {},
  resetIdentity: () => {},
  addUserProperties: () => {},
  addEventProperties: () => {},
  removeEventProperty: () => {},
  clearEventProperties: () => {},
  appid: '',
  userId: '',
  identity: null,
  config: {},
};

export const getHeapClient = (): Heap => {
  return window.heap || unloadedClient;
};

export const initHeap = (appId: string) => {
  const heap = getHeapClient();
  heap.load(appId);
};

export const heapTrack = (event: string, properties?: object) => {
  const client = getHeapClient();
  // TODO if client is unloaded client send it to our BE to trigger a server side heap event (workaround for Ad blockers)
  client.track(event, properties);
};

const SAGAS_TO_TRACK = new Set<string>([
  SessionActionTypes.START_SESSION,
  AlertsActionTypes.RETRIEVE_ALERTS,
  InsightsActionTypes.EDIT_DASHBOARD,
]);

const shouldTrackSagas = (
  rootAction: string,
  type: 'Hit' | 'Succeeded' | 'Failed',
): boolean => {
  return SAGAS_TO_TRACK.has(rootAction) && type !== 'Hit';
};

export const trackSagas = (
  rootAction: string,
  type: 'Hit' | 'Succeeded' | 'Failed',
  properties: object = {},
) => {
  if (!shouldTrackSagas(rootAction, type)) {
    return;
  }
  const actionWithoutIDs = rootAction.replace(/\d+/g, '');
  const sagasEvent = sagasToHeapEvent(actionWithoutIDs, type);
  heapTrack(sagasEvent, { ...properties, autogenerated: true });
};

export const heapLogin = ({
  email,
  unit,
  org,
  orgName,
}: {
  email: string;
  unit: number;
  org: number;
  orgName: string;
}) => {
  const heap = getHeapClient();
  heap.identify(email);
  heap.addUserProperties({
    unit,
    org,
    org_name: orgName,
  });
};

export const setHeapEventProperties = (properties: {
  isImpersonating: boolean;
}) => {
  const heap = getHeapClient();
  heap.clearEventProperties();
  heap.addEventProperties(properties);
};

export const heapLogout = () => {
  heapTrack(heapEvents.authentication.logout);

  // Commenting out to avoid generating new sessions if the user is kicked out (token expired) but logs in again with the same user
  // heap.identify won't generate new heap sessions if it is called with the same user but will work fine if a different id is passed
  // getHeapClient().resetIdentity();
};
