// Models
import { TeamDetail, TeamPermissions } from 'app/modules/teamsOld/models';
import {
  ActionTriggerModel,
  AlertActionTriggerModel,
  AlertComponentActionTriggerModel,
  CaseActionTriggerModel,
} from 'app/modules/webhooks/models';
import { CustomTableConfig } from 'app/shared/CustomConfig/models';
import { RefreshJWTResponse } from 'app/modules/session/responses';
import { OrgFeature } from 'app/modules/admin/models';

export enum SessionActionTypes {
  START_SESSION = '@@session/START_SESSION',
  START_SESSION_SUCCESS = '@@session/START_SESSION_SUCCESS',
  START_SESSION_ERROR = '@@session/START_SESSION_ERROR',
  LOGIN = '@@session/LOGIN',

  SEND_AUTH_EMAIL = '@@session/SEND_AUTH_EMAIL',
  EMAIL_TOKEN_EXCHANGE = '@@session/EMAIL_TOKEN_EXCHANGE',

  TOKEN_PING = '@@session/TOKEN_PING',
  TOKEN_PING_SUCCESS = '@@session/TOKEN_PING_SUCCESS',

  RETRIEVE_IDP = '@@session/RETRIEVE_IDP',
  RETRIEVE_IDP_SUCCESS = '@@session/RETRIEVE_IDP_SUCCESS',

  RETRIEVE_ACTION_TRIGGERS = '@@session/RETRIEVE_ACTION_TRIGGERS',
  RETRIEVE_ACTION_TRIGGERS_SUCCESS = '@@session/RETRIEVE_ACTION_TRIGGERS_SUCCESS',

  SET_ORG_SARS_SETTINGS = '@@session/SET_ORG_SARS_SETTINGS',
  SET_ORG_SARS_SETTINGS_PENDING = '@@session/SET_ORG_SARS_SETTINGS_PENDING',
  SET_ORG_SARS_SETTINGS_SUCCESS = '@@session/SET_ORG_SARS_SETTINGS_SUCCESS',
  SET_ORG_SARS_SETTINGS_ERROR = '@@session/SET_ORG_SARS_SETTINGS_ERROR',

  SET_SESSION_AGENT_PERMISSIONS = '@@session/SET_SESSION_AGENT_PERMISSIONS',

  SET_LAST_REQUEST_DATE = '@@session/SET_LAST_REQUEST_DATE',

  INIT_INTERCOM = '@@session/INIT_INTERCOM',

  UPDATE_AUTH_EMAIL_SENT = '@@session/UPDATE_AUTH_EMAIL_SENT',

  UPDATE_SESSION_AGENT_NAME_SUCCESS = '@@session/UPDATE_SESSION_AGENT_NAME_SUCCESS',
  UPDATE_SESSION_AGENT_PICTURE_SUCCESS = '@@session/UPDATE_SESSION_AGENT_PICTURE_SUCCESS',
}

export interface Auth0Config {
  readonly domain: string | undefined;
  readonly clientId: string | undefined;
  readonly loginRedirectUrl: string | undefined;
  readonly logoutRedirectUrl: string | undefined;
  readonly apiAudience: string | undefined;
  readonly apiBaseUrl?: string;
}

export interface SessionAgent {
  auth_provider?: string;
  created_at: string;
  email: string;
  full_name: string;
  id: number;
  org_name: string;
  org_features: OrgFeature[];
  org_display_name: string;
  picture: string;
  status: string;
  unit_name: string;
  org_id: number;
  is_parent_org?: boolean;
  parent_org_name?: string;
  is_default_login: boolean;
  default_org_display_name: string;
  default_org_id: number;
  unit_id: number;
  custom_configs?: CustomTableConfig[];
  is_reviewer: null | boolean;
  has_valid_auth?: boolean;
  recently_merged?: boolean;
  teams: TeamDetail[];
  teams_permissions: Partial<TeamPermissions>;
  can_impersonate?: boolean;
  accessible_queues: number[];
}

export interface SessionAgentIDP {
  access_token: string;
  expires_in: number;
  // only in initial IDP call
  provider?: string;
  user_id?: string;
  isSocial?: true;
  connection?: string;
  // only in refresh IDP call
  id_token?: string;

  scope?: string;
  token_type?: string;
}

export interface ActionTriggerState {
  alertTriggers: AlertActionTriggerModel[];
  alertComponentTriggers: AlertComponentActionTriggerModel[];
  entityTriggers: ActionTriggerModel[];
  caseTriggers: CaseActionTriggerModel[];
  ruleTriggers: ActionTriggerModel[];
  eventTriggers: ActionTriggerModel[];
}

export interface SessionState {
  agent: SessionAgent;
  relevantOrgs: SessionRelevantOrgs;
  idp: SessionAgentIDP;
  accessToken: string;
  permissions: string[];
  impersonating: boolean;
  tokenHasExpired: boolean;
  actionTriggers: ActionTriggerState;
  failedTokenPingCount: number;

  // optional, this is used logout out a user if their last request was after a specified time of inactivity in lumos_config.logout_after_inactivity, which is another optional param
  // we don't want to unnecessarily make this update on every request otherwise
  lastRequestDate?: Date;
}

export interface SessionRelevantOrgs {
  org_id: number;
  org_type: string;
  org_name: string;
  org_display_name?: string;
  parent_org_id?: number;
  parent_org_name?: string;
  parent_org_display_name?: string;
}

export interface AuthClient {
  isAuthenticated: () => boolean;
  renewSession: () => void;
  login: () => Promise<void>;
  scheduleCheckLastActivity: () => void;
  provider: string;
  logout: (showLogoutModal?: boolean) => Promise<void>;
  loadingFlags: boolean;
  hasRefreshedToken: boolean;
  authorize: () => void;
  loginSuccess: (authResponse: RefreshJWTResponse) => void;
  connection?: string;
}

export interface EmailTokenExchangeType {
  token: string;
  auth?: AuthClient;
}

export interface Auth0AuthArgs {
  scope: string;
  accessType: string;
  approvalPrompt?: 'force';
}
