import { QueueType } from 'app/modules/queues/models';
import { QueueSubtypes } from 'app/modules/alerts/models';

const BASE = ['queues'];

export const QUEUES_QUERY_KEYS = {
  getSingleQueueDeadlineSummary: (id: number) =>
    [...BASE, 'deadline-summary', id] as const,
  getAllQueues: (payload: QueueType) => [...BASE, 'all', payload] as const,
  getSubtypeAlertQueue: (type: QueueSubtypes) =>
    [...BASE, 'subtype', type] as const,
};
