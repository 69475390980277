import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store/storeHooks';
import styled from 'styled-components';

// Components
import {
  U21NoData,
  U21Select,
  U21Spacer,
  U21Table,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { DocumentDisplay } from 'app/modules/attachmentsRefresh/components/DocumentDisplay';

// Models
import { ShortTxnResponse } from 'app/modules/transactions/types/responses';
import { ShortEntityResponse } from 'app/modules/entities/types';

// Selectors
import {
  selectEntityTableConfig,
  selectInstrumentTableConfig,
} from 'app/shared/CustomConfig/selectors';
import { selectAttachments } from 'app/modules/attachmentsRefresh/selectors';

// Thunks
import { retrieveAttachmentsThunk } from 'app/modules/attachmentsRefresh/sliceAttachments';

// Utils
import { toReadableMediaType } from 'app/modules/attachmentsRefresh/utils';
import { createTableColumnConfig } from 'app/shared/utils/table';
import { ENTITY_COLUMN_CONFIG } from 'app/modules/entitiesRefresh/columns';
import { INSTRUMENT_COLUMN_CONFIG } from 'app/modules/instruments/columns';
import { useInstrumentDetails } from 'app/modules/instruments/queries/useInstrumentDetails';
import { ShortTxnInstrumentResponse } from 'app/modules/txnInstruments/types';

type OwnProps = {
  txnEvent: ShortTxnResponse;
  isOverlayMode: boolean;
  setParentOffsetCanvas?: (offsetCanvas: OffscreenCanvas) => void;
  setHasDocumentsToCompare?: (hasDocumentsToCompare: boolean) => void;
};

export const OtherImageComparisonModalBody = ({
  txnEvent,
  isOverlayMode,
  setParentOffsetCanvas,
  setHasDocumentsToCompare,
}: OwnProps) => {
  const dispatch = useAppDispatch();

  const entities = useMemo(() => {
    const objects: ShortEntityResponse[] = [];
    if (txnEvent.sender_entity) {
      objects.push(txnEvent.sender_entity);
    }
    if (txnEvent.receiver_entity) {
      objects.push(txnEvent.receiver_entity);
    }

    return objects;
  }, [txnEvent]);

  const instruments = useMemo(() => {
    const objects: string[] = [];
    if (txnEvent['sender_instrument.external_id']) {
      objects.push(txnEvent['sender_instrument.external_id']);
    }
    if (txnEvent['receiver_instrument.external_id']) {
      objects.push(txnEvent['receiver_instrument.external_id']);
    }

    return objects;
  }, [txnEvent]);

  const options = useMemo(() => {
    const entityList = entities.map((entity) => {
      return {
        value: entity.external_id,
        text: entity.name_readable,
        description: `Type: Entity`,
      };
    });
    const instrumentList = instruments.map((extId) => {
      return {
        value: extId,
        text: extId,
        description: `Type: Instrument`,
      };
    });
    return [...entityList, ...instrumentList];
  }, [entities, instruments]);

  const [externalId, setExternalId] = useState<string>(options[0].value);
  const [instrumentId, setInstrumentId] = useState<string>('');
  const [selectedAttachmentIdx, setSelectedAttachmentIdx] = useState(0);
  const [selectedEntity, setSelectedEntity] =
    useState<ShortEntityResponse | null>(null);
  const attachments = useSelector(selectAttachments);

  const { data: selectedInstrument } = useInstrumentDetails(instrumentId);

  useEffect(() => {
    const fetchDocuments = async (id: string, isEntity: boolean) => {
      try {
        await dispatch(
          retrieveAttachmentsThunk(
            isEntity
              ? {
                  entity_external_id: id,
                }
              : {
                  instrument_external_id: id,
                },
          ),
        ).unwrap();
        setSelectedAttachmentIdx(0);
      } catch {}
    };

    if (instruments.includes(externalId)) {
      fetchDocuments(externalId, false);
    } else if (entities.some((entity) => entity.external_id === externalId)) {
      fetchDocuments(externalId, true);
    }
  }, [dispatch, externalId, entities, instruments]);

  useEffect(() => {
    if (setHasDocumentsToCompare)
      setHasDocumentsToCompare(attachments.length > 0);
  }, [attachments, setHasDocumentsToCompare]);

  const documentOptions = useMemo(
    () =>
      attachments.map((attachment, idx) => {
        return {
          value: idx,
          text: `#${attachment.id}: ${attachment.name}`,
          description: `Media type: ${toReadableMediaType(
            attachment.media_type,
          )}`,
        };
      }),
    [attachments],
  );

  useEffect(() => {
    if (instruments.includes(externalId)) {
      if (
        txnEvent['sender_instrument.external_id'] === externalId &&
        txnEvent.sender_instrument_id
      ) {
        setInstrumentId(txnEvent.sender_instrument_id.toString());
      } else if (
        txnEvent['receiver_instrument.external_id'] === externalId &&
        txnEvent.receiver_instrument_id
      ) {
        setInstrumentId(txnEvent.receiver_instrument_id.toString());
      }

      setSelectedEntity(null);
    } else {
      const selected = entities.filter(
        (entity) => entity.external_id === externalId,
      );
      if (selected) {
        setSelectedEntity(selected[0]);
      }
      setInstrumentId('');
    }
  }, [externalId, txnEvent, entities, instruments]);

  const instrumentTableConfig = useSelector(selectInstrumentTableConfig);
  const entityTableConfig = useSelector(selectEntityTableConfig);
  const entityColumns = useMemo(
    () => [
      ...createTableColumnConfig<ShortEntityResponse>(
        entityTableConfig,
        ENTITY_COLUMN_CONFIG,
      ),
    ],
    [entityTableConfig],
  );
  const instrumentColumns = useMemo(
    () => [
      ...createTableColumnConfig<ShortTxnInstrumentResponse>(
        instrumentTableConfig,
        INSTRUMENT_COLUMN_CONFIG,
      ),
    ],
    [instrumentTableConfig],
  );

  return (
    <ModalBodySpacer spacing={4}>
      <U21Spacer spacing={0}>
        <U21Typography variant="h4">Other Documents</U21Typography>
        <U21Typography variant="body2" color="text.secondary">
          Select which images you would like to compare against
        </U21Typography>
      </U21Spacer>
      <StyledU21Spacer spacing={2}>
        <U21Spacer horizontal>
          <StyledU21SelectImage
            options={options}
            value={externalId}
            onChange={setExternalId}
            clearable={false}
          />
          <StyledU21SelectImage
            placeholder="Select an image"
            options={documentOptions}
            onChange={(idx: number) => setSelectedAttachmentIdx(idx)}
            value={selectedAttachmentIdx}
            clearable={false}
          />
        </U21Spacer>
        <U21Spacer>
          <>
            {attachments.length > 0 ? (
              <DocumentDisplay
                isEditableImage
                file={attachments[selectedAttachmentIdx]}
                isOverlayMode={isOverlayMode}
                setParentOffsetCanvas={setParentOffsetCanvas}
              />
            ) : (
              <U21NoData>No documents available.</U21NoData>
            )}
            {selectedEntity ? (
              <U21Spacer>
                <U21Typography variant="subtitle1">
                  Entity: {selectedEntity.name_readable}
                </U21Typography>
                <U21Table<ShortEntityResponse>
                  columns={entityColumns}
                  data={[selectedEntity]}
                />
              </U21Spacer>
            ) : undefined}
            {selectedInstrument ? (
              <U21Spacer>
                <U21Typography variant="subtitle1">
                  Instrument: {selectedInstrument.external_id}
                </U21Typography>
                <U21Table<ShortTxnInstrumentResponse>
                  columns={instrumentColumns}
                  data={[selectedInstrument]}
                />
              </U21Spacer>
            ) : undefined}
          </>
        </U21Spacer>
      </StyledU21Spacer>
    </ModalBodySpacer>
  );
};

const ModalBodySpacer = styled(U21Spacer)`
  width: 50%;
  height: 100%;
`;

const StyledU21Spacer = styled(U21Spacer)`
  height: 100%;
  align-content: space-between;
`;

const StyledU21SelectImage = styled(U21Select)`
  width: 350px;
`;
