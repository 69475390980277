import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AdminActionTypes, EditOrgRouterProps } from 'app/modules/admin/models';

// Actions
import {
  createOrg as createOrgAction,
  createOrgSuccess,
} from 'app/modules/admin/actions';

// API
import { createOrg as createOrgApi } from 'app/shared/api/admin';

// Utils
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = AdminActionTypes.CREATE_ORG;
export function* createOrg({ payload }: ReturnType<typeof createOrgAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to create the org.',
    successToastMessage: `${payload.org_name} org created successfully.`,
    request: call(createOrgApi, payload),
    success: function* onSuccess(result) {
      yield put(createOrgSuccess(result));
      const routerState: EditOrgRouterProps = { selectedOrgID: result.id };
      history.push(routes.lumos.adminUpdate, routerState);
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, createOrg);
}
