import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { BlacklistActionTypes } from 'app/modules/blacklists/models';

// Actions
import {
  addBusinessBlacklistItem as addBusinessBlacklistItemAction,
  addBusinessBlacklistItemSuccess,
} from 'app/modules/blacklists/actions';

// API
import { addBusinessBlacklistItem as addBusinessBlacklistItemApi } from 'app/shared/api/blacklists';

// Utils
import routes, { pathJoin } from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = BlacklistActionTypes.ADD_BUSINESS_BLACKLIST_ITEM;
function* addBusinessBlacklistItem({
  payload,
}: ReturnType<typeof addBusinessBlacklistItemAction>) {
  const config = {
    rootAction,
    request: call(addBusinessBlacklistItemApi, payload),
    errorToastMessage: 'Error adding blacklist item',
    successToastMessage: 'Blacklist item successfully added',
    success: function* onSuccess(result) {
      yield put(addBusinessBlacklistItemSuccess(result));
      if (result.blacklist_id) {
        history.push(pathJoin(routes.lumos.blacklists, result.blacklist_id));
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchAddBusinessBlacklistItem() {
  yield takeLatest(rootAction, addBusinessBlacklistItem);
}
