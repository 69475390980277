import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { isEmpty } from 'lodash';

import { U21SideMenu } from 'app/shared/u21-ui/components/dashboard';

import { TransactionSidebarDefinition } from 'app/modules/sidebar/models';

import { ROUTES_MAP } from 'app/shared/utils/routes';

import { useGetTransactionById } from 'app/modules/transactions/queries/useGetTransactionById';
import {
  U21Subsection,
  U21Typography,
  U21Chip,
  U21Spacer,
  U21Button,
  U21TitleCountLabel,
} from 'app/shared/u21-ui/components';
import { IconExternalLink, IconTag } from '@u21/tabler-icons';

import {
  selectDataSettingsById,
  selectDataSettingsByNativeKey,
} from 'app/modules/dataSettings/selectors';
import { DataSettingDataDisplay } from 'app/modules/dataSettings/shared/DataSettingDataDisplay';
import { SidebarDataSettingDataLabel } from 'app/modules/dataSettings/shared/DataSettingDataLabel';
import {
  DataSettingFieldType,
  FormattedDataItem,
} from 'app/modules/dataSettings/responses';

import {
  selectFormatAmount,
  selectRealTimeRulesEnabled,
} from 'app/modules/orgSettings/selectors';
import {
  selectHasEditEventPermissions,
  selectHasReadEntitiesPermissions,
  selectHasReadInstrumentsPermission,
} from 'app/modules/session/selectors';
import { keyPathToLabel } from 'app/modules/dataSettings/utils';
import { SidebarSummary } from 'app/modules/sidebar/components/utils/SidebarSummary';
import { SummaryViewConfigTypeClassifier } from 'app/modules/summarySettings/types';
import { getTxnDataByFlow } from 'app/modules/transactions/utils';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import { TransactionRiskScore } from 'app/modules/transactionScore/components/TransactionRiskScore';
import {
  selectAchRiskScoreFieldEnabled,
  selectFraudTransactionRulesEnabled,
} from 'app/shared/featureFlags/selectors';
import { SidebarEvaluationLogInfo } from 'app/modules/sidebar/components/SidebarEvaluationLogInfo';
import {
  selectEditEventLoading,
  selectEvaluationLog,
} from 'app/shared/events/selectors';
import {
  clearEvaluationLog,
  retrieveEvaluationLogThunk,
} from 'app/shared/events/sliceEvents';
import { DataLabelList } from 'app/modules/dataLabels/DataLabelList';
import { EditAssociatedDataLabelsButton } from 'app/modules/dataLabels/EditAssociatedDataLabelsButton';
import { editEvent } from 'app/shared/events/actions';
import { SidebarCommentsCollapsible } from 'app/modules/sidebar/components/SidebarCommentsCollapsible';
import { TxnDocumentChipList } from 'app/modules/transactions/components/TxnDocumentChipList';
import { ShortTxnResponse } from 'app/modules/transactions/types';
import { SidebarDataRow } from 'app/modules/sidebar/components/utils/SidebarDataRow';
import { InstrumentsDocumentChipListWrapper } from 'app/modules/instruments/components/InstrumentDocumentChipListWrapper';

interface SidebarTransactionProps {
  data: TransactionSidebarDefinition['data'];
}

export const SidebarTransaction = ({ data }: SidebarTransactionProps) => {
  // Navigation
  const history = useHistory();
  const isOnTransactionDetails = Boolean(
    useRouteMatch(ROUTES_MAP.dataExplorerTransactionsId.path),
  );

  const [showEmptyFields, setShowEmptyFields] = useState(false);

  // Fetch transaction data
  const {
    data: fullTxnResponse,
    isLoading,
    refetch,
  } = useGetTransactionById(`${data.id}`);
  const { data: txnData } = useGetTransactionById(
    `${data.id}`,
    (txnDataInner) => getTxnDataByFlow(txnDataInner.formatted_data),
  );

  // Amount Display
  const headerData = useSelector(selectDataSettingsByNativeKey).EVENT;
  const formatAmount = useSelector(selectFormatAmount);
  const achRiskScoreEnabled = useSelector(selectAchRiskScoreFieldEnabled);

  // related rules
  const dispatch = useDispatch();

  const isRTREnabled = useSelector(selectRealTimeRulesEnabled);

  const fraudTransactionRulesEnabled = useSelector(
    selectFraudTransactionRulesEnabled,
  );
  useEffect(() => {
    if (
      (isRTREnabled || fraudTransactionRulesEnabled) &&
      fullTxnResponse?.external_id
    ) {
      dispatch(clearEvaluationLog());
      dispatch(retrieveEvaluationLogThunk(fullTxnResponse.external_id));
    }
  }, [isRTREnabled, fraudTransactionRulesEnabled, fullTxnResponse, dispatch]);
  const evaluationLog = useSelector(selectEvaluationLog);
  const hasEditPermissions = useSelector(selectHasEditEventPermissions);
  const txnTagIds = useMemo(
    () => fullTxnResponse?.tags?.map((tag) => tag.id) ?? [],
    [fullTxnResponse],
  );
  const eventLoading = useSelector(selectEditEventLoading);

  return (
    <U21SideMenu
      footerLeftContent={
        <U21Button
          onClick={() => setShowEmptyFields((prev) => !prev)}
          variant="ghost"
        >
          {showEmptyFields ? 'Hide' : 'Show'} Empty Fields
        </U21Button>
      }
      onClose={() => {
        if (isOnTransactionDetails) {
          history.push(ROUTES_MAP.dataExplorerTransactions.path);
        }
      }}
      loading={isLoading}
      noPadding
      title="Transaction Details"
    >
      <Container>
        <AmountHeaderDiv>
          {headerData.sent_amount?.id && (
            <div>
              <U21TypographyCentered variant="subtitle2">
                Sent
              </U21TypographyCentered>
              <U21Typography variant="h4" color="colors.red.main">
                -
                {formatAmount({
                  amount:
                    fullTxnResponse?.formatted_data[headerData.sent_amount.id]
                      .formatted_value,
                  currencyCodeProps: headerData.sent_currency?.id
                    ? fullTxnResponse?.formatted_data[
                        headerData.sent_currency.id
                      ].formatted_value
                    : undefined,
                  shortenDecimalPlaces: false,
                })}
              </U21Typography>
            </div>
          )}
          {headerData.received_amount?.id && (
            <div>
              <U21TypographyCentered variant="subtitle2">
                Received
              </U21TypographyCentered>
              <U21Typography variant="h4" color="colors.green.main">
                +
                {formatAmount({
                  amount:
                    fullTxnResponse?.formatted_data[
                      headerData.received_amount.id
                    ].formatted_value,
                  currencyCodeProps: headerData.received_currency?.id
                    ? fullTxnResponse?.formatted_data[
                        headerData.received_currency.id
                      ].formatted_value
                    : undefined,
                  shortenDecimalPlaces: false,
                })}
              </U21Typography>
            </div>
          )}
        </AmountHeaderDiv>
        <TransactionsMetaDiv>
          {headerData.event_time && (
            <SidebarDataRow
              label={
                <SidebarDataSettingDataLabel
                  dataSetting={headerData.event_time}
                />
              }
              showEmptyFields={showEmptyFields}
              value={
                fullTxnResponse?.formatted_data ? (
                  <DataSettingDataDisplay
                    formattedData={fullTxnResponse.formatted_data}
                    dataSetting={headerData.event_time}
                  />
                ) : null
              }
            />
          )}
          {headerData.event_id && (
            <SidebarDataRow
              label={
                <SidebarDataSettingDataLabel
                  dataSetting={headerData.event_id}
                />
              }
              showEmptyFields={showEmptyFields}
              value={
                fullTxnResponse?.formatted_data ? (
                  <DataSettingDataDisplay
                    formattedData={fullTxnResponse?.formatted_data}
                    dataSetting={headerData.event_id}
                  />
                ) : null
              }
            />
          )}
          {headerData.amount && (
            <SidebarDataRow
              label={
                <SidebarDataSettingDataLabel dataSetting={headerData.amount} />
              }
              showEmptyFields={showEmptyFields}
              value={
                fullTxnResponse?.formatted_data ? (
                  <DataSettingDataDisplay
                    formattedData={fullTxnResponse?.formatted_data}
                    dataSetting={headerData.amount}
                  />
                ) : null
              }
            />
          )}
          {achRiskScoreEnabled && (
            <SidebarDataRow
              label={<SidebarFieldLabel>Unit21 risk score</SidebarFieldLabel>}
              showEmptyFields={showEmptyFields}
              value={
                fullTxnResponse && fullTxnResponse.u21_risk_score !== null ? (
                  <TransactionRiskScore
                    eventID={fullTxnResponse.external_id}
                    score={fullTxnResponse.u21_risk_score}
                  />
                ) : null
              }
            />
          )}
        </TransactionsMetaDiv>
      </Container>

      <U21Spacer spacing={0.5}>
        <U21Subsection title="Sending" collapsible shaded>
          <FlowDisplay
            flowData={txnData?.OUTBOUND}
            hasDocuments={fullTxnResponse?.sender_instrument_has_attachments}
            showEmptyFields={showEmptyFields}
          />
        </U21Subsection>
        <U21Subsection title="Receiving" collapsible shaded>
          <FlowDisplay
            flowData={txnData?.INBOUND}
            hasDocuments={fullTxnResponse?.receiver_instrument_has_attachments}
            showEmptyFields={showEmptyFields}
          />
        </U21Subsection>
        {!isEmpty(txnData?.OTHER) && (
          <U21Subsection title="Other" collapsible shaded>
            <FlowDisplay
              flowData={txnData?.OTHER}
              showEmptyFields={showEmptyFields}
            />
          </U21Subsection>
        )}
        <SidebarSummary
          classifier={SummaryViewConfigTypeClassifier.EVENT}
          details={fullTxnResponse}
          showEmptyFields={showEmptyFields}
        />
        <U21Subsection
          title={
            <U21TitleCountLabel
              count={fullTxnResponse?.documents.length || 0}
              label=""
            >
              Documents
            </U21TitleCountLabel>
          }
          collapsible
          collapsed
          shaded
        >
          {fullTxnResponse && fullTxnResponse.documents.length > 0 ? (
            <TxnDocumentChipList
              // only difference between short and full is custom_data
              txnEvent={fullTxnResponse as ShortTxnResponse}
              handleOnDelete={() => refetch()}
            />
          ) : (
            <U21Typography variant="caption" color="text.secondary">
              No documents found
            </U21Typography>
          )}
        </U21Subsection>
        <SidebarEvaluationLogInfo evaluationLog={evaluationLog} />
        <U21Subsection
          collapsed
          collapsible
          shaded
          title="Tags"
          titleIcon={<IconTag />}
        >
          <U21Spacer>
            <DataLabelList tagIds={txnTagIds} />

            {hasEditPermissions && fullTxnResponse && (
              <div>
                <EditAssociatedDataLabelsButton
                  objectDisplayName={fullTxnResponse.external_id}
                  tagIds={txnTagIds}
                  isEditLoading={eventLoading}
                  editObjectTags={async (tagIds: (string | number)[]) => {
                    dispatch(
                      editEvent({
                        eventId: fullTxnResponse.id.toString(),
                        tags: tagIds,
                      }),
                    );
                  }}
                  objectExternalId={fullTxnResponse.external_id}
                  objectType="TXN_EVENT"
                />
              </div>
            )}
          </U21Spacer>
        </U21Subsection>
        <SidebarCommentsCollapsible comments={fullTxnResponse?.comments} />
      </U21Spacer>
    </U21SideMenu>
  );
};

const AmountHeaderDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const U21TypographyCentered = styled(U21Typography)`
  justify-content: center;
`;

const TransactionsMetaDiv = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const FlowDisplay = ({
  flowData,
  hasDocuments = false,
  showEmptyFields,
}: {
  flowData:
    | { formattedData: FormattedDataItem; dataSettingId: number }[]
    | undefined;
  hasDocuments?: boolean;
  showEmptyFields: boolean;
}) => {
  const dataSettingsById = useSelector(selectDataSettingsById);

  // Data setting  ids of fields need displaying
  const nativeDataSettings = useSelector(selectDataSettingsByNativeKey);

  // Permissions
  const hasReadEntitiesPermission = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const hasReadInstrumentsPermission = useSelector(
    selectHasReadInstrumentsPermission,
  );

  return (
    <>
      {!isEmpty(flowData) ? (
        flowData?.map((value) => {
          const dataSetting = dataSettingsById[value.dataSettingId];
          if (!dataSetting) {
            return null;
          }
          const dataType = dataSetting.data_type;

          const linkTo = (() => {
            // if entity source is external, there will be no U21 ID
            if (!value.formattedData.formatted_value.unit21_id) {
              return undefined;
            }
            if (
              hasReadEntitiesPermission &&
              dataType === DataSettingFieldType.ENTITY_REFERENCE
            ) {
              return ROUTES_MAP.entitiesId.path.replace(
                ':id',
                value.formattedData.formatted_value.unit21_id.toString(),
              );
            }
            if (
              hasReadInstrumentsPermission &&
              dataType === DataSettingFieldType.INSTRUMENT_REFERENCE
            ) {
              return ROUTES_MAP.instrumentsId.path.replace(
                ':id',
                value.formattedData.formatted_value.unit21_id.toString(),
              );
            }
            return undefined;
          })();

          const readableName =
            value.formattedData.formatted_value.name_readable ||
            value.formattedData.formatted_value.external_id;
          return (
            <Fragment key={value.dataSettingId}>
              <ObjectHeaderDiv>
                <HeaderTitleSpacer horizontal>
                  <U21Typography variant="subtitle2" ellipsis>
                    {readableName}
                  </U21Typography>

                  <U21Chip>{keyPathToLabel(dataSetting)}</U21Chip>
                </HeaderTitleSpacer>
                {linkTo && (
                  <U21Button
                    icon={<IconExternalLink />}
                    to={linkTo}
                    aria-label={`Details page ${readableName}`}
                  />
                )}
              </ObjectHeaderDiv>
              {nativeDataSettings.ENTITY.registered_at &&
                dataType === DataSettingFieldType.ENTITY_REFERENCE && (
                  <SidebarDataRow
                    label={
                      <SidebarDataSettingDataLabel
                        dataSetting={nativeDataSettings.ENTITY.registered_at}
                      />
                    }
                    showEmptyFields={showEmptyFields}
                    value={
                      <DataSettingDataDisplay
                        formattedData={
                          value.formattedData.formatted_value.formatted_data
                        }
                        dataSetting={nativeDataSettings.ENTITY.registered_at}
                      />
                    }
                  />
                )}
              {nativeDataSettings.ENTITY.entity_type &&
                dataType === DataSettingFieldType.ENTITY_REFERENCE && (
                  <SidebarDataRow
                    label={
                      <SidebarDataSettingDataLabel
                        dataSetting={nativeDataSettings.ENTITY.entity_type}
                      />
                    }
                    showEmptyFields={showEmptyFields}
                    value={
                      <DataSettingDataDisplay
                        formattedData={
                          value.formattedData.formatted_value.formatted_data
                        }
                        dataSetting={nativeDataSettings.ENTITY.entity_type}
                      />
                    }
                  />
                )}
              {nativeDataSettings.INSTRUMENT.instrument_type &&
                dataType === DataSettingFieldType.INSTRUMENT_REFERENCE && (
                  <>
                    <SidebarDataRow
                      label={
                        <SidebarDataSettingDataLabel
                          dataSetting={
                            nativeDataSettings.INSTRUMENT.instrument_type
                          }
                        />
                      }
                      showEmptyFields={showEmptyFields}
                      value={
                        <DataSettingDataDisplay
                          formattedData={
                            value.formattedData.formatted_value.formatted_data
                          }
                          dataSetting={
                            nativeDataSettings.INSTRUMENT.instrument_type
                          }
                        />
                      }
                    />
                    <SidebarDataRow
                      label="Documents"
                      showEmptyFields={showEmptyFields}
                      value={
                        hasDocuments ? (
                          <InstrumentsDocumentChipListWrapper
                            externalId={
                              value.formattedData.formatted_value.external_id
                            }
                          />
                        ) : null
                      }
                    />
                  </>
                )}
            </Fragment>
          );
        })
      ) : (
        <U21Typography variant="caption" color="text.secondary">
          No information to display
        </U21Typography>
      )}
    </>
  );
};

const Container = styled.div`
  padding: 0 16px;
`;

const HeaderTitleSpacer = styled(U21Spacer)`
  max-width: 80%;
`;

const ObjectHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
