import watchCreateSarNarrative from 'app/modules/settings/sagas/watchCreateSarNarrative';
import watchRetrieveSarNarrative from 'app/modules/settings/sagas/watchRetrieveSarNarrative';
import watchEditSarNarrative from 'app/modules/settings/sagas/watchEditSarNarrative';
import watchDeleteSarNarrative from 'app/modules/settings/sagas/watchDeleteSarNarrative';
import watchRetrieveGoAMLTemplates from 'app/modules/settings/sagas/retrieveGoAMLTemplates';

const sagaRegistry = [
  watchCreateSarNarrative,
  watchRetrieveSarNarrative,
  watchEditSarNarrative,
  watchDeleteSarNarrative,
  watchRetrieveGoAMLTemplates,
];

export default sagaRegistry;
