import { SidenavConfig } from 'app/modules/navigator/models';

// Utils
import routes, { ROUTES_MAP } from 'app/shared/utils/routes';
import permissions from 'app/shared/utils/permissions';
import {
  IconAlertTriangle,
  IconClipboard,
  IconListSearch,
  IconReport,
  IconSettingsAutomation,
  IconUsers,
  IconDatabaseEdit,
  IconBriefcase,
  IconFlag,
  IconBuildingBank,
  IconChartLine,
  IconList,
  IconScale,
  IconWorld,
} from '@u21/tabler-icons';
import { FeatureFlag } from 'app/shared/featureFlags/models';

export const SIDENAV_CONFIG: SidenavConfig[] = [
  {
    hasPermissions: [permissions.readAlerts],
    tablerIcon: IconFlag,
    label: 'Alerts',
    link: routes.lumos.alerts,
    marker: 'first',
  },
  {
    hasPermissions: [permissions.readCases],
    tablerIcon: IconBriefcase,
    label: 'Cases',
    link: routes.lumos.cases,
  },
  {
    hasPermissions: [permissions.readBlacklists],
    tablerIcon: IconList,
    label: 'Matchlists',
    nonExactMatch: true,
    link: routes.lumos.blacklists,
  },
  {
    hasPermissions: [permissions.readRules],
    tablerIcon: IconScale,
    label: 'Detection Models',
    nonExactMatch: true,
    link: routes.lumos.detectionModels,
  },
  {
    hasPermissions: [permissions.readSars],
    tablerIcon: IconBuildingBank,
    label: 'Report Filings',
    link: routes.lumos.filings,
  },
  {
    hasPermissions: [permissions.readInsights],
    tablerIcon: IconChartLine,
    label: 'Dashboards',
    link: routes.lumos.dashboards,
    marker: 'first',
  },
  {
    anyPermissions: ROUTES_MAP.dataExplorer.anyPermissions,
    tablerIcon: IconListSearch,
    label: 'Data Explorer',
    link: routes.lumos.dataExplorer,
  },
  {
    tablerIcon: IconDatabaseEdit,
    label: 'Data Management',
    link: ROUTES_MAP.dataManagement.path,
  },
  {
    hasPermissions: [permissions.readRiskRatings],
    tablerIcon: IconAlertTriangle,
    label: 'Risk Ratings',
    link: ROUTES_MAP.riskRatings.path,
  },
  {
    anyPermissions: ROUTES_MAP.userManagement.anyPermissions,
    label: 'User Management',
    link: ROUTES_MAP.userManagement.path,
    tablerIcon: IconUsers,
  },
  {
    anyPermissions: ROUTES_MAP.workflows.anyPermissions,
    label: 'Workflows',
    link: ROUTES_MAP.workflows.path,
    tablerIcon: IconSettingsAutomation,
  },
  {
    anyPermissions: ROUTES_MAP.reporting.anyPermissions,
    label: 'Reporting',
    link: ROUTES_MAP.reporting.path,
    tablerIcon: IconReport,
    newLabel: FeatureFlag.CODE_INTERPRETER,
  },
  {
    hasPermissions: ROUTES_MAP.admin.permissions,
    hiddenByFeatureFlag: FeatureFlag.ADMIN_DASHBOARD_REFRESH,
    tablerIcon: IconWorld,
    label: 'Admin Dashboard',
    link: ROUTES_MAP.admin.path,
    marker: 'first',
  },
  {
    featureFlag: FeatureFlag.ADMIN_DASHBOARD_REFRESH,
    hasPermissions: ROUTES_MAP.orgManagement.permissions,
    tablerIcon: IconWorld,
    label: 'Org Management',
    link: ROUTES_MAP.orgManagement.path,
    marker: 'first',
  },
  {
    hasPermissions: ROUTES_MAP.rulesAdmin.permissions,
    label: 'Rules Admin Dashboard',
    link: ROUTES_MAP.rulesAdmin.path,
    tablerIcon: IconClipboard,
  },
];
