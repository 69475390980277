// Saga
import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Api
import { createSar as createSarApi } from 'app/shared/api/sars';

// Models
import { SarActionTypes, CreateSarPayload } from 'app/modules/fincenSar/models';

// Actions
import {
  createSarSuccess,
  createSar as createSarAction,
} from 'app/modules/fincenSar/actions';

// Routes
import routes from 'app/shared/utils/routes';
import { history } from 'app/store/browserHistory';

const rootAction = SarActionTypes.CREATE_SAR;
function* createSar(payload: CreateSarPayload) {
  const config = {
    rootAction,
    request: call(createSarApi, payload),
    success: function* onSuccess(
      result: ReturnType<typeof createSarSuccess>['payload'],
    ) {
      yield put(createSarSuccess(result));
      history.push(`${routes.lumos.filingsSar}/${result.id}`);
    },
  };
  yield call(makeSagaRequest, config);
}

function* initCreateSarFlow({ payload }: ReturnType<typeof createSarAction>) {
  yield call(createSar, payload);
}

export default function* watchCreateSar() {
  yield takeLatest(rootAction, initCreateSarFlow);
}
