import { createAction, ActionsUnion } from 'app/shared/utils/action-helpers';

// Models
import { CasesActionTypes, CaseEditResult } from 'app/modules/casesOld/models';
import { TriggerActionPayload } from 'app/modules/webhooks/models';
import { FullAttachmentResponse } from 'app/modules/attachments/types';

export const retrieveCase = (payload: number) =>
  createAction(CasesActionTypes.RETRIEVE_CASE, payload);

export const retrieveCaseSuccess = (result) =>
  createAction(CasesActionTypes.RETRIEVE_CASE_SUCCESS, result);

export const retrieveSidebarCase = (payload: number) =>
  createAction(CasesActionTypes.RETRIEVE_SIDEBAR_CASE, payload);

export const retrieveSidebarCaseSuccess = (result) =>
  createAction(CasesActionTypes.RETRIEVE_SIDEBAR_CASE_SUCCESS, result);

export const editCaseSuccess = (payload: CaseEditResult) =>
  createAction(CasesActionTypes.EDIT_CASE_SUCCESS, payload);

export const addDocumentsSuccess = (result) =>
  createAction(CasesActionTypes.ADD_DOCUMENTS_SUCCESS, result);

export const deleteDocumentSuccess = (result) =>
  createAction(CasesActionTypes.DELETE_DOCUMENT_SUCCESS, result);

export const editDocumentSuccess = (result: {
  attachments: FullAttachmentResponse[];
}) => createAction(CasesActionTypes.EDIT_DOCUMENT_SUCCESS, result);

export const uploadS3FilesSuccess = (payload) =>
  createAction(CasesActionTypes.UPLOAD_FILES_SUCCESS, payload);

/**
 * @deprecated Use resolveInvestigationsThunk instead
 */
export const triggerCaseAction = (payload: TriggerActionPayload) =>
  createAction(CasesActionTypes.TRIGGER_CASE_ACTION, payload);

export const triggerCaseActionSuccess = (payload) =>
  createAction(CasesActionTypes.TRIGGER_CASE_ACTION_SUCCESS, payload);

const CasesActionsList = {
  retrieveCase,
  retrieveCaseSuccess,
  retrieveSidebarCase,
  retrieveSidebarCaseSuccess,
  editCaseSuccess,
  addDocumentsSuccess,
  deleteDocumentSuccess,
  editDocumentSuccess,
  uploadS3FilesSuccess,
  triggerCaseAction,
  triggerCaseActionSuccess,
};
type CasesActions = ActionsUnion<typeof CasesActionsList>;

export default CasesActions;
