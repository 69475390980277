// Models
import { QueueType } from 'app/modules/queues/models';

import {
  CreateQueuePayload,
  EditQueuePayload,
  RetrieveQueueListPayload,
  ValidateAgentInQueuePayload,
  GetAssociatedAgentsPayload,
} from 'app/modules/queues/requests';
import {
  GetArticleCountResponse,
  QueueDetailsResponse,
  RetrieveQueuesListResponse,
  GetDeadlineSummaryResponse,
  ValidateAgentInQueueResponse,
  AssociatedAgentResponse,
} from 'app/modules/queues/responses';

// Utils
import { destroy, get, post, put } from 'app/shared/utils/fetchr';

export const listQueuesApi = (
  payload: RetrieveQueueListPayload,
): Promise<RetrieveQueuesListResponse> => {
  return post('/queues/list', payload, [{ key: 'type', value: payload.type }]);
};

export const retrieveQueueApi = (
  queueId: string,
): Promise<QueueDetailsResponse> => {
  return get('/queue/:id'.replace(':id', queueId));
};

export const retrieveAlertQueueBySubtypeApi = (
  queueSubtype: string,
): Promise<QueueDetailsResponse> => {
  return get(`/queue/${queueSubtype}`);
};

export const retrieveQueuedArticlesCountApi = (
  payload: QueueType,
): Promise<GetArticleCountResponse> => {
  return get('/queues/articles-count', [{ key: 'type', value: payload }]);
};

export const createQueueApi = (
  payload: CreateQueuePayload,
): Promise<QueueDetailsResponse> => {
  return post('/queue', payload, [{ key: 'type', value: payload.type }]);
};

export const editQueueApi = (
  payload: EditQueuePayload,
): Promise<QueueDetailsResponse> => {
  return put('/queue', payload);
};

export const deleteQueueApi = (
  queueId: number,
): Promise<QueueDetailsResponse> => {
  return destroy('/queue/:id'.replace(':id', String(queueId)));
};

export const getDeadlineSummaryApi = (
  queueType: QueueType,
): Promise<GetDeadlineSummaryResponse> => {
  return get('/queues/deadline-summary', [{ key: 'type', value: queueType }]);
};

export const validateAgentInQueueApi = (
  payload: ValidateAgentInQueuePayload,
): Promise<ValidateAgentInQueueResponse> => {
  return post('/queues/validate-agent-in-queue', payload);
};

export const retrieveAssociatedAgentsApi = (
  payload: GetAssociatedAgentsPayload,
): Promise<AssociatedAgentResponse> => {
  return post('/queue/associated-agents', payload);
};
