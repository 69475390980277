import { isValidElement, ReactNode } from 'react';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';
import { SidebarFieldValue } from 'app/modules/sidebar/components/SidebarFieldValue';
import objectIsEmpty from 'app/shared/utils/objectIsEmpty';
import styled from 'styled-components';

export const SidebarDataRow = ({
  label,
  showEmptyFields,
  value,
}: {
  label: ReactNode;
  showEmptyFields: boolean; // required so people don't forget this
  value: ReactNode;
}) => {
  if (objectIsEmpty(value) && !showEmptyFields) {
    return null;
  }
  const displayLabel = isValidElement(label) ? (
    label
  ) : (
    <SidebarFieldLabel>{label}</SidebarFieldLabel>
  );
  const displayValue = isValidElement(value) ? (
    value
  ) : (
    <SidebarFieldValue>{value}</SidebarFieldValue>
  );

  return (
    <StyledItem>
      {displayLabel}
      <StyledFieldValueWrapper>{displayValue}</StyledFieldValueWrapper>
    </StyledItem>
  );
};

export const StyledFieldValueWrapper = styled.div`
  flex: 1;
  overflow: hidden;
`;
