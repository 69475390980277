import { CommentModel } from 'app/modules/comments/models';

import { U21Typography } from 'app/shared/u21-ui/components';
import { SidebarTitleIcon } from 'app/modules/sidebar/components/SidebarTitleIcon';
import { IconMessage } from '@u21/tabler-icons';
import { CommentList } from 'app/modules/comments/components/CommentList';

interface OwnProps {
  comments?: CommentModel[];
}

export const SidebarComments = ({ comments }: OwnProps) => {
  if (!comments?.length) {
    return (
      <div>
        <SidebarTitleIcon count={0} title="Comments" icon={<IconMessage />} />
        <U21Typography variant="caption" color="grey.600">
          No comments found
        </U21Typography>
      </div>
    );
  }

  const orderedComments = comments.slice().reverse();

  return (
    <div>
      <SidebarTitleIcon
        count={comments.length}
        title="Comments"
        icon={<IconMessage />}
      />
      <CommentList comments={orderedComments} loading={false} />
    </div>
  );
};
