import {
  createState,
  createCheckboxItems,
} from 'app/modules/fincenSar/helpers';

import {
  SarCheckboxOptions,
  SarInput,
  SarOptions,
  Page4Model,
} from 'app/modules/fincenSar/models';

import { initialState as ipAddressInitialState } from 'app/modules/fincenSar/constants/04-ipAddress';
import { initialState as cyberEventIndicatorState } from 'app/modules/fincenSar/constants/04-cyberEventIndicators';

export const PAGE_NUMBER = '4';

// General Popup Content
const generalPopupContent = `Items 32 - 42. Types of suspicious activity. Use the suspicious activity categories specified to record the type(s)
of suspicious activity being reported. Check all boxes that
apply to the suspicious activity. If a category applies but
none of the options within the category apply, check the
category’s “Other” box and briefly describe the type of
suspicious activity in the associated text field. If necessary,
explain the type of suspicious activity in more detail in Part V.
`;

// ===================== 29 Amount Involved in this Report  =========================
const popupContentForAmountInvolved = `Item 29 - Amount involved in this report. Enter the total
  dollar amount involved in the suspicious activity for the time
  period of this report. If the amount is unknown, leave this
  field blank and select the checkbox for "Amount Unknown."
  If the checkbox for "Amount Unknown" is selected, explain
  why the dollar amount is unknown in Part V. If some dollar
  amounts are known while others are unknown, enter the total
  of all known amounts and explain the unknown amounts in
  Part V. If there is no amount involved in the suspicious
  activity, select the checkbox for “No amount involved.” If the
  SAR is a continuing activity report, enter only the amount
  involved for the time period covered by this SAR. Do not
  include amounts from prior SARs in Item 29.
  All monetary amounts must be recorded in U.S. Dollars
  rounded up to the next whole dollar. The amount $5,265.25
  would be recorded as $5,266. It the amount involves a
  foreign currency, record the currency name, amount, and
  country of origin in Part V. When converting a foreign
  currency to U.S. Dollars use an exchange rate for the date or
  dates the foreign currency was involved in the suspicious
  activity.
`;
const amountInvolvedOptions = ['Amount Unknown', 'No Amount Involved'];

export const typeOfAmountInvolved: SarCheckboxOptions = {
  title: '29. Amount Involved in this Report',
  name: '29_typeOfAmountInvolved',
  popupContent: popupContentForAmountInvolved,
  options: amountInvolvedOptions,
  initialValue: createCheckboxItems(amountInvolvedOptions),
};

export const amountInvolved: SarInput = {
  title: 'Enter Amount Here',
  name: '29_amountInvolved',
  popupContent: popupContentForAmountInvolved,
  initialValue: '',
};
// =================================================================

// ===================== 30 Date or date range of suspicious actvity for this report =========================
const popupContentForDateRange = `Item 30 - Date or date range of suspicious activity for this ‘
report. Use the format MM/DD/YYYY where MM = month, I
DD = day, YYYY = Century and Year (e.g. 2018). Enter the i
suspicious activity date or date range for this report. If the '
suspicious activity occurred on a single day, enter that date
in the "From" field and leave the "To" field blank. If the
suspicious activity occurred on multiple days, enter the |
earliest date of suspicious activity in the "From" field and the
most-recent date of suspicious activity in the "To" field. If
the exact date(s) of the suspicious activity is (are) unknown,
enter a date range that the filer believes will encompass the -
date(s) of the suspicious activity. Explain the nature of this
date range in Part V. If "Continuing activity report" is
selected as the filing type, enter only the date range covered ;
by this SAR. Record in Part V the aggregated date range of '
all SARs filed on the continuing suspicious activity.
`;

export const suspiciousActivityStartDate: SarInput = {
  title: 'From',
  name: '30_suspiciousActivityStartDate',
  popupContent: popupContentForDateRange,
  initialValue: '',
};

export const suspiciousActivityEndDate: SarInput = {
  title: 'To',
  name: '30_suspiciousActivityEndDate',
  popupContent: popupContentForDateRange,
  initialValue: '',
};

// =================================================================

// ===================== 31 Cumulative Amount =========================
export const cumulativeAmount: SarInput = {
  title:
    '31. Cumulative Amount (only applicable when "Continuing activity report" is checked in Item 1)',
  name: '31_cumulativeAmount',
  popupContent: `Item 31 - Cumulative amount. Enter the cumulative amount
  involved in the suspicious activity if "Continuing activity
  report" is selected as the filing type. The cumulative amount
  represents the total amount involved in all SARs filed on the
  suspicious activity, including the current BSA SAR. If the
  cumulative dollar amount is unknown because all dollar
  amounts are unknown or no dollar amount is involved, enter
  zero (0) and explain the entry in Part V. If some dollar
  amounts are known while others are unknown, enter the total
  of all known amounts here and explain the nature of the
  unknown amounts in Part V.
  All monetary amounts must be recorded in U.S. Dollars
  rounded up to the next whole dollar. The amount $5,265.25
  would be recorded as $5,266. If the amount involves a
  foreign currency, record the currency name, amount, and
  country of origin in Part V. When converting a foreign
  currency to U.S. Dollars use an exchange rate for the date or
  dates the foreign currency was involved in the suspicious
  activity.
  `,
  initialValue: '',
};
// =================================================================

// ===================== 32 Structuring =========================
const typesOfStructuringOptions = [
  'Alters or cancels transaction to avoid BSA recordkeeping requirement',
  'Alters or cancels transaction to avoid CTR requirement',
  'Suspicious inquiry by customer regarding  BSA reporting or recordkeeping requirements',
  'Transactions(s) below BSA recording threshold',
  'Transactions(s) below CTR threshold',
  'Other',
];

export const typeOfStructuring: SarCheckboxOptions = {
  title: '32. Structuring',
  name: '32_typeOfStructuring',
  popupContent: generalPopupContent,
  options: typesOfStructuringOptions,
  initialValue: createCheckboxItems(typesOfStructuringOptions),
};

export const typeOfStructuringOther: SarInput = {
  title: 'Other',
  name: '32_typeOfStructuringOther',
  initialValue: '',
};
// =================================================================

// ===================== 33 Terrorist Financing =========================
export const typesOfTerroristFinancingOptions = [
  'Known or suspected terrorist/terrorist organization',
  'Other',
];

export const typeOfTerroristFinancing: SarCheckboxOptions = {
  title: '33. Terrorist Financing',
  name: '33_typeOfTerroristFinancing',
  popupContent: generalPopupContent,
  options: typesOfTerroristFinancingOptions,
  initialValue: createCheckboxItems(typesOfTerroristFinancingOptions),
};

export const typeOfTerroristFinancingOther: SarInput = {
  title: 'Other',
  name: '33_typeOfTerroristFinancingOther',
  initialValue: '',
};
// =================================================================

// ===================== 34 Fraud =========================
export const typesOfFraudOptions = [
  'ACH',
  'Advance fee',
  'Business loan',
  'Check',
  'Consumer loan',
  'Credit / Debit card',
  'Healthcare / Public or private health insurance',
  'Mail',
  'Mass-marketing',
  'Ponzi Scheme',
  'Pyramid Scheme',
  'Securities Fraud',
  'Wire',
  'Other',
];

export const typeOfFraud: SarCheckboxOptions = {
  title: '34. Fraud',
  name: '34_typeOfFraud',
  popupContent: generalPopupContent,
  options: typesOfFraudOptions,
  initialValue: createCheckboxItems(typesOfFraudOptions),
};

export const typeOfFraudOther: SarInput = {
  title: 'Other',
  name: '34_typeOfFraudOther',
  initialValue: '',
};
// =================================================================

// ===================== 35 Gaming Activities =========================
export const typesOfGamingActivitiesOptions = [
  'Chip Walking',
  'Minimal Gaming with Large Transactions',
  'Suspicious User of Counter Checks or Markers',
  'Unknown Source of Chips',
  'Other',
];

export const typeOfGamingActivities: SarCheckboxOptions = {
  title: '35. Gaming Activities',
  name: '35_typeOfGamingActivities',
  popupContent: generalPopupContent,
  options: typesOfGamingActivitiesOptions,
  initialValue: createCheckboxItems(typesOfGamingActivitiesOptions),
};

export const typeOfGamingActivitiesOther: SarInput = {
  title: 'Other',
  name: '35_typeOfGamingActivitiesOther',
  initialValue: '',
};
// =================================================================

// ===================== 36 Money Laundering =========================
export const typesOfMoneyLaunderingOptions = [
  'Exchange Small Bills for Large Bills or Vice  Versa',
  'Funnel Account',
  'Suspicion Concerning the Physical Condition of Funds',
  'Suspicion Concerning the Source of Funds',
  'Suspicion Designation of Beneficiaries, Assignees or Joint Owners',
  'Suspicion EFT / Wire Transfers',
  'Suspicion Exchange of Currencies',
  'Suspicious Receipt of Government Payments / Benefits',
  'Suspicious Use of Multiple Accounts',
  'Suspicious Use of Noncash Monetary Instruments',
  'Suspicious Use of Third-Party Transactors (Straw-Man)',
  'Trade Based Money Laundering / Black Market Peso Exchange',
  'Transaction Out of Pattern for Customer(s)',
  'Other',
];
export const typeOfMoneyLaundering: SarCheckboxOptions = {
  title: '36. Money Laundering',
  name: '36_typeOfMoneyLaundering',
  popupContent: generalPopupContent,
  options: typesOfMoneyLaunderingOptions,
  initialValue: createCheckboxItems(typesOfMoneyLaunderingOptions),
};

export const typeOfMoneyLaunderingOther: SarInput = {
  title: 'Other',
  name: '36_typeOfMoneyLaunderingOther',
  initialValue: '',
};
// =================================================================

// ===================== 37 Identification/Documentation =========================
export const typesOfIdentificationOrDocumentationOptions = [
  'Changes Spelling or Arrangement of Name',
  'Multiple Individuals with Same or Similar Identities',
  'Provided Questionable or False Documentation',
  'Provided Questionable or False Identification',
  'Refused or Avoided Request for Documentation',
  'Single Individual with Multiple Identities',
  'Other',
];

export const typeOfIdentificationDocumentation: SarCheckboxOptions = {
  title: '37. Identification / Documentation',
  name: '37_typeOfIdentificationDocumentation',
  popupContent: generalPopupContent,
  options: typesOfIdentificationOrDocumentationOptions,
  initialValue: createCheckboxItems(
    typesOfIdentificationOrDocumentationOptions,
  ),
};

export const typeOfIdentificationDocumentationOther: SarInput = {
  title: 'Other',
  name: '37_typeOfIdentificationDocumentationOther',
  initialValue: '',
};
// =================================================================

// ===================== 38 Other Suspicious Activities =========================
export const typesOfOtherSuspiciousActivitiesOptions = [
  'Account Takeover',
  'Bribery or Gratuity',
  'Counterfeit Instruments',
  'Elder Financial Exploitation',
  `Embezzlement/theft/
disappearance of funds`,
  'Forgeries',
  'Human Smuggling',
  'Human Trafficking',
  'Identity Theft',
  'Little or No Concern for Product Performance Penalities, Fees, Or Tax Consequences',
  'Misuse of Position or Self-Dealing',
  'Suspected Public /Private Corruption (Domestic)',
  'Suspected public/private corruption (foreign)',
  'Suspicious Use of Informal Value Transfer',
  'Suspicious Use of Multiple Transaction Locations',
  'Transaction with No Apparent Economic, Business, or Lawful Purpose',
  'Transaction(s) Involving Foreign High Risk Jurisdiction',
  'Two or More Individual Working Together',
  'Unlicensed or Unregistered MSB',
  'Other',
];
export const typeOfOtherSuspiciousActivities: SarCheckboxOptions = {
  title: '38. Other Suspicious Activities',
  name: '38_typeOfOtherSuspiciousActivities',
  popupContent: generalPopupContent,
  options: typesOfOtherSuspiciousActivitiesOptions,
  initialValue: createCheckboxItems(typesOfOtherSuspiciousActivitiesOptions),
};

export const typeOfOtherSuspiciousActivitiesOther: SarInput = {
  title: 'Other',
  name: '38_typeOfOtherSuspiciousActivitiesOther',
  initialValue: '',
};
// =================================================================

// ===================== 39 Insurance =========================
export const typesOfInsuranceOptions = [
  'Excessive Insurance',
  'Excessive or Unusual Cash Borrowing Against Policy / Annuity',
  'Proceeds Sent To or Received from Unrelated  Third Party',
  'Suspicious Life Settlement Sales Insurance (e.g. STOLIs, Viaticals)',
  'Suspicious Termination of Policy or Contract',
  'Unclear or No Insurable Interest',
  'Other',
];
export const typeOfInsurance: SarCheckboxOptions = {
  title: '39. Insurance',
  name: '39_typeOfInsurance',
  popupContent: generalPopupContent,
  options: typesOfInsuranceOptions,
  initialValue: createCheckboxItems(typesOfInsuranceOptions),
};

export const isInsuranceBlockDisabledConfig = {
  name: '39_disabled',
  initialValue: false,
};

export const typeOfInsuranceOther: SarInput = {
  title: 'Other',
  name: '39_typeOfInsuranceOther',
  initialValue: '',
};
// =================================================================

// ===================== 40 Securities/Futures/Options =========================
export const typesOfSecuritiesFuturesOptions = [
  'Insider Trading',
  'Market Manipulation',
  'Misappropriation',
  'Unauthorized Pooling',
  'Wash Trading',
  'Other',
];
export const typeOfSecuritiesFuturesOptions: SarCheckboxOptions = {
  title: '40. Securities / Futures / Others',
  name: '40_typeOfSecuritiesFuturesOptions',
  popupContent: generalPopupContent,
  options: typesOfSecuritiesFuturesOptions,
  initialValue: createCheckboxItems(typesOfSecuritiesFuturesOptions),
};

export const isTypesOfSecuritiesFuturesBlockDisabledConfig = {
  name: '40_disabled',
  initialValue: false,
};

export const typeOfSecuritiesFuturesOptionsOther: SarInput = {
  title: 'Other',
  name: '40_typeOfSecuritiesFuturesOptionsOther',
  initialValue: '',
};
// =================================================================

// ===================== 41 Mortgage Fraud =========================
export const typesOfMortgageFraudOptions = [
  'Application Fraud',
  'Appraisal Fraud',
  'Foreclosure / Short Sale Fraud',
  'Loan Modification Fraud',
  'Origination Fraud',
  'Other',
];
export const typeOfMortgageFraud: SarCheckboxOptions = {
  title: '41. Mortgage Fraud',
  name: '41_typeOfMortgageFraud',
  popupContent: generalPopupContent,
  options: typesOfMortgageFraudOptions,
  initialValue: createCheckboxItems(typesOfMortgageFraudOptions),
};

export const typeOfMortgageFraudOther: SarInput = {
  title: 'Other',
  name: '41_typeOfMortgageFraudOther',
  initialValue: '',
};
// =================================================================

// ===================== 42 Cyber Event =========================
export const typesOfCyberEventOptions = [
  'Against Financial Institution(s)',
  'Against Financial Institution Customer(s)',
  'Other',
];
export const typeOfCyberEvent: SarCheckboxOptions = {
  title: '42. Cyber Event',
  name: '42_typeOfCyberEvent',
  popupContent: generalPopupContent,
  options: typesOfCyberEventOptions,
  initialValue: createCheckboxItems(typesOfCyberEventOptions),
};

export const typeOfCyberEventOther: SarInput = {
  title: 'Other',
  name: '42_typeOfCyberEventOther',
  initialValue: '',
};
// =================================================================

// ===================== 45 Products Involved =========================
export const typesOfProductsInvolvedOptions = [
  'Bonds / Notes',
  'Commercial Mortgage',
  'Commercial Paper',
  'Credit Card',
  'Debit Card',
  'Deposit Account',
  'Forex Transactions',
  'Futures / Options on Futures',
  'Hedge Fund',
  'Home Equity Line of Credit',
  'Home Equity Loan',
  'Insurance / Annuity Products',
  'Microcap  Securities',
  'Mutual Fund',
  'Options of Securities',
  'Prepaid Access',
  'Residential Mortgage',
  'Security Futures Products',
  'Stocks',
  'Swap, Hybird, or other Derivatives',
  'Other (List Below)',
];
export const typeOfProductsInvolved: SarCheckboxOptions = {
  title:
    '45. Were any of the following product type(s) involved in the suspicious activity? (Check all that apply)',
  name: '45_typesOfProductsInvolved',
  popupContent: generalPopupContent,
  options: typesOfProductsInvolvedOptions,
  initialValue: createCheckboxItems(typesOfProductsInvolvedOptions),
};

export const typeOfProductsInvolvedOther: SarInput = {
  title: 'Other',
  name: '45_typeOfProductsInvolvedOther',
  initialValue: '',
};
// =================================================================

// ===================== 46 Payments Involved =========================
export const typesOfPaymentInstrumentInvolvedOptions = [
  'Bank / Cashiers Check',
  'Foreign Currency',
  'Funds Transfer',
  'Gaming Instruments',
  'Government  Payment',
  'Money Orders',
  'Personal / Business Check',
  'Travelers Check',
  'U.S. Currency',
  'Other (List Below)',
];
export const typeOfPaymentInstrumentInvolved: SarCheckboxOptions = {
  title:
    '46. Were any of the following instrument type(s)/payment mechanism(s) involved in the suspicious activity? (Check all that apply)',
  name: '46_typeOfPaymentInstrumentInvolved',
  popupContent: generalPopupContent,
  options: typesOfPaymentInstrumentInvolvedOptions,
  initialValue: createCheckboxItems(typesOfPaymentInstrumentInvolvedOptions),
};

export const typeOfPaymentInstrumentInvolvedOther: SarInput = {
  title: 'Other',
  name: '46_typeOfPaymentInstrumentInvolvedOther',
  initialValue: '',
};
// =================================================================

// ===================== 43 IP Address =========================
const ipAddressPopupContent = `Item 43 - IP Address(s). Enter the Internet Protocol (I P) address, if known, of a subject’s electronic internet based
contact with the financial institution. In addition to the IP
address, enter the date (MM/DD/YYYY format) of the activity
and/or the Coordinated Universal Time (UTC) (HH:MM:SS
format) of the first instance of the reported IP address (if
known). Enter as many IP addresses as are known up to a
maximum of 99.
If the IP address is IPv4, use a variation of the format
nnn.nnn.nnn.nnn (n = number) with periods between the
segments. First set of numbers must be values between
1-255 (inclusive); second, third and fourth set of numbers
must be values between 0-255 (inclusive). If the IP address is
IPv6, use a variation of the format
cccc:cccc:cccc:cccc:cccc:cccc:cccczcccc (c = character,
IPv6 is alphanumeric in some segments) with colons
between the segments. Multiple IP addresses should be
completed if multiple IP addresses are involved in the
suspicious activity.
`;

export const ipAddressConfig = {
  title: '43. IP Address',
  name: '43_ipAddress',
  popupContent: ipAddressPopupContent,
  initialValue: [ipAddressInitialState],
};

export const ipAddressDate: SarInput = {
  title: 'Date',
  name: '43_ipAddressDate',
  popupContent: ipAddressPopupContent,
  initialValue: '22',
};

export const ipAddressTimestamp: SarInput = {
  title: 'Timestamp (UTC)',
  name: '43_ipAddressTimestamp',
  popupContent: ipAddressPopupContent,
  initialValue: '',
};
// =================================================================

// ===================== 44 Cyber Event Indicators =========================
const cyberEventIndicatorPopupContent = `Item 44 - Cyber Event Indicators. If one or more cyber-
events are involved in the suspicious activity, select the
appropriate indicator from the drop-down list and enter the
supporting information in the associated text fields. If none
of the indicators apply, select “Other” and provide a brief
description of the cyber—event in the associated text field.
The event value must be recorded if a cyber-event indictor is
selected. If the “Command and control IP address” or
“Suspicious IP address” indicator is selected, enter the date
(MM/DD/YYYY format) of the activity and/or the Coordinated
Universal Time (UTC) (HH:MM:SS format) of the first instance
of the reported IP address (if known). A maximum of 99
cyber—event indicators may be recorded. Cyber—event
indicator information must be explained in the FinCEN SAR
Part V narrative.`;

export const cyberEventIndicatorDescription: SarInput = {
  title: 'Description',
  name: '44_cyberEventIndicatorDescription',
  popupContent: cyberEventIndicatorPopupContent,
  initialValue: '',
};

export const cyberEventIndicatorsConfig = {
  name: '44_cyberEventIndicators',
  initialValue: [cyberEventIndicatorState],
};
// =================================================================

// ===================== 47 Commodity Type =========================
export const typeOfCommodityType: SarOptions = {
  title: '47. Commodity type (if applicable)',
  name: '47_commodityType',
  popupContent: `Item 47 - Commodity type. If a commodity is involved in the
  suspicious activity, record the commodity type here.
  Multiple commodity types should be reported if more than
  one commodity type is involved in the suspicious activity.
  `,
  initialValue: [''],
};
// =================================================================

// ===================== 48 Product/Instrument Description  =========================
export const productInstrumentDescription: SarOptions = {
  title: '48. Product/Instrument Description (if needed)',
  name: '48_productInstrumentDescription',
  popupContent: `Item 48 - Product/lnstrument description. Provide a
  description of the product recorded in Item 45 or instrument
  recorded in Item 46 for a more-complete understanding of
  the suspicious activity (if necessary). Multiple descriptions
  should be completed if multiple products/instruments
  requiring a description are involved in the suspicious activity.
  `,
  initialValue: [''],
};
// =================================================================

// ===================== 49 Market Where Traded  =========================
export const marketWhereTraded: SarOptions = {
  title: '49. Market Where Traded',
  name: '49_marketWhereTraded',
  popupContent: `Item 49 - Market where traded. Enter the three to five-Ietter
  code for the market where a commodity recorded in Items
  45 or 47 was traded. Multiple entries should be recorded if
  multiple markets are involved in the suspicious activity. Use
  only the ISO 10383 Exchange/Market Identifier Codes (MIC)
  found at https://www.i3020022.org/10383/iso-10383-
  market-identifier—codes
  `,
  initialValue: [''],
};
// =================================================================

// ===================== 50 CUSIP Number  =========================
export const cusipNumber: SarOptions = {
  title: '50. CUSIP Number',
  name: '50_cusipNumber',
  popupContent: `Item 50 - Enter the CUSIP® (Committee on Uniform
    Securities Identification Procedures) number of any
    securities products such as stocks and bonds involved in the
    suspicious activity. Multiple entries should be completed if
    there are multiple CUSIP numbers for multiple securities
    products involved in the suspicious activity.
  `,
  initialValue: [''],
};
// =================================================================

const allState = [
  typeOfAmountInvolved,
  amountInvolved,
  suspiciousActivityStartDate,
  suspiciousActivityEndDate,
  cumulativeAmount,
  typeOfStructuring,
  typeOfStructuringOther,
  typeOfTerroristFinancing,
  typeOfTerroristFinancingOther,
  typeOfFraud,
  typeOfFraudOther,
  typeOfGamingActivities,
  typeOfGamingActivitiesOther,
  typeOfMoneyLaundering,
  typeOfMoneyLaunderingOther,
  typeOfIdentificationDocumentation,
  typeOfIdentificationDocumentationOther,
  typeOfOtherSuspiciousActivities,
  typeOfOtherSuspiciousActivitiesOther,
  typeOfInsurance,
  typeOfInsuranceOther,
  typeOfSecuritiesFuturesOptions,
  typeOfSecuritiesFuturesOptionsOther,
  typeOfMortgageFraud,
  typeOfMortgageFraudOther,
  typeOfCyberEvent,
  typeOfCyberEventOther,
  typeOfProductsInvolved,
  typeOfProductsInvolvedOther,
  cyberEventIndicatorDescription,
  typeOfPaymentInstrumentInvolved,
  typeOfPaymentInstrumentInvolvedOther,
  typeOfCommodityType,
  productInstrumentDescription,
  marketWhereTraded,
  cusipNumber,
];

const restOfState = {
  [ipAddressConfig.name]: ipAddressConfig.initialValue,
  [ipAddressDate.name]: ipAddressDate.initialValue,
  [ipAddressTimestamp.name]: ipAddressTimestamp.initialValue,
  [cyberEventIndicatorsConfig.name]: cyberEventIndicatorsConfig.initialValue,
  [isInsuranceBlockDisabledConfig.name]:
    isInsuranceBlockDisabledConfig.initialValue,
  [isTypesOfSecuritiesFuturesBlockDisabledConfig.name]:
    isTypesOfSecuritiesFuturesBlockDisabledConfig.initialValue,
};

export const initialState = {
  ...createState(allState),
  ...restOfState,
} as unknown as Page4Model['content'];
