import { FC, ReactNode } from 'react';

// Components
import { U21Button, U21Spacer } from 'app/shared/u21-ui/components';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import { SidebarFieldValue } from 'app/modules/sidebar/components/SidebarFieldValue';
import { IconExternalLink } from '@u21/tabler-icons';
import { StyledItem } from 'app/modules/sidebar/components/Sidebar.styles';
import styled from 'styled-components';

export interface DataProps {
  label: ReactNode;
  value: ReactNode;
  id: number | string;
  route?: string;
}

interface OwnProps {
  list: DataProps[];
}

export const SidebarExternalLinkList: FC<OwnProps> = ({ list }) => {
  if (!list.length) {
    return null;
  }
  return (
    <>
      {list.map((item) => {
        return (
          <StyledItem key={item.id}>
            <SidebarFieldLabel>{item.label}</SidebarFieldLabel>
            <StyledU21Spacer align="start" horizontal>
              <SidebarFieldValue>{item.value}</SidebarFieldValue>
              {item.route && (
                <U21Button variant="outlined" size="small" to={item.route}>
                  <IconExternalLink />
                </U21Button>
              )}
            </StyledU21Spacer>
          </StyledItem>
        );
      })}
    </>
  );
};

const StyledU21Spacer = styled(U21Spacer)`
  width: 100%;
  justify-content: space-between;
`;
