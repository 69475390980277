import produce, { Draft } from 'immer';

// Models
import {
  SarState,
  SarActionTypes,
  SarSummary,
} from 'app/modules/fincenSar/models';
import { DeadlinesActionTypes } from 'app/modules/deadlines/models';

// Actions
import SarActions from 'app/modules/fincenSar/actions';
import { DeadlinesActions } from 'app/modules/deadlines/actions';

// Utils
import deepFreeze from 'app/shared/utils/deepFreeze';

// Helpers
import { sarDetailsInitialState } from 'app/modules/fincenSar/constants/shared';

const initialState: Readonly<SarState> = {
  sar: sarDetailsInitialState,
  sidebarSar: sarDetailsInitialState,
  sars: [] as SarSummary[],
  num_filings: 0,
  validationErrors: {},
};
// to ensure initialState is readonly
deepFreeze(initialState);

const reducer = (
  state = initialState,
  action: SarActions | DeadlinesActions,
) => {
  return produce(state, (draft: Draft<SarState>) => {
    switch (action.type) {
      case SarActionTypes.RETRIEVE_SIDEBAR_SAR_SUCCESS:
        draft.sidebarSar = action.payload;
        return;

      case SarActionTypes.RETRIEVE_SAR_LIST_SUCCESS:
        draft.sars = action.payload.filings;
        draft.num_filings = action.payload.num_filings;
        return;

      case SarActionTypes.REASSIGN_SAR_SUCCESS: {
        const reviewerMap = action.payload.filings.reduce(
          (acc, i) => ({ ...acc, [i.id]: i.reviewer }),
          {},
        );
        draft.sars = draft.sars.map((sar) => ({
          ...sar,
          reviewer: reviewerMap[sar.id] || sar.reviewer,
        }));
        return;
      }

      case SarActionTypes.ARCHIVE_SAR_SUCCESS: {
        const sarSummary: SarSummary = action.payload;
        draft.sars = draft.sars.map((sar) => {
          const returnSar = sar;
          if (sar.id === sarSummary.id) {
            returnSar.status = sarSummary.status;
          }
          return returnSar;
        });
        return;
      }

      case DeadlinesActionTypes.EDIT_ARTICLE_DEADLINE_SUCCESS:
        if (action.payload.article_id === draft.sar.id) {
          draft.sar.deadline = action.payload;
        }
        draft.sars = draft.sars.map((sar) => {
          if (sar.id === action.payload.article_id) {
            return {
              ...sar,
              deadline: action.payload,
            };
          }
          return sar;
        });
        return;

      case SarActionTypes.EDIT_SAR_REVIEWER_SUCCESS:
        draft.sars = draft.sars.map((sar) => {
          if (sar.id === action.payload?.id) {
            return { ...sar, reviewer: action.payload.reviewer };
          }
          return sar;
        });
        return;
    }
  });
};

export { reducer as sarsReducer, initialState };
