// Components
import { IconTag } from '@u21/tabler-icons';

// Types
import { FullTagResponse } from 'app/modules/tags/responses';

// Constants
import emptyFn from 'app/shared/utils/empty-fn';
import { U21MultiSelect, U21Typography } from 'app/shared/u21-ui/components';
import styled from 'styled-components';

export const SidebarAssociatedTags = ({
  tags,
}: {
  tags: FullTagResponse[];
}) => {
  return (
    <div>
      <U21Typography icon={<IconTag />} variant="subtitle2">
        Tags
      </U21Typography>
      <StyledU21MultiSelect
        onChange={emptyFn}
        options={tags.map((tag) => {
          return {
            text: tag.name,
            value: tag.name,
          };
        })}
        value={tags.map((tag) => tag.name)}
        placeholder="No associated tags"
        disabled
      />
    </div>
  );
};

const StyledU21MultiSelect = styled(U21MultiSelect)`
  margin-bottom: 8px;
  margin-top: 8px;
`;
