import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  U21CheckboxGroup,
  U21CheckboxGroupOptionProps,
  U21CheckboxGroupOptionValue,
  U21Modal,
  U21Typography,
} from 'app/shared/u21-ui/components';

// Actions
import {
  retrieveAlertActionEvents,
  retrieveAlertEntities,
  retrieveAlertTransactions,
} from 'app/modules/alerts/actions';

// Selectors
import {
  selectAlert,
  selectAlertActionEventsLoading,
  selectAlertLoading,
  selectAlertTransactionsLoading,
  selectRetrieveAlertEntitiesLoading,
} from 'app/modules/alerts/selectors';

// Models
import { CasesAddAlertParams } from 'app/modules/cases/models';

// Helpers
import {
  formatActionEventOptions,
  formatEntityOptions,
  formatEventOptions,
  ValueField,
} from 'app/modules/search/helpers';

interface OwnProps {
  alertId: number;
  onClose: () => void;
  open: boolean;
  handleAddAlertButtonPressed: (casesAddAlert: CasesAddAlertParams) => void;
}

const RELATED_ITEMS_OPTIONS = {
  RELATED_ENTITIES: 'RELATED_ENTITIES',
  RELATED_EVENTS: 'RELATED_EVENTS',
  RELATED_ACTION_EVENTS: 'RELATED_ACTION_EVENTS',
};

export const AlertAssociationToCasesModal = (props: OwnProps) => {
  const { alertId, onClose, open, handleAddAlertButtonPressed } = props;

  const dispatch = useDispatch();

  const alert = useSelector(selectAlert);

  const retrieveAlertLoading = useSelector(selectAlertLoading);
  const alertTransactionsLoading = useSelector(selectAlertTransactionsLoading);
  const alertEntitiesLoading = useSelector(selectRetrieveAlertEntitiesLoading);
  const alertActionEventsLoading = useSelector(selectAlertActionEventsLoading);

  const [relatedItemsOptions, setRelatedItemsOptions] = useState<
    U21CheckboxGroupOptionValue[]
  >([]);

  // We want to fetch associated entities and events after we retrieve the intended alert
  useEffect(() => {
    if (alertId > -1) {
      dispatch(
        retrieveAlertEntities({
          alertId: String(alertId),
          offset: 1,
          limit: 1000,
        }),
      );
      dispatch(
        retrieveAlertTransactions({
          hash_key: String(alertId),
          offset: 1,
          limit: 1000,
        }),
      );
      dispatch(
        retrieveAlertActionEvents({
          alert_id: alertId,
          offset: 1,
          limit: 1000,
        }),
      );
    }
  }, [alertId, dispatch]);

  const { entities, events, action_events: actionEvents } = alert;
  const relatedItemOptions = useMemo(() => {
    const options: U21CheckboxGroupOptionProps[] = [];

    if (entities?.length) {
      options.push({
        label: `Link ${entities?.length} related entities`,
        value: RELATED_ITEMS_OPTIONS.RELATED_ENTITIES,
      });
    }

    if (events?.length) {
      options.push({
        label: `Link ${events?.length} related transaction event(s)`,
        value: RELATED_ITEMS_OPTIONS.RELATED_EVENTS,
      });
    }

    if (actionEvents?.events.length) {
      options.push({
        label: `Link ${actionEvents?.events.length} related action event(s)`,
        value: RELATED_ITEMS_OPTIONS.RELATED_ACTION_EVENTS,
      });
    }
    return options;
  }, [entities, events, actionEvents]);

  const addAlertPressed = () => {
    const casesAddAlert: CasesAddAlertParams = {
      entities: [],
      events: [],
      action_events: [],
    };
    if (
      relatedItemsOptions.indexOf(RELATED_ITEMS_OPTIONS.RELATED_ENTITIES) > -1
    ) {
      casesAddAlert.entities = formatEntityOptions(
        alert.entities || [],
        undefined,
        ValueField.EXTERNAL_ID,
      );
    }
    if (
      relatedItemsOptions.indexOf(RELATED_ITEMS_OPTIONS.RELATED_EVENTS) > -1
    ) {
      casesAddAlert.events = formatEventOptions(
        alert.events || [],
        undefined,
        ValueField.EXTERNAL_ID,
      );
    }
    if (
      relatedItemsOptions.indexOf(RELATED_ITEMS_OPTIONS.RELATED_ACTION_EVENTS) >
      -1
    ) {
      casesAddAlert.action_events = formatActionEventOptions(
        alert.action_events?.events || [],
        undefined,
        ValueField.EXTERNAL_ID,
      );
    }

    handleAddAlertButtonPressed(casesAddAlert);
    setRelatedItemsOptions([]);
    onClose();
  };

  const handleOnClose = () => {
    setRelatedItemsOptions([]);
    onClose();
  };

  return (
    <U21Modal
      loading={
        retrieveAlertLoading ||
        alertTransactionsLoading ||
        alertActionEventsLoading ||
        alertEntitiesLoading
      }
      open={open}
      onClose={handleOnClose}
      title="Add Alert Associations to Case"
      actionButtonProps={{
        children: 'Add associations',
      }}
      onAction={addAlertPressed}
    >
      {!alert.entities?.length &&
      !alert.events?.length &&
      !actionEvents?.events.length ? (
        <U21Typography variant="subtitle2">
          No related entities, transaction events, or action events for this
          alert.
        </U21Typography>
      ) : (
        <>
          <U21Typography variant="subtitle2">
            Select the alert associations you wish to also add to this case
          </U21Typography>
          <U21CheckboxGroup
            options={relatedItemOptions}
            onChange={setRelatedItemsOptions}
            value={relatedItemsOptions}
          />
        </>
      )}
    </U21Modal>
  );
};
