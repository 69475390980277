import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CasesActionTypes } from 'app/modules/casesOld/models';
import { InvestigationType } from 'app/modules/investigations/models';

// Types
import { InvestigationsCaseResponse } from 'app/modules/investigations/types';

// Actions
import {
  retrieveCase,
  retrieveSidebarCaseSuccess,
} from 'app/modules/casesOld/actions';

// API
import { retrieveInvestigationsCase } from 'app/modules/investigations/api';

const rootAction = CasesActionTypes.RETRIEVE_SIDEBAR_CASE;
function* retrieveSidebarCaseFlow({
  payload,
}: ReturnType<typeof retrieveCase>) {
  const config = {
    rootAction,
    request: call(retrieveInvestigationsCase, {
      object_type: InvestigationType.CASE,
      object_ids: [payload],
      short: false,
    }),
    errorToastMessage: "Couldn't retrieve case to populate sidebar",
    success: function* onSuccess(result: InvestigationsCaseResponse) {
      yield put(retrieveSidebarCaseSuccess(result.cases[0]));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSidebarCase() {
  yield takeLatest(rootAction, retrieveSidebarCaseFlow);
}
