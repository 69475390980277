import { FC } from 'react';
import { isEmpty } from 'lodash';

// Models
import { CustomData, AnyObjectType } from 'app/shared/models';

// Components
import { Item } from 'app/shared/u21-ui/components/dashboard';
import { U21Typography } from 'app/shared/u21-ui/components';
import { SidebarFieldLabel } from 'app/modules/sidebar/components/SidebarFieldLabel';
import { SidebarFieldValue } from 'app/modules/sidebar/components/SidebarFieldValue';

// Styles
import styled from 'styled-components';

interface CustomDataLabel {
  label: string;
  description: string;
}

interface SidebarCustomDataProps {
  data?: CustomData;
  customDataLabels?: Record<string, CustomDataLabel | string>;
}

const renderNestedObjectData = (
  data: AnyObjectType,
  customDataLabels?: Record<string, CustomDataLabel | string>,
) => {
  return Object.keys(data || {}).map((key: string) => {
    const value = data[key];
    if (typeof value === 'object') {
      return (
        <div key={key}>
          <SidebarFieldLabel>{key}</SidebarFieldLabel>
          <NestedValues>
            {renderNestedObjectData(value, customDataLabels)}
          </NestedValues>
        </div>
      );
    }

    // Try to get the label, if not default to treating the value as the value
    // finally, if neither are present, just use the key
    const keyVal =
      (customDataLabels?.[key] as CustomDataLabel)?.label ??
      customDataLabels?.[key] ??
      key;
    return (
      <Item key={key}>
        <SidebarFieldLabel>{keyVal}</SidebarFieldLabel>
        <SidebarFieldValue>{String(value)}</SidebarFieldValue>
      </Item>
    );
  });
};

export const hasValidCustomData = (
  data: CustomData | undefined,
): data is NonNullable<CustomData> => {
  if (data && !isEmpty(data)) {
    return true;
  }
  return false;
};

export const SidebarCustomData: FC<SidebarCustomDataProps> = ({
  data,
  customDataLabels,
}) => {
  return (
    <>
      <StyledU21Typography variant="subtitle2">Custom Data</StyledU21Typography>
      {hasValidCustomData(data) ? (
        renderNestedObjectData(
          data.customer_raw ? data.customer_raw : data,
          customDataLabels,
        )
      ) : (
        <U21Typography variant="caption" color="grey.600">
          No custom data found
        </U21Typography>
      )}
      {/* handle when we send back the top level 'customer_raw' key */}
    </>
  );
};

const StyledU21Typography = styled(U21Typography)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const NestedValues = styled.div`
  padding-left: 20px;
`;
