// Types
import {
  FullCaseResponse,
  ShortCaseResponse,
} from 'app/modules/casesOld/types';

// Models
import { Attachment, GoogleDriveDoc } from 'app/modules/uploads/models';

export enum CasesActionTypes {
  RETRIEVE_CASES = '@@cases/RETRIEVE_CASES',
  RETRIEVE_CASES_SUCCESS = '@@cases/RETRIEVE_CASES_SUCCESS',

  RETRIEVE_CASE = '@@cases/RETRIEVE_CASE',
  RETRIEVE_CASE_SUCCESS = '@@cases/RETRIEVE_CASE_SUCCESS',

  EDIT_CASE_SUCCESS = '@@cases/EDIT_CASE_SUCCESS',

  EDIT_DOCUMENT_SUCCESS = '@@cases/EDIT_DOCUMENT_SUCCESS',

  ADD_DOCUMENTS_SUCCESS = '@@cases/ADD_DOCUMENTS_SUCCESS',

  DELETE_DOCUMENT_SUCCESS = '@@cases/DELETE_DOCUMENT_SUCCESS',

  UPLOAD_FILES_SUCCESS = '@@cases/UPLOAD_FILES_SUCCESS',

  TRIGGER_CASE_ACTION = '@@cases/TRIGGER_CASE_ACTION',
  TRIGGER_CASE_ACTION_SUCCESS = '@@cases/TRIGGER_CASE_ACTION_SUCCESS',

  RETRIEVE_SIDEBAR_CASE = '@@cases/RETRIEVE_SIDEBAR_CASE',
  RETRIEVE_SIDEBAR_CASE_SUCCESS = '@@cases/RETRIEVE_SIDEBAR_CASE_SUCCESS',
}

export interface CaseSummary extends ShortCaseResponse {}

export interface CreateCasePayload {
  createdFromAlert: number;
  form: {
    title: string;
    description: string;
    startDate: string;
    endDate: string;
  };
  docs: GoogleDriveDoc[];
  s3_docs: File[];
  watchers: number[];
  tags: number[];
  alerts: number[];
  entities: number[];
  events: string[];
  action_events: string[];
  assigned_to_id?: number;
  queue_id?: number;
  add_related_entities: boolean;
  add_related_txn_events: boolean;
}

export type CaseStatus = 'OPEN' | 'CLOSED' | 'DELETED' | 'IN_REVIEW';
export interface CaseDetails extends FullCaseResponse {}

export interface DeleteDocumentPayload {
  caseId: number;
  docId: number;
}

export interface CasesState {
  case: CaseDetails;
  sidebarCase: CaseDetails;
}

export interface CaseEditPayload {
  id: number;
  status?: CaseStatus;
  title?: string;
  description?: string;
  start_date?: string;
  end_date?: string;
  documents?: Attachment[];
  tags?: number[];
  watchers?: number[];
  entities?: number[];
  alerts?: number[];
  is_work_started?: boolean;
}

export interface CaseEditResult extends CaseSummary {
  isCasesPageChanged?: boolean;
}

export interface AddDocumentsPayload {
  caseId: number;
  documents: GoogleDriveDoc[];
  S3documents: Attachment[];
}
