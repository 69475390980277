// Components
import { U21Spacer } from 'app/shared/u21-ui/components';

import { Reassign } from 'app/modules/investigations/components/InvestigationActions/Reassign';
import { Requeue } from 'app/modules/investigations/components/InvestigationActions/Requeue';

// Models
import { InvestigationType } from 'app/modules/investigations/models';

interface OwnProps {
  type: InvestigationType;
  sidebar?: boolean;
}

export const InvestigationEscalations = ({ type, sidebar }: OwnProps) => {
  return (
    <U21Spacer spacing={2}>
      <Reassign type={type} sidebar={sidebar} />
      <Requeue type={type} sidebar={sidebar} />
    </U21Spacer>
  );
};
