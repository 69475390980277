import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  AlertsActionTypes,
  RetrieveAlertEntitiesPayload,
  RetrieveAlertTxnsPayload,
} from 'app/modules/alerts/models';
import { InvestigationType } from 'app/modules/investigations/models';

// Types
import { InvestigationsAlertResponse } from 'app/modules/investigations/types';
import { RetrieveAlertActionEventsPayload } from 'app/modules/alerts/requests';

// API
import { retrieveInvestigationsAlert } from 'app/modules/investigations/api';

// Actions
import {
  retrieveSidebarAlert as retrieveSidebarAlertAction,
  retrieveSidebarAlertSuccess,
  retrieveSidebarAlertEntities,
  retrieveSidebarAlertTransactions,
  retrieveSidebarAlertActionEvents,
} from 'app/modules/alerts/actions';

// Constants
import { DEFAULT_PAGINATION_PAYLOAD } from 'app/shared/pagination/constants';

const rootAction = AlertsActionTypes.RETRIEVE_SIDEBAR_ALERT;
function* retrieveSidebarAlert({
  payload,
}: ReturnType<typeof retrieveSidebarAlertAction>) {
  const config = {
    rootAction,
    errorToastMessage:
      'Something went wrong, unable to retrieve alert to populate sidebar.',
    request: call(retrieveInvestigationsAlert, {
      object_type: InvestigationType.ALERT,
      object_ids: [payload],
      short: false,
    }),
    success: function* onSuccess(result: InvestigationsAlertResponse) {
      const alert = result.alerts[0];

      yield put(retrieveSidebarAlertSuccess(alert));

      const entitiesPayload: RetrieveAlertEntitiesPayload = {
        alertId: String(alert.id),
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      const transactionsPayload: RetrieveAlertTxnsPayload = {
        hash_key: String(alert.id),
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      const actionEventsPayload: RetrieveAlertActionEventsPayload = {
        alert_id: alert.id,
        ...DEFAULT_PAGINATION_PAYLOAD,
      };

      yield put(retrieveSidebarAlertEntities(entitiesPayload));
      yield put(retrieveSidebarAlertTransactions(transactionsPayload));
      yield put(retrieveSidebarAlertActionEvents(actionEventsPayload));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveSidebarAlert() {
  yield takeLatest(rootAction, retrieveSidebarAlert);
}
