import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AdminActionTypes } from 'app/modules/admin/models';

// Actions
import {
  retrieveOrg as retrieveOrgAction,
  retrieveOrgSuccess,
} from 'app/modules/admin/actions';

// API
import { retrieveOrg as retrieveOrgApi } from 'app/shared/api/admin';

const rootAction = AdminActionTypes.RETRIEVE_ORG;
function* retrieveOrg({ payload }: ReturnType<typeof retrieveOrgAction>) {
  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to retrieve the org.',
    successToastMessage: `Org retrieved successfully.`,
    request: call(retrieveOrgApi, payload),
    success: function* onSuccess(result) {
      yield put(retrieveOrgSuccess(result));
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watch() {
  yield takeLatest(rootAction, retrieveOrg);
}
