import { useSelector } from 'react-redux';
import { selectOrgEventStatuses } from 'app/modules/orgSettings/selectors';
import { U21MultiSelect } from 'app/shared/u21-ui/components';
import { useMemo } from 'react';

interface OwnProps {
  onChange: (e) => void;
  value: string[];
}
export const StatusSelect = ({ onChange, value }: OwnProps) => {
  const statuses = useSelector(selectOrgEventStatuses);

  const options = useMemo(() => {
    return statuses.map((i) => ({ text: i, value: i }));
  }, [statuses]);

  return (
    <U21MultiSelect
      backdrop
      onChange={onChange}
      options={options}
      startIcon={<div />}
      value={value}
    />
  );
};
