import { Permission } from 'app/shared/utils/permissions';
import { OrgFeature } from 'app/modules/admin/models';

export enum PermissionsActionTypes {
  RETRIEVE_PERMISSIONS = '@@permissions/RETRIEVE_PERMISSIONS',
  RETRIEVE_PERMISSIONS_SUCCESS = '@@permissions/RETRIEVE_PERMISSIONS_SUCCESS',

  RETRIEVE_UNASSIGNED_PERMISSIONS = '@@permissions/RETRIEVE_UNASSIGNED_PERMISSIONS',
  RETRIEVE_UNASSIGNED_PERMISSIONS_SUCCESS = '@@permissions/RETRIEVE_UNASSIGNED_PERMISSIONS_SUCCESS',

  EDIT_PERMISSIONS = '@@permissions/EDIT_PERMISSIONS',
  EDIT_PERMISSIONS_SUCCESS = '@@permissions/EDIT_PERMISSIONS_SUCCESS',
}

export type PermissionAssociationType = 'team' | 'agent';

export interface RetrievePermissionsPayload {
  // id of the association type, team or agent or id for now
  associationId: number;
  // association type - team or agent for now, used in the url
  associationType: PermissionAssociationType;
}

export interface EditPermissionsPayload extends RetrievePermissionsPayload {
  permissions: number[];
}

export interface EditPermissionsResponse {
  // new permissions for that agent or team
  permissions: PermissionSummary[];
  // new available permissions for that agent or team
  unassigned_permissions: PermissionSummary[];
}

export interface PermissionTeamSummary {
  id: number;
  name: string;
}

export interface PermissionSummary {
  id: number;
  name: Permission;
  feature: OrgFeature;
  type: string;
  teams?: PermissionTeamSummary[];
}

export interface PermissionsState {
  permissions: PermissionSummary[];
  unassignedPermissions: PermissionSummary[];
}
