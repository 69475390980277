import { Filter, FilterOption } from 'app/modules/filters/models';
import { RuleSources, RuleStatus } from 'app/modules/rules/models';

import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';

import {
  getAgentFilterOption,
  TAG_FILTER_OPTION,
} from 'app/modules/filters/filters';
import { getValidFilters } from 'app/modules/filters/utils';
import { getLocalStorageJSON } from 'app/shared/utils/localStorage';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';

export const RULE_FILTER_KEYS = {
  CREATED_AT: 'created_at',
  CREATED_BY: 'created_by',
  ID_TITLE: 'phrase',
  SOURCE: 'source',
  STATUS: 'status',
  TAG: 'tag',
};

export const ALL_RULE_FILTER_OPTIONS: FilterOption[] = [
  {
    key: RULE_FILTER_KEYS.CREATED_AT,
    label: 'Created at',
    type: FILTER_TYPE.DATE_RANGE,
  },
  {
    key: RULE_FILTER_KEYS.CREATED_BY,
    ...getAgentFilterOption('Created by', false),
  },
  {
    key: RULE_FILTER_KEYS.ID_TITLE,
    label: 'ID / Title',
    type: FILTER_TYPE.TEXT,
  },
  {
    key: RULE_FILTER_KEYS.SOURCE,
    label: 'Source',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        inputProps: {
          options: [
            { text: 'Internal', value: 'INTERNAL' },
            { text: 'External', value: 'EXTERNAL' },
          ] satisfies { text: string; value: RuleSources }[],
        },
      },
    },
  },
  {
    key: RULE_FILTER_KEYS.STATUS,
    label: 'Status',
    type: FILTER_TYPE.SELECT,
    customize: {
      [FILTER_OPERATOR.IS]: {
        inputProps: {
          options: [
            { text: 'Active', value: 'ACTIVE' },
            { text: 'Inactive', value: 'INACTIVE' },
          ] satisfies { text: string; value: RuleStatus }[],
        },
      },
    },
  },
  {
    key: RULE_FILTER_KEYS.TAG,
    ...TAG_FILTER_OPTION,
  },
];

export const VALIDATING_MODEL_FILTERS = ALL_RULE_FILTER_OPTIONS.filter(
  (i) => i.key !== RULE_FILTER_KEYS.SOURCE && i.key !== RULE_FILTER_KEYS.STATUS,
);

export const SHADOW_MODEL_FILTERS = ALL_RULE_FILTER_OPTIONS.filter(
  (i) => i.key !== RULE_FILTER_KEYS.STATUS,
);

export const DEFAULT_LIVE_RULE_FILTERS: Filter[] = [
  {
    key: RULE_FILTER_KEYS.STATUS,
    operator: FILTER_OPERATOR.IS,
    value: 'ACTIVE',
  },
];

export const getDefaultLiveRuleFilters = (): Filter[] => {
  const filters = getValidFilters(
    getLocalStorageJSON(LocalStorageKeys.LIVE_RULE_FILTERS),
    ALL_RULE_FILTER_OPTIONS,
  );
  return [
    ...DEFAULT_LIVE_RULE_FILTERS,
    ...filters.filter((i) => i.key !== RULE_FILTER_KEYS.STATUS),
  ];
};
