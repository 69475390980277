import { useSelector } from 'react-redux';

// Components
import { SidebarLabelValue } from 'app/modules/sidebar/components/SidebarLabelValue';

// Selectors
import { selectSidebarObject } from 'app/modules/sidebar/selectors';

export const SidebarAssignedTo = () => {
  const sidebarObject = useSelector(selectSidebarObject);

  const assignedToString =
    sidebarObject.assigned_to_name || sidebarObject.assigned_to || 'N/A';

  return <SidebarLabelValue label="Assigned to" value={assignedToString} />;
};
