import { call, takeLatest, put } from 'redux-saga/effects';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { CasesActionTypes } from 'app/modules/casesOld/models';

// Actions
import {
  editCaseSuccess,
  triggerCaseAction as action,
  triggerCaseActionSuccess,
} from 'app/modules/casesOld/actions';
import { retrieveActionTriggers } from 'app/modules/session/actions';

// API
import { triggerAction as triggerActionApi } from 'app/shared/api/cases';

const rootAction = CasesActionTypes.TRIGGER_CASE_ACTION;
function* triggerCaseAction({ payload }: ReturnType<typeof action>) {
  const config = {
    rootAction,
    request: call(triggerActionApi, payload),
    successToastMessage: `Case action submitted successfully`,
    errorToastMessage: 'Case action failed',
    success: function* onSuccess(result) {
      yield put(editCaseSuccess(result));
      yield put(triggerCaseActionSuccess(result));
    },
    error: function* onError() {
      // if we have a button error reload the buttons on the assumption that state got out of sync
      yield put(retrieveActionTriggers());
    },
  };
  yield call(makeSagaRequest, config);
}

export default function* watchTriggerCaseAction() {
  yield takeLatest(rootAction, triggerCaseAction);
}
