// Models
import { InvestigationType } from 'app/modules/investigations/models';

// Actions
import { reassignAlerts, triggerAlertAction } from 'app/modules/alerts/actions';
import { triggerCaseAction } from 'app/modules/casesOld/actions';

// This function is defined here to make introducing case reassignment easier later on
export const reassignInvestigations = reassignAlerts;

export const triggerActionTrigger = (type: InvestigationType) =>
  type === InvestigationType.ALERT ? triggerAlertAction : triggerCaseAction;
