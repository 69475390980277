import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// Components
import { SidebarLabelValue } from 'app/modules/sidebar/components/SidebarLabelValue';

// Selectors
import { selectSidebarSar } from 'app/modules/fincenSar/selectors';

// Utils
import { getLocalFormat as getLocalFormatHelper } from 'app/shared/utils/timeHelpers';

const getLocalFormat = (value: string | undefined | null) => {
  return value ? getLocalFormatHelper(value, 'LLL') : 'N/A';
};

export const SidebarFincenSARInfo = () => {
  const sidebarSar = useSelector(selectSidebarSar);

  const createdAt = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Created at"
        value={getLocalFormat(sidebarSar.created_at)}
      />
    );
  }, [sidebarSar.created_at]);

  const filedAt = useMemo(() => {
    return (
      <SidebarLabelValue
        label="Filed at"
        value={getLocalFormat(sidebarSar.filed_at)}
      />
    );
  }, [sidebarSar.filed_at]);

  const bsaTrackingID = useMemo(() => {
    const bsaTrackingIDString = sidebarSar.bsa_tracking_id || 'N/A';

    return (
      <SidebarLabelValue label="BSA tracking ID" value={bsaTrackingIDString} />
    );
  }, [sidebarSar.bsa_tracking_id]);

  const bsaID = useMemo(() => {
    const bsaIDString = sidebarSar.bsa_id || 'N/A';

    return <SidebarLabelValue label="BSA ID" value={bsaIDString} />;
  }, [sidebarSar.bsa_id]);

  return (
    <>
      {createdAt}
      {filedAt}
      {bsaTrackingID}
      {bsaID}
    </>
  );
};
