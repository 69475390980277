// Components
import {
  U21Spacer,
  U21Loading,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { Comment } from 'app/modules/comments/components/Comment';

// Models
import { CommentModel } from 'app/modules/comments/models';

interface OwnProps {
  comments: CommentModel[];
  loading?: boolean;
}
export const CommentList = ({ comments, loading }: OwnProps) => {
  if (loading) {
    return <U21Loading loading variant="graphic" />;
  }

  return (
    <U21Spacer spacing={4}>
      {!comments.length && (
        <U21Typography variant="body2">No comments</U21Typography>
      )}
      {comments.map((comment) => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </U21Spacer>
  );
};
