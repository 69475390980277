import createOrg from 'app/modules/admin/sagas/createOrg';
import retrieveOrg from 'app/modules/admin/sagas/retrieveOrg';
import retrieveOrgs from 'app/modules/admin/sagas/retrieveOrgs';
import editOrg from 'app/modules/admin/sagas/editOrg';
import editOrgKytConfig from 'app/modules/admin/sagas/editOrgKytConfig';
import retrieveDefaultRules from 'app/modules/admin/sagas/retrieveDefaultRules';

const sagaRegistry = [
  createOrg,
  editOrg,
  editOrgKytConfig,
  retrieveDefaultRules,
  retrieveOrg,
  retrieveOrgs,
];

export default sagaRegistry;
