// Models
import {
  CompositeQueryTreeNode,
  ConstantValue,
  DmObjectToFlag,
  DmOperator,
  FieldValue,
  IRSchema,
  QueryTreeNode,
  VariableValue,
} from 'app/modules/rulesAdvanced/types';
import {
  Condition,
  DropdownBuilderFormValues,
} from 'app/modules/detectionModels/components/DropdownBuilderForm/models';
import { Operand } from 'app/modules/rulesAdvanced/lib/SymbolTable';
import { FormApi } from 'final-form';

// Constants
import { ConditionalOperators } from 'app/modules/detectionModels/constants';

// NOTE: Dropdown Builder helper functions
// Extracted from helpers.tsx to avoid circular dependencies
export const generateEmptyIrRule = (object: DmObjectToFlag): IRSchema => {
  return {
    facts: [],
    rule_condition: {
      type: 'rule_condition_composite',
      operands: [],
      operator: 'AND',
    },
    object,
    field_to_flag: {
      type: 'FLAG_FIELD',
      field: `${object === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
      model: object.toLowerCase(),
      values: [],
    },
  };
};

export const isCompositeType = (
  condition: Condition | FieldValue | ConstantValue | VariableValue,
): condition is Condition =>
  condition.type === 'rule_condition_composite' ||
  condition.type === 'COMPOSITE';
export const convertConditionsToQueryTreeNodes = (
  condition: Condition | FieldValue | ConstantValue | VariableValue | null,
): QueryTreeNode => {
  if (!condition) return null;
  if (isCompositeType(condition)) {
    const { type, operator, leftOperand, rightOperand, betweenOperand } =
      condition;

    const operands = [
      leftOperand,
      convertConditionsToQueryTreeNodes(rightOperand),
    ];
    if (betweenOperand)
      operands.push(convertConditionsToQueryTreeNodes(betweenOperand));

    return {
      type,
      operands,
      operator,
      meta_ui: {
        input_choice: 'dropdown',
        ui_operator: operator,
      },
    };
  }
  return condition;
};

export const generateIRSchemaFromForm = (
  value: DropdownBuilderFormValues,
): IRSchema => {
  const {
    conditions,
    eventSubTypes,
    facts,
    objectToFlag,
    primaryConditionGroupAndOrOperator,
  } = value;
  const ruleCondition: CompositeQueryTreeNode = {
    type: 'rule_condition_composite',
    operator: primaryConditionGroupAndOrOperator ?? 'AND',
    operands: [],
  };

  ruleCondition.operands = conditions.map((condition) =>
    convertConditionsToQueryTreeNodes(condition),
  );

  return {
    facts,
    field_to_flag: {
      type: 'FLAG_FIELD',
      field: `${objectToFlag === 'TXN_EVENT' ? 'internal_txn_type' : 'action_type'}`,
      model: objectToFlag.toLowerCase(), // TODO: create explicit mapping object
      values: eventSubTypes,
    },
    object: objectToFlag,
    rule_condition: ruleCondition,
  };
};

export const generateNestedOperandKey = (groupIndex: number[]) => {
  let operatorKey: string = ``;
  groupIndex.forEach((gIndex) => {
    operatorKey = operatorKey.concat(
      `${operatorKey === '' ? '' : '.'}operands[${gIndex}]`,
    );
  });
  return operatorKey;
};

export const extractFactNames = (
  ruleCondition: CompositeQueryTreeNode,
): Set<string> => {
  const foundFacts: QueryTreeNode[] = ruleCondition.operands.filter(
    (operand) => operand?.type === 'VARIABLE',
  );
  const factNames: Set<string> = new Set();
  if (foundFacts.length) {
    foundFacts.forEach((variable) => {
      if (variable?.type === 'VARIABLE') factNames.add(variable.name);
    });
  }
  return factNames;
};

export const extractFactNamesFromGroup = (
  groupCondition: CompositeQueryTreeNode,
): Set<string> => {
  const factNames: Set<string> = new Set();
  groupCondition.operands.forEach((operand) => {
    if (operand?.type === 'rule_condition_composite') {
      if (['AND', 'OR'].includes(operand.operator))
        extractFactNamesFromGroup(operand).forEach((factName) =>
          factNames.add(factName),
        );
      // NOTE: still a "group" condition
      else
        extractFactNames(operand).forEach((factName) =>
          factNames.add(factName),
        );
    }
  });
  return factNames;
};

export const comparisonOperatorSideEffect = (
  operand: Operand | undefined,
): { uiOperator: DmOperator; operator: DmOperator } | undefined => {
  if (!operand) return undefined;
  let res;
  if (
    operand.dropdownValues?.length ||
    ['enum', 'multiselect', 'select'].includes(operand.operandType)
  ) {
    res = {
      uiOperator: ConditionalOperators.IN,
      operator: ConditionalOperators.ANY_IN,
    };
  } else if (['date', 'datetime'].includes(operand.operandType)) {
    res = {
      uiOperator: ConditionalOperators.GT,
      operator: ConditionalOperators.GT,
    };
  } else {
    res = {
      uiOperator: ConditionalOperators.EQ,
      operator: ConditionalOperators.EQ,
    };
  }

  return res;
};

export const resetRTMDropdownFormValues = (
  form: FormApi<DropdownBuilderFormValues, Partial<DropdownBuilderFormValues>>,
  objectToFlag: DmObjectToFlag,
) => {
  form.change('objectToFlag', objectToFlag);
  form.change('eventSubTypes', []);
  form.change('primaryConditionGroupAndOrOperator', 'AND');
  form.change('conditions', [
    {
      type: 'rule_condition_composite',
      operator: 'EQ',
      leftOperand: {
        type: 'FIELD',
        field: '',
        datatype: '',
        model: '',
      },
      rightOperand: null,
      betweenOperand: null,
    },
  ]);
  form.change('facts', []);
};
