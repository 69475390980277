// Helpers
import {
  createCheckboxItems,
  createState,
  createUnknownName,
} from 'app/modules/fincenSar/helpers';
import { createDropdownItems } from 'app/shared/helpers';

// Models
import {
  SarInput,
  SarDropdownOptions,
  SarCheckboxOptions,
  SarUnknownCheckbox,
  SarOptions,
  Page3ItemModel,
  Page3ContentModel,
} from 'app/modules/fincenSar/models';

// Constants
import { initialState as formOfIdInitialState } from 'app/modules/fincenSar/constants/03-formOfId';
import { initialState as relationshipInitialState } from 'app/modules/fincenSar/constants/03-relationship';
import { initialState as accountNumbersInitialState } from 'app/modules/fincenSar/constants/03-accountNumbers';
import { naicsCodes } from 'app/modules/fincenSar/constants/naiscodes';

export const PAGE_NUMBER = '3';

// ============ 3.Check ======================
export const itemsUnavailableOptions = [
  'If entity',
  'All critical information unavailable (does not include item 24)',
];
export const checkIfInformationIsUnavailable: SarCheckboxOptions = {
  title: `3. Check if information is unavailable`,
  name: '3_entityInformationUnknown',
  options: itemsUnavailableOptions,
  initialValue: createCheckboxItems(itemsUnavailableOptions),
  popupContent: `Item 3. Check "If entity" if the subject 
  described in Part 1 is an entity and not an individual. Check "All critical information unavailable" if the 
  information in Part I is unavailable for all critical subject items except Item 27. These critical 
  items (4, 5, 11, 12, 13, 14, 15, 16, 17, and 18) and their associated “Unknown” fields must be left blank. 
  Checking this box will cause these items to become inactive. Do not check this box if
  information for even one of these items is available. Instead,
  enter the available information and check the "Unknown"
  boxes in each item for which the information is unavailable.`,
};
// ====================================================

// ============ 4. Legal Name ======================
export const legalName: SarInput = {
  title: `4. Individual's last name or entity's legal name`,
  name: '4_legalName',
  initialValue: '',
  maxLen: 150,
  popupContent: `Item 4 - Individual's last name or entity's legal name. Enter
  the individual‘s last name or the entity's legal name. The
  legal name is the name on the articles of incorporation or
  other document that established the entity. Do not
  abbreviate names unless an abbreviation is part of the legal
  name. Check "Unknown" if the individual‘s last name or the
  entity's legal name is unknown.`,
};

export const legalNameUnknown: SarUnknownCheckbox = {
  title: '',
  name: '4_legalNameUnknown',
  initialValue: false,
};
// ====================================================

// ============ 5. First Name ======================
export const firstName: SarInput = {
  title: `5. First Name`,
  name: '5_firstName',
  initialValue: '',
  maxLen: 35,
  popupContent: `Item 5 - First name. If the subject is an individual, enter the subject's first name. Check "Unknown" if the first name of the subject is unknown.`,
};

export const firstNameUnknown: SarUnknownCheckbox = {
  title: '',
  name: '5_firstNameUnknown',
  initialValue: false,
};
// ====================================================

// ============ 6. Middle name/initial ======================
export const middleName: SarInput = {
  title: `6. Middle name/initial`,
  name: '6_middleName',
  initialValue: '',
  popupContent: `Item 6 - Middle name/initial. If the subject is an individual,
  enter the subject's middle name or middle initial. Leave this
  item blank if the subject's middle name or middle initial is
  unknown.`,
};
// ====================================================

// ============ 7. Suffix ======================
export const suffix: SarInput = {
  title: `7. Suffix`,
  name: '7_suffix',
  initialValue: '',
  popupContent: `Item 7 - Suffix. If the subject is an individual, enter the
  subject‘s suffix such as JR, SR, III, etc. Leave this item blank
  if the suffix is unknown or the individual’s legal name does
  not include a suffix.
`,
};
// ====================================================

// ============ 8. Gender ======================
export const gender: SarDropdownOptions = {
  title: `8. Gender`,
  name: `8_gender`,
  options: [
    {
      key: 'MALE',
      value: 'MALE',
      text: 'Male',
    },
    {
      key: 'FEMALE',
      value: 'FEMALE',
      text: 'Female',
    },
    {
      key: 'UNKNOWN',
      value: 'UNKNOWN',
      text: 'Unknown',
    },
  ],
  initialValue: '',
  popupContent: `Item 8 - Gender. If the subject is an individual, select the
  appropriate gender option if known. Select "Unknown" if the
  subject‘s gender is unknown. This item does not create an
  obligation for a financial institution to collect gender
  information when such collection would be in conflict with
  the financial institution’s obligations under any other federal
  law.`,
};
// ====================================================

// ============ 19. Date of birth ======================
export const dateOfBirth: SarInput = {
  title: `19. Date of birth`,
  name: '19_dateOfBirth',
  initialValue: '',
  popupContent: `Item 19 - Date of birth. If the subject is an individual enter
  the subject's data of birth in MM/DD/YYYY format where
  MM = Month, DD = Day, and YYYY = Century and Year (e.g.
  01/25/1970). If the birth day and/or month is (are) unknown,
  enter “00” for the unknown day and/or month. For example,
  a date of birth with an unknown day in February 1978 would
  be entered as 02/00/1978. Do not enter zeros for the year if
  the year is unknown. Check "Unknown" if the year of birth
  or the complete date of birth is unknown.`,
};

// do not change `name` field, see https://github.com/u21/patronus/issues/1850
export const dateOfBirthUnknown: SarUnknownCheckbox = {
  title: '',
  name: '18_dateOfBirthUnknown',
  initialValue: false,
};
// ====================================================

// ============ 9. Alternative Name ===================
export const alternativeName: SarOptions = {
  title: `9. Alternate name, e.g., AKA - individual or trade name, DBA - entity`,
  name: '9_alternativeName',
  initialValue: [''],
  popupContent: `Item 9 - Alternate name. Enter the individual‘s also known
  as (AKA) name or the entity's doing business as (DBA) name
  if different from the name entered in Items 4-7. Do not
  include the acronyms DBA or AKA with the alternate name.
  Multiple AKA/DBA names may be completed if multiple
  subject alternate names are known.`,
};
// ====================================================

// ============ 10 Occupation or type of business ======================
export const occupation: SarInput = {
  title: `10. Occupation or type of business`,
  name: '10_occupation',
  initialValue: '',
  popupContent: `Item 10 - Occupation or type of business. Record the
  occupation, profession, or type of business of the individual
  or entity recorded in Part |. Use specific descriptions such
  as doctor, carpenter, attorney, used car dealership, plumber,
  truck driver, hardware store, etc. Do not use non-descriptive
  items such as businessman, merchant, retailer, retired, or
  self—employed. If words like seIf—employed, unemployed, or
  retired are used, add the current or former profession if
  known (e.g. seIf-employed building contractor, retired
  teacher, or unemployed carpenter). If the occupation or
  business activity can be described in more detail, include the
  additional information in Part V.`,
};
// ====================================================

// ============ 10a. NAICS Code ======================
export const naicsCode: SarDropdownOptions = {
  title: `10a. NAICS Code`,
  name: `10a_naicsCode`,
  options: createDropdownItems(naicsCodes),
  initialValue: '',
  popupContent: `Item 10a - NAICS Code. Select from the attached list the
  North American Industry Classification System Code that
  most-closely matches the occupation or type of business
  entered in Item 10.`,
};
// ====================================================

// =========== 16. Taxpayer Identification Number ==============
export const taxPayerIdentificationNumberUnknown: SarUnknownCheckbox = {
  title: 'Unknown',
  name: '16_taxPayerIdentificationNumberUnknown',
  initialValue: false,
};

export const taxPayerIdentificationNumber: SarInput = {
  title: `16. Taxpayer Identification Number`,
  name: '16_taxPayerIdentificationNumber',
  initialValue: '',
  popupContent: `Item 16 - TIN. Enter the Taxpayer Identification Number (TIN)
  or foreign equivalent of the subject. Enter all identifying
  numbers as a single text string without formatting or special
  characters such as hyphens or periods. An identifying
  number in the format NNN-NN-NNNN would be entered as
  NNNNNNNNN. Check "Unknown" if the TIN is unknown.
  Item 17 TIN type is required if TIN is known.`,
};
// =============================================================

// =========== 17. Taxpayer Identification Type ==============
const taxPayerTypes = ['EIN', 'SSN-ITIN', 'Foreign'];
const taxpayerTypeOptions = createDropdownItems(taxPayerTypes);
export const taxPayerIdentificationType: SarDropdownOptions = {
  title: `17. Taxpayer Identification Number Type`,
  name: `17_taxPayerIdentificationType`,
  initialValue: '',
  options: taxpayerTypeOptions,
  popupContent: `Item 17 - TIN type. Select "EIN" if the subject has a U.S.
  Employer Identification Number (EIN). If "EIN" is selected,
  then Item 3 “If entity” must also be checked because an EIN
  is assigned only to an entity. Select "SSN/ITIN" if the
  subject has a U.S. Social Security Number (SSN) or
  Individual Taxpayer Identification Number (ITIN). Select
  "Foreign" if the subject has a foreign TIN of any type and
  record in Part V the name of the country that issued the
  foreign TIN. Enter the subject’s TIN type (if known) even if
  "Unknown" is checked for the actual TIN. If a 9-digit TIN is
  entered but the TIN type is unknown, check "EIN" if the
  subject is an entity or “SSN-ITIN” if the subject is an
  individual. TINs that are not 9 digits are presumed to be
  foreign; therefore "Foreign" should be selected.`,
};
// =============================================================

// ============ 21 Phone Number ======================
export const phoneNumber = {
  title: `21. Phone Number`,
  name: '21_phoneNumber',
  initialValue: '',
  popupContent: `Item 21 - Phone number. Enter the subject's U.S. or foreign
  telephone number and extension in the space provided.
  Record all telephone numbers, both foreign and domestic,
  as a single number string without formatting or special
  characters such as parentheses or hyphens. For example, a
  number in the format (NNN) NNN-NNNN would be recorded
  as NNNNNNNNNN. Provide the telephone extension
  number if known. Telephone numbers that are part of the
  North American Numbering Plan used by the U.S., Canada,
  many Caribbean countries, and current/former U.S. Pacific
  island protectorates must consist of an area code and
  seven-digit telephone number. Other foreign telephone
  numbers should include the country number code. If only a
  partial telephone number is known record that number in the
  phone number item and explain in Part V that the entry is a
  partial number. Leave blank any part of the telephone
  number that is unknown. If the telephone extension and/or
  telephone type for the subject are known, then telephone
  number must be recorded. Multiple sets of telephone
  contact information may be completed if multiple telephone
  numbers for the subject are known.`,
};
// ====================================================

// ============ 20 Phone Number Type ======================
const phoneNumberTypeOptions = ['Home', 'Work', 'Mobile', 'Fax'];
export const phoneNumberType: SarDropdownOptions = {
  title: `20. Type`,
  name: '20_phoneNumberType',
  initialValue: '',
  popupContent: `Item 20 Phone Type - Indicate the type of telephone number
  provided (home, work, mobile, or fax) by selecting the
  appropriate entry.`,
  options: createDropdownItems(phoneNumberTypeOptions),
};
// ====================================================

// ============== 20. - 21. Config ====================
const phoneNumberValues = [
  {
    [phoneNumberType.name]: phoneNumberType.initialValue,
    [phoneNumber.name]: phoneNumber.initialValue,
  },
];

export const phoneNumberConfig = {
  name: '20-21_phoneNumberValues',
  initialValue: phoneNumberValues as any,
  title: '',
};
// =====================================================

// ============ 22. Email Address ===================
export const emailAddress: SarOptions = {
  title: `22. Email address`,
  name: '22_emailAddress',
  initialValue: [''],
  popupContent: `Item 22 - E-mail address. Enter the subject's E-mail address
  if known. Include all formatting, punctuation, and special
  characters in the E-mail address. Leave this item blank if the
  E-mail address is unknown. An Email address must contain
  the “@” sign and a period in the text following the “@” sign.
  Examples: johndoe@business.com or
  richardroephd@co||ege.edu. Multiple E-mail addresses may
  be completed if multiple E-mail addresses for the subject are
  known.`,
};
// ====================================================

// ============ 22a. Website URL ===================
export const websiteURL: SarOptions = {
  title: `22a. Website (URL) address`,
  name: '22a._websiteURL',
  initialValue: [''],
  popupContent: `Item 22a - Website (URL) address. Enter the subject's
  website URL (Uniform Resource Locator) if known. Include
  all punctuation and special characters present in the URL.
  Leave this item blank if the URL is unknown. Multiple
  website addresses may be completed if multiple website
  addresses for the subject are known.`,
};
// ====================================================

// =========== 23. Corroborative statement to filer? ==============
const statementToFilerTypes = ['Yes', 'No'];
const statementToFilerOptions = createDropdownItems(statementToFilerTypes);
export const statementToFiler: SarDropdownOptions = {
  title: `23. Corroborative statement to filer?`,
  name: `23_statementToFiler`,
  initialValue: '',
  options: statementToFilerOptions,
  popupContent: `Item 23 - Corroborative statement to filer. Select "Yes" if the
  subject has made a statement to the filer admitting to
  involvement in or otherwise substantiating the suspicious
  activity. Record in Part V the nature of the corroborative
  statement and how it was recorded, i.e. written confession,
  verbal statement, etc. Select "No" if no corroborative
  statement was made to the filer.`,
};
// =============================================================

// =========== 28. Subject's role in suspicious activity ==============
const roleInActivityTypes = ['Purchaser/Sender', 'Payee/Receiver', 'Both'];
const roleInActivityOptions = createDropdownItems(roleInActivityTypes);
export const roleInActivity: SarDropdownOptions = {
  title: `28. Subject's role in suspicious activity`,
  name: `28_roleInActivity`,
  initialValue: '',
  options: roleInActivityOptions,
  popupContent: `Item 28 - Subject‘s role in suspicious activity. Record the
  subject‘s role in the suspicious activity by selecting the
  appropriate option. Select "Purchaser/Sender" if the subject
  purchased or sent the financial instrument(s) or product(s)
  involved in the suspicious activity. Select "Payee/Receiver"
  if the subject was the payee or receiver of the instrument(s)
  or product(s). Select "Both" if the subject was both a
  purchaser/sender and payee/receiver. Instruments and
  products are not limited to the instruments and products
  listed in Items 45 and 46. Leave Item 28 blank if none of the
  roles apply to the suspicious activity.`,
};
// =============================================================

const addressPopupContent = `Items 11 - 15. Subject address items. Enter the permanent street address, city, state/territory/province (U.S.lCanada/
Mexico only), ZIP Code or foreign postal code, and country
for the subject. Complete any address item that is known
and check "Unknown" for any required address item that is
not known. Provide the apartment number or suite number, if
known, following the street address. A non-location address
such as a post office box or rural route number should be
used only if no other street address information is available.
ZIP Codes must be five or nine digits. ZIP Codes and
foreign postal codes must be entered without formatting or
special characters such as spaces or hyphens. For
example, the ZIP Code 12354-6120 would be entered as
123546120. The foreign postal code HKW 702 would be
entered HKW702. For other foreign addresses enter the
street address, city, postal code, and two letter country
abbreviation or address equivalent. If a foreign address
contains other address information that does not conform to
the SAR address format, record equivalent address
information in the SAR address items and the full address in
Part V. No abbreviations are permitted in city names, which
must be completely spelled out. A U.S. city name must
match the city name used by the U.S. Postal Service for the
associated state and ZIP Code. If multiple branches are
involved in the suspicious activity, complete a set of branch
address items for each branch. Multiple sets of address
fields may be completed if multiple present and past subject
addresses are known.`;

// =================== 11. Address ====================
export const address: SarInput = {
  title: `11. Address`,
  name: '11_address',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const addressUnknown = {
  name: createUnknownName(address.name),
  initialValue: false,
};
// ========================================================

// ============== 12. City ==============================
export const city: SarInput = {
  title: `12. City`,
  name: '12_city',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const cityUnknown = {
  name: createUnknownName(city.name),
  initialValue: false,
};
// ========================================================

// ============== 13. State ==============================
export const state: SarInput = {
  title: `13. State`,
  name: '13_state',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const stateUnknown = {
  name: createUnknownName(state.name),
  initialValue: false,
};
// ========================================================

// ============== 14. ZIP/Postal Code ==============================
export const zip: SarInput = {
  title: `14. ZIP/Postal Code `,
  name: '14_zipcode',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const zipUnknown = {
  name: createUnknownName(zip.name),
  initialValue: false,
};
// ========================================================

// ============== 15. Country ==============================
export const country: SarInput = {
  title: `15. Country`,
  name: '15_country',
  initialValue: '',
  popupContent: addressPopupContent,
};

export const countryUnknown = {
  name: createUnknownName(country.name),
  initialValue: false,
};
// ========================================================

// ============= 11. - 15. Config ========================
const addressInformation = [
  {
    ...createState([address, city, state, zip, country]),
    [addressUnknown.name]: false,
    [cityUnknown.name]: false,
    [stateUnknown.name]: false,
    [zipUnknown.name]: false,
    [countryUnknown.name]: false,
  },
];

export const addressInformationConfig: SarOptions = {
  initialValue: addressInformation as any,
  name: '11-15_addressInformation',
  title: '',
};
// ========================================================

const allState = [
  checkIfInformationIsUnavailable,
  legalName,
  legalNameUnknown,
  firstName,
  firstNameUnknown,
  middleName,
  suffix,
  gender,
  dateOfBirth,
  dateOfBirthUnknown,
  alternativeName,
  occupation,
  naicsCode,
  taxPayerIdentificationNumberUnknown,
  taxPayerIdentificationNumber,
  taxPayerIdentificationType,
  emailAddress,
  websiteURL,
  statementToFiler,
  roleInActivity,
  addressInformationConfig,
  phoneNumberConfig,
];

export const relationshipConfig = {
  name: '24_relationships',
  initialValue: [relationshipInitialState],
};

export const formsOfIdConfig = {
  name: '18_formsOfId',
  initialValue: [formOfIdInitialState],
};

export const accountNumbersConfig = {
  name: '27_accountNumbers',
  initialValue: [accountNumbersInitialState],
};

const restOfState = {
  [addressInformationConfig.name]: addressInformationConfig.initialValue,
  [formsOfIdConfig.name]: formsOfIdConfig.initialValue,
  [relationshipConfig.name]: relationshipConfig.initialValue,
  [accountNumbersConfig.name]: accountNumbersConfig.initialValue,
};
export const initialState = {
  ...createState(allState),
  ...restOfState,
} as unknown as Page3ItemModel;

export const initialContentState: Page3ContentModel = {
  forms: [initialState],
};
